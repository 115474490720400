@import "@webpixels/css/variables/colors";

$gray-50:  #FAFAFA;    // Alternate background
$gray-100: #F5F9FC;    // Base background
$gray-200: #E7EAF0;
$gray-300: #CFD6DF;
$gray-400: #ABB6C5;
$gray-500: #8898A9;    // Disabled text
$gray-600: #6B7B93;    // Placeholder text
$gray-700: #525F7F;    // Text content
$gray-800: #2D3748;    // Text content old(44444e)
$gray-900: #16192C;    // Text content
$white: #FFF;

// Theme colors
$primary: 	#3D5A80;
$secondary: #98C1D9;
$tertiary: 	#EE6C4D;
$dark: 			#252422;

// Sections
// Colors used for content area background colors
$surface-secondary: #f1f5f9;
// $surface-secondary: tint-color($secondary, 90%);

// Typography
$font-display: 					'Satoshi', sans-serif;
$headings-font-family: 	$font-display;

// Prefix 
$prefix: x-;

// // Pagination
// $pagination-color:                  $gray-700;
// // $pagination-border-color:           $border-color;
// $pagination-hover-color:            $white;
// $pagination-hover-bg:               $gray-900;
// $pagination-hover-border-color:     $gray-900;
// $pagination-focus-color:            $white;
// $pagination-focus-bg:               $gray-900;
// $pagination-active-bg:              $gray-900;
// $pagination-active-border-color:    $pagination-active-bg;

// Modal
$modal-backdrop-bg:                 rgba($gray-900, .2);
$modal-backdrop-opacity:            1;

// Table
$table-border-color: var(--x-border-color);

$table-light-th-bg: $white;
$table-light-th-color: $gray-600;
$table-light-action-color: $gray-800;

// Grid
// $grid-gutter-width: 2.5rem;

