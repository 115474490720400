/*!
 * Webpixels CSS v2.0.0-beta.22 (https://webpixels.io/)
 * Copyright 2024 Webpixels
 * Licensed under MIT (https://github.com/webpixels/css/blob/main/LICENSE)
 */

// Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// Include any default variable overrides here
@import "./variables";

// Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// Include remainder of required parts
@import "bootstrap/scss/mixins";

// Webpixels extra styles
@import "./extras";