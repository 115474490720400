//
// Input group
//

.input-group {
    @include box-shadow($input-box-shadow);
    @include border-radius($input-border-radius);

    .form-control,
    .form-select,
    .dropdown {
        box-shadow: none;
    }

    // Group focus
    &:focus-within {
        @if $enable-shadows {
            @include box-shadow($input-box-shadow, $input-focus-box-shadow);
        } @else {
            // Avoid using mixin so we can pass custom focus shadow properly
            box-shadow: $input-focus-box-shadow;
        }

        .input-group-text {
            border-color: $input-focus-border-color;
        }

        .form-control,
        .form-select,
        .dropdown {
            box-shadow: none;
            border-color: $input-focus-border-color;
        }
    }
}

.input-group-text {
    transition: $input-transition;
}


// Flush

.input-group-flush {
    .form-control,
    .input-group-text {
        border: 0 !important;
    }
}


// Inline

.input-group-inline {
    position: relative;
    @include border-radius($input-border-radius);

    // Sizing
    &.input-group-sm {
        @include border-radius($input-border-radius-sm);
    }

    &.input-group-lg {
        @include border-radius($input-border-radius-lg);
    }

    .input-group-text {
        background: $input-bg;
    }

    // When uses .form-control-muted
    &:has(.form-control-muted) {
        .input-group-text {
            background-color: $input-muted-bg;
            border-color: $input-muted-border-color;
        }
    }

    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: 0 !important;
        border-left: 0;
        padding-left: $input-padding-x;
    }

    > :not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback)  {
        margin-right: 0 !important;
        border-right: 0;
        padding-right: 0;
    }

    &:focus-within {
        .form-control-muted {
            border-color: $input-muted-focus-border-color;
        }

        &:has(.form-control-muted) {
            .input-group-text {
                background-color: $input-muted-focus-bg;
                border-color: $input-muted-focus-border-color;
            }
        }
    }
}
