//
// Form stacked
//


.form-group-stacked {
    .form-control,
    .form-select {
        position: relative;
        box-sizing: border-box;
        height: auto;

        &:focus {
            z-index: 2;
        }
    }

    > :first-child {
        .form-control,
        .form-select {
            margin-bottom: -1px;
            @include border-bottom-radius(0);
        }
    }

    > :last-child {
        .form-control,
        .form-select {
            @include border-top-radius(0);
        }
    }

    > .row {
        margin: 0;

        > :first-child,
        > :last-child {
            padding: 0;
        }

        > :first-child {
            .form-control,
            .form-select {
                @include border-end-radius(0);
            }

            .form-control,
            .form-select {
                &:not(:focus) {
                    border-right-color: transparent;
                }
            }
        }
        > :last-child {
            .form-control,
            .form-select {
                @include border-start-radius(0);
            }
        }
    }
}