@mixin mauve {
  $primary: #6D213C;
  $secondary: #FAFF70;
  $tertiary: #E3C16F;
  $dark: $gray-900;

  color-scheme: light;

  --x-primary: #{$primary};
  --x-primary-rgb: #{to-rgb($primary)};
  --x-secondary: #{$secondary};
  --x-secondary-rgb: #{to-rgb($secondary)};
  --x-tertiary: #{$tertiary};
  --x-tertiary-rgb: #{to-rgb($tertiary)};
  --x-dark: #{$dark};
  --x-dark-rgb: #{to-rgb($dark)};

  --x-link-color: var(--x-primary);
  --x-link-hover-color: #{darken($primary, 5%)};
  
  .btn-primary {
    --x-btn-bg: var(--x-primary);
    --x-btn-color: var(--x-white);
    --x-btn-border-color: var(--x-primary);
    --x-btn-hover-bg: #{darken($primary, 5%)};
    --x-btn-hover-color: var(--x-white);
    --x-btn-hover-border-color: #{darken($primary, 5%)};
    --x-btn-active-bg: #{darken($primary, 8%)};
    --x-btn-active-color: var(--x-white);
    --x-btn-active-border-color: #{darken($primary, 8%)};
  }

  .btn-secondary {
    --x-btn-bg: var(--x-secondary);
    --x-btn-color: var(--x-white);
    --x-btn-border-color: var(--x-secondary);
    --x-btn-hover-bg: #{darken($secondary, 5%)};
    --x-btn-hover-color: var(--x-white);
    --x-btn-hover-border-color: var(--x-btn-hover-bg);
    --x-btn-active-bg: #{darken($secondary, 8%)};
    --x-btn-active-color: var(--x-white);
    --x-btn-active-border-color: var(--x-btn-active-bg);
  }

  .btn-tertiary {
    --x-btn-bg: var(--x-tertiary);
    --x-btn-color: var(--x-white);
    --x-btn-border-color: var(--x-tertiary);
    --x-btn-hover-bg: #{darken($tertiary, 5%)};
    --x-btn-hover-color: var(--x-white);
    --x-btn-hover-border-color: var(--x-btn-hover-bg);
    --x-btn-active-bg: #{darken($tertiary, 8%)};
    --x-btn-active-color: var(--x-white);
    --x-btn-active-border-color: var(--x-btn-active-bg);
  }

  .btn-dark {
    --x-btn-bg: var(--x-dark);
    --x-btn-color: var(--x-white);
    --x-btn-border-color: var(--x-dark);
    --x-btn-hover-bg: #{darken($dark, 5%)};
    --x-btn-hover-color: var(--x-white);
    --x-btn-hover-border-color: var(--x-btn-hover-bg);
    --x-btn-active-bg: #{darken($dark, 8%)};
    --x-btn-active-color: var(--x-white);
    --x-btn-active-border-color: var(--x-btn-active-bg);
  }

  .btn-neutral {
    --x-btn-hover-bg: var(--x-dark);
    --x-btn-hover-border-color: var(--x-dark);
    --x-btn-hover-color: #{color-contrast($dark)};
  }
}