/**
 * Theme: default
 */

// Variables
@import "ff/variables";

// Themes
@import "ff/main";
@import "ff/flame";
@import "ff/red";
@import "ff/green";
@import "ff/blue";
@import "ff/purple";
@import "ff/yellow";
@import "ff/mauve";
@import "ff/pink";
