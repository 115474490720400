:root, [data-bs-theme="light"] {
  --x-blue: #3b82f6;
  --x-indigo: #6366f1;
  --x-purple: #8b5cf6;
  --x-pink: #ec4899;
  --x-red: #ef4444;
  --x-orange: #f97316;
  --x-yellow: #eab308;
  --x-green: #22c55e;
  --x-teal: #14b8a6;
  --x-cyan: #06b6d4;
  --x-black: #000;
  --x-white: #fff;
  --x-gray: #6b7b93;
  --x-gray-dark: #2d3748;
  --x-gray-50: #f8fafc;
  --x-gray-100: #f1f5f9;
  --x-gray-200: #e2e8f0;
  --x-gray-300: #cbd5e1;
  --x-gray-400: #94a3b8;
  --x-gray-500: #64748b;
  --x-gray-600: #475569;
  --x-gray-700: #334155;
  --x-gray-800: #1e293b;
  --x-gray-900: #0f172a;
  --x-gray-950: #020617;
  --x-primary: #3d5a80;
  --x-secondary: #98c1d9;
  --x-success: #22c55e;
  --x-info: #06b6d4;
  --x-warning: #f97316;
  --x-danger: #ef4444;
  --x-light: #f1f5f9;
  --x-dark: #252422;
  --x-primary-rgb: 61, 90, 128;
  --x-secondary-rgb: 152, 193, 217;
  --x-success-rgb: 34, 197, 94;
  --x-info-rgb: 6, 182, 212;
  --x-warning-rgb: 249, 115, 22;
  --x-danger-rgb: 239, 68, 68;
  --x-light-rgb: 241, 245, 249;
  --x-dark-rgb: 37, 36, 34;
  --x-primary-text-emphasis: #182433;
  --x-secondary-text-emphasis: #3d4d57;
  --x-success-text-emphasis: #0e4f26;
  --x-info-text-emphasis: #024955;
  --x-warning-text-emphasis: #642e09;
  --x-danger-text-emphasis: #601b1b;
  --x-light-text-emphasis: #525f7f;
  --x-dark-text-emphasis: #525f7f;
  --x-primary-bg-subtle: #d8dee6;
  --x-secondary-bg-subtle: #eaf3f7;
  --x-success-bg-subtle: #d3f3df;
  --x-info-bg-subtle: #cdf0f6;
  --x-warning-bg-subtle: #fee3d0;
  --x-danger-bg-subtle: #fcdada;
  --x-light-bg-subtle: #fafcfe;
  --x-dark-bg-subtle: #abb6c5;
  --x-primary-border-subtle: #b1bdcc;
  --x-secondary-border-subtle: #d6e6f0;
  --x-success-border-subtle: #a7e8bf;
  --x-info-border-subtle: #9be2ee;
  --x-warning-border-subtle: #fdc7a2;
  --x-danger-border-subtle: #f9b4b4;
  --x-light-border-subtle: #e7eaf0;
  --x-dark-border-subtle: #8898a9;
  --x-white-rgb: 255, 255, 255;
  --x-black-rgb: 0, 0, 0;
  --x-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --x-font-monospace: var(--x-font-monospace);
  --x-gradient: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
  --x-body-font-family: var(--x-font-sans-serif);
  --x-body-font-size: 1rem;
  --x-body-font-weight: 400;
  --x-body-line-height: 1.625;
  --x-body-color: #525f7f;
  --x-body-color-rgb: 82, 95, 127;
  --x-body-bg: #fff;
  --x-body-bg-rgb: 255, 255, 255;
  --x-emphasis-color: #16192c;
  --x-emphasis-color-rgb: 22, 25, 44;
  --x-secondary-color: rgba(82, 95, 127, .75);
  --x-secondary-color-rgb: 82, 95, 127;
  --x-secondary-bg: #f5f9fc;
  --x-secondary-bg-rgb: 245, 249, 252;
  --x-tertiary-color: rgba(82, 95, 127, .5);
  --x-tertiary-color-rgb: 82, 95, 127;
  --x-tertiary-bg: #fafafa;
  --x-tertiary-bg-rgb: 250, 250, 250;
  --x-heading-color: #16192c;
  --x-link-color: #3d5a80;
  --x-link-color-rgb: 61, 90, 128;
  --x-link-decoration: none;
  --x-link-hover-color: #344d6d;
  --x-link-hover-color-rgb: 52, 77, 109;
  --x-link-hover-decoration: none;
  --x-code-color: #ec4899;
  --x-highlight-color: #525f7f;
  --x-highlight-bg: #fcf8e3;
  --x-border-width: 1px;
  --x-border-style: solid;
  --x-border-color: #e7eaf0;
  --x-border-color-translucent: rgba(0, 0, 0, .176);
  --x-border-radius: .375rem;
  --x-border-radius-sm: .25rem;
  --x-border-radius-lg: .5rem;
  --x-border-radius-xl: 1rem;
  --x-border-radius-xxl: 2rem;
  --x-border-radius-2xl: var(--x-border-radius-xxl);
  --x-border-radius-pill: 50rem;
  --x-box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-sm: 0px 1px 1px 0px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-lg: 0px 6px 6px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, .075);
  --x-focus-ring-width: .15rem;
  --x-focus-ring-opacity: .25;
  --x-focus-ring-color: var(--x-primary-bg-subtle);
  --x-form-valid-color: #22c55e;
  --x-form-valid-border-color: #22c55e;
  --x-form-invalid-color: #ef4444;
  --x-form-invalid-border-color: #ef4444;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --x-body-color: #cfd6df;
  --x-body-color-rgb: 207, 214, 223;
  --x-body-bg: #16192c;
  --x-body-bg-rgb: 22, 25, 44;
  --x-emphasis-color: #fff;
  --x-emphasis-color-rgb: 255, 255, 255;
  --x-secondary-color: rgba(207, 214, 223, .75);
  --x-secondary-color-rgb: 207, 214, 223;
  --x-secondary-bg: #2d3748;
  --x-secondary-bg-rgb: 45, 55, 72;
  --x-tertiary-color: rgba(207, 214, 223, .5);
  --x-tertiary-color-rgb: 207, 214, 223;
  --x-tertiary-bg: #22283a;
  --x-tertiary-bg-rgb: 34, 40, 58;
  --x-primary-text-emphasis: #8b9cb3;
  --x-secondary-text-emphasis: #c1dae8;
  --x-success-text-emphasis: #7adc9e;
  --x-info-text-emphasis: #6ad3e5;
  --x-warning-text-emphasis: #fbab73;
  --x-danger-text-emphasis: #f58f8f;
  --x-light-text-emphasis: #f5f9fc;
  --x-dark-text-emphasis: #cfd6df;
  --x-primary-bg-subtle: #0c121a;
  --x-secondary-bg-subtle: #1e272b;
  --x-success-bg-subtle: #072713;
  --x-info-bg-subtle: #01242a;
  --x-warning-bg-subtle: #321704;
  --x-danger-bg-subtle: #300e0e;
  --x-light-bg-subtle: #2d3748;
  --x-dark-bg-subtle: #171c24;
  --x-primary-border-subtle: #25364d;
  --x-secondary-border-subtle: #5b7482;
  --x-success-border-subtle: #147638;
  --x-info-border-subtle: #046d7f;
  --x-warning-border-subtle: #95450d;
  --x-danger-border-subtle: #8f2929;
  --x-light-border-subtle: #525f7f;
  --x-dark-border-subtle: #2d3748;
  --x-heading-color: inherit;
  --x-link-color: #8b9cb3;
  --x-link-hover-color: #a2b0c2;
  --x-link-color-rgb: 139, 156, 179;
  --x-link-hover-color-rgb: 162, 176, 194;
  --x-code-color: #f491c2;
  --x-highlight-color: #cfd6df;
  --x-highlight-bg: #5e4803;
  --x-border-color: #525f7f;
  --x-border-color-translucent: rgba(255, 255, 255, .15);
  --x-form-valid-color: #7adc9e;
  --x-form-valid-border-color: #7adc9e;
  --x-form-invalid-color: #f58f8f;
  --x-form-invalid-border-color: #f58f8f;
}

iframe {
  vertical-align: middle;
  display: block;
}

figcaption, figure, main {
  margin: 0;
  display: block;
}

figure {
  max-width: 100%;
  min-height: 1px;
}

img, video {
  max-width: 100%;
  height: auto;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

a {
  outline: none;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--x-body-font-family);
  font-size: var(--x-body-font-size);
  font-weight: var(--x-body-font-weight);
  line-height: var(--x-body-line-height);
  color: var(--x-body-color);
  text-align: var(--x-body-text-align);
  background-color: var(--x-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--x-border-width) solid;
  opacity: .1;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--x-heading-color);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Satoshi, sans-serif;
  font-weight: 600;
  line-height: 1.3;
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}

h3, .h3 {
  font-size: calc(1.2625rem + .15vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.375rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: .875rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: 700;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  color: var(--x-highlight-color);
  background-color: var(--x-highlight-bg);
  padding: 0;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--x-link-color-rgb), var(--x-link-opacity, 1));
  text-decoration: none;
}

a:hover {
  --x-link-color-rgb: var(--x-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--x-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--x-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--x-body-bg);
  background-color: var(--x-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--x-secondary-color);
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
  font-weight: 500;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

:root, [data-bs-theme="light"] {
  --x-gray-50: #fafafa;
  --x-gray-100: #f5f9fc;
  --x-gray-200: #e7eaf0;
  --x-gray-300: #cfd6df;
  --x-gray-400: #abb6c5;
  --x-gray-500: #8898a9;
  --x-gray-600: #6b7b93;
  --x-gray-700: #525f7f;
  --x-gray-800: #2d3748;
  --x-gray-900: #16192c;
  --x-gray-950: #020617;
  --x-blue-100: #d8e6fd;
  --x-blue-200: #b1cdfb;
  --x-blue-300: #89b4fa;
  --x-blue-400: #629bf8;
  --x-blue-500: #3b82f6;
  --x-blue-600: #2f68c5;
  --x-blue-700: #234e94;
  --x-blue-800: #183462;
  --x-blue-900: #0c1a31;
  --x-indigo-100: #e0e0fc;
  --x-indigo-200: #c1c2f9;
  --x-indigo-300: #a1a3f7;
  --x-indigo-400: #8285f4;
  --x-indigo-500: #6366f1;
  --x-indigo-600: #4f52c1;
  --x-indigo-700: #3b3d91;
  --x-indigo-800: #282960;
  --x-indigo-900: #141430;
  --x-purple-100: #e8defd;
  --x-purple-200: #d1befb;
  --x-purple-300: #b99dfa;
  --x-purple-400: #a27df8;
  --x-purple-500: #8b5cf6;
  --x-purple-600: #6f4ac5;
  --x-purple-700: #533794;
  --x-purple-800: #382562;
  --x-purple-900: #1c1231;
  --x-pink-100: #fbdaeb;
  --x-pink-200: #f7b6d6;
  --x-pink-300: #f491c2;
  --x-pink-400: #f06dad;
  --x-pink-500: #ec4899;
  --x-pink-600: #bd3a7a;
  --x-pink-700: #8e2b5c;
  --x-pink-800: #5e1d3d;
  --x-pink-900: #2f0e1f;
  --x-red-100: #fcdada;
  --x-red-200: #f9b4b4;
  --x-red-300: #f58f8f;
  --x-red-400: #f26969;
  --x-red-500: #ef4444;
  --x-red-600: #bf3636;
  --x-red-700: #8f2929;
  --x-red-800: #601b1b;
  --x-red-900: #300e0e;
  --x-orange-100: #fee3d0;
  --x-orange-200: #fdc7a2;
  --x-orange-300: #fbab73;
  --x-orange-400: #fa8f45;
  --x-orange-500: #f97316;
  --x-orange-600: #c75c12;
  --x-orange-700: #95450d;
  --x-orange-800: #642e09;
  --x-orange-900: #321704;
  --x-yellow-100: #fbf0ce;
  --x-yellow-200: #f7e19c;
  --x-yellow-300: #f2d16b;
  --x-yellow-400: #eec239;
  --x-yellow-500: #eab308;
  --x-yellow-600: #bb8f06;
  --x-yellow-700: #8c6b05;
  --x-yellow-800: #5e4803;
  --x-yellow-900: #2f2402;
  --x-green-100: #d3f3df;
  --x-green-200: #a7e8bf;
  --x-green-300: #7adc9e;
  --x-green-400: #4ed17e;
  --x-green-500: #22c55e;
  --x-green-600: #1b9e4b;
  --x-green-700: #147638;
  --x-green-800: #0e4f26;
  --x-green-900: #072713;
  --x-teal-100: #d0f1ed;
  --x-teal-200: #a1e3db;
  --x-teal-300: #72d4ca;
  --x-teal-400: #43c6b8;
  --x-teal-500: #14b8a6;
  --x-teal-600: #109385;
  --x-teal-700: #0c6e64;
  --x-teal-800: #084a42;
  --x-teal-900: #042521;
  --x-cyan-100: #cdf0f6;
  --x-cyan-200: #9be2ee;
  --x-cyan-300: #6ad3e5;
  --x-cyan-400: #38c5dd;
  --x-cyan-500: #06b6d4;
  --x-cyan-600: #0592aa;
  --x-cyan-700: #046d7f;
  --x-cyan-800: #024955;
  --x-cyan-900: #01242a;
  --x-gray-50-rgb: 250, 250, 250;
  --x-gray-100-rgb: 245, 249, 252;
  --x-gray-200-rgb: 231, 234, 240;
  --x-gray-300-rgb: 207, 214, 223;
  --x-gray-400-rgb: 171, 182, 197;
  --x-gray-500-rgb: 136, 152, 169;
  --x-gray-600-rgb: 107, 123, 147;
  --x-gray-700-rgb: 82, 95, 127;
  --x-gray-800-rgb: 45, 55, 72;
  --x-gray-900-rgb: 22, 25, 44;
  --x-gray-950-rgb: 2, 6, 23;
  --x-blue-100-rgb: 216, 230, 253;
  --x-blue-200-rgb: 177, 205, 251;
  --x-blue-300-rgb: 137, 180, 250;
  --x-blue-400-rgb: 98, 155, 248;
  --x-blue-500-rgb: 59, 130, 246;
  --x-blue-600-rgb: 47, 104, 197;
  --x-blue-700-rgb: 35, 78, 148;
  --x-blue-800-rgb: 24, 52, 98;
  --x-blue-900-rgb: 12, 26, 49;
  --x-indigo-100-rgb: 224, 224, 252;
  --x-indigo-200-rgb: 193, 194, 249;
  --x-indigo-300-rgb: 161, 163, 247;
  --x-indigo-400-rgb: 130, 133, 244;
  --x-indigo-500-rgb: 99, 102, 241;
  --x-indigo-600-rgb: 79, 82, 193;
  --x-indigo-700-rgb: 59, 61, 145;
  --x-indigo-800-rgb: 40, 41, 96;
  --x-indigo-900-rgb: 20, 20, 48;
  --x-purple-100-rgb: 232, 222, 253;
  --x-purple-200-rgb: 209, 190, 251;
  --x-purple-300-rgb: 185, 157, 250;
  --x-purple-400-rgb: 162, 125, 248;
  --x-purple-500-rgb: 139, 92, 246;
  --x-purple-600-rgb: 111, 74, 197;
  --x-purple-700-rgb: 83, 55, 148;
  --x-purple-800-rgb: 56, 37, 98;
  --x-purple-900-rgb: 28, 18, 49;
  --x-pink-100-rgb: 251, 218, 235;
  --x-pink-200-rgb: 247, 182, 214;
  --x-pink-300-rgb: 244, 145, 194;
  --x-pink-400-rgb: 240, 109, 173;
  --x-pink-500-rgb: 236, 72, 153;
  --x-pink-600-rgb: 189, 58, 122;
  --x-pink-700-rgb: 142, 43, 92;
  --x-pink-800-rgb: 94, 29, 61;
  --x-pink-900-rgb: 47, 14, 31;
  --x-red-100-rgb: 252, 218, 218;
  --x-red-200-rgb: 249, 180, 180;
  --x-red-300-rgb: 245, 143, 143;
  --x-red-400-rgb: 242, 105, 105;
  --x-red-500-rgb: 239, 68, 68;
  --x-red-600-rgb: 191, 54, 54;
  --x-red-700-rgb: 143, 41, 41;
  --x-red-800-rgb: 96, 27, 27;
  --x-red-900-rgb: 48, 14, 14;
  --x-orange-100-rgb: 254, 227, 208;
  --x-orange-200-rgb: 253, 199, 162;
  --x-orange-300-rgb: 251, 171, 115;
  --x-orange-400-rgb: 250, 143, 69;
  --x-orange-500-rgb: 249, 115, 22;
  --x-orange-600-rgb: 199, 92, 18;
  --x-orange-700-rgb: 149, 69, 13;
  --x-orange-800-rgb: 100, 46, 9;
  --x-orange-900-rgb: 50, 23, 4;
  --x-yellow-100-rgb: 251, 240, 206;
  --x-yellow-200-rgb: 247, 225, 156;
  --x-yellow-300-rgb: 242, 209, 107;
  --x-yellow-400-rgb: 238, 194, 57;
  --x-yellow-500-rgb: 234, 179, 8;
  --x-yellow-600-rgb: 187, 143, 6;
  --x-yellow-700-rgb: 140, 107, 5;
  --x-yellow-800-rgb: 94, 72, 3;
  --x-yellow-900-rgb: 47, 36, 2;
  --x-green-100-rgb: 211, 243, 223;
  --x-green-200-rgb: 167, 232, 191;
  --x-green-300-rgb: 122, 220, 158;
  --x-green-400-rgb: 78, 209, 126;
  --x-green-500-rgb: 34, 197, 94;
  --x-green-600-rgb: 27, 158, 75;
  --x-green-700-rgb: 20, 118, 56;
  --x-green-800-rgb: 14, 79, 38;
  --x-green-900-rgb: 7, 39, 19;
  --x-teal-100-rgb: 208, 241, 237;
  --x-teal-200-rgb: 161, 227, 219;
  --x-teal-300-rgb: 114, 212, 202;
  --x-teal-400-rgb: 67, 198, 184;
  --x-teal-500-rgb: 20, 184, 166;
  --x-teal-600-rgb: 16, 147, 133;
  --x-teal-700-rgb: 12, 110, 100;
  --x-teal-800-rgb: 8, 74, 66;
  --x-teal-900-rgb: 4, 37, 33;
  --x-cyan-100-rgb: 205, 240, 246;
  --x-cyan-200-rgb: 155, 226, 238;
  --x-cyan-300-rgb: 106, 211, 229;
  --x-cyan-400-rgb: 56, 197, 221;
  --x-cyan-500-rgb: 6, 182, 212;
  --x-cyan-600-rgb: 5, 146, 170;
  --x-cyan-700-rgb: 4, 109, 127;
  --x-cyan-800-rgb: 2, 73, 85;
  --x-cyan-900-rgb: 1, 36, 42;
  --x-font-display: Satoshi, sans-serif, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --x-font-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  --x-font-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --x-box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-1: 0px 1px 1px 0px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-2: 0px 6px 6px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-3: 0px 9px 9px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-4: 0px 16px 16px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-5: 0px 32px 40px -2px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-6: 0px 36px 50px 0 rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-soft-shadow-1: 0px 1px 1px 0px rgba(10, 22, 70, .04);
  --x-box-soft-shadow-2: 0px 6px 6px -1px rgba(10, 22, 70, .04);
  --x-box-soft-shadow-3: 0px 9px 9px -1px rgba(10, 22, 70, .04);
  --x-box-soft-shadow-4: 0px 16px 16px -1px rgba(10, 22, 70, .04);
  --x-box-soft-shadow-5: 0px 32px 40px -2px rgba(10, 22, 70, .04);
  --x-box-soft-shadow-6: 0px 36px 50px 0 rgba(10, 22, 70, .04);
}

:root .logo-light, [data-bs-theme="light"] .logo-light {
  display: none;
}

:root .logo-dark, [data-bs-theme="light"] .logo-dark, [data-bs-theme="dark"] .logo-light {
  display: block;
}

[data-bs-theme="dark"] .logo-dark {
  display: none;
}

[data-bs-theme="dark"] .btn-neutral {
  --x-btn-bg: #2d3748;
  --x-btn-border-color: #2d3748;
  --x-btn-color: #f5f9fc;
  --x-btn-hover-bg: #525f7f;
  --x-btn-hover-color: #fafafa;
  --x-btn-hover-border-color: #e7eaf0;
  --x-btn-active-bg: #2d3748;
  --x-btn-active-color: #fafafa;
  --x-btn-active-border-color: #e7eaf0;
}

.lead {
  font-size: 1.125rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6b7b93;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--x-body-bg);
  border: var(--x-border-width) solid var(--x-border-color);
  border-radius: var(--x-border-radius);
  box-shadow: var(--x-box-shadow-sm);
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: var(--x-secondary-color);
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --x-gutter-x: 1.5rem;
  --x-gutter-y: 0;
  padding-right: calc(var(--x-gutter-x) * .5);
  padding-left: calc(var(--x-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1360px;
  }
}

:root {
  --x-breakpoint-xs: 0;
  --x-breakpoint-sm: 576px;
  --x-breakpoint-md: 768px;
  --x-breakpoint-lg: 992px;
  --x-breakpoint-xl: 1200px;
  --x-breakpoint-xxl: 1400px;
}

.row {
  --x-gutter-x: 1.5rem;
  --x-gutter-y: 0;
  margin-top: calc(-1 * var(--x-gutter-y));
  margin-right: calc(-.5 * var(--x-gutter-x));
  margin-left: calc(-.5 * var(--x-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--x-gutter-x) * .5);
  padding-left: calc(var(--x-gutter-x) * .5);
  margin-top: var(--x-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.grid {
  grid-template-rows: repeat(var(--x-rows, 1), 1fr);
  grid-template-columns: repeat(var(--x-columns, 12), 1fr);
  gap: var(--x-gap, 1.5rem);
  display: grid;
}

.grid .g-col-1 {
  grid-column: auto / span 1;
}

.grid .g-col-2 {
  grid-column: auto / span 2;
}

.grid .g-col-3 {
  grid-column: auto / span 3;
}

.grid .g-col-4 {
  grid-column: auto / span 4;
}

.grid .g-col-5 {
  grid-column: auto / span 5;
}

.grid .g-col-6 {
  grid-column: auto / span 6;
}

.grid .g-col-7 {
  grid-column: auto / span 7;
}

.grid .g-col-8 {
  grid-column: auto / span 8;
}

.grid .g-col-9 {
  grid-column: auto / span 9;
}

.grid .g-col-10 {
  grid-column: auto / span 10;
}

.grid .g-col-11 {
  grid-column: auto / span 11;
}

.grid .g-col-12 {
  grid-column: auto / span 12;
}

.grid .g-start-1 {
  grid-column-start: 1;
}

.grid .g-start-2 {
  grid-column-start: 2;
}

.grid .g-start-3 {
  grid-column-start: 3;
}

.grid .g-start-4 {
  grid-column-start: 4;
}

.grid .g-start-5 {
  grid-column-start: 5;
}

.grid .g-start-6 {
  grid-column-start: 6;
}

.grid .g-start-7 {
  grid-column-start: 7;
}

.grid .g-start-8 {
  grid-column-start: 8;
}

.grid .g-start-9 {
  grid-column-start: 9;
}

.grid .g-start-10 {
  grid-column-start: 10;
}

.grid .g-start-11 {
  grid-column-start: 11;
}

@media (min-width: 576px) {
  .grid .g-col-sm-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-sm-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-sm-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-sm-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-sm-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-sm-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-sm-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-sm-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-sm-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-sm-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-sm-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-sm-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-sm-1 {
    grid-column-start: 1;
  }

  .grid .g-start-sm-2 {
    grid-column-start: 2;
  }

  .grid .g-start-sm-3 {
    grid-column-start: 3;
  }

  .grid .g-start-sm-4 {
    grid-column-start: 4;
  }

  .grid .g-start-sm-5 {
    grid-column-start: 5;
  }

  .grid .g-start-sm-6 {
    grid-column-start: 6;
  }

  .grid .g-start-sm-7 {
    grid-column-start: 7;
  }

  .grid .g-start-sm-8 {
    grid-column-start: 8;
  }

  .grid .g-start-sm-9 {
    grid-column-start: 9;
  }

  .grid .g-start-sm-10 {
    grid-column-start: 10;
  }

  .grid .g-start-sm-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 768px) {
  .grid .g-col-md-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-md-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-md-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-md-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-md-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-md-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-md-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-md-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-md-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-md-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-md-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-md-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-md-1 {
    grid-column-start: 1;
  }

  .grid .g-start-md-2 {
    grid-column-start: 2;
  }

  .grid .g-start-md-3 {
    grid-column-start: 3;
  }

  .grid .g-start-md-4 {
    grid-column-start: 4;
  }

  .grid .g-start-md-5 {
    grid-column-start: 5;
  }

  .grid .g-start-md-6 {
    grid-column-start: 6;
  }

  .grid .g-start-md-7 {
    grid-column-start: 7;
  }

  .grid .g-start-md-8 {
    grid-column-start: 8;
  }

  .grid .g-start-md-9 {
    grid-column-start: 9;
  }

  .grid .g-start-md-10 {
    grid-column-start: 10;
  }

  .grid .g-start-md-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 992px) {
  .grid .g-col-lg-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-lg-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-lg-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-lg-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-lg-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-lg-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-lg-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-lg-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-lg-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-lg-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-lg-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-lg-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-lg-1 {
    grid-column-start: 1;
  }

  .grid .g-start-lg-2 {
    grid-column-start: 2;
  }

  .grid .g-start-lg-3 {
    grid-column-start: 3;
  }

  .grid .g-start-lg-4 {
    grid-column-start: 4;
  }

  .grid .g-start-lg-5 {
    grid-column-start: 5;
  }

  .grid .g-start-lg-6 {
    grid-column-start: 6;
  }

  .grid .g-start-lg-7 {
    grid-column-start: 7;
  }

  .grid .g-start-lg-8 {
    grid-column-start: 8;
  }

  .grid .g-start-lg-9 {
    grid-column-start: 9;
  }

  .grid .g-start-lg-10 {
    grid-column-start: 10;
  }

  .grid .g-start-lg-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 1200px) {
  .grid .g-col-xl-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-xl-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-xl-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-xl-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-xl-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-xl-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-xl-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-xl-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-xl-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-xl-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-xl-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-xl-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-xl-1 {
    grid-column-start: 1;
  }

  .grid .g-start-xl-2 {
    grid-column-start: 2;
  }

  .grid .g-start-xl-3 {
    grid-column-start: 3;
  }

  .grid .g-start-xl-4 {
    grid-column-start: 4;
  }

  .grid .g-start-xl-5 {
    grid-column-start: 5;
  }

  .grid .g-start-xl-6 {
    grid-column-start: 6;
  }

  .grid .g-start-xl-7 {
    grid-column-start: 7;
  }

  .grid .g-start-xl-8 {
    grid-column-start: 8;
  }

  .grid .g-start-xl-9 {
    grid-column-start: 9;
  }

  .grid .g-start-xl-10 {
    grid-column-start: 10;
  }

  .grid .g-start-xl-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 1400px) {
  .grid .g-col-xxl-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-xxl-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-xxl-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-xxl-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-xxl-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-xxl-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-xxl-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-xxl-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-xxl-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-xxl-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-xxl-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-xxl-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-xxl-1 {
    grid-column-start: 1;
  }

  .grid .g-start-xxl-2 {
    grid-column-start: 2;
  }

  .grid .g-start-xxl-3 {
    grid-column-start: 3;
  }

  .grid .g-start-xxl-4 {
    grid-column-start: 4;
  }

  .grid .g-start-xxl-5 {
    grid-column-start: 5;
  }

  .grid .g-start-xxl-6 {
    grid-column-start: 6;
  }

  .grid .g-start-xxl-7 {
    grid-column-start: 7;
  }

  .grid .g-start-xxl-8 {
    grid-column-start: 8;
  }

  .grid .g-start-xxl-9 {
    grid-column-start: 9;
  }

  .grid .g-start-xxl-10 {
    grid-column-start: 10;
  }

  .grid .g-start-xxl-11 {
    grid-column-start: 11;
  }
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --x-gutter-x: 0;
}

.g-0, .gy-0 {
  --x-gutter-y: 0;
}

.g-1, .gx-1 {
  --x-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --x-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --x-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --x-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --x-gutter-x: .75rem;
}

.g-3, .gy-3 {
  --x-gutter-y: .75rem;
}

.g-4, .gx-4 {
  --x-gutter-x: 1rem;
}

.g-4, .gy-4 {
  --x-gutter-y: 1rem;
}

.g-5, .gx-5 {
  --x-gutter-x: 1.25rem;
}

.g-5, .gy-5 {
  --x-gutter-y: 1.25rem;
}

.g-6, .gx-6 {
  --x-gutter-x: 1.5rem;
}

.g-6, .gy-6 {
  --x-gutter-y: 1.5rem;
}

.g-7, .gx-7 {
  --x-gutter-x: 1.75rem;
}

.g-7, .gy-7 {
  --x-gutter-y: 1.75rem;
}

.g-8, .gx-8 {
  --x-gutter-x: 2rem;
}

.g-8, .gy-8 {
  --x-gutter-y: 2rem;
}

.g-10, .gx-10 {
  --x-gutter-x: 2.5rem;
}

.g-10, .gy-10 {
  --x-gutter-y: 2.5rem;
}

.g-12, .gx-12 {
  --x-gutter-x: 3rem;
}

.g-12, .gy-12 {
  --x-gutter-y: 3rem;
}

.g-14, .gx-14 {
  --x-gutter-x: 3.5rem;
}

.g-14, .gy-14 {
  --x-gutter-y: 3.5rem;
}

.g-16, .gx-16 {
  --x-gutter-x: 4rem;
}

.g-16, .gy-16 {
  --x-gutter-y: 4rem;
}

.g-18, .gx-18 {
  --x-gutter-x: 4.5rem;
}

.g-18, .gy-18 {
  --x-gutter-y: 4.5rem;
}

.g-20, .gx-20 {
  --x-gutter-x: 5rem;
}

.g-20, .gy-20 {
  --x-gutter-y: 5rem;
}

.g-24, .gx-24 {
  --x-gutter-x: 6rem;
}

.g-24, .gy-24 {
  --x-gutter-y: 6rem;
}

.g-32, .gx-32 {
  --x-gutter-x: 8rem;
}

.g-32, .gy-32 {
  --x-gutter-y: 8rem;
}

.g-40, .gx-40 {
  --x-gutter-x: 10rem;
}

.g-40, .gy-40 {
  --x-gutter-y: 10rem;
}

.g-48, .gx-48 {
  --x-gutter-x: 12rem;
}

.g-48, .gy-48 {
  --x-gutter-y: 12rem;
}

.g-56, .gx-56 {
  --x-gutter-x: 14rem;
}

.g-56, .gy-56 {
  --x-gutter-y: 14rem;
}

.g-64, .gx-64 {
  --x-gutter-x: 16rem;
}

.g-64, .gy-64 {
  --x-gutter-y: 16rem;
}

.g-72, .gx-72 {
  --x-gutter-x: 18rem;
}

.g-72, .gy-72 {
  --x-gutter-y: 18rem;
}

.g-80, .gx-80 {
  --x-gutter-x: 20rem;
}

.g-80, .gy-80 {
  --x-gutter-y: 20rem;
}

.g-px, .gx-px {
  --x-gutter-x: 1px;
}

.g-px, .gy-px {
  --x-gutter-y: 1px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --x-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --x-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --x-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --x-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --x-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --x-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --x-gutter-x: .75rem;
  }

  .g-sm-3, .gy-sm-3 {
    --x-gutter-y: .75rem;
  }

  .g-sm-4, .gx-sm-4 {
    --x-gutter-x: 1rem;
  }

  .g-sm-4, .gy-sm-4 {
    --x-gutter-y: 1rem;
  }

  .g-sm-5, .gx-sm-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-sm-5, .gy-sm-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-sm-6, .gx-sm-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-sm-6, .gy-sm-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-sm-7, .gx-sm-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-sm-7, .gy-sm-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-sm-8, .gx-sm-8 {
    --x-gutter-x: 2rem;
  }

  .g-sm-8, .gy-sm-8 {
    --x-gutter-y: 2rem;
  }

  .g-sm-10, .gx-sm-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-sm-10, .gy-sm-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-sm-12, .gx-sm-12 {
    --x-gutter-x: 3rem;
  }

  .g-sm-12, .gy-sm-12 {
    --x-gutter-y: 3rem;
  }

  .g-sm-14, .gx-sm-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-sm-14, .gy-sm-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-sm-16, .gx-sm-16 {
    --x-gutter-x: 4rem;
  }

  .g-sm-16, .gy-sm-16 {
    --x-gutter-y: 4rem;
  }

  .g-sm-18, .gx-sm-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-sm-18, .gy-sm-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-sm-20, .gx-sm-20 {
    --x-gutter-x: 5rem;
  }

  .g-sm-20, .gy-sm-20 {
    --x-gutter-y: 5rem;
  }

  .g-sm-24, .gx-sm-24 {
    --x-gutter-x: 6rem;
  }

  .g-sm-24, .gy-sm-24 {
    --x-gutter-y: 6rem;
  }

  .g-sm-32, .gx-sm-32 {
    --x-gutter-x: 8rem;
  }

  .g-sm-32, .gy-sm-32 {
    --x-gutter-y: 8rem;
  }

  .g-sm-40, .gx-sm-40 {
    --x-gutter-x: 10rem;
  }

  .g-sm-40, .gy-sm-40 {
    --x-gutter-y: 10rem;
  }

  .g-sm-48, .gx-sm-48 {
    --x-gutter-x: 12rem;
  }

  .g-sm-48, .gy-sm-48 {
    --x-gutter-y: 12rem;
  }

  .g-sm-56, .gx-sm-56 {
    --x-gutter-x: 14rem;
  }

  .g-sm-56, .gy-sm-56 {
    --x-gutter-y: 14rem;
  }

  .g-sm-64, .gx-sm-64 {
    --x-gutter-x: 16rem;
  }

  .g-sm-64, .gy-sm-64 {
    --x-gutter-y: 16rem;
  }

  .g-sm-72, .gx-sm-72 {
    --x-gutter-x: 18rem;
  }

  .g-sm-72, .gy-sm-72 {
    --x-gutter-y: 18rem;
  }

  .g-sm-80, .gx-sm-80 {
    --x-gutter-x: 20rem;
  }

  .g-sm-80, .gy-sm-80 {
    --x-gutter-y: 20rem;
  }

  .g-sm-px, .gx-sm-px {
    --x-gutter-x: 1px;
  }

  .g-sm-px, .gy-sm-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --x-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --x-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --x-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --x-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --x-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --x-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --x-gutter-x: .75rem;
  }

  .g-md-3, .gy-md-3 {
    --x-gutter-y: .75rem;
  }

  .g-md-4, .gx-md-4 {
    --x-gutter-x: 1rem;
  }

  .g-md-4, .gy-md-4 {
    --x-gutter-y: 1rem;
  }

  .g-md-5, .gx-md-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-md-5, .gy-md-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-md-6, .gx-md-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-md-6, .gy-md-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-md-7, .gx-md-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-md-7, .gy-md-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-md-8, .gx-md-8 {
    --x-gutter-x: 2rem;
  }

  .g-md-8, .gy-md-8 {
    --x-gutter-y: 2rem;
  }

  .g-md-10, .gx-md-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-md-10, .gy-md-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-md-12, .gx-md-12 {
    --x-gutter-x: 3rem;
  }

  .g-md-12, .gy-md-12 {
    --x-gutter-y: 3rem;
  }

  .g-md-14, .gx-md-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-md-14, .gy-md-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-md-16, .gx-md-16 {
    --x-gutter-x: 4rem;
  }

  .g-md-16, .gy-md-16 {
    --x-gutter-y: 4rem;
  }

  .g-md-18, .gx-md-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-md-18, .gy-md-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-md-20, .gx-md-20 {
    --x-gutter-x: 5rem;
  }

  .g-md-20, .gy-md-20 {
    --x-gutter-y: 5rem;
  }

  .g-md-24, .gx-md-24 {
    --x-gutter-x: 6rem;
  }

  .g-md-24, .gy-md-24 {
    --x-gutter-y: 6rem;
  }

  .g-md-32, .gx-md-32 {
    --x-gutter-x: 8rem;
  }

  .g-md-32, .gy-md-32 {
    --x-gutter-y: 8rem;
  }

  .g-md-40, .gx-md-40 {
    --x-gutter-x: 10rem;
  }

  .g-md-40, .gy-md-40 {
    --x-gutter-y: 10rem;
  }

  .g-md-48, .gx-md-48 {
    --x-gutter-x: 12rem;
  }

  .g-md-48, .gy-md-48 {
    --x-gutter-y: 12rem;
  }

  .g-md-56, .gx-md-56 {
    --x-gutter-x: 14rem;
  }

  .g-md-56, .gy-md-56 {
    --x-gutter-y: 14rem;
  }

  .g-md-64, .gx-md-64 {
    --x-gutter-x: 16rem;
  }

  .g-md-64, .gy-md-64 {
    --x-gutter-y: 16rem;
  }

  .g-md-72, .gx-md-72 {
    --x-gutter-x: 18rem;
  }

  .g-md-72, .gy-md-72 {
    --x-gutter-y: 18rem;
  }

  .g-md-80, .gx-md-80 {
    --x-gutter-x: 20rem;
  }

  .g-md-80, .gy-md-80 {
    --x-gutter-y: 20rem;
  }

  .g-md-px, .gx-md-px {
    --x-gutter-x: 1px;
  }

  .g-md-px, .gy-md-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --x-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --x-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --x-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --x-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --x-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --x-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --x-gutter-x: .75rem;
  }

  .g-lg-3, .gy-lg-3 {
    --x-gutter-y: .75rem;
  }

  .g-lg-4, .gx-lg-4 {
    --x-gutter-x: 1rem;
  }

  .g-lg-4, .gy-lg-4 {
    --x-gutter-y: 1rem;
  }

  .g-lg-5, .gx-lg-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-lg-5, .gy-lg-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-lg-6, .gx-lg-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-lg-6, .gy-lg-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-lg-7, .gx-lg-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-lg-7, .gy-lg-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-lg-8, .gx-lg-8 {
    --x-gutter-x: 2rem;
  }

  .g-lg-8, .gy-lg-8 {
    --x-gutter-y: 2rem;
  }

  .g-lg-10, .gx-lg-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-lg-10, .gy-lg-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-lg-12, .gx-lg-12 {
    --x-gutter-x: 3rem;
  }

  .g-lg-12, .gy-lg-12 {
    --x-gutter-y: 3rem;
  }

  .g-lg-14, .gx-lg-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-lg-14, .gy-lg-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-lg-16, .gx-lg-16 {
    --x-gutter-x: 4rem;
  }

  .g-lg-16, .gy-lg-16 {
    --x-gutter-y: 4rem;
  }

  .g-lg-18, .gx-lg-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-lg-18, .gy-lg-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-lg-20, .gx-lg-20 {
    --x-gutter-x: 5rem;
  }

  .g-lg-20, .gy-lg-20 {
    --x-gutter-y: 5rem;
  }

  .g-lg-24, .gx-lg-24 {
    --x-gutter-x: 6rem;
  }

  .g-lg-24, .gy-lg-24 {
    --x-gutter-y: 6rem;
  }

  .g-lg-32, .gx-lg-32 {
    --x-gutter-x: 8rem;
  }

  .g-lg-32, .gy-lg-32 {
    --x-gutter-y: 8rem;
  }

  .g-lg-40, .gx-lg-40 {
    --x-gutter-x: 10rem;
  }

  .g-lg-40, .gy-lg-40 {
    --x-gutter-y: 10rem;
  }

  .g-lg-48, .gx-lg-48 {
    --x-gutter-x: 12rem;
  }

  .g-lg-48, .gy-lg-48 {
    --x-gutter-y: 12rem;
  }

  .g-lg-56, .gx-lg-56 {
    --x-gutter-x: 14rem;
  }

  .g-lg-56, .gy-lg-56 {
    --x-gutter-y: 14rem;
  }

  .g-lg-64, .gx-lg-64 {
    --x-gutter-x: 16rem;
  }

  .g-lg-64, .gy-lg-64 {
    --x-gutter-y: 16rem;
  }

  .g-lg-72, .gx-lg-72 {
    --x-gutter-x: 18rem;
  }

  .g-lg-72, .gy-lg-72 {
    --x-gutter-y: 18rem;
  }

  .g-lg-80, .gx-lg-80 {
    --x-gutter-x: 20rem;
  }

  .g-lg-80, .gy-lg-80 {
    --x-gutter-y: 20rem;
  }

  .g-lg-px, .gx-lg-px {
    --x-gutter-x: 1px;
  }

  .g-lg-px, .gy-lg-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --x-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --x-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --x-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --x-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --x-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --x-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --x-gutter-x: .75rem;
  }

  .g-xl-3, .gy-xl-3 {
    --x-gutter-y: .75rem;
  }

  .g-xl-4, .gx-xl-4 {
    --x-gutter-x: 1rem;
  }

  .g-xl-4, .gy-xl-4 {
    --x-gutter-y: 1rem;
  }

  .g-xl-5, .gx-xl-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-xl-5, .gy-xl-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-xl-6, .gx-xl-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-xl-6, .gy-xl-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-xl-7, .gx-xl-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-xl-7, .gy-xl-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-xl-8, .gx-xl-8 {
    --x-gutter-x: 2rem;
  }

  .g-xl-8, .gy-xl-8 {
    --x-gutter-y: 2rem;
  }

  .g-xl-10, .gx-xl-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-xl-10, .gy-xl-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-xl-12, .gx-xl-12 {
    --x-gutter-x: 3rem;
  }

  .g-xl-12, .gy-xl-12 {
    --x-gutter-y: 3rem;
  }

  .g-xl-14, .gx-xl-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-xl-14, .gy-xl-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-xl-16, .gx-xl-16 {
    --x-gutter-x: 4rem;
  }

  .g-xl-16, .gy-xl-16 {
    --x-gutter-y: 4rem;
  }

  .g-xl-18, .gx-xl-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-xl-18, .gy-xl-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-xl-20, .gx-xl-20 {
    --x-gutter-x: 5rem;
  }

  .g-xl-20, .gy-xl-20 {
    --x-gutter-y: 5rem;
  }

  .g-xl-24, .gx-xl-24 {
    --x-gutter-x: 6rem;
  }

  .g-xl-24, .gy-xl-24 {
    --x-gutter-y: 6rem;
  }

  .g-xl-32, .gx-xl-32 {
    --x-gutter-x: 8rem;
  }

  .g-xl-32, .gy-xl-32 {
    --x-gutter-y: 8rem;
  }

  .g-xl-40, .gx-xl-40 {
    --x-gutter-x: 10rem;
  }

  .g-xl-40, .gy-xl-40 {
    --x-gutter-y: 10rem;
  }

  .g-xl-48, .gx-xl-48 {
    --x-gutter-x: 12rem;
  }

  .g-xl-48, .gy-xl-48 {
    --x-gutter-y: 12rem;
  }

  .g-xl-56, .gx-xl-56 {
    --x-gutter-x: 14rem;
  }

  .g-xl-56, .gy-xl-56 {
    --x-gutter-y: 14rem;
  }

  .g-xl-64, .gx-xl-64 {
    --x-gutter-x: 16rem;
  }

  .g-xl-64, .gy-xl-64 {
    --x-gutter-y: 16rem;
  }

  .g-xl-72, .gx-xl-72 {
    --x-gutter-x: 18rem;
  }

  .g-xl-72, .gy-xl-72 {
    --x-gutter-y: 18rem;
  }

  .g-xl-80, .gx-xl-80 {
    --x-gutter-x: 20rem;
  }

  .g-xl-80, .gy-xl-80 {
    --x-gutter-y: 20rem;
  }

  .g-xl-px, .gx-xl-px {
    --x-gutter-x: 1px;
  }

  .g-xl-px, .gy-xl-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --x-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --x-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --x-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --x-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --x-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --x-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --x-gutter-x: .75rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --x-gutter-y: .75rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --x-gutter-x: 1rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --x-gutter-y: 1rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-xxl-6, .gx-xxl-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-xxl-6, .gy-xxl-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-xxl-7, .gx-xxl-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-xxl-7, .gy-xxl-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-xxl-8, .gx-xxl-8 {
    --x-gutter-x: 2rem;
  }

  .g-xxl-8, .gy-xxl-8 {
    --x-gutter-y: 2rem;
  }

  .g-xxl-10, .gx-xxl-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-xxl-10, .gy-xxl-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-xxl-12, .gx-xxl-12 {
    --x-gutter-x: 3rem;
  }

  .g-xxl-12, .gy-xxl-12 {
    --x-gutter-y: 3rem;
  }

  .g-xxl-14, .gx-xxl-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-xxl-14, .gy-xxl-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-xxl-16, .gx-xxl-16 {
    --x-gutter-x: 4rem;
  }

  .g-xxl-16, .gy-xxl-16 {
    --x-gutter-y: 4rem;
  }

  .g-xxl-18, .gx-xxl-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-xxl-18, .gy-xxl-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-xxl-20, .gx-xxl-20 {
    --x-gutter-x: 5rem;
  }

  .g-xxl-20, .gy-xxl-20 {
    --x-gutter-y: 5rem;
  }

  .g-xxl-24, .gx-xxl-24 {
    --x-gutter-x: 6rem;
  }

  .g-xxl-24, .gy-xxl-24 {
    --x-gutter-y: 6rem;
  }

  .g-xxl-32, .gx-xxl-32 {
    --x-gutter-x: 8rem;
  }

  .g-xxl-32, .gy-xxl-32 {
    --x-gutter-y: 8rem;
  }

  .g-xxl-40, .gx-xxl-40 {
    --x-gutter-x: 10rem;
  }

  .g-xxl-40, .gy-xxl-40 {
    --x-gutter-y: 10rem;
  }

  .g-xxl-48, .gx-xxl-48 {
    --x-gutter-x: 12rem;
  }

  .g-xxl-48, .gy-xxl-48 {
    --x-gutter-y: 12rem;
  }

  .g-xxl-56, .gx-xxl-56 {
    --x-gutter-x: 14rem;
  }

  .g-xxl-56, .gy-xxl-56 {
    --x-gutter-y: 14rem;
  }

  .g-xxl-64, .gx-xxl-64 {
    --x-gutter-x: 16rem;
  }

  .g-xxl-64, .gy-xxl-64 {
    --x-gutter-y: 16rem;
  }

  .g-xxl-72, .gx-xxl-72 {
    --x-gutter-x: 18rem;
  }

  .g-xxl-72, .gy-xxl-72 {
    --x-gutter-y: 18rem;
  }

  .g-xxl-80, .gx-xxl-80 {
    --x-gutter-x: 20rem;
  }

  .g-xxl-80, .gy-xxl-80 {
    --x-gutter-y: 20rem;
  }

  .g-xxl-px, .gx-xxl-px {
    --x-gutter-x: 1px;
  }

  .g-xxl-px, .gy-xxl-px {
    --x-gutter-y: 1px;
  }
}

.table {
  --x-table-color-type: initial;
  --x-table-bg-type: initial;
  --x-table-color-state: initial;
  --x-table-bg-state: initial;
  --x-table-color: var(--x-emphasis-color);
  --x-table-bg: var(--x-body-bg);
  --x-table-border-color: var(--x-border-color);
  --x-table-accent-bg: transparent;
  --x-table-striped-color: var(--x-emphasis-color);
  --x-table-striped-bg: rgba(var(--x-emphasis-color-rgb), .03);
  --x-table-active-color: var(--x-emphasis-color);
  --x-table-active-bg: rgba(var(--x-emphasis-color-rgb), .1);
  --x-table-hover-color: var(--x-emphasis-color);
  --x-table-hover-bg: rgba(var(--x-emphasis-color-rgb), .075);
  vertical-align: middle;
  border-color: var(--x-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  color: var(--x-table-color-state, var(--x-table-color-type, var(--x-table-color)));
  background-color: var(--x-table-bg);
  border-bottom-width: var(--x-border-width);
  box-shadow: inset 0 0 0 9999px var(--x-table-bg-state, var(--x-table-bg-type, var(--x-table-accent-bg)));
  padding: 1rem 1.5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--x-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .375rem 1.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--x-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--x-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --x-table-color-type: var(--x-table-striped-color);
  --x-table-bg-type: var(--x-table-striped-bg);
}

.table-active {
  --x-table-color-state: var(--x-table-active-color);
  --x-table-bg-state: var(--x-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --x-table-color-state: var(--x-table-hover-color);
  --x-table-bg-state: var(--x-table-hover-bg);
}

.table-primary {
  --x-table-color: #000;
  --x-table-bg: #d8dee6;
  --x-table-border-color: #adb2b8;
  --x-table-striped-bg: #d2d7df;
  --x-table-striped-color: #000;
  --x-table-active-bg: #c2c8cf;
  --x-table-active-color: #000;
  --x-table-hover-bg: #c8cdd5;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-secondary {
  --x-table-color: #000;
  --x-table-bg: #eaf3f7;
  --x-table-border-color: #bbc2c6;
  --x-table-striped-bg: #e3ecf0;
  --x-table-striped-color: #000;
  --x-table-active-bg: #d3dbde;
  --x-table-active-color: #000;
  --x-table-hover-bg: #d8e1e4;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-success {
  --x-table-color: #000;
  --x-table-bg: #d3f3df;
  --x-table-border-color: #a9c2b2;
  --x-table-striped-bg: #cdecd8;
  --x-table-striped-color: #000;
  --x-table-active-bg: #bedbc9;
  --x-table-active-color: #000;
  --x-table-hover-bg: #c3e1ce;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-info {
  --x-table-color: #000;
  --x-table-bg: #cdf0f6;
  --x-table-border-color: #a4c0c5;
  --x-table-striped-bg: #c7e9ef;
  --x-table-striped-color: #000;
  --x-table-active-bg: #b9d8dd;
  --x-table-active-color: #000;
  --x-table-hover-bg: #bedee4;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-warning {
  --x-table-color: #000;
  --x-table-bg: #fee3d0;
  --x-table-border-color: #cbb6a6;
  --x-table-striped-bg: #f6dcca;
  --x-table-striped-color: #000;
  --x-table-active-bg: #e5ccbb;
  --x-table-active-color: #000;
  --x-table-hover-bg: #ebd2c0;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-danger {
  --x-table-color: #000;
  --x-table-bg: #fcdada;
  --x-table-border-color: #caaeae;
  --x-table-striped-bg: #f4d3d3;
  --x-table-striped-color: #000;
  --x-table-active-bg: #e3c4c4;
  --x-table-active-color: #000;
  --x-table-hover-bg: #e9caca;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-light {
  --x-table-color: #000;
  --x-table-bg: #f1f5f9;
  --x-table-border-color: #c1c4c7;
  --x-table-striped-bg: #eaeef2;
  --x-table-striped-color: #000;
  --x-table-active-bg: #d9dde0;
  --x-table-active-color: #000;
  --x-table-hover-bg: #dfe3e6;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-dark {
  --x-table-color: #fff;
  --x-table-bg: #252422;
  --x-table-border-color: #51504e;
  --x-table-striped-bg: #2c2b29;
  --x-table-striped-color: #fff;
  --x-table-active-bg: #3b3a38;
  --x-table-active-color: #fff;
  --x-table-hover-bg: #353433;
  --x-table-hover-color: #fff;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  margin-right: .875rem;
}

.form-control-sm:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: .875rem;
}

.form-control-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: .875rem;
}

.form-control-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: .875rem;
}

.form-control-sm:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  margin-right: .875rem;
}

.form-control-sm:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: .875rem;
}

.form-control-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: .875rem;
}

.form-control-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: .875rem;
}

.form-control-sm:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  margin-right: 1.25rem;
}

.form-control-lg:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: 1.25rem;
}

.form-control-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: 1.25rem;
}

.form-control-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: 1.25rem;
}

.form-control-lg:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

.form-control-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  margin-right: 1.25rem;
}

.form-control-lg:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: 1.25rem;
}

.form-control-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: 1.25rem;
}

.form-control-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: 1.25rem;
}

.form-control-lg:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control::-moz-placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control-plaintext::-moz-placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn {
  --x-btn-padding-x: 1rem;
  --x-btn-padding-y: .5rem;
  --x-btn-font-family: ;
  --x-btn-font-size: 1rem;
  --x-btn-font-weight: 500;
  --x-btn-line-height: 1.625;
  --x-btn-color: var(--x-body-color);
  --x-btn-bg: transparent;
  --x-btn-border-width: var(--x-border-width);
  --x-btn-border-color: transparent;
  --x-btn-border-radius: var(--x-border-radius);
  --x-btn-hover-border-color: transparent;
  --x-btn-box-shadow: 0 1px 1px rgba(0, 0, 0, .075);
  --x-btn-disabled-opacity: .65;
  --x-btn-focus-box-shadow: 0 0 0 .15rem rgba(var(--x-btn-focus-shadow-rgb), .5);
  padding: var(--x-btn-padding-y) var(--x-btn-padding-x);
  font-family: var(--x-btn-font-family);
  font-size: var(--x-btn-font-size);
  font-weight: var(--x-btn-font-weight);
  line-height: var(--x-btn-line-height);
  color: var(--x-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--x-btn-border-width) solid var(--x-btn-border-color);
  border-radius: var(--x-btn-border-radius);
  background-color: var(--x-btn-bg);
  box-shadow: var(--x-btn-box-shadow);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--x-btn-hover-color);
  background-color: var(--x-btn-hover-bg);
  border-color: var(--x-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--x-btn-color);
  background-color: var(--x-btn-bg);
  border-color: var(--x-btn-border-color);
}

.btn:focus-visible {
  color: var(--x-btn-hover-color);
  background-color: var(--x-btn-hover-bg);
  border-color: var(--x-btn-hover-border-color);
  box-shadow: var(--x-btn-box-shadow), var(--x-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--x-btn-hover-border-color);
  box-shadow: var(--x-btn-box-shadow), var(--x-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--x-btn-active-color);
  background-color: var(--x-btn-active-bg);
  border-color: var(--x-btn-active-border-color);
  box-shadow: var(--x-btn-active-shadow);
}

.btn-check:checked + .btn:focus-visible {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

:not(.btn-check) + .btn:active:focus-visible {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

.btn:first-child:active:focus-visible {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

.btn.active:focus-visible {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

.btn.show:focus-visible {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--x-btn-disabled-color);
  pointer-events: none;
  background-color: var(--x-btn-disabled-bg);
  border-color: var(--x-btn-disabled-border-color);
  opacity: var(--x-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --x-btn-color: #fff;
  --x-btn-bg: #3d5a80;
  --x-btn-border-color: #3d5a80;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #314866;
  --x-btn-hover-border-color: #314866;
  --x-btn-focus-shadow-rgb: 90, 115, 147;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #314866;
  --x-btn-active-border-color: #2e4460;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #3d5a80;
  --x-btn-disabled-border-color: #3d5a80;
}

.btn-secondary {
  --x-btn-color: #000;
  --x-btn-bg: #98c1d9;
  --x-btn-border-color: #98c1d9;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #adcde1;
  --x-btn-hover-border-color: #a2c7dd;
  --x-btn-focus-shadow-rgb: 129, 164, 184;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #adcde1;
  --x-btn-active-border-color: #a2c7dd;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #98c1d9;
  --x-btn-disabled-border-color: #98c1d9;
}

.btn-success {
  --x-btn-color: #000;
  --x-btn-bg: #22c55e;
  --x-btn-border-color: #22c55e;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #4ed17e;
  --x-btn-hover-border-color: #38cb6e;
  --x-btn-focus-shadow-rgb: 29, 167, 80;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #4ed17e;
  --x-btn-active-border-color: #38cb6e;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #22c55e;
  --x-btn-disabled-border-color: #22c55e;
}

.btn-info {
  --x-btn-color: #fff;
  --x-btn-bg: #06b6d4;
  --x-btn-border-color: #06b6d4;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #0592aa;
  --x-btn-hover-border-color: #0592aa;
  --x-btn-focus-shadow-rgb: 43, 193, 218;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #0592aa;
  --x-btn-active-border-color: #05899f;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #06b6d4;
  --x-btn-disabled-border-color: #06b6d4;
}

.btn-warning {
  --x-btn-color: #fff;
  --x-btn-bg: #f97316;
  --x-btn-border-color: #f97316;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #c75c12;
  --x-btn-hover-border-color: #c75c12;
  --x-btn-focus-shadow-rgb: 250, 136, 57;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #c75c12;
  --x-btn-active-border-color: #bb5611;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #f97316;
  --x-btn-disabled-border-color: #f97316;
}

.btn-danger {
  --x-btn-color: #fff;
  --x-btn-bg: #ef4444;
  --x-btn-border-color: #ef4444;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #bf3636;
  --x-btn-hover-border-color: #bf3636;
  --x-btn-focus-shadow-rgb: 241, 96, 96;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #bf3636;
  --x-btn-active-border-color: #b33333;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #ef4444;
  --x-btn-disabled-border-color: #ef4444;
}

.btn-light {
  --x-btn-color: #000;
  --x-btn-bg: #f1f5f9;
  --x-btn-border-color: #f1f5f9;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #c1c4c7;
  --x-btn-hover-border-color: #c1c4c7;
  --x-btn-focus-shadow-rgb: 205, 208, 212;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #c1c4c7;
  --x-btn-active-border-color: #b5b8bb;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #f1f5f9;
  --x-btn-disabled-border-color: #f1f5f9;
}

.btn-dark {
  --x-btn-color: #fff;
  --x-btn-bg: #252422;
  --x-btn-border-color: #252422;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #51504e;
  --x-btn-hover-border-color: #3b3a38;
  --x-btn-focus-shadow-rgb: 70, 69, 67;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #51504e;
  --x-btn-active-border-color: #3b3a38;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #252422;
  --x-btn-disabled-border-color: #252422;
}

.btn-outline-primary {
  --x-btn-color: #3d5a80;
  --x-btn-border-color: #3d5a80;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #3d5a80;
  --x-btn-hover-border-color: #3d5a80;
  --x-btn-focus-shadow-rgb: 61, 90, 128;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #3d5a80;
  --x-btn-active-border-color: #3d5a80;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #3d5a80;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #3d5a80;
  --x-gradient: none;
}

.btn-outline-secondary {
  --x-btn-color: #98c1d9;
  --x-btn-border-color: #98c1d9;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #98c1d9;
  --x-btn-hover-border-color: #98c1d9;
  --x-btn-focus-shadow-rgb: 152, 193, 217;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #98c1d9;
  --x-btn-active-border-color: #98c1d9;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #98c1d9;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #98c1d9;
  --x-gradient: none;
}

.btn-outline-success {
  --x-btn-color: #22c55e;
  --x-btn-border-color: #22c55e;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #22c55e;
  --x-btn-hover-border-color: #22c55e;
  --x-btn-focus-shadow-rgb: 34, 197, 94;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #22c55e;
  --x-btn-active-border-color: #22c55e;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #22c55e;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #22c55e;
  --x-gradient: none;
}

.btn-outline-info {
  --x-btn-color: #06b6d4;
  --x-btn-border-color: #06b6d4;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #06b6d4;
  --x-btn-hover-border-color: #06b6d4;
  --x-btn-focus-shadow-rgb: 6, 182, 212;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #06b6d4;
  --x-btn-active-border-color: #06b6d4;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #06b6d4;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #06b6d4;
  --x-gradient: none;
}

.btn-outline-warning {
  --x-btn-color: #f97316;
  --x-btn-border-color: #f97316;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #f97316;
  --x-btn-hover-border-color: #f97316;
  --x-btn-focus-shadow-rgb: 249, 115, 22;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #f97316;
  --x-btn-active-border-color: #f97316;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #f97316;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #f97316;
  --x-gradient: none;
}

.btn-outline-danger {
  --x-btn-color: #ef4444;
  --x-btn-border-color: #ef4444;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #ef4444;
  --x-btn-hover-border-color: #ef4444;
  --x-btn-focus-shadow-rgb: 239, 68, 68;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #ef4444;
  --x-btn-active-border-color: #ef4444;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #ef4444;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #ef4444;
  --x-gradient: none;
}

.btn-outline-light {
  --x-btn-color: #f1f5f9;
  --x-btn-border-color: #f1f5f9;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #f1f5f9;
  --x-btn-hover-border-color: #f1f5f9;
  --x-btn-focus-shadow-rgb: 241, 245, 249;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #f1f5f9;
  --x-btn-active-border-color: #f1f5f9;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #f1f5f9;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #f1f5f9;
  --x-gradient: none;
}

.btn-outline-dark {
  --x-btn-color: #252422;
  --x-btn-border-color: #252422;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #252422;
  --x-btn-hover-border-color: #252422;
  --x-btn-focus-shadow-rgb: 37, 36, 34;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #252422;
  --x-btn-active-border-color: #252422;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #252422;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #252422;
  --x-gradient: none;
}

.btn-link {
  --x-btn-font-weight: 400;
  --x-btn-color: var(--x-link-color);
  --x-btn-bg: transparent;
  --x-btn-border-color: transparent;
  --x-btn-hover-color: var(--x-link-hover-color);
  --x-btn-hover-border-color: transparent;
  --x-btn-active-color: var(--x-link-hover-color);
  --x-btn-active-border-color: transparent;
  --x-btn-disabled-color: #6b7b93;
  --x-btn-disabled-border-color: transparent;
  --x-btn-box-shadow: 0 0 0 #000;
  --x-btn-focus-shadow-rgb: 90, 115, 147;
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: none;
}

.btn-link:focus-visible {
  text-decoration: none;
}

.btn-link:focus-visible {
  color: var(--x-btn-color);
}

.btn-link:hover {
  color: var(--x-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --x-btn-padding-y: .75rem;
  --x-btn-padding-x: 1.25rem;
  --x-btn-font-size: 1rem;
  --x-btn-border-radius: var(--x-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --x-btn-padding-y: .375rem;
  --x-btn-padding-x: .875rem;
  --x-btn-font-size: .875rem;
  --x-btn-border-radius: var(--x-border-radius);
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --x-dropdown-zindex: 1000;
  --x-dropdown-min-width: 12rem;
  --x-dropdown-padding-x: .5rem;
  --x-dropdown-padding-y: .5rem;
  --x-dropdown-spacer: .125rem;
  --x-dropdown-font-size: .875rem;
  --x-dropdown-color: var(--x-body-color);
  --x-dropdown-bg: var(--x-body-bg);
  --x-dropdown-border-color: var(--x-border-color);
  --x-dropdown-border-radius: var(--x-border-radius-lg);
  --x-dropdown-border-width: var(--x-border-width);
  --x-dropdown-inner-border-radius: calc(var(--x-border-radius-lg)  - var(--x-border-width));
  --x-dropdown-divider-bg: var(--x-border-color);
  --x-dropdown-divider-margin-y: .5rem;
  --x-dropdown-box-shadow: var(--x-box-shadow-4);
  --x-dropdown-link-color: var(--x-body-color);
  --x-dropdown-link-hover-color: var(--x-body-color);
  --x-dropdown-link-hover-bg: var(--x-secondary-bg);
  --x-dropdown-link-active-color: var(--x-body-color);
  --x-dropdown-link-active-bg: var(--x-secondary-bg);
  --x-dropdown-link-disabled-color: var(--x-tertiary-color);
  --x-dropdown-item-padding-x: 1rem;
  --x-dropdown-item-padding-y: .5rem;
  --x-dropdown-header-color: #6b7b93;
  --x-dropdown-header-padding-x: 1rem;
  --x-dropdown-header-padding-y: .5rem;
  z-index: var(--x-dropdown-zindex);
  min-width: var(--x-dropdown-min-width);
  padding: var(--x-dropdown-padding-y) var(--x-dropdown-padding-x);
  font-size: var(--x-dropdown-font-size);
  color: var(--x-dropdown-color);
  text-align: left;
  background-color: var(--x-dropdown-bg);
  border: var(--x-dropdown-border-width) solid var(--x-dropdown-border-color);
  border-radius: var(--x-dropdown-border-radius);
  box-shadow: var(--x-dropdown-box-shadow);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--x-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--x-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--x-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--x-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid rgba(0, 0, 0, 0);
  border-bottom: .3em solid rgba(0, 0, 0, 0);
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  margin: var(--x-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--x-dropdown-divider-bg);
  opacity: 1;
  height: 0;
  overflow: hidden;
}

.dropdown-item {
  padding: var(--x-dropdown-item-padding-y) var(--x-dropdown-item-padding-x);
  clear: both;
  color: var(--x-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--x-dropdown-item-border-radius, 0);
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  width: 100%;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--x-dropdown-link-hover-color);
  background-color: var(--x-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--x-dropdown-link-active-color);
  background-color: var(--x-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--x-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--x-dropdown-header-padding-y) var(--x-dropdown-header-padding-x);
  color: var(--x-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--x-dropdown-item-padding-y) var(--x-dropdown-item-padding-x);
  color: var(--x-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --x-dropdown-color: #cfd6df;
  --x-dropdown-bg: #2d3748;
  --x-dropdown-border-color: var(--x-border-color);
  --x-dropdown-box-shadow: ;
  --x-dropdown-link-color: #cfd6df;
  --x-dropdown-link-hover-color: #fff;
  --x-dropdown-divider-bg: var(--x-border-color);
  --x-dropdown-link-hover-bg: rgba(255, 255, 255, .15);
  --x-dropdown-link-active-color: var(--x-body-color);
  --x-dropdown-link-active-bg: var(--x-secondary-bg);
  --x-dropdown-link-disabled-color: #8898a9;
  --x-dropdown-header-color: #8898a9;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--x-border-radius);
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--x-border-width) * -1);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .65625rem;
  padding-right: .65625rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 0 rgba(0, 0, 0, 0);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--x-border-width) * -1);
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --x-nav-link-padding-x: 1rem;
  --x-nav-link-padding-y: .5rem;
  --x-nav-link-font-size: .875rem;
  --x-nav-link-font-weight: 500;
  --x-nav-link-color: rgba(var(--x-emphasis-color-rgb), .65);
  --x-nav-link-hover-color: rgba(var(--x-emphasis-color-rgb), .95);
  --x-nav-link-disabled-color: var(--x-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--x-nav-link-padding-y) var(--x-nav-link-padding-x);
  font-size: var(--x-nav-link-font-size);
  font-weight: var(--x-nav-link-font-weight);
  color: var(--x-nav-link-color);
  background: none;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--x-nav-link-hover-color);
}

.nav-link:focus-visible {
  box-shadow: 0 0 0 .15rem var(--x-primary-bg-subtle);
  outline: 0;
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--x-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --x-nav-tabs-border-width: var(--x-border-width);
  --x-nav-tabs-border-color: var(--x-border-color);
  --x-nav-tabs-border-radius: var(--x-border-radius);
  --x-nav-tabs-link-hover-border-color: var(--x-secondary-bg) var(--x-secondary-bg) var(--x-border-color);
  --x-nav-tabs-link-active-color: var(--x-emphasis-color);
  --x-nav-tabs-link-active-bg: var(--x-body-bg);
  --x-nav-tabs-link-active-border-color: var(--x-border-color) var(--x-border-color) var(--x-body-bg);
  border-bottom: var(--x-nav-tabs-border-width) solid var(--x-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--x-nav-tabs-border-width));
  border: var(--x-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--x-nav-tabs-border-radius);
  border-top-right-radius: var(--x-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--x-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--x-nav-tabs-link-active-color);
  background-color: var(--x-nav-tabs-link-active-bg);
  border-color: var(--x-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--x-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --x-nav-pills-border-radius: var(--x-border-radius);
  --x-nav-pills-link-active-color: #fff;
  --x-nav-pills-link-active-bg: #3d5a80;
}

.nav-pills .nav-link {
  border-radius: var(--x-nav-pills-border-radius);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--x-nav-pills-link-active-color);
  background-color: var(--x-nav-pills-link-active-bg);
}

.nav-underline {
  --x-nav-underline-gap: 1rem;
  --x-nav-underline-border-width: .125rem;
  --x-nav-underline-link-active-color: var(--x-emphasis-color);
  gap: var(--x-nav-underline-gap);
}

.nav-underline .nav-link {
  border-bottom: var(--x-nav-underline-border-width) solid transparent;
  padding-left: 0;
  padding-right: 0;
}

.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentColor;
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
  color: var(--x-nav-underline-link-active-color);
  border-bottom-color: currentColor;
  font-weight: 600;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --x-navbar-padding-x: 1rem;
  --x-navbar-padding-y: 1rem;
  --x-navbar-color: rgba(var(--x-emphasis-color-rgb), .85);
  --x-navbar-hover-color: rgba(var(--x-emphasis-color-rgb), .95);
  --x-navbar-disabled-color: rgba(var(--x-emphasis-color-rgb), .3);
  --x-navbar-active-color: rgba(var(--x-emphasis-color-rgb), .95);
  --x-navbar-brand-padding-y: .296875rem;
  --x-navbar-brand-margin-end: 1rem;
  --x-navbar-brand-font-size: 1.25rem;
  --x-navbar-brand-color: rgba(var(--x-emphasis-color-rgb), .95);
  --x-navbar-brand-hover-color: rgba(var(--x-emphasis-color-rgb), .95);
  --x-navbar-nav-link-padding-x: 1rem;
  --x-navbar-toggler-padding-y: .25rem;
  --x-navbar-toggler-padding-x: .375rem;
  --x-navbar-toggler-font-size: 1.25rem;
  --x-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%236B7B93' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --x-navbar-toggler-border-color: transparent;
  --x-navbar-toggler-border-radius: var(--x-border-radius);
  --x-navbar-toggler-focus-width: .15rem;
  --x-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--x-navbar-padding-y) var(--x-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--x-navbar-brand-padding-y);
  padding-bottom: var(--x-navbar-brand-padding-y);
  margin-right: var(--x-navbar-brand-margin-end);
  font-size: var(--x-navbar-brand-font-size);
  color: var(--x-navbar-brand-color);
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--x-navbar-brand-hover-color);
}

.navbar-nav {
  --x-nav-link-padding-x: 0;
  --x-nav-link-padding-y: .5rem;
  --x-nav-link-font-size: .875rem;
  --x-nav-link-font-weight: 500;
  --x-nav-link-color: var(--x-navbar-color);
  --x-nav-link-hover-color: var(--x-navbar-hover-color);
  --x-nav-link-disabled-color: var(--x-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--x-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--x-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--x-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--x-navbar-toggler-padding-y) var(--x-navbar-toggler-padding-x);
  font-size: var(--x-navbar-toggler-font-size);
  color: var(--x-navbar-color);
  border: var(--x-border-width) solid var(--x-navbar-toggler-border-color);
  border-radius: var(--x-navbar-toggler-border-radius);
  transition: var(--x-navbar-toggler-transition);
  background-color: rgba(0, 0, 0, 0);
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--x-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--x-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--x-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--x-navbar-nav-link-padding-x);
  padding-left: var(--x-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  box-shadow: none;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark, .navbar[data-bs-theme="dark"] {
  --x-navbar-color: rgba(255, 255, 255, .75);
  --x-navbar-hover-color: rgba(255, 255, 255, .95);
  --x-navbar-disabled-color: rgba(255, 255, 255, .25);
  --x-navbar-active-color: rgba(255, 255, 255, .95);
  --x-navbar-brand-color: rgba(255, 255, 255, .95);
  --x-navbar-brand-hover-color: rgba(255, 255, 255, .95);
  --x-navbar-toggler-border-color: transparent;
  --x-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23E7EAF0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --x-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23E7EAF0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.card {
  --x-card-spacer-y: 1.5rem;
  --x-card-spacer-x: 1.5rem;
  --x-card-title-spacer-y: .5rem;
  --x-card-title-color: var(--x-heading-color);
  --x-card-subtitle-color: ;
  --x-card-border-width: var(--x-border-width);
  --x-card-border-color: var(--x-border-color);
  --x-card-border-radius: var(--x-border-radius);
  --x-card-box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, .04);
  --x-card-inner-border-radius: calc(var(--x-border-radius)  - (var(--x-border-width)));
  --x-card-cap-padding-y: 1rem;
  --x-card-cap-padding-x: 1.5rem;
  --x-card-cap-bg: rgba(var(--x-body-color-rgb), 0);
  --x-card-cap-color: var(--x-heading-color);
  --x-card-height: ;
  --x-card-color: ;
  --x-card-bg: var(--x-body-bg);
  --x-card-img-overlay-padding: 1rem;
  --x-card-group-margin: .75rem;
  height: var(--x-card-height);
  color: var(--x-body-color);
  word-wrap: break-word;
  background-color: var(--x-card-bg);
  border: var(--x-card-border-width) solid var(--x-card-border-color);
  border-radius: var(--x-card-border-radius);
  box-shadow: var(--x-card-box-shadow);
  background-clip: border-box;
  flex-direction: column;
  min-width: 0;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--x-card-inner-border-radius);
  border-top-right-radius: var(--x-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--x-card-inner-border-radius);
  border-bottom-left-radius: var(--x-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--x-card-spacer-y) var(--x-card-spacer-x);
  color: var(--x-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--x-card-title-spacer-y);
  color: var(--x-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--x-card-title-spacer-y));
  color: var(--x-card-subtitle-color);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--x-card-spacer-x);
}

.card-header {
  padding: var(--x-card-cap-padding-y) var(--x-card-cap-padding-x);
  color: var(--x-card-cap-color);
  background-color: var(--x-card-cap-bg);
  border-bottom: var(--x-card-border-width) solid var(--x-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--x-card-inner-border-radius) var(--x-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--x-card-cap-padding-y) var(--x-card-cap-padding-x);
  color: var(--x-card-cap-color);
  background-color: var(--x-card-cap-bg);
  border-top: var(--x-card-border-width) solid var(--x-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--x-card-inner-border-radius) var(--x-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--x-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--x-card-cap-padding-y));
  margin-left: calc(-.5 * var(--x-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--x-card-bg);
  border-bottom-color: var(--x-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--x-card-cap-padding-x));
  margin-left: calc(-.5 * var(--x-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--x-card-img-overlay-padding);
  border-radius: var(--x-card-inner-border-radius);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--x-card-inner-border-radius);
  border-top-right-radius: var(--x-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--x-card-inner-border-radius);
  border-bottom-left-radius: var(--x-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--x-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --x-accordion-color: var(--x-body-color);
  --x-accordion-bg: transparent;
  --x-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --x-accordion-border-color: #e7eaf0;
  --x-accordion-border-width: 1px;
  --x-accordion-border-radius: .375rem;
  --x-accordion-inner-border-radius: calc(.375rem - 1px);
  --x-accordion-btn-padding-x: 1.25rem;
  --x-accordion-btn-padding-y: 1.5rem;
  --x-accordion-btn-color: var(--x-body-color);
  --x-accordion-btn-bg: transparent;
  --x-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--x-body-color%29'%3e%3cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3e%3c/svg%3e");
  --x-accordion-btn-icon-width: 1.25rem;
  --x-accordion-btn-icon-transform: rotate(180deg);
  --x-accordion-btn-icon-transition: transform .2s ease-in-out;
  --x-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233D5A80'%3e%3cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3e%3c/svg%3e");
  --x-accordion-btn-focus-box-shadow: none;
  --x-accordion-body-padding-x: 1.25rem;
  --x-accordion-body-padding-y: 0 1.5rem;
  --x-accordion-active-color: #3d5a80;
  --x-accordion-active-bg: transparent;
}

.accordion-button {
  padding: var(--x-accordion-btn-padding-y) var(--x-accordion-btn-padding-x);
  color: var(--x-accordion-btn-color);
  text-align: left;
  background-color: var(--x-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--x-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--x-accordion-active-color);
  background-color: var(--x-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--x-accordion-border-width)) 0 var(--x-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--x-accordion-btn-active-icon);
  transform: var(--x-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--x-accordion-btn-icon-width);
  height: var(--x-accordion-btn-icon-width);
  content: "";
  background-image: var(--x-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--x-accordion-btn-icon-width);
  transition: var(--x-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  box-shadow: var(--x-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--x-accordion-color);
  background-color: var(--x-accordion-bg);
  border: var(--x-accordion-border-width) solid var(--x-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--x-accordion-border-radius);
  border-top-right-radius: var(--x-accordion-border-radius);
}

.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--x-accordion-inner-border-radius);
  border-top-right-radius: var(--x-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--x-accordion-border-radius);
  border-bottom-left-radius: var(--x-accordion-border-radius);
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--x-accordion-inner-border-radius);
  border-bottom-left-radius: var(--x-accordion-inner-border-radius);
}

.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--x-accordion-border-radius);
  border-bottom-left-radius: var(--x-accordion-border-radius);
}

.accordion-body {
  padding: var(--x-accordion-body-padding-y) var(--x-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed, .accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button:after {
  --x-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238b9cb3'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --x-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238b9cb3'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --x-breadcrumb-padding-x: 0;
  --x-breadcrumb-padding-y: 0;
  --x-breadcrumb-margin-bottom: 0;
  --x-breadcrumb-font-size: .875rem;
  --x-breadcrumb-bg: ;
  --x-breadcrumb-border-radius: ;
  --x-breadcrumb-divider-color: #abb6c5;
  --x-breadcrumb-item-padding-x: .375rem;
  --x-breadcrumb-item-active-color: #16192c;
  padding: var(--x-breadcrumb-padding-y) var(--x-breadcrumb-padding-x);
  margin-bottom: var(--x-breadcrumb-margin-bottom);
  font-size: var(--x-breadcrumb-font-size);
  background-color: var(--x-breadcrumb-bg);
  border-radius: var(--x-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--x-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--x-breadcrumb-item-padding-x);
  color: var(--x-breadcrumb-divider-color);
  content: var(--x-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--x-breadcrumb-item-active-color);
}

.pagination {
  --x-pagination-padding-x: 1rem;
  --x-pagination-padding-y: .375rem;
  --x-pagination-font-size: .875rem;
  --x-pagination-color: var(--x-body-color-secondary);
  --x-pagination-bg: var(--x-body-bg);
  --x-pagination-border-width: var(--x-border-width);
  --x-pagination-border-color: var(--x-border-color);
  --x-pagination-border-radius: var(--x-border-radius);
  --x-pagination-hover-color: var(--x-link-hover-color);
  --x-pagination-hover-bg: var(--x-tertiary-bg);
  --x-pagination-hover-border-color: var(--x-border-color);
  --x-pagination-focus-color: var(--x-link-hover-color);
  --x-pagination-focus-bg: var(--x-secondary-bg);
  --x-pagination-focus-box-shadow: 0 0 0 .15rem var(--x-primary-bg-subtle);
  --x-pagination-active-color: #fff;
  --x-pagination-active-bg: #3d5a80;
  --x-pagination-active-border-color: #3d5a80;
  --x-pagination-disabled-color: var(--x-secondary-color);
  --x-pagination-disabled-bg: var(--x-secondary-bg);
  --x-pagination-disabled-border-color: var(--x-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--x-pagination-padding-y) var(--x-pagination-padding-x);
  font-size: var(--x-pagination-font-size);
  color: var(--x-pagination-color);
  background-color: var(--x-pagination-bg);
  border: var(--x-pagination-border-width) solid var(--x-pagination-border-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--x-pagination-hover-color);
  background-color: var(--x-pagination-hover-bg);
  border-color: var(--x-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--x-pagination-focus-color);
  background-color: var(--x-pagination-focus-bg);
  box-shadow: var(--x-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--x-pagination-active-color);
  background-color: var(--x-pagination-active-bg);
  border-color: var(--x-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--x-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--x-pagination-disabled-bg);
  border-color: var(--x-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--x-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--x-pagination-border-radius);
  border-bottom-left-radius: var(--x-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--x-pagination-border-radius);
  border-bottom-right-radius: var(--x-pagination-border-radius);
}

.pagination-lg {
  --x-pagination-padding-x: 1.5rem;
  --x-pagination-padding-y: .75rem;
  --x-pagination-font-size: 1.25rem;
  --x-pagination-border-radius: var(--x-border-radius-lg);
}

.pagination-sm {
  --x-pagination-padding-x: .75rem;
  --x-pagination-padding-y: .25rem;
  --x-pagination-font-size: .875rem;
  --x-pagination-border-radius: var(--x-border-radius-sm);
}

.badge {
  --x-badge-padding-x: .6rem;
  --x-badge-padding-y: .2rem;
  --x-badge-font-size: .75em;
  --x-badge-font-weight: 600;
  --x-badge-color: #fff;
  --x-badge-border-radius: .375rem;
  padding: var(--x-badge-padding-y) var(--x-badge-padding-x);
  font-size: var(--x-badge-font-size);
  font-weight: var(--x-badge-font-weight);
  color: var(--x-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--x-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --x-alert-bg: transparent;
  --x-alert-padding-x: 1rem;
  --x-alert-padding-y: .875rem;
  --x-alert-margin-bottom: 0;
  --x-alert-color: inherit;
  --x-alert-border-color: transparent;
  --x-alert-border: 1px solid var(--x-alert-border-color);
  --x-alert-border-radius: .375rem;
  --x-alert-link-color: inherit;
  padding: var(--x-alert-padding-y) var(--x-alert-padding-x);
  margin-bottom: var(--x-alert-margin-bottom);
  color: var(--x-alert-color);
  background-color: var(--x-alert-bg);
  border: var(--x-alert-border);
  border-radius: var(--x-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: var(--x-alert-link-color);
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.09375rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --x-alert-color: var(--x-primary-text-emphasis);
  --x-alert-bg: var(--x-primary-bg-subtle);
  --x-alert-border-color: var(--x-primary-border-subtle);
  --x-alert-link-color: var(--x-primary-text-emphasis);
}

.alert-secondary {
  --x-alert-color: var(--x-secondary-text-emphasis);
  --x-alert-bg: var(--x-secondary-bg-subtle);
  --x-alert-border-color: var(--x-secondary-border-subtle);
  --x-alert-link-color: var(--x-secondary-text-emphasis);
}

.alert-success {
  --x-alert-color: var(--x-success-text-emphasis);
  --x-alert-bg: var(--x-success-bg-subtle);
  --x-alert-border-color: var(--x-success-border-subtle);
  --x-alert-link-color: var(--x-success-text-emphasis);
}

.alert-info {
  --x-alert-color: var(--x-info-text-emphasis);
  --x-alert-bg: var(--x-info-bg-subtle);
  --x-alert-border-color: var(--x-info-border-subtle);
  --x-alert-link-color: var(--x-info-text-emphasis);
}

.alert-warning {
  --x-alert-color: var(--x-warning-text-emphasis);
  --x-alert-bg: var(--x-warning-bg-subtle);
  --x-alert-border-color: var(--x-warning-border-subtle);
  --x-alert-link-color: var(--x-warning-text-emphasis);
}

.alert-danger {
  --x-alert-color: var(--x-danger-text-emphasis);
  --x-alert-bg: var(--x-danger-bg-subtle);
  --x-alert-border-color: var(--x-danger-border-subtle);
  --x-alert-link-color: var(--x-danger-text-emphasis);
}

.alert-light {
  --x-alert-color: var(--x-light-text-emphasis);
  --x-alert-bg: var(--x-light-bg-subtle);
  --x-alert-border-color: var(--x-light-border-subtle);
  --x-alert-link-color: var(--x-light-text-emphasis);
}

.alert-dark {
  --x-alert-color: var(--x-dark-text-emphasis);
  --x-alert-bg: var(--x-dark-bg-subtle);
  --x-alert-border-color: var(--x-dark-border-subtle);
  --x-alert-link-color: var(--x-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: .25rem;
  }
}

.progress, .progress-stacked {
  --x-progress-height: .25rem;
  --x-progress-font-size: .75rem;
  --x-progress-bg: var(--x-secondary-bg);
  --x-progress-border-radius: var(--x-border-radius);
  --x-progress-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-progress-bar-color: #fff;
  --x-progress-bar-bg: #3d5a80;
  --x-progress-bar-transition: width .6s ease;
  height: var(--x-progress-height);
  font-size: var(--x-progress-font-size);
  background-color: var(--x-progress-bg);
  border-radius: var(--x-progress-border-radius);
  box-shadow: var(--x-progress-box-shadow);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--x-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--x-progress-bar-bg);
  transition: var(--x-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
  background-size: var(--x-progress-height) var(--x-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --x-list-group-color: var(--x-body-color);
  --x-list-group-bg: var(--x-body-bg);
  --x-list-group-border-color: var(--x-border-color);
  --x-list-group-border-width: var(--x-border-width);
  --x-list-group-border-radius: var(--x-border-radius);
  --x-list-group-item-padding-x: 1.25rem;
  --x-list-group-item-padding-y: 1.125rem;
  --x-list-group-action-color: var(--x-secondary-color);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-tertiary-bg);
  --x-list-group-action-active-color: var(--x-body-color);
  --x-list-group-action-active-bg: var(--x-secondary-bg);
  --x-list-group-disabled-color: var(--x-secondary-color);
  --x-list-group-disabled-bg: var(--x-body-bg);
  --x-list-group-active-color: #fff;
  --x-list-group-active-bg: #3d5a80;
  --x-list-group-active-border-color: #3d5a80;
  border-radius: var(--x-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  color: var(--x-list-group-action-color);
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--x-list-group-action-hover-color);
  background-color: var(--x-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--x-list-group-action-active-color);
  background-color: var(--x-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--x-list-group-item-padding-y) var(--x-list-group-item-padding-x);
  color: var(--x-list-group-color);
  background-color: var(--x-list-group-bg);
  border: var(--x-list-group-border-width) solid var(--x-list-group-border-color);
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--x-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--x-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--x-list-group-active-color);
  background-color: var(--x-list-group-active-bg);
  border-color: var(--x-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--x-list-group-border-width));
  border-top-width: var(--x-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--x-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--x-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--x-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--x-list-group-border-width));
  border-left-width: var(--x-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--x-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --x-list-group-color: var(--x-primary-text-emphasis);
  --x-list-group-bg: var(--x-primary-bg-subtle);
  --x-list-group-border-color: var(--x-primary-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-primary-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-primary-border-subtle);
  --x-list-group-active-color: var(--x-primary-bg-subtle);
  --x-list-group-active-bg: var(--x-primary-text-emphasis);
  --x-list-group-active-border-color: var(--x-primary-text-emphasis);
}

.list-group-item-secondary {
  --x-list-group-color: var(--x-secondary-text-emphasis);
  --x-list-group-bg: var(--x-secondary-bg-subtle);
  --x-list-group-border-color: var(--x-secondary-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-secondary-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-secondary-border-subtle);
  --x-list-group-active-color: var(--x-secondary-bg-subtle);
  --x-list-group-active-bg: var(--x-secondary-text-emphasis);
  --x-list-group-active-border-color: var(--x-secondary-text-emphasis);
}

.list-group-item-success {
  --x-list-group-color: var(--x-success-text-emphasis);
  --x-list-group-bg: var(--x-success-bg-subtle);
  --x-list-group-border-color: var(--x-success-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-success-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-success-border-subtle);
  --x-list-group-active-color: var(--x-success-bg-subtle);
  --x-list-group-active-bg: var(--x-success-text-emphasis);
  --x-list-group-active-border-color: var(--x-success-text-emphasis);
}

.list-group-item-info {
  --x-list-group-color: var(--x-info-text-emphasis);
  --x-list-group-bg: var(--x-info-bg-subtle);
  --x-list-group-border-color: var(--x-info-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-info-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-info-border-subtle);
  --x-list-group-active-color: var(--x-info-bg-subtle);
  --x-list-group-active-bg: var(--x-info-text-emphasis);
  --x-list-group-active-border-color: var(--x-info-text-emphasis);
}

.list-group-item-warning {
  --x-list-group-color: var(--x-warning-text-emphasis);
  --x-list-group-bg: var(--x-warning-bg-subtle);
  --x-list-group-border-color: var(--x-warning-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-warning-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-warning-border-subtle);
  --x-list-group-active-color: var(--x-warning-bg-subtle);
  --x-list-group-active-bg: var(--x-warning-text-emphasis);
  --x-list-group-active-border-color: var(--x-warning-text-emphasis);
}

.list-group-item-danger {
  --x-list-group-color: var(--x-danger-text-emphasis);
  --x-list-group-bg: var(--x-danger-bg-subtle);
  --x-list-group-border-color: var(--x-danger-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-danger-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-danger-border-subtle);
  --x-list-group-active-color: var(--x-danger-bg-subtle);
  --x-list-group-active-bg: var(--x-danger-text-emphasis);
  --x-list-group-active-border-color: var(--x-danger-text-emphasis);
}

.list-group-item-light {
  --x-list-group-color: var(--x-light-text-emphasis);
  --x-list-group-bg: var(--x-light-bg-subtle);
  --x-list-group-border-color: var(--x-light-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-light-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-light-border-subtle);
  --x-list-group-active-color: var(--x-light-bg-subtle);
  --x-list-group-active-bg: var(--x-light-text-emphasis);
  --x-list-group-active-border-color: var(--x-light-text-emphasis);
}

.list-group-item-dark {
  --x-list-group-color: var(--x-dark-text-emphasis);
  --x-list-group-bg: var(--x-dark-bg-subtle);
  --x-list-group-border-color: var(--x-dark-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-dark-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-dark-border-subtle);
  --x-list-group-active-color: var(--x-dark-bg-subtle);
  --x-list-group-active-bg: var(--x-dark-text-emphasis);
  --x-list-group-active-border-color: var(--x-dark-text-emphasis);
}

.btn-close {
  --x-btn-close-color: currentColor;
  --x-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  --x-btn-close-opacity: .5;
  --x-btn-close-hover-opacity: .75;
  --x-btn-close-focus-shadow: 0 0 0 .15rem var(--x-primary-bg-subtle);
  --x-btn-close-focus-opacity: 1;
  --x-btn-close-disabled-opacity: .25;
  --x-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  color: var(--x-btn-close-color);
  background: transparent var(--x-btn-close-bg) center / 1em auto no-repeat;
  opacity: var(--x-btn-close-opacity);
  border: 0;
  border-radius: .375rem;
  width: 1em;
  height: 1em;
  padding: .25em;
}

.btn-close:hover {
  color: var(--x-btn-close-color);
  opacity: var(--x-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--x-btn-close-focus-shadow);
  opacity: var(--x-btn-close-focus-opacity);
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: var(--x-btn-close-disabled-opacity);
}

.btn-close-white, [data-bs-theme="dark"] .btn-close {
  filter: var(--x-btn-close-white-filter);
}

.toast {
  --x-toast-zindex: 1090;
  --x-toast-padding-x: .75rem;
  --x-toast-padding-y: .5rem;
  --x-toast-spacing: 1.5rem;
  --x-toast-max-width: 350px;
  --x-toast-font-size: .875rem;
  --x-toast-color: ;
  --x-toast-bg: rgba(var(--x-body-bg-rgb), .85);
  --x-toast-border-width: var(--x-border-width);
  --x-toast-border-color: var(--x-border-color-translucent);
  --x-toast-border-radius: var(--x-border-radius);
  --x-toast-box-shadow: var(--x-box-shadow);
  --x-toast-header-color: var(--x-secondary-color);
  --x-toast-header-bg: rgba(var(--x-body-bg-rgb), .85);
  --x-toast-header-border-color: var(--x-border-color-translucent);
  width: var(--x-toast-max-width);
  font-size: var(--x-toast-font-size);
  color: var(--x-toast-color);
  pointer-events: auto;
  background-color: var(--x-toast-bg);
  border: var(--x-toast-border-width) solid var(--x-toast-border-color);
  box-shadow: var(--x-toast-box-shadow);
  border-radius: var(--x-toast-border-radius);
  background-clip: padding-box;
  max-width: 100%;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --x-toast-zindex: 1090;
  z-index: var(--x-toast-zindex);
  pointer-events: none;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--x-toast-spacing);
}

.toast-header {
  padding: var(--x-toast-padding-y) var(--x-toast-padding-x);
  color: var(--x-toast-header-color);
  background-color: var(--x-toast-header-bg);
  border-bottom: var(--x-toast-border-width) solid var(--x-toast-header-border-color);
  border-top-left-radius: calc(var(--x-toast-border-radius)  - var(--x-toast-border-width));
  border-top-right-radius: calc(var(--x-toast-border-radius)  - var(--x-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--x-toast-padding-x));
  margin-left: var(--x-toast-padding-x);
}

.toast-body {
  padding: var(--x-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --x-modal-zindex: 1055;
  --x-modal-width: 500px;
  --x-modal-padding: 1.5rem;
  --x-modal-margin: .5rem;
  --x-modal-color: ;
  --x-modal-bg: var(--x-body-bg);
  --x-modal-border-color: var(--x-border-color);
  --x-modal-border-width: var(--x-border-width);
  --x-modal-border-radius: var(--x-border-radius-lg);
  --x-modal-box-shadow: var(--x-box-shadow-sm);
  --x-modal-inner-border-radius: calc(var(--x-border-radius-lg)  - (var(--x-border-width)));
  --x-modal-header-padding-x: 1.5rem;
  --x-modal-header-padding-y: 1.5rem;
  --x-modal-header-padding: 1.5rem 1.5rem;
  --x-modal-header-border-color: var(--x-border-color);
  --x-modal-header-border-width: var(--x-border-width);
  --x-modal-title-line-height: 1.625;
  --x-modal-footer-gap: .75rem;
  --x-modal-footer-bg: ;
  --x-modal-footer-border-color: var(--x-border-color);
  --x-modal-footer-border-width: var(--x-border-width);
  z-index: var(--x-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  margin: var(--x-modal-margin);
  pointer-events: none;
  width: auto;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: all .15s ease-out;
  transform: translate(0, 15px)scale(.95);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--x-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--x-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  color: var(--x-modal-color);
  pointer-events: auto;
  background-color: var(--x-modal-bg);
  border: var(--x-modal-border-width) solid var(--x-modal-border-color);
  border-radius: var(--x-modal-border-radius);
  box-shadow: var(--x-modal-box-shadow);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --x-backdrop-zindex: 1050;
  --x-backdrop-bg: rgba(22, 25, 44, .2);
  --x-backdrop-opacity: 1;
  z-index: var(--x-backdrop-zindex);
  background-color: var(--x-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--x-backdrop-opacity);
}

.modal-header {
  padding: var(--x-modal-header-padding);
  border-bottom: var(--x-modal-header-border-width) solid var(--x-modal-header-border-color);
  border-top-left-radius: var(--x-modal-inner-border-radius);
  border-top-right-radius: var(--x-modal-inner-border-radius);
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--x-modal-header-padding-y) * .5) calc(var(--x-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--x-modal-header-padding-y)) calc(-.5 * var(--x-modal-header-padding-x)) calc(-.5 * var(--x-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--x-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--x-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--x-modal-padding)  - var(--x-modal-footer-gap) * .5);
  background-color: var(--x-modal-footer-bg);
  border-top: var(--x-modal-footer-border-width) solid var(--x-modal-footer-border-color);
  border-bottom-right-radius: var(--x-modal-inner-border-radius);
  border-bottom-left-radius: var(--x-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--x-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --x-modal-margin: 1.75rem;
    --x-modal-box-shadow: var(--x-box-shadow);
  }

  .modal-dialog {
    max-width: var(--x-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --x-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    --x-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --x-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --x-tooltip-zindex: 1080;
  --x-tooltip-max-width: 200px;
  --x-tooltip-padding-x: .5rem;
  --x-tooltip-padding-y: .25rem;
  --x-tooltip-margin: ;
  --x-tooltip-font-size: .875rem;
  --x-tooltip-color: var(--x-body-bg);
  --x-tooltip-bg: var(--x-emphasis-color);
  --x-tooltip-border-radius: var(--x-border-radius);
  --x-tooltip-opacity: .9;
  --x-tooltip-arrow-width: .8rem;
  --x-tooltip-arrow-height: .4rem;
  z-index: var(--x-tooltip-zindex);
  margin: var(--x-tooltip-margin);
  font-family: var(--x-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  font-size: var(--x-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--x-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--x-tooltip-arrow-width);
  height: var(--x-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--x-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--x-tooltip-arrow-height) calc(var(--x-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--x-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--x-tooltip-arrow-height));
  width: var(--x-tooltip-arrow-height);
  height: var(--x-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--x-tooltip-arrow-width) * .5) var(--x-tooltip-arrow-height) calc(var(--x-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--x-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--x-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--x-tooltip-arrow-width) * .5) var(--x-tooltip-arrow-height);
  border-bottom-color: var(--x-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--x-tooltip-arrow-height));
  width: var(--x-tooltip-arrow-height);
  height: var(--x-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--x-tooltip-arrow-width) * .5) 0 calc(var(--x-tooltip-arrow-width) * .5) var(--x-tooltip-arrow-height);
  border-left-color: var(--x-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--x-tooltip-max-width);
  padding: var(--x-tooltip-padding-y) var(--x-tooltip-padding-x);
  color: var(--x-tooltip-color);
  text-align: center;
  background-color: var(--x-tooltip-bg);
  border-radius: var(--x-tooltip-border-radius);
}

.popover {
  --x-popover-zindex: 1070;
  --x-popover-max-width: 276px;
  --x-popover-font-size: .875rem;
  --x-popover-bg: #fff;
  --x-popover-border-width: 1px;
  --x-popover-border-color: rgba(0, 0, 0, .05);
  --x-popover-border-radius: .5rem;
  --x-popover-inner-border-radius: calc(.5rem - 1px);
  --x-popover-box-shadow: 0px .5rem 2rem 0px rgba(0, 0, 0, .2);
  --x-popover-header-padding-x: .75rem;
  --x-popover-header-padding-y: .75rem;
  --x-popover-header-font-size: 1rem;
  --x-popover-header-color: #16192c;
  --x-popover-header-bg: #fff;
  --x-popover-body-padding-x: .75rem;
  --x-popover-body-padding-y: .75rem;
  --x-popover-body-color: var(--x-body-color);
  --x-popover-arrow-width: 1rem;
  --x-popover-arrow-height: .5rem;
  --x-popover-arrow-border: var(--x-popover-border-color);
  z-index: var(--x-popover-zindex);
  max-width: var(--x-popover-max-width);
  font-family: var(--x-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  font-size: var(--x-popover-font-size);
  word-wrap: break-word;
  background-color: var(--x-popover-bg);
  border: var(--x-popover-border-width) solid var(--x-popover-border-color);
  border-radius: var(--x-popover-border-radius);
  box-shadow: var(--x-popover-box-shadow);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--x-popover-arrow-width);
  height: var(--x-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--x-popover-arrow-height) calc(var(--x-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--x-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--x-popover-border-width);
  border-top-color: var(--x-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
  width: var(--x-popover-arrow-height);
  height: var(--x-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--x-popover-arrow-width) * .5) var(--x-popover-arrow-height) calc(var(--x-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--x-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--x-popover-border-width);
  border-right-color: var(--x-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--x-popover-arrow-width) * .5) var(--x-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--x-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--x-popover-border-width);
  border-bottom-color: var(--x-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--x-popover-arrow-width);
  margin-left: calc(-.5 * var(--x-popover-arrow-width));
  content: "";
  border-bottom: var(--x-popover-border-width) solid var(--x-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
  width: var(--x-popover-arrow-height);
  height: var(--x-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--x-popover-arrow-width) * .5) 0 calc(var(--x-popover-arrow-width) * .5) var(--x-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--x-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--x-popover-border-width);
  border-left-color: var(--x-popover-bg);
}

.popover-header {
  padding: var(--x-popover-header-padding-y) var(--x-popover-header-padding-x);
  font-size: var(--x-popover-header-font-size);
  color: var(--x-popover-header-color);
  background-color: var(--x-popover-header-bg);
  border-bottom: var(--x-popover-border-width) solid var(--x-popover-border-color);
  border-top-left-radius: var(--x-popover-inner-border-radius);
  border-top-right-radius: var(--x-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--x-popover-body-padding-y) var(--x-popover-body-padding-x);
  color: var(--x-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid rgba(0, 0, 0, 0);
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon, [data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon, [data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--x-spinner-width);
  height: var(--x-spinner-height);
  vertical-align: var(--x-spinner-vertical-align);
  animation: var(--x-spinner-animation-speed) linear infinite var(--x-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --x-spinner-width: 2rem;
  --x-spinner-height: 2rem;
  --x-spinner-vertical-align: -.125em;
  --x-spinner-border-width: .25em;
  --x-spinner-animation-speed: .75s;
  --x-spinner-animation-name: spinner-border;
  border: var(--x-spinner-border-width) solid currentcolor;
  border-right-color: rgba(0, 0, 0, 0);
}

.spinner-border-sm {
  --x-spinner-width: 1rem;
  --x-spinner-height: 1rem;
  --x-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --x-spinner-width: 2rem;
  --x-spinner-height: 2rem;
  --x-spinner-vertical-align: -.125em;
  --x-spinner-animation-speed: .75s;
  --x-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --x-spinner-width: 1rem;
  --x-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --x-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --x-offcanvas-zindex: 1045;
  --x-offcanvas-width: 400px;
  --x-offcanvas-height: 30vh;
  --x-offcanvas-padding-x: 1.5rem;
  --x-offcanvas-padding-y: 1.5rem;
  --x-offcanvas-color: var(--x-body-color);
  --x-offcanvas-bg: var(--x-body-bg);
  --x-offcanvas-border-width: var(--x-border-width);
  --x-offcanvas-border-color: var(--x-border-color);
  --x-offcanvas-box-shadow: var(--x-box-shadow-sm);
  --x-offcanvas-transition: transform .3s ease-in-out;
  --x-offcanvas-title-line-height: 1.625;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    z-index: var(--x-offcanvas-zindex);
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    transition: var(--x-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--x-offcanvas-height);
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    z-index: var(--x-offcanvas-zindex);
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    transition: var(--x-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--x-offcanvas-height);
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    z-index: var(--x-offcanvas-zindex);
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    transition: var(--x-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--x-offcanvas-height);
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    z-index: var(--x-offcanvas-zindex);
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    transition: var(--x-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--x-offcanvas-height);
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--x-offcanvas-zindex);
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    transition: var(--x-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--x-offcanvas-height);
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.offcanvas {
  z-index: var(--x-offcanvas-zindex);
  color: var(--x-offcanvas-color);
  visibility: hidden;
  background-color: var(--x-offcanvas-bg);
  box-shadow: var(--x-offcanvas-box-shadow);
  transition: var(--x-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--x-offcanvas-width);
  border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--x-offcanvas-width);
  border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--x-offcanvas-height);
  border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--x-offcanvas-height);
  border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: rgba(22, 25, 44, .2);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 1;
}

.offcanvas-header {
  padding: var(--x-offcanvas-padding-y) var(--x-offcanvas-padding-x);
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--x-offcanvas-padding-y) * .5) calc(var(--x-offcanvas-padding-x) * .5);
  margin: calc(-.5 * var(--x-offcanvas-padding-y)) calc(-.5 * var(--x-offcanvas-padding-x)) calc(-.5 * var(--x-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  line-height: var(--x-offcanvas-title-line-height);
  margin-bottom: 0;
}

.offcanvas-body {
  padding: var(--x-offcanvas-padding-y) var(--x-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.avatar {
  --x-avatar-size: 2.5rem;
  --x-avatar-border-radius: .375rem;
  --x-avatar-font-size: 1rem;
  --x-avatar-font-weight: 600;
  vertical-align: middle;
  font-weight: var(--x-avatar-font-weight);
  height: var(--x-avatar-size);
  width: var(--x-avatar-size);
  font-size: var(--x-avatar-font-size);
  border-radius: var(--x-avatar-border-radius);
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.avatar img {
  border-radius: inherit;
  width: 100%;
}

.avatar + .avatar {
  margin-left: .25rem;
}

.avatar + .avatar-content {
  margin-left: .75rem;
  display: inline-block;
}

.avatar-xl {
  --x-avatar-size: 3.5rem;
  --x-avatar-font-size: 1.375rem;
}

.avatar-lg {
  --x-avatar-size: 3rem;
  --x-avatar-font-size: 1.25rem;
}

.avatar-sm {
  --x-avatar-size: 2rem;
  --x-avatar-font-size: .75rem;
  --x-avatar-border-radius: .25rem;
}

.avatar-xs {
  --x-avatar-size: 1.5rem;
  --x-avatar-font-size: .675rem;
  --x-avatar-border-radius: .25rem;
}

.avatar-group {
  line-height: 1;
  display: inline-block;
}

.avatar-group .avatar {
  z-index: 1;
  transition: margin .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .avatar-group .avatar {
    transition: none;
  }
}

.avatar-group .avatar:hover {
  z-index: 2;
}

.avatar-group .avatar + .avatar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1rem;
}

.avatar-group .avatar-sm + .avatar-sm, .avatar-group .avatar-xs + .avatar-xs {
  margin-left: -.75rem;
}

.avatar-group:hover .avatar {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.avatar-group:hover .avatar-sm {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.avatar-ungroup-hover:hover .avatar:not(:first-child) {
  margin-left: 0;
}

.badge {
  --x-badge-text-transform: ;
  --x-badge-line-height: 1rem;
  text-transform: var(--x-badge-text-transform);
  line-height: var(--x-badge-line-height);
}

.badge-xs {
  --x-badge-padding-x: .5rem;
  --x-badge-padding-y: .1rem;
  font-size: 60%;
}

.badge-md {
  --x-badge-padding-x: .675rem;
  --x-badge-padding-y: .375rem;
}

.badge-lg {
  --x-badge-padding-x: 1rem;
  --x-badge-padding-y: .875rem;
  font-size: 1em;
}

.badge-dot {
  --x-badge-color: var(--x-body-color);
  --x-badge-font-weight: 400;
  font-weight: var(--x-badge-font-weight);
  color: var(--x-badge-color);
  background: none;
  align-items: center;
  padding: 0;
  display: inline-flex;
}

.badge-dot i {
  vertical-align: middle;
  border-radius: 50%;
  width: .375rem;
  height: .375rem;
  margin-right: .5rem;
  display: inline-block;
}

.badge-dot.badge-md i {
  width: .5rem;
  height: .5rem;
}

.badge-dot.badge-lg i {
  width: .625rem;
  height: .625rem;
}

.badge-count {
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  font-size: .75rem;
  font-weight: 600;
  display: inline-flex;
  padding: 0 !important;
}

.badge-count.badge-xs {
  width: 1rem;
  height: 1rem;
  font-size: .5rem;
}

.badge-count.badge-md {
  width: 1.5rem;
  height: 1.5rem;
}

.badge-count.badge-lg {
  width: 2rem;
  height: 2rem;
}

.badge-count svg {
  margin: 0;
}

.btn-soft-primary {
  --x-btn-color: #3d5a80;
  --x-btn-bg: #c5ced9;
  --x-btn-border-color: #c5ced9;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #3d5a80;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 177, 189, 204;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #d1d8e1;
  --x-btn-active-border-color: #cbd3dd;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #c5ced9;
  --x-btn-disabled-border-color: #c5ced9;
}

.btn-soft-secondary {
  --x-btn-color: #98c1d9;
  --x-btn-bg: #e0ecf4;
  --x-btn-border-color: #e0ecf4;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #98c1d9;
  --x-btn-hover-border-color: #000;
  --x-btn-focus-shadow-rgb: 213, 230, 240;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #e6f0f6;
  --x-btn-active-border-color: #e3eef5;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #e0ecf4;
  --x-btn-disabled-border-color: #e0ecf4;
}

.btn-soft-success {
  --x-btn-color: #22c55e;
  --x-btn-bg: #bdeecf;
  --x-btn-border-color: #bdeecf;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #22c55e;
  --x-btn-hover-border-color: #000;
  --x-btn-focus-shadow-rgb: 166, 232, 190;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #caf1d9;
  --x-btn-active-border-color: #c4f0d4;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #bdeecf;
  --x-btn-disabled-border-color: #bdeecf;
}

.btn-soft-info {
  --x-btn-color: #06b6d4;
  --x-btn-bg: #b4e9f2;
  --x-btn-border-color: #b4e9f2;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #06b6d4;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 154, 225, 238;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #c3edf5;
  --x-btn-active-border-color: #bcebf3;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #b4e9f2;
  --x-btn-disabled-border-color: #b4e9f2;
}

.btn-soft-warning {
  --x-btn-color: #f97316;
  --x-btn-bg: #fdd5b9;
  --x-btn-border-color: #fdd5b9;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #f97316;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 252, 198, 161;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #fdddc7;
  --x-btn-active-border-color: #fdd9c0;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #fdd5b9;
  --x-btn-disabled-border-color: #fdd5b9;
}

.btn-soft-danger {
  --x-btn-color: #ef4444;
  --x-btn-bg: #fac7c7;
  --x-btn-border-color: #fac7c7;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #ef4444;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 248, 179, 179;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #fbd2d2;
  --x-btn-active-border-color: #fbcdcd;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #fac7c7;
  --x-btn-disabled-border-color: #fac7c7;
}

.btn-soft-light {
  --x-btn-color: #f1f5f9;
  --x-btn-bg: #fbfcfd;
  --x-btn-border-color: #fbfcfd;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #f1f5f9;
  --x-btn-hover-border-color: #000;
  --x-btn-focus-shadow-rgb: 250, 251, 252;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #fcfdfd;
  --x-btn-active-border-color: #fbfcfd;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #fbfcfd;
  --x-btn-disabled-border-color: #fbfcfd;
}

.btn-soft-dark {
  --x-btn-color: #252422;
  --x-btn-bg: #bebdbd;
  --x-btn-border-color: #bebdbd;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #252422;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 167, 166, 166;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #cbcaca;
  --x-btn-active-border-color: #c5c4c4;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #bebdbd;
  --x-btn-disabled-border-color: #bebdbd;
}

.btn-neutral {
  --x-btn-color: #525f7f;
  --x-btn-bg: #fff;
  --x-btn-border-color: #cfd6df;
  --x-btn-hover-color: #525f7f;
  --x-btn-hover-bg: #f5f9fc;
  --x-btn-hover-border-color: #e7eaf0;
  --x-btn-focus-shadow-rgb: 188, 196, 209;
  --x-btn-active-color: #525f7f;
  --x-btn-active-bg: #e7eaf0;
  --x-btn-active-border-color: #cfd6df;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #fff;
  --x-btn-disabled-border-color: #cfd6df;
}

.btn-white, .btn-inverse {
  --x-btn-color: #252422;
  --x-btn-bg: #fff;
  --x-btn-border-color: #fff;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #3d5a80;
  --x-btn-hover-border-color: #3d5a80;
  --x-btn-focus-shadow-rgb: 222, 222, 222;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #3d5a80;
  --x-btn-active-border-color: #3d5a80;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #fff;
  --x-btn-disabled-border-color: #fff;
}

.btn-xs {
  --x-btn-padding-y: .25rem;
  --x-btn-padding-x: .75rem;
  --x-btn-font-size: .875rem;
}

.btn-square {
  --x-btn-size: 44px;
  width: var(--x-btn-size);
  height: var(--x-btn-size);
  justify-content: center;
  align-items: center;
  padding: 0;
  display: inline-flex;
}

.btn-square.btn-xs {
  --x-btn-size: 35.5px;
}

.btn-square.btn-sm, .btn-group-sm > .btn-square.btn {
  --x-btn-size: 36.75px;
}

.btn-square.btn-lg, .btn-group-lg > .btn-square.btn {
  --x-btn-size: 52px;
}

.btn-square.btn-xl {
  --x-btn-size: 56px;
}

.btn-animated {
  position: relative;
  overflow: hidden;
}

.btn-animated .btn-inner-visible {
  position: relative;
}

.btn-animated .btn-inner-hidden {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.btn-animated-x .btn-inner-visible {
  transition: right .3s;
  right: 0;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-x .btn-inner-visible {
    transition: none;
  }
}

.btn-animated-x .btn-inner-hidden {
  transition: right .3s;
  right: -100%;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-x .btn-inner-hidden {
    transition: none;
  }
}

.btn-animated-x:hover .btn-inner-hidden {
  right: 0;
}

.btn-animated-x:hover .btn-inner-visible {
  right: 150%;
}

.btn-animated-y .btn-inner-visible {
  transition: top .3s;
  top: 0;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-y .btn-inner-visible {
    transition: none;
  }
}

.btn-animated-y .btn-inner-hidden {
  transition: top .3s;
  top: -100%;
  left: 0;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-y .btn-inner-hidden {
    transition: none;
  }
}

.btn-animated-y:hover .btn-inner-hidden {
  top: 50%;
}

.btn-animated-y:hover .btn-inner-visible {
  top: 100px;
}

.card-comment-box {
  width: 100%;
}

.card-comment-box input, .card-comment-box textarea {
  box-shadow: none;
  color: #2d3748;
  resize: none;
  background: none;
  border: 0;
  outline: none;
  margin: 0;
  padding: .3125rem 0;
}

.card-comment-box input:focus, .card-comment-box textarea:focus {
  box-shadow: none;
  background: none;
}

.dropdown-item {
  --x-dropdown-item-border-radius: var(--x-border-radius-lg);
  border-radius: var(--x-dropdown-item-border-radius, 0);
}

.dropdown-menu-fluid {
  width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
  left: var(--x-gutter-x, 1.5rem) !important;
}

.dropdown-menu-fluid[data-bs-popper] {
  left: var(--x-gutter-x, 1.5rem);
}

@media (min-width: 576px) {
  .dropdown-menu-sm-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-sm-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-md-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-lg-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-xl-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-xxl-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 768px) {
  .dropdown-menu-sm {
    --x-dropdown-min-width: 10rem;
  }

  .dropdown-menu-sm.dropdown-menu-arrow.dropdown-menu-right:before {
    left: auto;
    right: 2rem;
  }

  .dropdown-menu-md {
    --x-dropdown-min-width: 25rem;
  }

  .dropdown-menu-lg {
    --x-dropdown-min-width: 30rem;
  }

  .dropdown-menu-xl {
    --x-dropdown-min-width: 40rem;
  }

  .dropdown-menu-2xl {
    --x-dropdown-min-width: 50rem;
  }

  .dropdown-menu-centered {
    transform: translateX(-50%)translateY(-3px)scale(.96);
    left: 50% !important;
  }
}

.dropdown-body {
  padding-left: var(--x-dropdown-item-padding-x);
  padding-right: var(--x-dropdown-item-padding-x);
}

.dropdown-heading {
  color: var(--x-heading-color);
  font-size: 1rem;
  font-weight: 500;
}

.dropdown-helper {
  color: #6b7b93;
}

.dropdown-group {
  display: block;
}

.dropdown-group:hover .dropdown-heading {
  color: #3d5a80;
}

.dropdown-secondary {
  background-color: var(--x-secondary-bg);
}

.frame-laptop {
  position: relative;
}

.frame-laptop .frame-inner {
  width: 70%;
  height: 78%;
  margin: 2.5% 15% 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame-iphone {
  z-index: 100;
  position: relative;
}

.frame-iphone .frame-inner {
  z-index: -1;
  border-radius: 5%;
  width: 92%;
  height: 96%;
  margin: 4% 4% 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame-stack {
  position: relative;
}

.frame-stack .frame {
  position: absolute;
}

.frame-stack-iphone-iphone {
  padding-bottom: 130.25%;
}

.frame-stack-iphone-iphone > .frame-iphone:first-child {
  z-index: 1;
  width: 65.526%;
  bottom: 0;
  left: 0;
}

.frame-stack-iphone-iphone > .frame-iphone:last-child {
  width: 72.8324%;
  top: 0;
  right: 0;
}

.frame-stack-iphone-laptop, .frame-stack-laptop-iphone {
  padding-bottom: 62.426%;
}

.frame-stack-iphone-laptop > .frame-laptop, .frame-stack-laptop-iphone > .frame-laptop {
  width: 91.716%;
}

.frame-stack-iphone-laptop > .frame-iphone, .frame-stack-laptop-iphone > .frame-iphone {
  z-index: 1;
  width: 27.9586%;
}

.frame-stack-laptop-iphone > .frame-laptop {
  top: 0;
  left: 0;
}

.frame-stack-iphone-laptop > .frame-laptop {
  top: 0;
  right: 0;
}

.frame-stack-laptop-iphone > .frame-iphone {
  bottom: 0;
  right: 0;
}

.frame-stack-iphone-laptop > .frame-iphone {
  bottom: 0;
  left: 0;
}

.icon {
  --x-size: 3rem;
  font-size: calc(var(--x-size) / 1.75);
  line-height: 1;
}

.icon svg {
  width: 1em;
  height: 1em;
}

.icon-xl {
  --x-size: 5rem;
}

.icon-lg {
  --x-size: 4rem;
}

.icon-sm {
  --x-size: 2rem;
}

.icon-xs {
  --x-size: 1.25rem;
}

.icon-shape {
  text-align: center;
  vertical-align: middle;
  width: var(--x-size);
  height: var(--x-size);
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.list-group-flush {
  --x-list-group-item-padding-x: 0;
}

.list-group-borderless {
  --x-list-group-border-width: 0;
  --x-list-group-item-padding-y: 0;
}

.list-group .form-check-input:checked + .form-checked-content {
  opacity: .5;
}

.list-group .form-check-input-placeholder {
  border-style: dashed;
}

.list-group [contenteditable]:focus {
  outline: 0;
}

.list-group-checkable .list-group-item {
  cursor: pointer;
}

.list-group-item-check {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.list-group-item-check:hover + .list-group-item {
  color: var(--x-list-group-action-hover-color);
  background-color: var(--x-list-group-action-hover-bg);
  border-color: var(--x-list-group-action-hover-bg);
}

.list-group-item-check:checked + .list-group-item {
  color: var(--x-list-group-action-active-color);
  background-color: var(--x-list-group-action-active-bg);
  border-color: var(--x-list-group-action-active-bg);
}

.list-group-item-check[disabled] + .list-group-item, .list-group-item-check:disabled + .list-group-item {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.list-group-radio {
  --x-list-group-checked-border-color: var(--x-primary);
  --x-list-group-checked-shadow-color: var(--x-primary);
}

.list-group-radio .list-group-item {
  cursor: pointer;
}

.list-group-radio .form-check-input {
  z-index: 2;
  margin-top: -.5em;
}

.list-group-radio .form-check-input:checked + .list-group-item {
  border-color: var(--x-list-group-checked-border-color);
  box-shadow: 0 0 0 1px var(--x-list-group-checked-shadow-color);
}

.list-group-radio .form-check-input[disabled] + .list-group-item, .list-group-radio .form-check-input:disabled + .list-group-item {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

@media (max-width: 767.98px) {
  .modal-drawer {
    --x-modal-border-radius: var(--x-border-radius-lg) var(--x-border-radius-lg) 0 0;
    justify-content: center;
    align-items: end;
    margin: 0;
    transition: transform .15s;
    display: flex;
    transform: translateY(100%);
  }

  .modal-drawer.show {
    transform: translateY(0);
  }

  .modal-drawer .modal-dialog {
    align-items: end;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
  }
}

.nav-tabs-flush {
  --x-nav-link-padding-x: 0;
  --x-nav-tabs-border-radius: 0;
  --x-nav-tabs-link-active-border-color: #3d5a80;
  border: 0;
}

.nav-tabs-flush .nav-item.show .nav-link {
  border-color: rgba(0, 0, 0, 0);
}

.nav-tabs-flush .nav-link {
  border-bottom: var(--x-nav-tabs-border-width) solid transparent;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
}

.navbar {
  --x-navbar-nav-link-bg: transparent;
  --x-navbar-nav-link-hover-bg: #f5f9fc;
  --x-navbar-nav-link-active-bg: #f5f9fc;
  --x-navbar-nav-link-padding-x: 1rem;
  --x-navbar-nav-link-padding-y: .375rem;
  --x-navbar-nav-link-border-radius: var(--x-border-radius);
  --x-navbar-icon-color: #2d3748;
  --x-navbar-collapse-border-color: rgba(37, 36, 34, .1);
}

.navbar-user {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.navbar-img-brand {
  font-size: initial;
}

.navbar-collapse:before {
  content: "";
  opacity: 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--x-navbar-collapse-border-color);
  margin: .75rem -1rem;
  display: block;
}

.navbar-collapse.show:before, .navbar-collapse.collapsing:before {
  opacity: 1;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav {
    --x-navbar-nav-link-padding-x: 1rem;
    --x-navbar-nav-link-padding-y: .5rem;
  }

  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link, .navbar-expand-sm .navbar-nav > .nav-link {
    background-color: var(--x-navbar-nav-link-bg);
    padding-top: var(--x-navbar-nav-link-padding-y);
    padding-bottom: var(--x-navbar-nav-link-padding-y);
    border-radius: var(--x-navbar-nav-link-border-radius);
  }

  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand-sm .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand-sm .navbar-nav > .nav-link:hover, .navbar-expand-sm .navbar-nav > .nav-link:focus {
    background-color: var(--x-navbar-nav-link-hover-bg);
  }

  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link.active, .navbar-expand-sm .navbar-nav > .nav-link.active {
    background-color: var(--x-navbar-nav-link-active-bg);
  }

  .navbar-expand-sm .navbar-brand.dropdown-toggle:after, .navbar-expand-sm .navbar-collapse .collapse-header, .navbar-expand-sm .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav {
    --x-navbar-nav-link-padding-x: 1rem;
    --x-navbar-nav-link-padding-y: .5rem;
  }

  .navbar-expand-md .navbar-nav > .nav-item > .nav-link, .navbar-expand-md .navbar-nav > .nav-link {
    background-color: var(--x-navbar-nav-link-bg);
    padding-top: var(--x-navbar-nav-link-padding-y);
    padding-bottom: var(--x-navbar-nav-link-padding-y);
    border-radius: var(--x-navbar-nav-link-border-radius);
  }

  .navbar-expand-md .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand-md .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand-md .navbar-nav > .nav-link:hover, .navbar-expand-md .navbar-nav > .nav-link:focus {
    background-color: var(--x-navbar-nav-link-hover-bg);
  }

  .navbar-expand-md .navbar-nav > .nav-item > .nav-link.active, .navbar-expand-md .navbar-nav > .nav-link.active {
    background-color: var(--x-navbar-nav-link-active-bg);
  }

  .navbar-expand-md .navbar-brand.dropdown-toggle:after, .navbar-expand-md .navbar-collapse .collapse-header, .navbar-expand-md .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    --x-navbar-nav-link-padding-x: 1rem;
    --x-navbar-nav-link-padding-y: .5rem;
  }

  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link, .navbar-expand-lg .navbar-nav > .nav-link {
    background-color: var(--x-navbar-nav-link-bg);
    padding-top: var(--x-navbar-nav-link-padding-y);
    padding-bottom: var(--x-navbar-nav-link-padding-y);
    border-radius: var(--x-navbar-nav-link-border-radius);
  }

  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand-lg .navbar-nav > .nav-link:hover, .navbar-expand-lg .navbar-nav > .nav-link:focus {
    background-color: var(--x-navbar-nav-link-hover-bg);
  }

  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link.active, .navbar-expand-lg .navbar-nav > .nav-link.active {
    background-color: var(--x-navbar-nav-link-active-bg);
  }

  .navbar-expand-lg .navbar-brand.dropdown-toggle:after, .navbar-expand-lg .navbar-collapse .collapse-header, .navbar-expand-lg .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav {
    --x-navbar-nav-link-padding-x: 1rem;
    --x-navbar-nav-link-padding-y: .5rem;
  }

  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link, .navbar-expand-xl .navbar-nav > .nav-link {
    background-color: var(--x-navbar-nav-link-bg);
    padding-top: var(--x-navbar-nav-link-padding-y);
    padding-bottom: var(--x-navbar-nav-link-padding-y);
    border-radius: var(--x-navbar-nav-link-border-radius);
  }

  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand-xl .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand-xl .navbar-nav > .nav-link:hover, .navbar-expand-xl .navbar-nav > .nav-link:focus {
    background-color: var(--x-navbar-nav-link-hover-bg);
  }

  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link.active, .navbar-expand-xl .navbar-nav > .nav-link.active {
    background-color: var(--x-navbar-nav-link-active-bg);
  }

  .navbar-expand-xl .navbar-brand.dropdown-toggle:after, .navbar-expand-xl .navbar-collapse .collapse-header, .navbar-expand-xl .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-nav {
    --x-navbar-nav-link-padding-x: 1rem;
    --x-navbar-nav-link-padding-y: .5rem;
  }

  .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link, .navbar-expand-xxl .navbar-nav > .nav-link {
    background-color: var(--x-navbar-nav-link-bg);
    padding-top: var(--x-navbar-nav-link-padding-y);
    padding-bottom: var(--x-navbar-nav-link-padding-y);
    border-radius: var(--x-navbar-nav-link-border-radius);
  }

  .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand-xxl .navbar-nav > .nav-link:hover, .navbar-expand-xxl .navbar-nav > .nav-link:focus {
    background-color: var(--x-navbar-nav-link-hover-bg);
  }

  .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link.active, .navbar-expand-xxl .navbar-nav > .nav-link.active {
    background-color: var(--x-navbar-nav-link-active-bg);
  }

  .navbar-expand-xxl .navbar-brand.dropdown-toggle:after, .navbar-expand-xxl .navbar-collapse .collapse-header, .navbar-expand-xxl .navbar-collapse:before {
    display: none;
  }
}

.navbar-expand .navbar-nav {
  --x-navbar-nav-link-padding-x: 1rem;
  --x-navbar-nav-link-padding-y: .5rem;
}

.navbar-expand .navbar-nav > .nav-item > .nav-link, .navbar-expand .navbar-nav > .nav-link {
  background-color: var(--x-navbar-nav-link-bg);
  padding-top: var(--x-navbar-nav-link-padding-y);
  padding-bottom: var(--x-navbar-nav-link-padding-y);
  border-radius: var(--x-navbar-nav-link-border-radius);
}

.navbar-expand .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand .navbar-nav > .nav-link:hover, .navbar-expand .navbar-nav > .nav-link:focus {
  background-color: var(--x-navbar-nav-link-hover-bg);
}

.navbar-expand .navbar-nav > .nav-item > .nav-link.active, .navbar-expand .navbar-nav > .nav-link.active {
  background-color: var(--x-navbar-nav-link-active-bg);
}

.navbar-expand .navbar-brand.dropdown-toggle:after, .navbar-expand .navbar-collapse .collapse-header, .navbar-expand .navbar-collapse:before {
  display: none;
}

.navbar-toggler {
  border-radius: var(--x-border-radius);
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  padding: .25rem .375rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  outline: 0;
  text-decoration: none;
  box-shadow: 0 0 0 .15rem;
}

.navbar-light .navbar-toggler {
  color: #6b7b93;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.navbar-light .navbar-toggler:focus {
  color: rgba(0, 0, 0, 0);
  background-color: #f5f9fc;
}

.navbar-dark .navbar-toggler {
  color: #e7eaf0;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.navbar-dark .navbar-toggler:focus {
  color: rgba(0, 0, 0, 0);
  background-color: #f5f9fc;
}

.navbar-toggler-icon {
  width: 1.25em;
  height: 1.25em;
}

.navbar-dark, .navbar[data-bs-theme="dark"] {
  --x-navbar-nav-link-bg: transparent;
  --x-navbar-nav-link-hover-bg: rgba(255, 255, 255, .1);
  --x-navbar-nav-link-active-bg: rgba(255, 255, 255, .1);
  --x-navbar-collapse-border-color: rgba(255, 255, 255, .25);
}

.navbar-vertical {
  --x-nav-link-font-size: .875rem;
}

.navbar-vertical .navbar-nav {
  --x-nav-link-padding-x: 1rem;
  --x-nav-link-padding-y: .675rem;
}

.navbar-vertical .navbar-nav .nav-link {
  white-space: nowrap;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar-vertical .navbar-nav .nav-link i, .navbar-vertical .navbar-nav .nav-link svg {
  min-width: 1.625rem;
}

.navbar-vertical .navbar-nav .nav-link .dropdown-menu {
  border: none;
}

.navbar-vertical .navbar-nav .nav-link .dropdown-menu .dropdown-menu {
  margin-left: .5rem;
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  content: "";
  background-repeat: no-repeat;
  background-size: .875rem .875rem;
  width: .875rem;
  height: .875rem;
  margin-left: auto;
  transition: all .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
    transition: none;
  }
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]:after {
  color: #3d5a80;
  transform: rotate(90deg);
}

.navbar-vertical .navbar-nav > .nav-item {
  margin-top: 2px;
}

.navbar-vertical .navbar-nav .nav-sm .nav-link {
  font-size: .875rem;
}

.navbar-vertical .navbar-nav .nav .nav-link {
  padding-top: calc(var(--x-nav-link-padding-y) / 1.5);
  padding-bottom: calc(var(--x-nav-link-padding-y) / 1.5);
  padding-left: calc(var(--x-nav-link-padding-x)  + 1.625rem);
  font-weight: 400;
}

.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 2.4375rem;
}

.navbar-vertical.navbar-light .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='rgba(var(--x-emphasis-color-rgb), 0.85)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.navbar-vertical.navbar-light .nav-link-text, .navbar-vertical.navbar-light .navbar-heading {
  color: var(--x-navbar-color);
}

.navbar-vertical.navbar-dark .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='rgba(255, 255, 255, 0.75)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.navbar-vertical.navbar-dark .nav-link-text, .navbar-vertical.navbar-dark .navbar-heading {
  color: rgba(255, 255, 255, .75);
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    z-index: 1000;
    border-radius: 0;
    width: 100%;
    max-width: 280px;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }

  .navbar-vertical.navbar-expand-sm + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-sm .nav-link-text, .navbar-vertical.navbar-expand-sm .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .15s ease-in-out;
  }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-sm .nav-link-text, .navbar-vertical.navbar-expand-sm .navbar-heading {
    transition: none;
  }
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.show {
    border-radius: 0;
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-sm.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-sm.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-sm.show .nav-link-text, .navbar-vertical.navbar-expand-sm.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-sm.show + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-sm.hide {
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-sm.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-sm.hide + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-sm .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-sm > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    z-index: 1000;
    border-radius: 0;
    width: 100%;
    max-width: 280px;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }

  .navbar-vertical.navbar-expand-md + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-md .nav-link-text, .navbar-vertical.navbar-expand-md .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .15s ease-in-out;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-md .nav-link-text, .navbar-vertical.navbar-expand-md .navbar-heading {
    transition: none;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.show {
    border-radius: 0;
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-md.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-md.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-md.show .nav-link-text, .navbar-vertical.navbar-expand-md.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-md.show + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-md.hide {
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-md.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-md.hide + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-md .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-md > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    z-index: 1000;
    border-radius: 0;
    width: 100%;
    max-width: 280px;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }

  .navbar-vertical.navbar-expand-lg + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-lg .nav-link-text, .navbar-vertical.navbar-expand-lg .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .15s ease-in-out;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-lg .nav-link-text, .navbar-vertical.navbar-expand-lg .navbar-heading {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.show {
    border-radius: 0;
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-lg.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-lg.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-lg.show .nav-link-text, .navbar-vertical.navbar-expand-lg.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-lg.show + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-lg.hide {
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-lg.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-lg.hide + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-lg .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-lg > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    z-index: 1000;
    border-radius: 0;
    width: 100%;
    max-width: 280px;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }

  .navbar-vertical.navbar-expand-xl + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xl .nav-link-text, .navbar-vertical.navbar-expand-xl .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .15s ease-in-out;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xl .nav-link-text, .navbar-vertical.navbar-expand-xl .navbar-heading {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.show {
    border-radius: 0;
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-xl.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-xl.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-xl.show .nav-link-text, .navbar-vertical.navbar-expand-xl.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-xl.show + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xl.hide {
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-xl.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-xl.hide + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xl .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-xl > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    z-index: 1000;
    border-radius: 0;
    width: 100%;
    max-width: 280px;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }

  .navbar-vertical.navbar-expand-xxl + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xxl .nav-link-text, .navbar-vertical.navbar-expand-xxl .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .15s ease-in-out;
  }
}

@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xxl .nav-link-text, .navbar-vertical.navbar-expand-xxl .navbar-heading {
    transition: none;
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl.show {
    border-radius: 0;
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-xxl.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-xxl.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-xxl.show .nav-link-text, .navbar-vertical.navbar-expand-xxl.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-xxl.show + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xxl.hide {
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-xxl.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-xxl.hide + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-xxl > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

.navbar-vertical.navbar-expand {
  z-index: 1000;
  border-radius: 0;
  width: 100%;
  max-width: 280px;
  display: block;
  overflow-y: auto;
  box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
}

.navbar-vertical.navbar-expand + .main-content {
  margin-left: 280px;
}

.navbar-vertical.navbar-expand .nav-link-text, .navbar-vertical.navbar-expand .navbar-heading {
  opacity: 0;
  pointer-events: none;
  transition: all .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand .nav-link-text, .navbar-vertical.navbar-expand .navbar-heading {
    transition: none;
  }
}

.navbar-vertical.navbar-expand.show {
  border-radius: 0;
  max-width: 280px;
}

.navbar-vertical.navbar-expand.show.navbar-lg {
  max-width: 320px;
}

.navbar-vertical.navbar-expand.show.navbar-xl {
  max-width: 370px;
}

.navbar-vertical.navbar-expand.show .nav-link-text, .navbar-vertical.navbar-expand.show .navbar-heading {
  opacity: 1;
  pointer-events: auto;
}

.navbar-vertical.navbar-expand.show + .main-content {
  margin-left: 280px;
}

.navbar-vertical.navbar-expand.hide {
  max-width: 280px;
}

.navbar-vertical.navbar-expand.hide .nav-link-text {
  opacity: 0;
  pointer-events: none;
}

.navbar-vertical.navbar-expand.hide + .main-content {
  margin-left: 280px;
}

.navbar-vertical.navbar-expand .navbar-brand {
  margin-right: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.navbar-vertical.navbar-expand > [class*="container"] {
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.navbar-vertical.navbar-expand .navbar-collapse {
  opacity: 1;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  display: flex;
}

.navbar-vertical.navbar-expand .navbar-nav {
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
}

.pagination {
  margin: 0;
}

.pagination-spaced {
  border-radius: 0;
}

.pagination-spaced .page-link {
  border-radius: var(--x-border-radius);
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  display: inline-flex;
}

.pagination-spaced.pagination-sm .page-link {
  border-radius: .25rem;
  width: 1.875rem;
  height: 1.875rem;
}

.pagination-spaced.pagination-lg .page-link {
  width: 3.25rem;
  height: 3.25rem;
}

@keyframes progressCircleGrow {
  0%, 33% {
    --x-progress-circle-percentage: 0;
  }

  100% {
    --x-progress-circle-percentage: var(--value);
  }
}

@property --x-progress-circle-percentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

.progress-circle {
  --x-progress-circle-size: 44px;
  --x-progress-circle-bg: var(--x-secondary-bg);
  --x-progress-circle-color: #3d5a80;
  --x-progress-circle-bar-bg: #3d5a80;
  --x-progress-circle-percentage: var(--value);
  width: var(--x-progress-circle-size);
  height: var(--x-progress-circle-size);
  background: radial-gradient(closest-side, currentColor 80%, transparent 0 99.9%, currentColor 0), conic-gradient(var(--x-progress-circle-bar-bg) calc(var(--x-progress-circle-percentage) * 1%), var(--x-progress-circle-bg) 0);
  font-size: calc(var(--x-progress-circle-size) / 5);
  border-radius: 50%;
  place-items: center;
  animation: 3s forwards progressCircleGrow;
  display: grid;
}

.progress-circle-counter:before {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
  color: var(--x-progress-circle-color);
}

.progress-circle-xl {
  --x-progress-circle-size: 56px;
}

.progress-circle-lg {
  --x-progress-circle-size: 52px;
}

.progress-circle-sm {
  --x-progress-circle-size: 36.75px;
}

.progress-circle-primary {
  --x-progress-circle-color: var(--x-primary);
  --x-progress-circle-bar-bg: var(--x-primary);
}

.progress-circle-secondary {
  --x-progress-circle-color: var(--x-secondary);
  --x-progress-circle-bar-bg: var(--x-secondary);
}

.progress-circle-success {
  --x-progress-circle-color: var(--x-success);
  --x-progress-circle-bar-bg: var(--x-success);
}

.progress-circle-info {
  --x-progress-circle-color: var(--x-info);
  --x-progress-circle-bar-bg: var(--x-info);
}

.progress-circle-warning {
  --x-progress-circle-color: var(--x-warning);
  --x-progress-circle-bar-bg: var(--x-warning);
}

.progress-circle-danger {
  --x-progress-circle-color: var(--x-danger);
  --x-progress-circle-bar-bg: var(--x-danger);
}

.progress-circle-light {
  --x-progress-circle-color: var(--x-light);
  --x-progress-circle-bar-bg: var(--x-light);
}

.progress-circle-dark {
  --x-progress-circle-color: var(--x-dark);
  --x-progress-circle-bar-bg: var(--x-dark);
}

.table {
  --x-table-cell-font-size: .8125rem;
  --x-table-th-bg: transparent;
  --x-table-th-color: #525f7f;
  --x-table-th-font-size: .675rem;
  --x-table-th-font-weight: 500;
  --x-table-th-letter-spacing: .025em;
  --x-table-th-text-transform: uppercase;
  margin-bottom: 0;
}

.table thead th {
  font-size: var(--x-table-th-font-size);
  font-weight: var(--x-table-th-font-weight);
  text-transform: var(--x-table-th-text-transform);
  letter-spacing: var(--x-table-th-letter-spacing);
  border-bottom-width: var(--x-border-width);
  white-space: nowrap;
  vertical-align: middle;
}

.table tbody th {
  font-size: var(--x-table-cell-font-size);
}

.table td {
  font-size: var(--x-table-cell-font-size);
  white-space: nowrap;
}

.table td .progress {
  width: 120px;
  height: 3px;
  margin: 0;
}

.table-flush th, .table-flush td {
  border-left: 0;
  border-right: 0;
}

.table-flush thead > tr th {
  border-top: 0;
}

.table-flush thead > tr th:first-child {
  padding-left: 0;
}

.table-flush thead > tr th:last-child {
  padding-right: 0;
}

.table-flush tbody > tr:last-child th, .table-flush tbody > tr:last-child td {
  border-bottom: 0;
}

.table-flush tbody > tr th:first-child, .table-flush tbody > tr td:first-child {
  padding-left: 0;
}

.table-flush tbody > tr th:last-child, .table-flush tbody > tr td:last-child {
  padding-right: 0;
}

.table-spaced {
  border-collapse: separate;
  border-spacing: 0 1rem;
}

.table-spaced thead th {
  border: 0;
}

.table-spaced thead tr th, .table-spaced thead tr td, .table-spaced tbody tr th, .table-spaced tbody tr td {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  border-top: var(--x-border-width) solid var(--x-border-color) !important;
  border-bottom: var(--x-border-width) solid var(--x-border-color) !important;
}

.table-spaced thead tr th:first-child, .table-spaced thead tr td:first-child, .table-spaced tbody tr th:first-child, .table-spaced tbody tr td:first-child {
  border-radius: var(--x-border-radius) 0 0 var(--x-border-radius);
  border-left: var(--x-border-width) solid var(--x-border-color) !important;
}

.table-spaced thead tr th:first-child:after, .table-spaced thead tr td:first-child:after, .table-spaced tbody tr th:first-child:after, .table-spaced tbody tr td:first-child:after {
  border-left: var(--x-border-width) solid var(--x-border-color);
  border-radius: var(--x-border-radius) 0 0 var(--x-border-radius);
}

.table-spaced thead tr th:last-child, .table-spaced thead tr td:last-child, .table-spaced tbody tr th:last-child, .table-spaced tbody tr td:last-child {
  border-radius: 0 var(--x-border-radius) var(--x-border-radius) 0;
  border-right: var(--x-border-width) solid var(--x-border-color) !important;
}

.table-spaced tbody tr {
  border-radius: var(--x-border-radius);
}

.surtitle {
  color: var(--x-body-color);
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 500;
}

a.text-muted:hover {
  color: var(--x-primary);
}

.dropcaps > p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.dropcaps > p:first-child:first-letter {
  float: left;
  margin-top: .05em;
  margin-right: .15em;
  font-size: 3.5em;
  font-weight: 700;
  line-height: 1;
}

.form-label {
  margin-bottom: .5rem;
  font-size: .875rem;
  font-weight: 500;
}

.col-form-label {
  padding-top: calc(.5rem + var(--x-border-width));
  padding-bottom: calc(.5rem + var(--x-border-width));
  font-size: inherit;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.625;
}

.col-form-label-lg {
  padding-top: calc(.75rem + var(--x-border-width));
  padding-bottom: calc(.75rem + var(--x-border-width));
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(.375rem + var(--x-border-width));
  padding-bottom: calc(.375rem + var(--x-border-width));
  font-size: .875rem;
}

.form-text {
  color: var(--x-secondary-color);
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  color: var(--x-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--x-body-bg);
  border: var(--x-border-width) solid var(--x-border-color);
  border-radius: var(--x-border-radius);
  background-clip: padding-box;
  width: 100%;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: 0 1px 2px rgba(50, 50, 71, .08);
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--x-body-color);
  background-color: var(--x-body-bg);
  border-color: var(--x-primary);
  box-shadow: 0px 1px 2px rgba(50, 50, 71, .08), 0 0 0 .15rem var(--x-primary-bg-subtle);
  outline: 0;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.625em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::placeholder {
  color: var(--x-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--x-secondary-bg);
  opacity: 1;
}

.form-control::-webkit-file-upload-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  color: var(--x-body-color);
  background-color: var(--x-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-radius: 0;
  padding: .5rem 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control::-webkit-file-upload-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  color: var(--x-body-color);
  background-color: var(--x-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-radius: 0;
  padding: .5rem 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  color: var(--x-body-color);
  background-color: var(--x-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-radius: 0;
  padding: .5rem 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--x-secondary-bg);
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--x-secondary-bg);
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--x-secondary-bg);
}

.form-control-plaintext {
  color: var(--x-body-color);
  border: solid rgba(0, 0, 0, 0);
  border-width: var(--x-border-width) 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  margin-bottom: 0;
  padding: .5rem 0;
  line-height: 1.625;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.625em + .75rem + calc(var(--x-border-width) * 2));
  border-radius: var(--x-border-radius-sm);
  padding: .375rem .875rem;
  font-size: .875rem;
}

.form-control-sm::-webkit-file-upload-button {
  -webkit-margin-end: .875rem;
  margin: -.375rem -.875rem;
  padding: .375rem .875rem;
}

.form-control-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  margin-right: .875rem;
}

.form-control-sm:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: .875rem;
}

.form-control-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: .875rem;
}

.form-control-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: .875rem;
}

.form-control-sm:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-sm::-webkit-file-upload-button {
  -webkit-margin-end: .875rem;
  margin: -.375rem -.875rem;
  padding: .375rem .875rem;
}

.form-control-sm::file-selector-button {
  -webkit-margin-end: .875rem;
  margin: -.375rem -.875rem;
  padding: .375rem .875rem;
}

.form-control-sm:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  margin-right: .875rem;
}

.form-control-sm:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: .875rem;
}

.form-control-sm:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: .875rem;
}

.form-control-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: .875rem;
}

.form-control-sm:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-lg {
  min-height: calc(1.625em + 1.5rem + calc(var(--x-border-width) * 2));
  border-radius: var(--x-border-radius-lg);
  padding: .75rem 1.25rem;
  font-size: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  -webkit-margin-end: 1.25rem;
  margin: -.75rem -1.25rem;
  padding: .75rem 1.25rem;
}

.form-control-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  margin-right: 1.25rem;
}

.form-control-lg:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: 1.25rem;
}

.form-control-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: 1.25rem;
}

.form-control-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: 1.25rem;
}

.form-control-lg:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

.form-control-lg::-webkit-file-upload-button {
  -webkit-margin-end: 1.25rem;
  margin: -.75rem -1.25rem;
  padding: .75rem 1.25rem;
}

.form-control-lg::file-selector-button {
  -webkit-margin-end: 1.25rem;
  margin: -.75rem -1.25rem;
  padding: .75rem 1.25rem;
}

.form-control-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::-webkit-file-upload-button {
  margin-right: 1.25rem;
}

.form-control-lg:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: 1.25rem;
}

.form-control-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)))::file-selector-button {
  margin-right: 1.25rem;
}

.form-control-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: 1.25rem;
}

.form-control-lg:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

textarea.form-control {
  min-height: calc(1.625em + 1rem + calc(var(--x-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.625em + .75rem + calc(var(--x-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.625em + 1.5rem + calc(var(--x-border-width) * 2));
}

.form-control-color {
  height: calc(1.625em + 1rem + calc(var(--x-border-width) * 2));
  width: 3rem;
  padding: .5rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: var(--x-border-radius);
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: var(--x-border-radius);
  border: 0 !important;
}

.form-control-color.form-control-sm {
  height: calc(1.625em + .75rem + calc(var(--x-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.625em + 1.5rem + calc(var(--x-border-width) * 2));
}

.form-select {
  --x-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  color: var(--x-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--x-body-bg);
  background-image: var(--x-form-select-bg-img), var(--x-form-select-bg-icon, none);
  border: var(--x-border-width) solid var(--x-border-color);
  border-radius: var(--x-border-radius);
  box-shadow: var(--x-box-shadow-inset);
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  width: 100%;
  padding: .5rem 3rem .5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: var(--x-primary);
  box-shadow: var(--x-box-shadow-inset), 0 0 0 .15rem var(--x-primary-bg-subtle);
  outline: 0;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

.form-select:disabled {
  background-color: var(--x-secondary-bg);
}

.form-select:-moz-focusring {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 0 var(--x-body-color);
}

.form-select-sm {
  border-radius: var(--x-border-radius-sm);
  padding-top: .375rem;
  padding-bottom: .375rem;
  padding-left: .875rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: var(--x-border-radius-lg);
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: 1.25rem;
  font-size: 1rem;
}

[data-bs-theme="dark"] .form-select {
  --x-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23CFD6DF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  min-height: 1.625rem;
  margin-bottom: 0;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  --x-form-check-bg: var(--x-body-bg);
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--x-form-check-bg);
  background-image: var(--x-form-check-bg-image);
  border: var(--x-border-width) solid var(--x-border-color);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: .3125em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: var(--x-primary);
  box-shadow: 0 0 0 .15rem var(--x-primary-bg-subtle);
  outline: 0;
}

.form-check-input:checked {
  background-color: var(--x-primary);
  border-color: var(--x-primary);
}

.form-check-input:checked[type="checkbox"] {
  --x-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --x-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFF'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  --x-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  background-color: #3d5a80;
  border-color: #3d5a80;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 3.375em;
}

.form-switch .form-check-input {
  --x-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-image: var(--x-form-switch-bg);
  background-position: 0;
  border-radius: 2.875em;
  width: 2.875em;
  margin-left: -3.375em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --x-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var%28--x-primary%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  --x-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 3.375em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -3.375em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --x-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 1.3rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .15rem var(--x-primary-bg-subtle);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .15rem var(--x-primary-bg-subtle);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #3d5a80;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #c5ced9;
}

.form-range::-webkit-slider-runnable-track {
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: var(--x-secondary-bg);
  box-shadow: var(--x-box-shadow-inset);
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #3d5a80;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #c5ced9;
}

.form-range::-moz-range-track {
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: var(--x-secondary-bg);
  box-shadow: var(--x-box-shadow-inset);
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--x-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--x-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--x-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--x-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  z-index: 2;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--x-border-width) solid transparent;
  transform-origin: 0 0;
  height: 100%;
  padding: 1rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem;
}

.form-floating > .form-control::-moz-placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control-plaintext::-moz-placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: rgba(var(--x-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  color: rgba(var(--x-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--x-body-bg);
  border-radius: var(--x-border-radius);
  height: 1.5em;
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  left: .5rem;
  right: .5rem;
}

.form-floating > .form-control:focus ~ label:after, .form-floating > .form-control:not(:placeholder-shown) ~ label:after, .form-floating > .form-control-plaintext ~ label:after, .form-floating > .form-select ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--x-body-bg);
  border-radius: var(--x-border-radius);
  height: 1.5em;
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  left: .5rem;
  right: .5rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--x-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--x-border-width) 0;
}

.form-floating > :disabled ~ label, .form-floating > .form-control:disabled ~ label {
  color: #6b7b93;
}

.form-floating > :disabled ~ label:after, .form-floating > .form-control:disabled ~ label:after {
  background-color: var(--x-secondary-bg);
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--x-secondary-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--x-body-bg);
  border: var(--x-border-width) solid var(--x-border-color);
  border-radius: var(--x-border-radius);
  align-items: center;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: var(--x-border-radius-lg);
  padding: .75rem 1.25rem;
  font-size: 1rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: var(--x-border-radius-sm);
  padding: .375rem .875rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--x-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  color: var(--x-form-valid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--x-success);
  border-radius: var(--x-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--x-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2322c55e' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.40625em + .25rem) center;
  background-repeat: no-repeat;
  background-size: calc(.8125em + .5rem) calc(.8125em + .5rem);
  padding-right: calc(1.625em + 1rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--x-form-valid-border-color);
  box-shadow: 0px 1px 2px rgba(50, 50, 71, .08), 0 0 0 .15rem rgba(var(--x-success-rgb), .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.40625em + .25rem) top calc(.40625em + .25rem);
  padding-right: calc(1.625em + 1rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--x-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --x-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2322c55e' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, right 3rem center;
  background-size: 16px 12px, calc(.8125em + .5rem) calc(.8125em + .5rem);
  padding-right: 5.5rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--x-form-valid-border-color);
  box-shadow: var(--x-box-shadow-inset), 0 0 0 .15rem rgba(var(--x-success-rgb), .25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.625em + 4rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--x-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--x-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .15rem rgba(var(--x-success-rgb), .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--x-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  color: var(--x-form-invalid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--x-danger);
  border-radius: var(--x-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--x-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ef4444'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef4444' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.40625em + .25rem) center;
  background-repeat: no-repeat;
  background-size: calc(.8125em + .5rem) calc(.8125em + .5rem);
  padding-right: calc(1.625em + 1rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--x-form-invalid-border-color);
  box-shadow: 0px 1px 2px rgba(50, 50, 71, .08), 0 0 0 .15rem rgba(var(--x-danger-rgb), .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.40625em + .25rem) top calc(.40625em + .25rem);
  padding-right: calc(1.625em + 1rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--x-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --x-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ef4444'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef4444' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, right 3rem center;
  background-size: 16px 12px, calc(.8125em + .5rem) calc(.8125em + .5rem);
  padding-right: 5.5rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--x-form-invalid-border-color);
  box-shadow: var(--x-box-shadow-inset), 0 0 0 .15rem rgba(var(--x-danger-rgb), .25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.625em + 4rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--x-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--x-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .15rem rgba(var(--x-danger-rgb), .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--x-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.form-check-label {
  font-size: .875rem;
  font-weight: 500;
}

form {
  -webkit-margin-after: 0;
  margin-bottom: 0;
}

.form-control-muted {
  background-color: var(--x-secondary-bg);
  border-color: var(--x-secondary-bg);
}

.form-control-muted:focus {
  background-color: var(--x-body-bg);
}

.form-control-alt {
  background-color: var(--x-body-bg);
  border-color: var(--x-body-bg);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .1);
}

.form-control-alt:focus {
  background-color: var(--x-body-bg);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 2px #3d5a80;
}

.form-control-flush {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  padding: 0;
}

.form-control-flush:focus {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
}

textarea[resize="none"] {
  resize: none !important;
}

textarea[resize="both"] {
  resize: both !important;
}

textarea[resize="vertical"] {
  resize: vertical !important;
}

textarea[resize="horizontal"] {
  resize: horizontal !important;
}

.textarea-autosize {
  display: grid;
}

.textarea-autosize:after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}

.textarea-autosize > textarea {
  resize: none;
  overflow: hidden;
}

.textarea-autosize > textarea, .textarea-autosize:after {
  font: inherit;
  border: 1px solid #000;
  grid-area: 1 / 1 / 2 / 2;
  padding: .5rem;
}

.form-group-stacked .form-control, .form-group-stacked .form-select {
  box-sizing: border-box;
  height: auto;
  position: relative;
}

.form-group-stacked .form-control:focus, .form-group-stacked .form-select:focus {
  z-index: 2;
}

.form-group-stacked > :first-child .form-control, .form-group-stacked > :first-child .form-select {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: -1px;
}

.form-group-stacked > :last-child .form-control, .form-group-stacked > :last-child .form-select {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-group-stacked > .row {
  margin: 0;
}

.form-group-stacked > .row > :first-child, .form-group-stacked > .row > :last-child {
  padding: 0;
}

.form-group-stacked > .row > :first-child .form-control, .form-group-stacked > .row > :first-child .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-group-stacked > .row > :first-child .form-control:not(:focus), .form-group-stacked > .row > :first-child .form-select:not(:focus) {
  border-right-color: rgba(0, 0, 0, 0);
}

.form-group-stacked > .row > :last-child .form-control, .form-group-stacked > .row > :last-child .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group {
  border-radius: var(--x-border-radius);
  box-shadow: 0 1px 2px rgba(50, 50, 71, .08);
}

.input-group .form-control, .input-group .form-select, .input-group .dropdown {
  box-shadow: none;
}

.input-group:focus-within {
  box-shadow: 0px 1px 2px rgba(50, 50, 71, .08), 0 0 0 .15rem var(--x-primary-bg-subtle);
}

.input-group:focus-within .input-group-text {
  border-color: var(--x-primary);
}

.input-group:focus-within .form-control, .input-group:focus-within .form-select, .input-group:focus-within .dropdown {
  box-shadow: none;
  border-color: var(--x-primary);
}

.input-group-text {
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-group-flush .form-control, .input-group-flush .input-group-text {
  border: 0 !important;
}

.input-group-inline {
  border-radius: var(--x-border-radius);
  position: relative;
}

.input-group-inline.input-group-sm {
  border-radius: var(--x-border-radius-sm);
}

.input-group-inline.input-group-lg {
  border-radius: var(--x-border-radius-lg);
}

.input-group-inline .input-group-text {
  background: var(--x-body-bg);
}

.input-group-inline:has(.form-control-muted) .input-group-text {
  background-color: var(--x-secondary-bg);
  border-color: var(--x-secondary-bg);
}

.input-group-inline > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-left: 0;
  padding-left: 1rem;
  margin-left: 0 !important;
}

.input-group-inline > :not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-right: 0;
  padding-right: 0;
  margin-right: 0 !important;
}

.input-group-inline:focus-within .form-control-muted {
  border-color: var(--x-border-color);
}

.input-group-inline:focus-within:has(.form-control-muted) .input-group-text {
  background-color: var(--x-body-bg);
  border-color: var(--x-border-color);
}

.form-switch {
  min-height: 1.5rem;
}

.form-switch > .form-check-input {
  height: 1.5rem;
  margin-top: 0;
}

.form-switch > .form-check-label {
  margin-top: calc(.75rem - .8125em);
}

.form-check-linethrough .form-check-input:checked + .form-check-label {
  text-decoration: line-through;
}

.form-item-checkable {
  margin: 2px;
}

.form-item-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  display: none;
  position: absolute;
}

.form-item-check:checked + .form-item .form-item-click {
  border-color: #3d5a80;
  box-shadow: 0 0 0 1px #3d5a80;
}

.form-item-check[disabled] + .form-item .form-item-click, .form-item-check:disabled + .form-item .form-item-click {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-color {
  padding: 0;
  list-style: none;
}

.form-color:after, .form-color:before {
  content: "";
  display: table;
}

.form-color:after {
  clear: both;
}

.form-color label {
  float: left;
  opacity: .7;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  margin-bottom: 0;
  margin-right: .375rem;
  padding: .375rem;
  transition: all .15s ease-in-out;
  display: flex;
  transform: scale(.95);
}

@media (prefers-reduced-motion: reduce) {
  .form-color label {
    transition: none;
  }
}

.form-color label:hover {
  cursor: pointer;
  opacity: 1;
}

.form-color label i, .form-color label svg {
  opacity: 0;
  color: rgba(255, 255, 255, .9);
}

.form-color input {
  position: absolute;
  left: -9999px;
}

.form-color input:checked + label {
  opacity: 1;
  transform: scale(1.1);
}

.form-color input:checked + label i, .form-color input:checked + label svg {
  opacity: 1;
}

.form-color-wide label {
  height: 1.5rem;
}

.form-color-xl label {
  width: 5rem;
  height: 5rem;
}

.form-color-xl.form-color-wide label {
  width: 5rem;
  height: 3.5rem;
}

.form-color-lg label {
  width: 3.25rem;
  height: 3.25rem;
}

.form-color-sm label {
  width: 1.25rem;
  height: 1.25rem;
}

.form-dark .form-control {
  color: rgba(255, 255, 255, .9);
  border: var(--x-border-width) solid #3b3a38;
  background-color: #3b3a38;
}

.form-dark .form-control::placeholder {
  color: rgba(255, 255, 255, .8);
}

.form-dark .form-control:focus {
  border: var(--x-border-width) solid #302f2d;
  background-color: #302f2d;
}

.form-dark .form-control:focus::placeholder {
  color: rgba(255, 255, 255, .6);
}

.form-dark .input-group .input-group-text {
  color: rgba(255, 255, 255, .9);
  background-color: #3b3a38;
  border-color: #3b3a38;
}

.form-dark .input-group:focus-within .input-group-text {
  background-color: #302f2d;
  border-color: #302f2d;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--x-primary-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(var(--x-secondary-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--x-success-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--x-info-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(var(--x-warning-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--x-danger-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--x-light-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--x-dark-rgb), var(--x-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--x-primary-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-primary-rgb), var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-primary-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-primary-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(49, 72, 102, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(49, 72, 102, var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(49, 72, 102, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(49, 72, 102, var(--x-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--x-secondary-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-secondary-rgb), var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-secondary-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-secondary-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(173, 205, 225, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(173, 205, 225, var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(173, 205, 225, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(173, 205, 225, var(--x-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--x-success-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-success-rgb), var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-success-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-success-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(78, 209, 126, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(78, 209, 126, var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(78, 209, 126, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(78, 209, 126, var(--x-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--x-info-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-info-rgb), var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-info-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-info-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-info:hover, .link-info:focus {
  color: RGBA(5, 146, 170, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(5, 146, 170, var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(5, 146, 170, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(5, 146, 170, var(--x-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--x-warning-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-warning-rgb), var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-warning-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-warning-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(199, 92, 18, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(199, 92, 18, var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(199, 92, 18, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(199, 92, 18, var(--x-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--x-danger-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-danger-rgb), var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-danger-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-danger-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(191, 54, 54, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(191, 54, 54, var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(191, 54, 54, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(191, 54, 54, var(--x-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--x-light-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-light-rgb), var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-light-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-light-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(244, 247, 250, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(244, 247, 250, var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(244, 247, 250, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(244, 247, 250, var(--x-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--x-dark-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-dark-rgb), var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-dark-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-dark-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(30, 29, 27, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(30, 29, 27, var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(30, 29, 27, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(30, 29, 27, var(--x-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-opacity, .75)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-underline-opacity, .75)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-underline-opacity, .75)) !important;
  text-decoration-color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-underline-opacity, .75)) !important;
}

.focus-ring:focus {
  box-shadow: var(--x-focus-ring-x, 0) var(--x-focus-ring-y, 0) var(--x-focus-ring-blur, 0) var(--x-focus-ring-width) var(--x-focus-ring-color);
  outline: 0;
}

.icon-link {
  -webkit-text-decoration-color: rgba(var(--x-link-color-rgb), var(--x-link-opacity, .5));
  -webkit-text-decoration-color: rgba(var(--x-link-color-rgb), var(--x-link-opacity, .5));
  text-decoration-color: rgba(var(--x-link-color-rgb), var(--x-link-opacity, .5));
  text-underline-offset: .25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.icon-link > .bi {
  fill: currentColor;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi {
  transform: var(--x-icon-link-transform, translate3d(.25em, 0, 0));
}

.icon-link-hover:focus-visible > .bi {
  transform: var(--x-icon-link-transform, translate3d(.25em, 0, 0));
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--x-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --x-aspect-ratio: 100%;
}

.ratio-4x3 {
  --x-aspect-ratio: 75%;
}

.ratio-16x9 {
  --x-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --x-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden:not(caption), .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: var(--x-border-width);
  opacity: .1;
  background-color: currentColor;
  align-self: stretch;
  min-height: 1em;
  display: inline-block;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .15s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.bg-none {
  background-image: none !important;
}

.bg-between {
  background-position: 0, 100%;
  background-repeat: no-repeat;
  background-size: auto 90%;
}

@media (min-width: 576px) {
  .bg-sm-none {
    background-image: none !important;
  }

  .bg-sm-between {
    background-position: 0, 100%;
    background-repeat: no-repeat;
    background-size: auto 90%;
  }
}

@media (min-width: 768px) {
  .bg-md-none {
    background-image: none !important;
  }

  .bg-md-between {
    background-position: 0, 100%;
    background-repeat: no-repeat;
    background-size: auto 90%;
  }
}

@media (min-width: 992px) {
  .bg-lg-none {
    background-image: none !important;
  }

  .bg-lg-between {
    background-position: 0, 100%;
    background-repeat: no-repeat;
    background-size: auto 90%;
  }
}

@media (min-width: 1200px) {
  .bg-xl-none {
    background-image: none !important;
  }

  .bg-xl-between {
    background-position: 0, 100%;
    background-repeat: no-repeat;
    background-size: auto 90%;
  }
}

@media (min-width: 1400px) {
  .bg-xxl-none {
    background-image: none !important;
  }

  .bg-xxl-between {
    background-position: 0, 100%;
    background-repeat: no-repeat;
    background-size: auto 90%;
  }
}

.gradient-top {
  background-image: linear-gradient(to top, var(--x-gradient)) !important;
}

.gradient-top-right {
  background-image: linear-gradient(to top right, var(--x-gradient)) !important;
}

.gradient-right {
  background-image: linear-gradient(to right, var(--x-gradient)) !important;
}

.gradient-bottom-right {
  background-image: linear-gradient(to bottom right, var(--x-gradient)) !important;
}

.gradient-bottom {
  background-image: linear-gradient(to bottom, var(--x-gradient)) !important;
}

.gradient-bottom-left {
  background-image: linear-gradient(to bottom left, var(--x-gradient)) !important;
}

.gradient-left {
  background-image: linear-gradient(to left, var(--x-gradient)) !important;
}

.gradient-top-left {
  background-image: linear-gradient(to top left, var(--x-gradient)) !important;
}

.start-transparent, .start-transparent-hover:hover {
  --x-start-color: transparent;
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-50, .start-gray-50-hover:hover {
  --x-start-color: var(--x-gray-50);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-100, .start-gray-100-hover:hover {
  --x-start-color: var(--x-gray-100);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-200, .start-gray-200-hover:hover {
  --x-start-color: var(--x-gray-200);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-300, .start-gray-300-hover:hover {
  --x-start-color: var(--x-gray-300);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-400, .start-gray-400-hover:hover {
  --x-start-color: var(--x-gray-400);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-500, .start-gray-500-hover:hover {
  --x-start-color: var(--x-gray-500);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-600, .start-gray-600-hover:hover {
  --x-start-color: var(--x-gray-600);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-700, .start-gray-700-hover:hover {
  --x-start-color: var(--x-gray-700);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-800, .start-gray-800-hover:hover {
  --x-start-color: var(--x-gray-800);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-900, .start-gray-900-hover:hover {
  --x-start-color: var(--x-gray-900);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-gray-950, .start-gray-950-hover:hover {
  --x-start-color: var(--x-gray-950);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-blue-100, .start-blue-100-hover:hover {
  --x-start-color: var(--x-blue-100);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-blue-200, .start-blue-200-hover:hover {
  --x-start-color: var(--x-blue-200);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-blue-300, .start-blue-300-hover:hover {
  --x-start-color: var(--x-blue-300);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-blue-400, .start-blue-400-hover:hover {
  --x-start-color: var(--x-blue-400);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-blue-500, .start-blue-500-hover:hover {
  --x-start-color: var(--x-blue-500);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-blue-600, .start-blue-600-hover:hover {
  --x-start-color: var(--x-blue-600);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-blue-700, .start-blue-700-hover:hover {
  --x-start-color: var(--x-blue-700);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-blue-800, .start-blue-800-hover:hover {
  --x-start-color: var(--x-blue-800);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-blue-900, .start-blue-900-hover:hover {
  --x-start-color: var(--x-blue-900);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-indigo-100, .start-indigo-100-hover:hover {
  --x-start-color: var(--x-indigo-100);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-indigo-200, .start-indigo-200-hover:hover {
  --x-start-color: var(--x-indigo-200);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-indigo-300, .start-indigo-300-hover:hover {
  --x-start-color: var(--x-indigo-300);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-indigo-400, .start-indigo-400-hover:hover {
  --x-start-color: var(--x-indigo-400);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-indigo-500, .start-indigo-500-hover:hover {
  --x-start-color: var(--x-indigo-500);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-indigo-600, .start-indigo-600-hover:hover {
  --x-start-color: var(--x-indigo-600);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-indigo-700, .start-indigo-700-hover:hover {
  --x-start-color: var(--x-indigo-700);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-indigo-800, .start-indigo-800-hover:hover {
  --x-start-color: var(--x-indigo-800);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-indigo-900, .start-indigo-900-hover:hover {
  --x-start-color: var(--x-indigo-900);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-purple-100, .start-purple-100-hover:hover {
  --x-start-color: var(--x-purple-100);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-purple-200, .start-purple-200-hover:hover {
  --x-start-color: var(--x-purple-200);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-purple-300, .start-purple-300-hover:hover {
  --x-start-color: var(--x-purple-300);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-purple-400, .start-purple-400-hover:hover {
  --x-start-color: var(--x-purple-400);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-purple-500, .start-purple-500-hover:hover {
  --x-start-color: var(--x-purple-500);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-purple-600, .start-purple-600-hover:hover {
  --x-start-color: var(--x-purple-600);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-purple-700, .start-purple-700-hover:hover {
  --x-start-color: var(--x-purple-700);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-purple-800, .start-purple-800-hover:hover {
  --x-start-color: var(--x-purple-800);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-purple-900, .start-purple-900-hover:hover {
  --x-start-color: var(--x-purple-900);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-pink-100, .start-pink-100-hover:hover {
  --x-start-color: var(--x-pink-100);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-pink-200, .start-pink-200-hover:hover {
  --x-start-color: var(--x-pink-200);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-pink-300, .start-pink-300-hover:hover {
  --x-start-color: var(--x-pink-300);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-pink-400, .start-pink-400-hover:hover {
  --x-start-color: var(--x-pink-400);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-pink-500, .start-pink-500-hover:hover {
  --x-start-color: var(--x-pink-500);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-pink-600, .start-pink-600-hover:hover {
  --x-start-color: var(--x-pink-600);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-pink-700, .start-pink-700-hover:hover {
  --x-start-color: var(--x-pink-700);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-pink-800, .start-pink-800-hover:hover {
  --x-start-color: var(--x-pink-800);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-pink-900, .start-pink-900-hover:hover {
  --x-start-color: var(--x-pink-900);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-red-100, .start-red-100-hover:hover {
  --x-start-color: var(--x-red-100);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-red-200, .start-red-200-hover:hover {
  --x-start-color: var(--x-red-200);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-red-300, .start-red-300-hover:hover {
  --x-start-color: var(--x-red-300);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-red-400, .start-red-400-hover:hover {
  --x-start-color: var(--x-red-400);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-red-500, .start-red-500-hover:hover {
  --x-start-color: var(--x-red-500);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-red-600, .start-red-600-hover:hover {
  --x-start-color: var(--x-red-600);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-red-700, .start-red-700-hover:hover {
  --x-start-color: var(--x-red-700);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-red-800, .start-red-800-hover:hover {
  --x-start-color: var(--x-red-800);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-red-900, .start-red-900-hover:hover {
  --x-start-color: var(--x-red-900);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-orange-100, .start-orange-100-hover:hover {
  --x-start-color: var(--x-orange-100);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-orange-200, .start-orange-200-hover:hover {
  --x-start-color: var(--x-orange-200);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-orange-300, .start-orange-300-hover:hover {
  --x-start-color: var(--x-orange-300);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-orange-400, .start-orange-400-hover:hover {
  --x-start-color: var(--x-orange-400);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-orange-500, .start-orange-500-hover:hover {
  --x-start-color: var(--x-orange-500);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-orange-600, .start-orange-600-hover:hover {
  --x-start-color: var(--x-orange-600);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-orange-700, .start-orange-700-hover:hover {
  --x-start-color: var(--x-orange-700);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-orange-800, .start-orange-800-hover:hover {
  --x-start-color: var(--x-orange-800);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-orange-900, .start-orange-900-hover:hover {
  --x-start-color: var(--x-orange-900);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-yellow-100, .start-yellow-100-hover:hover {
  --x-start-color: var(--x-yellow-100);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-yellow-200, .start-yellow-200-hover:hover {
  --x-start-color: var(--x-yellow-200);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-yellow-300, .start-yellow-300-hover:hover {
  --x-start-color: var(--x-yellow-300);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-yellow-400, .start-yellow-400-hover:hover {
  --x-start-color: var(--x-yellow-400);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-yellow-500, .start-yellow-500-hover:hover {
  --x-start-color: var(--x-yellow-500);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-yellow-600, .start-yellow-600-hover:hover {
  --x-start-color: var(--x-yellow-600);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-yellow-700, .start-yellow-700-hover:hover {
  --x-start-color: var(--x-yellow-700);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-yellow-800, .start-yellow-800-hover:hover {
  --x-start-color: var(--x-yellow-800);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-yellow-900, .start-yellow-900-hover:hover {
  --x-start-color: var(--x-yellow-900);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-green-100, .start-green-100-hover:hover {
  --x-start-color: var(--x-green-100);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-green-200, .start-green-200-hover:hover {
  --x-start-color: var(--x-green-200);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-green-300, .start-green-300-hover:hover {
  --x-start-color: var(--x-green-300);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-green-400, .start-green-400-hover:hover {
  --x-start-color: var(--x-green-400);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-green-500, .start-green-500-hover:hover {
  --x-start-color: var(--x-green-500);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-green-600, .start-green-600-hover:hover {
  --x-start-color: var(--x-green-600);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-green-700, .start-green-700-hover:hover {
  --x-start-color: var(--x-green-700);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-green-800, .start-green-800-hover:hover {
  --x-start-color: var(--x-green-800);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-green-900, .start-green-900-hover:hover {
  --x-start-color: var(--x-green-900);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-teal-100, .start-teal-100-hover:hover {
  --x-start-color: var(--x-teal-100);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-teal-200, .start-teal-200-hover:hover {
  --x-start-color: var(--x-teal-200);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-teal-300, .start-teal-300-hover:hover {
  --x-start-color: var(--x-teal-300);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-teal-400, .start-teal-400-hover:hover {
  --x-start-color: var(--x-teal-400);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-teal-500, .start-teal-500-hover:hover {
  --x-start-color: var(--x-teal-500);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-teal-600, .start-teal-600-hover:hover {
  --x-start-color: var(--x-teal-600);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-teal-700, .start-teal-700-hover:hover {
  --x-start-color: var(--x-teal-700);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-teal-800, .start-teal-800-hover:hover {
  --x-start-color: var(--x-teal-800);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-teal-900, .start-teal-900-hover:hover {
  --x-start-color: var(--x-teal-900);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-cyan-100, .start-cyan-100-hover:hover {
  --x-start-color: var(--x-cyan-100);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-cyan-200, .start-cyan-200-hover:hover {
  --x-start-color: var(--x-cyan-200);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-cyan-300, .start-cyan-300-hover:hover {
  --x-start-color: var(--x-cyan-300);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-cyan-400, .start-cyan-400-hover:hover {
  --x-start-color: var(--x-cyan-400);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-cyan-500, .start-cyan-500-hover:hover {
  --x-start-color: var(--x-cyan-500);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-cyan-600, .start-cyan-600-hover:hover {
  --x-start-color: var(--x-cyan-600);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-cyan-700, .start-cyan-700-hover:hover {
  --x-start-color: var(--x-cyan-700);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-cyan-800, .start-cyan-800-hover:hover {
  --x-start-color: var(--x-cyan-800);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.start-cyan-900, .start-cyan-900-hover:hover {
  --x-start-color: var(--x-cyan-900);
  --x-gradient: var(--x-start-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.middle-transparent, .middle-transparent-hover:hover {
  --x-middle-color: transparent;
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(0, 0, 0, 0));
}

.middle-gray-50, .middle-gray-50-hover:hover {
  --x-middle-color: var(--x-gray-50);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(250, 250, 250, 0));
}

.middle-gray-100, .middle-gray-100-hover:hover {
  --x-middle-color: var(--x-gray-100);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(245, 249, 252, 0));
}

.middle-gray-200, .middle-gray-200-hover:hover {
  --x-middle-color: var(--x-gray-200);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(231, 234, 240, 0));
}

.middle-gray-300, .middle-gray-300-hover:hover {
  --x-middle-color: var(--x-gray-300);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(207, 214, 223, 0));
}

.middle-gray-400, .middle-gray-400-hover:hover {
  --x-middle-color: var(--x-gray-400);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(171, 182, 197, 0));
}

.middle-gray-500, .middle-gray-500-hover:hover {
  --x-middle-color: var(--x-gray-500);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(136, 152, 169, 0));
}

.middle-gray-600, .middle-gray-600-hover:hover {
  --x-middle-color: var(--x-gray-600);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(107, 123, 147, 0));
}

.middle-gray-700, .middle-gray-700-hover:hover {
  --x-middle-color: var(--x-gray-700);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(82, 95, 127, 0));
}

.middle-gray-800, .middle-gray-800-hover:hover {
  --x-middle-color: var(--x-gray-800);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(45, 55, 72, 0));
}

.middle-gray-900, .middle-gray-900-hover:hover {
  --x-middle-color: var(--x-gray-900);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(22, 25, 44, 0));
}

.middle-gray-950, .middle-gray-950-hover:hover {
  --x-middle-color: var(--x-gray-950);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(2, 6, 23, 0));
}

.middle-blue-100, .middle-blue-100-hover:hover {
  --x-middle-color: var(--x-blue-100);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(216, 230, 253, 0));
}

.middle-blue-200, .middle-blue-200-hover:hover {
  --x-middle-color: var(--x-blue-200);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(177, 205, 251, 0));
}

.middle-blue-300, .middle-blue-300-hover:hover {
  --x-middle-color: var(--x-blue-300);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(137, 180, 250, 0));
}

.middle-blue-400, .middle-blue-400-hover:hover {
  --x-middle-color: var(--x-blue-400);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(98, 155, 248, 0));
}

.middle-blue-500, .middle-blue-500-hover:hover {
  --x-middle-color: var(--x-blue-500);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(59, 130, 246, 0));
}

.middle-blue-600, .middle-blue-600-hover:hover {
  --x-middle-color: var(--x-blue-600);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(47, 104, 197, 0));
}

.middle-blue-700, .middle-blue-700-hover:hover {
  --x-middle-color: var(--x-blue-700);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(35, 78, 148, 0));
}

.middle-blue-800, .middle-blue-800-hover:hover {
  --x-middle-color: var(--x-blue-800);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(24, 52, 98, 0));
}

.middle-blue-900, .middle-blue-900-hover:hover {
  --x-middle-color: var(--x-blue-900);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(12, 26, 49, 0));
}

.middle-indigo-100, .middle-indigo-100-hover:hover {
  --x-middle-color: var(--x-indigo-100);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(224, 224, 252, 0));
}

.middle-indigo-200, .middle-indigo-200-hover:hover {
  --x-middle-color: var(--x-indigo-200);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(193, 194, 249, 0));
}

.middle-indigo-300, .middle-indigo-300-hover:hover {
  --x-middle-color: var(--x-indigo-300);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(161, 163, 247, 0));
}

.middle-indigo-400, .middle-indigo-400-hover:hover {
  --x-middle-color: var(--x-indigo-400);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(130, 133, 244, 0));
}

.middle-indigo-500, .middle-indigo-500-hover:hover {
  --x-middle-color: var(--x-indigo-500);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(99, 102, 241, 0));
}

.middle-indigo-600, .middle-indigo-600-hover:hover {
  --x-middle-color: var(--x-indigo-600);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(79, 82, 193, 0));
}

.middle-indigo-700, .middle-indigo-700-hover:hover {
  --x-middle-color: var(--x-indigo-700);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(59, 61, 145, 0));
}

.middle-indigo-800, .middle-indigo-800-hover:hover {
  --x-middle-color: var(--x-indigo-800);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(40, 41, 96, 0));
}

.middle-indigo-900, .middle-indigo-900-hover:hover {
  --x-middle-color: var(--x-indigo-900);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(20, 20, 48, 0));
}

.middle-purple-100, .middle-purple-100-hover:hover {
  --x-middle-color: var(--x-purple-100);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(232, 222, 253, 0));
}

.middle-purple-200, .middle-purple-200-hover:hover {
  --x-middle-color: var(--x-purple-200);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(209, 190, 251, 0));
}

.middle-purple-300, .middle-purple-300-hover:hover {
  --x-middle-color: var(--x-purple-300);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(185, 157, 250, 0));
}

.middle-purple-400, .middle-purple-400-hover:hover {
  --x-middle-color: var(--x-purple-400);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(162, 125, 248, 0));
}

.middle-purple-500, .middle-purple-500-hover:hover {
  --x-middle-color: var(--x-purple-500);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(139, 92, 246, 0));
}

.middle-purple-600, .middle-purple-600-hover:hover {
  --x-middle-color: var(--x-purple-600);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(111, 74, 197, 0));
}

.middle-purple-700, .middle-purple-700-hover:hover {
  --x-middle-color: var(--x-purple-700);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(83, 55, 148, 0));
}

.middle-purple-800, .middle-purple-800-hover:hover {
  --x-middle-color: var(--x-purple-800);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(56, 37, 98, 0));
}

.middle-purple-900, .middle-purple-900-hover:hover {
  --x-middle-color: var(--x-purple-900);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(28, 18, 49, 0));
}

.middle-pink-100, .middle-pink-100-hover:hover {
  --x-middle-color: var(--x-pink-100);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(251, 218, 235, 0));
}

.middle-pink-200, .middle-pink-200-hover:hover {
  --x-middle-color: var(--x-pink-200);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(247, 182, 214, 0));
}

.middle-pink-300, .middle-pink-300-hover:hover {
  --x-middle-color: var(--x-pink-300);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(244, 145, 194, 0));
}

.middle-pink-400, .middle-pink-400-hover:hover {
  --x-middle-color: var(--x-pink-400);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(240, 109, 173, 0));
}

.middle-pink-500, .middle-pink-500-hover:hover {
  --x-middle-color: var(--x-pink-500);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(236, 72, 153, 0));
}

.middle-pink-600, .middle-pink-600-hover:hover {
  --x-middle-color: var(--x-pink-600);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(189, 58, 122, 0));
}

.middle-pink-700, .middle-pink-700-hover:hover {
  --x-middle-color: var(--x-pink-700);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(142, 43, 92, 0));
}

.middle-pink-800, .middle-pink-800-hover:hover {
  --x-middle-color: var(--x-pink-800);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(94, 29, 61, 0));
}

.middle-pink-900, .middle-pink-900-hover:hover {
  --x-middle-color: var(--x-pink-900);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(47, 14, 31, 0));
}

.middle-red-100, .middle-red-100-hover:hover {
  --x-middle-color: var(--x-red-100);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(252, 218, 218, 0));
}

.middle-red-200, .middle-red-200-hover:hover {
  --x-middle-color: var(--x-red-200);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(249, 180, 180, 0));
}

.middle-red-300, .middle-red-300-hover:hover {
  --x-middle-color: var(--x-red-300);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(245, 143, 143, 0));
}

.middle-red-400, .middle-red-400-hover:hover {
  --x-middle-color: var(--x-red-400);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(242, 105, 105, 0));
}

.middle-red-500, .middle-red-500-hover:hover {
  --x-middle-color: var(--x-red-500);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(239, 68, 68, 0));
}

.middle-red-600, .middle-red-600-hover:hover {
  --x-middle-color: var(--x-red-600);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(191, 54, 54, 0));
}

.middle-red-700, .middle-red-700-hover:hover {
  --x-middle-color: var(--x-red-700);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(143, 41, 41, 0));
}

.middle-red-800, .middle-red-800-hover:hover {
  --x-middle-color: var(--x-red-800);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(96, 27, 27, 0));
}

.middle-red-900, .middle-red-900-hover:hover {
  --x-middle-color: var(--x-red-900);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(48, 14, 14, 0));
}

.middle-orange-100, .middle-orange-100-hover:hover {
  --x-middle-color: var(--x-orange-100);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(254, 227, 208, 0));
}

.middle-orange-200, .middle-orange-200-hover:hover {
  --x-middle-color: var(--x-orange-200);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(253, 199, 162, 0));
}

.middle-orange-300, .middle-orange-300-hover:hover {
  --x-middle-color: var(--x-orange-300);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(251, 171, 115, 0));
}

.middle-orange-400, .middle-orange-400-hover:hover {
  --x-middle-color: var(--x-orange-400);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(250, 143, 69, 0));
}

.middle-orange-500, .middle-orange-500-hover:hover {
  --x-middle-color: var(--x-orange-500);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(249, 115, 22, 0));
}

.middle-orange-600, .middle-orange-600-hover:hover {
  --x-middle-color: var(--x-orange-600);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(199, 92, 18, 0));
}

.middle-orange-700, .middle-orange-700-hover:hover {
  --x-middle-color: var(--x-orange-700);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(149, 69, 13, 0));
}

.middle-orange-800, .middle-orange-800-hover:hover {
  --x-middle-color: var(--x-orange-800);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(100, 46, 9, 0));
}

.middle-orange-900, .middle-orange-900-hover:hover {
  --x-middle-color: var(--x-orange-900);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(50, 23, 4, 0));
}

.middle-yellow-100, .middle-yellow-100-hover:hover {
  --x-middle-color: var(--x-yellow-100);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(251, 240, 206, 0));
}

.middle-yellow-200, .middle-yellow-200-hover:hover {
  --x-middle-color: var(--x-yellow-200);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(247, 225, 156, 0));
}

.middle-yellow-300, .middle-yellow-300-hover:hover {
  --x-middle-color: var(--x-yellow-300);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(242, 209, 107, 0));
}

.middle-yellow-400, .middle-yellow-400-hover:hover {
  --x-middle-color: var(--x-yellow-400);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(238, 194, 57, 0));
}

.middle-yellow-500, .middle-yellow-500-hover:hover {
  --x-middle-color: var(--x-yellow-500);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(234, 179, 8, 0));
}

.middle-yellow-600, .middle-yellow-600-hover:hover {
  --x-middle-color: var(--x-yellow-600);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(187, 143, 6, 0));
}

.middle-yellow-700, .middle-yellow-700-hover:hover {
  --x-middle-color: var(--x-yellow-700);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(140, 107, 5, 0));
}

.middle-yellow-800, .middle-yellow-800-hover:hover {
  --x-middle-color: var(--x-yellow-800);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(94, 72, 3, 0));
}

.middle-yellow-900, .middle-yellow-900-hover:hover {
  --x-middle-color: var(--x-yellow-900);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(47, 36, 2, 0));
}

.middle-green-100, .middle-green-100-hover:hover {
  --x-middle-color: var(--x-green-100);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(211, 243, 223, 0));
}

.middle-green-200, .middle-green-200-hover:hover {
  --x-middle-color: var(--x-green-200);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(167, 232, 191, 0));
}

.middle-green-300, .middle-green-300-hover:hover {
  --x-middle-color: var(--x-green-300);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(122, 220, 158, 0));
}

.middle-green-400, .middle-green-400-hover:hover {
  --x-middle-color: var(--x-green-400);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(78, 209, 126, 0));
}

.middle-green-500, .middle-green-500-hover:hover {
  --x-middle-color: var(--x-green-500);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(34, 197, 94, 0));
}

.middle-green-600, .middle-green-600-hover:hover {
  --x-middle-color: var(--x-green-600);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(27, 158, 75, 0));
}

.middle-green-700, .middle-green-700-hover:hover {
  --x-middle-color: var(--x-green-700);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(20, 118, 56, 0));
}

.middle-green-800, .middle-green-800-hover:hover {
  --x-middle-color: var(--x-green-800);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(14, 79, 38, 0));
}

.middle-green-900, .middle-green-900-hover:hover {
  --x-middle-color: var(--x-green-900);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(7, 39, 19, 0));
}

.middle-teal-100, .middle-teal-100-hover:hover {
  --x-middle-color: var(--x-teal-100);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(208, 241, 237, 0));
}

.middle-teal-200, .middle-teal-200-hover:hover {
  --x-middle-color: var(--x-teal-200);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(161, 227, 219, 0));
}

.middle-teal-300, .middle-teal-300-hover:hover {
  --x-middle-color: var(--x-teal-300);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(114, 212, 202, 0));
}

.middle-teal-400, .middle-teal-400-hover:hover {
  --x-middle-color: var(--x-teal-400);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(67, 198, 184, 0));
}

.middle-teal-500, .middle-teal-500-hover:hover {
  --x-middle-color: var(--x-teal-500);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(20, 184, 166, 0));
}

.middle-teal-600, .middle-teal-600-hover:hover {
  --x-middle-color: var(--x-teal-600);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(16, 147, 133, 0));
}

.middle-teal-700, .middle-teal-700-hover:hover {
  --x-middle-color: var(--x-teal-700);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(12, 110, 100, 0));
}

.middle-teal-800, .middle-teal-800-hover:hover {
  --x-middle-color: var(--x-teal-800);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(8, 74, 66, 0));
}

.middle-teal-900, .middle-teal-900-hover:hover {
  --x-middle-color: var(--x-teal-900);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(4, 37, 33, 0));
}

.middle-cyan-100, .middle-cyan-100-hover:hover {
  --x-middle-color: var(--x-cyan-100);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(205, 240, 246, 0));
}

.middle-cyan-200, .middle-cyan-200-hover:hover {
  --x-middle-color: var(--x-cyan-200);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(155, 226, 238, 0));
}

.middle-cyan-300, .middle-cyan-300-hover:hover {
  --x-middle-color: var(--x-cyan-300);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(106, 211, 229, 0));
}

.middle-cyan-400, .middle-cyan-400-hover:hover {
  --x-middle-color: var(--x-cyan-400);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(56, 197, 221, 0));
}

.middle-cyan-500, .middle-cyan-500-hover:hover {
  --x-middle-color: var(--x-cyan-500);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(6, 182, 212, 0));
}

.middle-cyan-600, .middle-cyan-600-hover:hover {
  --x-middle-color: var(--x-cyan-600);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(5, 146, 170, 0));
}

.middle-cyan-700, .middle-cyan-700-hover:hover {
  --x-middle-color: var(--x-cyan-700);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(4, 109, 127, 0));
}

.middle-cyan-800, .middle-cyan-800-hover:hover {
  --x-middle-color: var(--x-cyan-800);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(2, 73, 85, 0));
}

.middle-cyan-900, .middle-cyan-900-hover:hover {
  --x-middle-color: var(--x-cyan-900);
  --x-gradient: var(--x-start-color), var(--x-middle-color), var(--x-end-color, rgba(1, 36, 42, 0));
}

.end-transparent, .end-transparent-hover:hover {
  --x-end-color: transparent;
}

.end-gray-50, .end-gray-50-hover:hover {
  --x-end-color: var(--x-gray-50);
}

.end-gray-100, .end-gray-100-hover:hover {
  --x-end-color: var(--x-gray-100);
}

.end-gray-200, .end-gray-200-hover:hover {
  --x-end-color: var(--x-gray-200);
}

.end-gray-300, .end-gray-300-hover:hover {
  --x-end-color: var(--x-gray-300);
}

.end-gray-400, .end-gray-400-hover:hover {
  --x-end-color: var(--x-gray-400);
}

.end-gray-500, .end-gray-500-hover:hover {
  --x-end-color: var(--x-gray-500);
}

.end-gray-600, .end-gray-600-hover:hover {
  --x-end-color: var(--x-gray-600);
}

.end-gray-700, .end-gray-700-hover:hover {
  --x-end-color: var(--x-gray-700);
}

.end-gray-800, .end-gray-800-hover:hover {
  --x-end-color: var(--x-gray-800);
}

.end-gray-900, .end-gray-900-hover:hover {
  --x-end-color: var(--x-gray-900);
}

.end-gray-950, .end-gray-950-hover:hover {
  --x-end-color: var(--x-gray-950);
}

.end-blue-100, .end-blue-100-hover:hover {
  --x-end-color: var(--x-blue-100);
}

.end-blue-200, .end-blue-200-hover:hover {
  --x-end-color: var(--x-blue-200);
}

.end-blue-300, .end-blue-300-hover:hover {
  --x-end-color: var(--x-blue-300);
}

.end-blue-400, .end-blue-400-hover:hover {
  --x-end-color: var(--x-blue-400);
}

.end-blue-500, .end-blue-500-hover:hover {
  --x-end-color: var(--x-blue-500);
}

.end-blue-600, .end-blue-600-hover:hover {
  --x-end-color: var(--x-blue-600);
}

.end-blue-700, .end-blue-700-hover:hover {
  --x-end-color: var(--x-blue-700);
}

.end-blue-800, .end-blue-800-hover:hover {
  --x-end-color: var(--x-blue-800);
}

.end-blue-900, .end-blue-900-hover:hover {
  --x-end-color: var(--x-blue-900);
}

.end-indigo-100, .end-indigo-100-hover:hover {
  --x-end-color: var(--x-indigo-100);
}

.end-indigo-200, .end-indigo-200-hover:hover {
  --x-end-color: var(--x-indigo-200);
}

.end-indigo-300, .end-indigo-300-hover:hover {
  --x-end-color: var(--x-indigo-300);
}

.end-indigo-400, .end-indigo-400-hover:hover {
  --x-end-color: var(--x-indigo-400);
}

.end-indigo-500, .end-indigo-500-hover:hover {
  --x-end-color: var(--x-indigo-500);
}

.end-indigo-600, .end-indigo-600-hover:hover {
  --x-end-color: var(--x-indigo-600);
}

.end-indigo-700, .end-indigo-700-hover:hover {
  --x-end-color: var(--x-indigo-700);
}

.end-indigo-800, .end-indigo-800-hover:hover {
  --x-end-color: var(--x-indigo-800);
}

.end-indigo-900, .end-indigo-900-hover:hover {
  --x-end-color: var(--x-indigo-900);
}

.end-purple-100, .end-purple-100-hover:hover {
  --x-end-color: var(--x-purple-100);
}

.end-purple-200, .end-purple-200-hover:hover {
  --x-end-color: var(--x-purple-200);
}

.end-purple-300, .end-purple-300-hover:hover {
  --x-end-color: var(--x-purple-300);
}

.end-purple-400, .end-purple-400-hover:hover {
  --x-end-color: var(--x-purple-400);
}

.end-purple-500, .end-purple-500-hover:hover {
  --x-end-color: var(--x-purple-500);
}

.end-purple-600, .end-purple-600-hover:hover {
  --x-end-color: var(--x-purple-600);
}

.end-purple-700, .end-purple-700-hover:hover {
  --x-end-color: var(--x-purple-700);
}

.end-purple-800, .end-purple-800-hover:hover {
  --x-end-color: var(--x-purple-800);
}

.end-purple-900, .end-purple-900-hover:hover {
  --x-end-color: var(--x-purple-900);
}

.end-pink-100, .end-pink-100-hover:hover {
  --x-end-color: var(--x-pink-100);
}

.end-pink-200, .end-pink-200-hover:hover {
  --x-end-color: var(--x-pink-200);
}

.end-pink-300, .end-pink-300-hover:hover {
  --x-end-color: var(--x-pink-300);
}

.end-pink-400, .end-pink-400-hover:hover {
  --x-end-color: var(--x-pink-400);
}

.end-pink-500, .end-pink-500-hover:hover {
  --x-end-color: var(--x-pink-500);
}

.end-pink-600, .end-pink-600-hover:hover {
  --x-end-color: var(--x-pink-600);
}

.end-pink-700, .end-pink-700-hover:hover {
  --x-end-color: var(--x-pink-700);
}

.end-pink-800, .end-pink-800-hover:hover {
  --x-end-color: var(--x-pink-800);
}

.end-pink-900, .end-pink-900-hover:hover {
  --x-end-color: var(--x-pink-900);
}

.end-red-100, .end-red-100-hover:hover {
  --x-end-color: var(--x-red-100);
}

.end-red-200, .end-red-200-hover:hover {
  --x-end-color: var(--x-red-200);
}

.end-red-300, .end-red-300-hover:hover {
  --x-end-color: var(--x-red-300);
}

.end-red-400, .end-red-400-hover:hover {
  --x-end-color: var(--x-red-400);
}

.end-red-500, .end-red-500-hover:hover {
  --x-end-color: var(--x-red-500);
}

.end-red-600, .end-red-600-hover:hover {
  --x-end-color: var(--x-red-600);
}

.end-red-700, .end-red-700-hover:hover {
  --x-end-color: var(--x-red-700);
}

.end-red-800, .end-red-800-hover:hover {
  --x-end-color: var(--x-red-800);
}

.end-red-900, .end-red-900-hover:hover {
  --x-end-color: var(--x-red-900);
}

.end-orange-100, .end-orange-100-hover:hover {
  --x-end-color: var(--x-orange-100);
}

.end-orange-200, .end-orange-200-hover:hover {
  --x-end-color: var(--x-orange-200);
}

.end-orange-300, .end-orange-300-hover:hover {
  --x-end-color: var(--x-orange-300);
}

.end-orange-400, .end-orange-400-hover:hover {
  --x-end-color: var(--x-orange-400);
}

.end-orange-500, .end-orange-500-hover:hover {
  --x-end-color: var(--x-orange-500);
}

.end-orange-600, .end-orange-600-hover:hover {
  --x-end-color: var(--x-orange-600);
}

.end-orange-700, .end-orange-700-hover:hover {
  --x-end-color: var(--x-orange-700);
}

.end-orange-800, .end-orange-800-hover:hover {
  --x-end-color: var(--x-orange-800);
}

.end-orange-900, .end-orange-900-hover:hover {
  --x-end-color: var(--x-orange-900);
}

.end-yellow-100, .end-yellow-100-hover:hover {
  --x-end-color: var(--x-yellow-100);
}

.end-yellow-200, .end-yellow-200-hover:hover {
  --x-end-color: var(--x-yellow-200);
}

.end-yellow-300, .end-yellow-300-hover:hover {
  --x-end-color: var(--x-yellow-300);
}

.end-yellow-400, .end-yellow-400-hover:hover {
  --x-end-color: var(--x-yellow-400);
}

.end-yellow-500, .end-yellow-500-hover:hover {
  --x-end-color: var(--x-yellow-500);
}

.end-yellow-600, .end-yellow-600-hover:hover {
  --x-end-color: var(--x-yellow-600);
}

.end-yellow-700, .end-yellow-700-hover:hover {
  --x-end-color: var(--x-yellow-700);
}

.end-yellow-800, .end-yellow-800-hover:hover {
  --x-end-color: var(--x-yellow-800);
}

.end-yellow-900, .end-yellow-900-hover:hover {
  --x-end-color: var(--x-yellow-900);
}

.end-green-100, .end-green-100-hover:hover {
  --x-end-color: var(--x-green-100);
}

.end-green-200, .end-green-200-hover:hover {
  --x-end-color: var(--x-green-200);
}

.end-green-300, .end-green-300-hover:hover {
  --x-end-color: var(--x-green-300);
}

.end-green-400, .end-green-400-hover:hover {
  --x-end-color: var(--x-green-400);
}

.end-green-500, .end-green-500-hover:hover {
  --x-end-color: var(--x-green-500);
}

.end-green-600, .end-green-600-hover:hover {
  --x-end-color: var(--x-green-600);
}

.end-green-700, .end-green-700-hover:hover {
  --x-end-color: var(--x-green-700);
}

.end-green-800, .end-green-800-hover:hover {
  --x-end-color: var(--x-green-800);
}

.end-green-900, .end-green-900-hover:hover {
  --x-end-color: var(--x-green-900);
}

.end-teal-100, .end-teal-100-hover:hover {
  --x-end-color: var(--x-teal-100);
}

.end-teal-200, .end-teal-200-hover:hover {
  --x-end-color: var(--x-teal-200);
}

.end-teal-300, .end-teal-300-hover:hover {
  --x-end-color: var(--x-teal-300);
}

.end-teal-400, .end-teal-400-hover:hover {
  --x-end-color: var(--x-teal-400);
}

.end-teal-500, .end-teal-500-hover:hover {
  --x-end-color: var(--x-teal-500);
}

.end-teal-600, .end-teal-600-hover:hover {
  --x-end-color: var(--x-teal-600);
}

.end-teal-700, .end-teal-700-hover:hover {
  --x-end-color: var(--x-teal-700);
}

.end-teal-800, .end-teal-800-hover:hover {
  --x-end-color: var(--x-teal-800);
}

.end-teal-900, .end-teal-900-hover:hover {
  --x-end-color: var(--x-teal-900);
}

.end-cyan-100, .end-cyan-100-hover:hover {
  --x-end-color: var(--x-cyan-100);
}

.end-cyan-200, .end-cyan-200-hover:hover {
  --x-end-color: var(--x-cyan-200);
}

.end-cyan-300, .end-cyan-300-hover:hover {
  --x-end-color: var(--x-cyan-300);
}

.end-cyan-400, .end-cyan-400-hover:hover {
  --x-end-color: var(--x-cyan-400);
}

.end-cyan-500, .end-cyan-500-hover:hover {
  --x-end-color: var(--x-cyan-500);
}

.end-cyan-600, .end-cyan-600-hover:hover {
  --x-end-color: var(--x-cyan-600);
}

.end-cyan-700, .end-cyan-700-hover:hover {
  --x-end-color: var(--x-cyan-700);
}

.end-cyan-800, .end-cyan-800-hover:hover {
  --x-end-color: var(--x-cyan-800);
}

.end-cyan-900, .end-cyan-900-hover:hover {
  --x-end-color: var(--x-cyan-900);
}

.filter {
  --x-blur: 0;
  --x-contrast: 0;
  --x-grayscale: 0;
  --x-hue-rotate: 0;
  --x-drop-shadow: 0;
  filter: blur(var(--x-blur));
}

.transform {
  --x-translate-x: 0;
  --x-translate-y: 0;
  --x-translate-z: 0;
  --x-perspective: 0;
  --x-rotate: 0;
  --x-rotate-x: 0;
  --x-rotate-y: 0;
  --x-skew-x: 0;
  --x-skew-y: 0;
  --x-scale-x: 1;
  --x-scale-y: 1;
  transform: translateX(var(--x-translate-x)) translateY(var(--x-translate-y)) perspective(var(--x-perspective)) rotate(var(--x-rotate)) rotateX(var(--x-rotate-x)) rotateY(var(--x-rotate-y)) skewX(var(--x-skew-x)) skewY(var(--x-skew-y)) scaleX(var(--x-scale-x)) scaleY(var(--x-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.h-calc {
  --x-h: 0px;
  height: calc(100vh - var(--x-h));
}

.w-calc {
  --x-w: 0px;
  width: calc(100vw - var(--x-w));
}

@media (min-width: 576px) {
  .h-sm-calc {
    --x-h-sm: 0px;
    height: calc(100vh - var(--x-h-sm));
  }

  .w-sm-calc {
    --x-w-sm: 0px;
    width: calc(100vw - var(--x-w-sm));
  }
}

@media (min-width: 768px) {
  .h-md-calc {
    --x-h-md: 0px;
    height: calc(100vh - var(--x-h-md));
  }

  .w-md-calc {
    --x-w-md: 0px;
    width: calc(100vw - var(--x-w-md));
  }
}

@media (min-width: 992px) {
  .h-lg-calc {
    --x-h-lg: 0px;
    height: calc(100vh - var(--x-h-lg));
  }

  .w-lg-calc {
    --x-w-lg: 0px;
    width: calc(100vw - var(--x-w-lg));
  }
}

@media (min-width: 1200px) {
  .h-xl-calc {
    --x-h-xl: 0px;
    height: calc(100vh - var(--x-h-xl));
  }

  .w-xl-calc {
    --x-w-xl: 0px;
    width: calc(100vw - var(--x-w-xl));
  }
}

@media (min-width: 1400px) {
  .h-xxl-calc {
    --x-h-xxl: 0px;
    height: calc(100vh - var(--x-h-xxl));
  }

  .w-xxl-calc {
    --x-w-xxl: 0px;
    width: calc(100vw - var(--x-w-xxl));
  }
}

[class*="hover-"] {
  transition: all .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  [class*="hover-"] {
    transition: none;
  }
}

[class*="shadow"] {
  transition: all .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  [class*="shadow"] {
    transition: none;
  }
}

.scrollable-x {
  white-space: nowrap;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: auto;
  overflow-y: hidden;
}

.scrollable-x::-webkit-scrollbar {
  display: none;
}

.scrollable-y {
  white-space: nowrap;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: hidden;
  overflow-y: auto;
}

.scrollable-y::-webkit-scrollbar {
  display: none;
}

.border-body {
  --x-border-color: var(--x-body-bg);
}

.offset-start-screen-sm {
  padding-left: max(.75rem, 50% - 320px + .75rem);
}

.offset-end-screen-sm {
  padding-right: max(.75rem, 50% - 320px + .75rem);
}

.offset-start-screen-md {
  padding-left: max(.75rem, 50% - 384px + .75rem);
}

.offset-end-screen-md {
  padding-right: max(.75rem, 50% - 384px + .75rem);
}

.offset-start-screen-lg {
  padding-left: max(.75rem, 50% - 512px + .75rem);
}

.offset-end-screen-lg {
  padding-right: max(.75rem, 50% - 512px + .75rem);
}

.offset-start-screen-xl {
  padding-left: max(.75rem, 50% - 640px + .75rem);
}

.offset-end-screen-xl {
  padding-right: max(.75rem, 50% - 640px + .75rem);
}

.offset-start-screen-xxl {
  padding-left: max(.75rem, 50% - 768px + .75rem);
}

.offset-end-screen-xxl {
  padding-right: max(.75rem, 50% - 768px + .75rem);
}

[class*="faded"], [class*="blured"] {
  position: relative;
}

[class*="faded"]:after, [class*="blured"]:after {
  content: "";
  width: 100%;
  height: 3.9375rem;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

.faded-top:after {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00000000", endColorstr= "#a6000000", GradientType= 0);
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%);
  top: 0;
}

.faded-bottom:after {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00000000", endColorstr= "#a6000000", GradientType= 0);
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%);
  bottom: 0;
}

.blured-top:after {
  -webkit-backdrop-filter: blur(10px) saturate(200%);
  backdrop-filter: blur(10px) saturate(200%);
  background: rgba(255, 255, 255, .75);
  top: 0;
}

.blured-bottom:after {
  -webkit-backdrop-filter: blur(10px) saturate(200%);
  backdrop-filter: blur(10px) saturate(200%);
  background: rgba(255, 255, 255, .75);
  bottom: 0;
}

a.text-muted:hover, a.text-heading:hover, a.text-current:hover {
  color: #344d6d;
}

.img-grayscale {
  filter: grayscale();
}

.img-saturate {
  filter: saturate(150%);
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialised {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.text-highlight-primary {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(61, 90, 128, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-secondary {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(152, 193, 217, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-success {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(34, 197, 94, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-info {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(6, 182, 212, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-warning {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(249, 115, 22, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-danger {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(239, 68, 68, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-light {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(241, 245, 249, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-dark {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(37, 36, 34, .15) 34%);
  background-repeat: repeat-x;
}

.svg-fluid svg {
  max-width: 100%;
  height: 100%;
}

.group-item-invisible .group-item {
  visibility: hidden;
}

.group-item-visible-hover:hover .group-item {
  visibility: visible;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
  object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow, .shadow-hover:hover {
  box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-1, .shadow-1-hover:hover {
  box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-2, .shadow-2-hover:hover {
  box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-3, .shadow-3-hover:hover {
  box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-4, .shadow-4-hover:hover {
  box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-5, .shadow-5-hover:hover {
  box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-6, .shadow-6-hover:hover {
  box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
}

.shadow-inset, .shadow-inset-hover:hover {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
}

.shadow-none, .shadow-none-hover:hover {
  box-shadow: none !important;
}

.focus-ring-primary {
  --x-focus-ring-color: rgba(var(--x-primary-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-secondary {
  --x-focus-ring-color: rgba(var(--x-secondary-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-success {
  --x-focus-ring-color: rgba(var(--x-success-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-info {
  --x-focus-ring-color: rgba(var(--x-info-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-warning {
  --x-focus-ring-color: rgba(var(--x-warning-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-danger {
  --x-focus-ring-color: rgba(var(--x-danger-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-light {
  --x-focus-ring-color: rgba(var(--x-light-rgb), var(--x-focus-ring-opacity));
}

.focus-ring-dark {
  --x-focus-ring-color: rgba(var(--x-dark-rgb), var(--x-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.top-1 {
  top: .25rem !important;
}

.top-2 {
  top: .5rem !important;
}

.top-3 {
  top: .75rem !important;
}

.top-4 {
  top: 1rem !important;
}

.top-5 {
  top: 1.25rem !important;
}

.top-6 {
  top: 1.5rem !important;
}

.top-7 {
  top: 1.75rem !important;
}

.top-8 {
  top: 2rem !important;
}

.top-10 {
  top: 2.5rem !important;
}

.top-12 {
  top: 3rem !important;
}

.top-14 {
  top: 3.5rem !important;
}

.top-16 {
  top: 4rem !important;
}

.top-18 {
  top: 4.5rem !important;
}

.top-20 {
  top: 5rem !important;
}

.top-24 {
  top: 6rem !important;
}

.top-32 {
  top: 8rem !important;
}

.top-40 {
  top: 10rem !important;
}

.top-48 {
  top: 12rem !important;
}

.top-56 {
  top: 14rem !important;
}

.top-64 {
  top: 16rem !important;
}

.top-72 {
  top: 18rem !important;
}

.top-80 {
  top: 20rem !important;
}

.top-px {
  top: 1px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary, .border-primary-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-primary-rgb), var(--x-border-opacity)) !important;
}

.border-secondary, .border-secondary-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-secondary-rgb), var(--x-border-opacity)) !important;
}

.border-success, .border-success-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-success-rgb), var(--x-border-opacity)) !important;
}

.border-info, .border-info-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-info-rgb), var(--x-border-opacity)) !important;
}

.border-warning, .border-warning-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-warning-rgb), var(--x-border-opacity)) !important;
}

.border-danger, .border-danger-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-danger-rgb), var(--x-border-opacity)) !important;
}

.border-light, .border-light-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-light-rgb), var(--x-border-opacity)) !important;
}

.border-dark, .border-dark-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-dark-rgb), var(--x-border-opacity)) !important;
}

.border-black, .border-black-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-black-rgb), var(--x-border-opacity)) !important;
}

.border-white, .border-white-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-white-rgb), var(--x-border-opacity)) !important;
}

.border-gray-50, .border-gray-50-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-gray-50-rgb), var(--x-border-opacity)) !important;
}

.border-gray-100, .border-gray-100-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-gray-100-rgb), var(--x-border-opacity)) !important;
}

.border-gray-200, .border-gray-200-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-gray-200-rgb), var(--x-border-opacity)) !important;
}

.border-gray-300, .border-gray-300-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-gray-300-rgb), var(--x-border-opacity)) !important;
}

.border-gray-400, .border-gray-400-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-gray-400-rgb), var(--x-border-opacity)) !important;
}

.border-gray-500, .border-gray-500-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-gray-500-rgb), var(--x-border-opacity)) !important;
}

.border-gray-600, .border-gray-600-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-gray-600-rgb), var(--x-border-opacity)) !important;
}

.border-gray-700, .border-gray-700-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-gray-700-rgb), var(--x-border-opacity)) !important;
}

.border-gray-800, .border-gray-800-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-gray-800-rgb), var(--x-border-opacity)) !important;
}

.border-gray-900, .border-gray-900-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-gray-900-rgb), var(--x-border-opacity)) !important;
}

.border-gray-950, .border-gray-950-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-gray-950-rgb), var(--x-border-opacity)) !important;
}

.border-blue-100, .border-blue-100-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-blue-100-rgb), var(--x-border-opacity)) !important;
}

.border-blue-200, .border-blue-200-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-blue-200-rgb), var(--x-border-opacity)) !important;
}

.border-blue-300, .border-blue-300-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-blue-300-rgb), var(--x-border-opacity)) !important;
}

.border-blue-400, .border-blue-400-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-blue-400-rgb), var(--x-border-opacity)) !important;
}

.border-blue-500, .border-blue-500-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-blue-500-rgb), var(--x-border-opacity)) !important;
}

.border-blue-600, .border-blue-600-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-blue-600-rgb), var(--x-border-opacity)) !important;
}

.border-blue-700, .border-blue-700-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-blue-700-rgb), var(--x-border-opacity)) !important;
}

.border-blue-800, .border-blue-800-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-blue-800-rgb), var(--x-border-opacity)) !important;
}

.border-blue-900, .border-blue-900-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-blue-900-rgb), var(--x-border-opacity)) !important;
}

.border-indigo-100, .border-indigo-100-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-indigo-100-rgb), var(--x-border-opacity)) !important;
}

.border-indigo-200, .border-indigo-200-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-indigo-200-rgb), var(--x-border-opacity)) !important;
}

.border-indigo-300, .border-indigo-300-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-indigo-300-rgb), var(--x-border-opacity)) !important;
}

.border-indigo-400, .border-indigo-400-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-indigo-400-rgb), var(--x-border-opacity)) !important;
}

.border-indigo-500, .border-indigo-500-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-indigo-500-rgb), var(--x-border-opacity)) !important;
}

.border-indigo-600, .border-indigo-600-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-indigo-600-rgb), var(--x-border-opacity)) !important;
}

.border-indigo-700, .border-indigo-700-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-indigo-700-rgb), var(--x-border-opacity)) !important;
}

.border-indigo-800, .border-indigo-800-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-indigo-800-rgb), var(--x-border-opacity)) !important;
}

.border-indigo-900, .border-indigo-900-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-indigo-900-rgb), var(--x-border-opacity)) !important;
}

.border-purple-100, .border-purple-100-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-purple-100-rgb), var(--x-border-opacity)) !important;
}

.border-purple-200, .border-purple-200-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-purple-200-rgb), var(--x-border-opacity)) !important;
}

.border-purple-300, .border-purple-300-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-purple-300-rgb), var(--x-border-opacity)) !important;
}

.border-purple-400, .border-purple-400-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-purple-400-rgb), var(--x-border-opacity)) !important;
}

.border-purple-500, .border-purple-500-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-purple-500-rgb), var(--x-border-opacity)) !important;
}

.border-purple-600, .border-purple-600-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-purple-600-rgb), var(--x-border-opacity)) !important;
}

.border-purple-700, .border-purple-700-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-purple-700-rgb), var(--x-border-opacity)) !important;
}

.border-purple-800, .border-purple-800-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-purple-800-rgb), var(--x-border-opacity)) !important;
}

.border-purple-900, .border-purple-900-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-purple-900-rgb), var(--x-border-opacity)) !important;
}

.border-pink-100, .border-pink-100-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-pink-100-rgb), var(--x-border-opacity)) !important;
}

.border-pink-200, .border-pink-200-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-pink-200-rgb), var(--x-border-opacity)) !important;
}

.border-pink-300, .border-pink-300-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-pink-300-rgb), var(--x-border-opacity)) !important;
}

.border-pink-400, .border-pink-400-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-pink-400-rgb), var(--x-border-opacity)) !important;
}

.border-pink-500, .border-pink-500-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-pink-500-rgb), var(--x-border-opacity)) !important;
}

.border-pink-600, .border-pink-600-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-pink-600-rgb), var(--x-border-opacity)) !important;
}

.border-pink-700, .border-pink-700-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-pink-700-rgb), var(--x-border-opacity)) !important;
}

.border-pink-800, .border-pink-800-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-pink-800-rgb), var(--x-border-opacity)) !important;
}

.border-pink-900, .border-pink-900-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-pink-900-rgb), var(--x-border-opacity)) !important;
}

.border-red-100, .border-red-100-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-red-100-rgb), var(--x-border-opacity)) !important;
}

.border-red-200, .border-red-200-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-red-200-rgb), var(--x-border-opacity)) !important;
}

.border-red-300, .border-red-300-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-red-300-rgb), var(--x-border-opacity)) !important;
}

.border-red-400, .border-red-400-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-red-400-rgb), var(--x-border-opacity)) !important;
}

.border-red-500, .border-red-500-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-red-500-rgb), var(--x-border-opacity)) !important;
}

.border-red-600, .border-red-600-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-red-600-rgb), var(--x-border-opacity)) !important;
}

.border-red-700, .border-red-700-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-red-700-rgb), var(--x-border-opacity)) !important;
}

.border-red-800, .border-red-800-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-red-800-rgb), var(--x-border-opacity)) !important;
}

.border-red-900, .border-red-900-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-red-900-rgb), var(--x-border-opacity)) !important;
}

.border-orange-100, .border-orange-100-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-orange-100-rgb), var(--x-border-opacity)) !important;
}

.border-orange-200, .border-orange-200-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-orange-200-rgb), var(--x-border-opacity)) !important;
}

.border-orange-300, .border-orange-300-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-orange-300-rgb), var(--x-border-opacity)) !important;
}

.border-orange-400, .border-orange-400-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-orange-400-rgb), var(--x-border-opacity)) !important;
}

.border-orange-500, .border-orange-500-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-orange-500-rgb), var(--x-border-opacity)) !important;
}

.border-orange-600, .border-orange-600-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-orange-600-rgb), var(--x-border-opacity)) !important;
}

.border-orange-700, .border-orange-700-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-orange-700-rgb), var(--x-border-opacity)) !important;
}

.border-orange-800, .border-orange-800-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-orange-800-rgb), var(--x-border-opacity)) !important;
}

.border-orange-900, .border-orange-900-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-orange-900-rgb), var(--x-border-opacity)) !important;
}

.border-yellow-100, .border-yellow-100-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-yellow-100-rgb), var(--x-border-opacity)) !important;
}

.border-yellow-200, .border-yellow-200-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-yellow-200-rgb), var(--x-border-opacity)) !important;
}

.border-yellow-300, .border-yellow-300-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-yellow-300-rgb), var(--x-border-opacity)) !important;
}

.border-yellow-400, .border-yellow-400-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-yellow-400-rgb), var(--x-border-opacity)) !important;
}

.border-yellow-500, .border-yellow-500-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-yellow-500-rgb), var(--x-border-opacity)) !important;
}

.border-yellow-600, .border-yellow-600-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-yellow-600-rgb), var(--x-border-opacity)) !important;
}

.border-yellow-700, .border-yellow-700-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-yellow-700-rgb), var(--x-border-opacity)) !important;
}

.border-yellow-800, .border-yellow-800-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-yellow-800-rgb), var(--x-border-opacity)) !important;
}

.border-yellow-900, .border-yellow-900-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-yellow-900-rgb), var(--x-border-opacity)) !important;
}

.border-green-100, .border-green-100-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-green-100-rgb), var(--x-border-opacity)) !important;
}

.border-green-200, .border-green-200-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-green-200-rgb), var(--x-border-opacity)) !important;
}

.border-green-300, .border-green-300-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-green-300-rgb), var(--x-border-opacity)) !important;
}

.border-green-400, .border-green-400-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-green-400-rgb), var(--x-border-opacity)) !important;
}

.border-green-500, .border-green-500-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-green-500-rgb), var(--x-border-opacity)) !important;
}

.border-green-600, .border-green-600-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-green-600-rgb), var(--x-border-opacity)) !important;
}

.border-green-700, .border-green-700-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-green-700-rgb), var(--x-border-opacity)) !important;
}

.border-green-800, .border-green-800-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-green-800-rgb), var(--x-border-opacity)) !important;
}

.border-green-900, .border-green-900-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-green-900-rgb), var(--x-border-opacity)) !important;
}

.border-teal-100, .border-teal-100-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-teal-100-rgb), var(--x-border-opacity)) !important;
}

.border-teal-200, .border-teal-200-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-teal-200-rgb), var(--x-border-opacity)) !important;
}

.border-teal-300, .border-teal-300-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-teal-300-rgb), var(--x-border-opacity)) !important;
}

.border-teal-400, .border-teal-400-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-teal-400-rgb), var(--x-border-opacity)) !important;
}

.border-teal-500, .border-teal-500-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-teal-500-rgb), var(--x-border-opacity)) !important;
}

.border-teal-600, .border-teal-600-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-teal-600-rgb), var(--x-border-opacity)) !important;
}

.border-teal-700, .border-teal-700-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-teal-700-rgb), var(--x-border-opacity)) !important;
}

.border-teal-800, .border-teal-800-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-teal-800-rgb), var(--x-border-opacity)) !important;
}

.border-teal-900, .border-teal-900-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-teal-900-rgb), var(--x-border-opacity)) !important;
}

.border-cyan-100, .border-cyan-100-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-cyan-100-rgb), var(--x-border-opacity)) !important;
}

.border-cyan-200, .border-cyan-200-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-cyan-200-rgb), var(--x-border-opacity)) !important;
}

.border-cyan-300, .border-cyan-300-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-cyan-300-rgb), var(--x-border-opacity)) !important;
}

.border-cyan-400, .border-cyan-400-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-cyan-400-rgb), var(--x-border-opacity)) !important;
}

.border-cyan-500, .border-cyan-500-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-cyan-500-rgb), var(--x-border-opacity)) !important;
}

.border-cyan-600, .border-cyan-600-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-cyan-600-rgb), var(--x-border-opacity)) !important;
}

.border-cyan-700, .border-cyan-700-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-cyan-700-rgb), var(--x-border-opacity)) !important;
}

.border-cyan-800, .border-cyan-800-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-cyan-800-rgb), var(--x-border-opacity)) !important;
}

.border-cyan-900, .border-cyan-900-hover:hover {
  --x-border-opacity: 1;
  border-color: rgba(var(--x-cyan-900-rgb), var(--x-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--x-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--x-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--x-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--x-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--x-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--x-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--x-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--x-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-0, .border-opacity-0-hover:hover {
  --x-border-opacity: 0;
}

.border-opacity-10, .border-opacity-10-hover:hover {
  --x-border-opacity: .1;
}

.border-opacity-25, .border-opacity-25-hover:hover {
  --x-border-opacity: .25;
}

.border-opacity-50, .border-opacity-50-hover:hover {
  --x-border-opacity: .5;
}

.border-opacity-75, .border-opacity-75-hover:hover {
  --x-border-opacity: .75;
}

.border-opacity-100, .border-opacity-100-hover:hover {
  --x-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-screen-sm {
  max-width: 640px !important;
}

.mw-screen-md {
  max-width: 768px !important;
}

.mw-screen-lg {
  max-width: 1024px !important;
}

.mw-screen-xl {
  max-width: 1280px !important;
}

.mw-screen-xxl {
  max-width: 1536px !important;
}

.mw-0 {
  max-width: 0 !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-read {
  max-width: 65ch !important;
}

.mw-fit {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-initial {
  flex: 0 auto !important;
}

.flex-none {
  flex: none !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: .75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.m-32 {
  margin: 8rem !important;
}

.m-40 {
  margin: 10rem !important;
}

.m-48 {
  margin: 12rem !important;
}

.m-56 {
  margin: 14rem !important;
}

.m-64 {
  margin: 16rem !important;
}

.m-72 {
  margin: 18rem !important;
}

.m-80 {
  margin: 20rem !important;
}

.m-px {
  margin: 1px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-7 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-10 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.mx-12 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-14 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.mx-16 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-18 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.mx-20 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.mx-24 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.mx-32 {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.mx-40 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.mx-48 {
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}

.mx-56 {
  margin-left: 14rem !important;
  margin-right: 14rem !important;
}

.mx-64 {
  margin-left: 16rem !important;
  margin-right: 16rem !important;
}

.mx-72 {
  margin-left: 18rem !important;
  margin-right: 18rem !important;
}

.mx-80 {
  margin-left: 20rem !important;
  margin-right: 20rem !important;
}

.mx-px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-24 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-32 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-40 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-48 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-56 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-64 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-72 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.my-80 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-24 {
  margin-top: 6rem !important;
}

.mt-32 {
  margin-top: 8rem !important;
}

.mt-40 {
  margin-top: 10rem !important;
}

.mt-48 {
  margin-top: 12rem !important;
}

.mt-56 {
  margin-top: 14rem !important;
}

.mt-64 {
  margin-top: 16rem !important;
}

.mt-72 {
  margin-top: 18rem !important;
}

.mt-80 {
  margin-top: 20rem !important;
}

.mt-px {
  margin-top: 1px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: .75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-12 {
  margin-right: 3rem !important;
}

.me-14 {
  margin-right: 3.5rem !important;
}

.me-16 {
  margin-right: 4rem !important;
}

.me-18 {
  margin-right: 4.5rem !important;
}

.me-20 {
  margin-right: 5rem !important;
}

.me-24 {
  margin-right: 6rem !important;
}

.me-32 {
  margin-right: 8rem !important;
}

.me-40 {
  margin-right: 10rem !important;
}

.me-48 {
  margin-right: 12rem !important;
}

.me-56 {
  margin-right: 14rem !important;
}

.me-64 {
  margin-right: 16rem !important;
}

.me-72 {
  margin-right: 18rem !important;
}

.me-80 {
  margin-right: 20rem !important;
}

.me-px {
  margin-right: 1px !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: .75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-24 {
  margin-bottom: 6rem !important;
}

.mb-32 {
  margin-bottom: 8rem !important;
}

.mb-40 {
  margin-bottom: 10rem !important;
}

.mb-48 {
  margin-bottom: 12rem !important;
}

.mb-56 {
  margin-bottom: 14rem !important;
}

.mb-64 {
  margin-bottom: 16rem !important;
}

.mb-72 {
  margin-bottom: 18rem !important;
}

.mb-80 {
  margin-bottom: 20rem !important;
}

.mb-px {
  margin-bottom: 1px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: .75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-12 {
  margin-left: 3rem !important;
}

.ms-14 {
  margin-left: 3.5rem !important;
}

.ms-16 {
  margin-left: 4rem !important;
}

.ms-18 {
  margin-left: 4.5rem !important;
}

.ms-20 {
  margin-left: 5rem !important;
}

.ms-24 {
  margin-left: 6rem !important;
}

.ms-32 {
  margin-left: 8rem !important;
}

.ms-40 {
  margin-left: 10rem !important;
}

.ms-48 {
  margin-left: 12rem !important;
}

.ms-56 {
  margin-left: 14rem !important;
}

.ms-64 {
  margin-left: 16rem !important;
}

.ms-72 {
  margin-left: 18rem !important;
}

.ms-80 {
  margin-left: 20rem !important;
}

.ms-px {
  margin-left: 1px !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.m-n3 {
  margin: -.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.m-n12 {
  margin: -3rem !important;
}

.m-n14 {
  margin: -3.5rem !important;
}

.m-n16 {
  margin: -4rem !important;
}

.m-n18 {
  margin: -4.5rem !important;
}

.m-n20 {
  margin: -5rem !important;
}

.m-n24 {
  margin: -6rem !important;
}

.m-n32 {
  margin: -8rem !important;
}

.m-n40 {
  margin: -10rem !important;
}

.m-n48 {
  margin: -12rem !important;
}

.m-n56 {
  margin: -14rem !important;
}

.m-n64 {
  margin: -16rem !important;
}

.m-n72 {
  margin: -18rem !important;
}

.m-n80 {
  margin: -20rem !important;
}

.m-npx {
  margin: -1px !important;
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-n3 {
  margin-left: -.75rem !important;
  margin-right: -.75rem !important;
}

.mx-n4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.mx-n6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n7 {
  margin-left: -1.75rem !important;
  margin-right: -1.75rem !important;
}

.mx-n8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.mx-n10 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.mx-n12 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.mx-n14 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.mx-n16 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.mx-n18 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}

.mx-n20 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.mx-n24 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.mx-n32 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.mx-n40 {
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.mx-n48 {
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.mx-n56 {
  margin-left: -14rem !important;
  margin-right: -14rem !important;
}

.mx-n64 {
  margin-left: -16rem !important;
  margin-right: -16rem !important;
}

.mx-n72 {
  margin-left: -18rem !important;
  margin-right: -18rem !important;
}

.mx-n80 {
  margin-left: -20rem !important;
  margin-right: -20rem !important;
}

.mx-npx {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.my-n1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.my-n2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.my-n3 {
  margin-top: -.75rem !important;
  margin-bottom: -.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n18 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n24 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n32 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n40 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n48 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.my-n56 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.my-n64 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.my-n72 {
  margin-top: -18rem !important;
  margin-bottom: -18rem !important;
}

.my-n80 {
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.my-npx {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3 {
  margin-top: -.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -1.75rem !important;
}

.mt-n8 {
  margin-top: -2rem !important;
}

.mt-n10 {
  margin-top: -2.5rem !important;
}

.mt-n12 {
  margin-top: -3rem !important;
}

.mt-n14 {
  margin-top: -3.5rem !important;
}

.mt-n16 {
  margin-top: -4rem !important;
}

.mt-n18 {
  margin-top: -4.5rem !important;
}

.mt-n20 {
  margin-top: -5rem !important;
}

.mt-n24 {
  margin-top: -6rem !important;
}

.mt-n32 {
  margin-top: -8rem !important;
}

.mt-n40 {
  margin-top: -10rem !important;
}

.mt-n48 {
  margin-top: -12rem !important;
}

.mt-n56 {
  margin-top: -14rem !important;
}

.mt-n64 {
  margin-top: -16rem !important;
}

.mt-n72 {
  margin-top: -18rem !important;
}

.mt-n80 {
  margin-top: -20rem !important;
}

.mt-npx {
  margin-top: -1px !important;
}

.me-n1 {
  margin-right: -.25rem !important;
}

.me-n2 {
  margin-right: -.5rem !important;
}

.me-n3 {
  margin-right: -.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.25rem !important;
}

.me-n6 {
  margin-right: -1.5rem !important;
}

.me-n7 {
  margin-right: -1.75rem !important;
}

.me-n8 {
  margin-right: -2rem !important;
}

.me-n10 {
  margin-right: -2.5rem !important;
}

.me-n12 {
  margin-right: -3rem !important;
}

.me-n14 {
  margin-right: -3.5rem !important;
}

.me-n16 {
  margin-right: -4rem !important;
}

.me-n18 {
  margin-right: -4.5rem !important;
}

.me-n20 {
  margin-right: -5rem !important;
}

.me-n24 {
  margin-right: -6rem !important;
}

.me-n32 {
  margin-right: -8rem !important;
}

.me-n40 {
  margin-right: -10rem !important;
}

.me-n48 {
  margin-right: -12rem !important;
}

.me-n56 {
  margin-right: -14rem !important;
}

.me-n64 {
  margin-right: -16rem !important;
}

.me-n72 {
  margin-right: -18rem !important;
}

.me-n80 {
  margin-right: -20rem !important;
}

.me-npx {
  margin-right: -1px !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mb-n2 {
  margin-bottom: -.5rem !important;
}

.mb-n3 {
  margin-bottom: -.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -1.75rem !important;
}

.mb-n8 {
  margin-bottom: -2rem !important;
}

.mb-n10 {
  margin-bottom: -2.5rem !important;
}

.mb-n12 {
  margin-bottom: -3rem !important;
}

.mb-n14 {
  margin-bottom: -3.5rem !important;
}

.mb-n16 {
  margin-bottom: -4rem !important;
}

.mb-n18 {
  margin-bottom: -4.5rem !important;
}

.mb-n20 {
  margin-bottom: -5rem !important;
}

.mb-n24 {
  margin-bottom: -6rem !important;
}

.mb-n32 {
  margin-bottom: -8rem !important;
}

.mb-n40 {
  margin-bottom: -10rem !important;
}

.mb-n48 {
  margin-bottom: -12rem !important;
}

.mb-n56 {
  margin-bottom: -14rem !important;
}

.mb-n64 {
  margin-bottom: -16rem !important;
}

.mb-n72 {
  margin-bottom: -18rem !important;
}

.mb-n80 {
  margin-bottom: -20rem !important;
}

.mb-npx {
  margin-bottom: -1px !important;
}

.ms-n1 {
  margin-left: -.25rem !important;
}

.ms-n2 {
  margin-left: -.5rem !important;
}

.ms-n3 {
  margin-left: -.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.25rem !important;
}

.ms-n6 {
  margin-left: -1.5rem !important;
}

.ms-n7 {
  margin-left: -1.75rem !important;
}

.ms-n8 {
  margin-left: -2rem !important;
}

.ms-n10 {
  margin-left: -2.5rem !important;
}

.ms-n12 {
  margin-left: -3rem !important;
}

.ms-n14 {
  margin-left: -3.5rem !important;
}

.ms-n16 {
  margin-left: -4rem !important;
}

.ms-n18 {
  margin-left: -4.5rem !important;
}

.ms-n20 {
  margin-left: -5rem !important;
}

.ms-n24 {
  margin-left: -6rem !important;
}

.ms-n32 {
  margin-left: -8rem !important;
}

.ms-n40 {
  margin-left: -10rem !important;
}

.ms-n48 {
  margin-left: -12rem !important;
}

.ms-n56 {
  margin-left: -14rem !important;
}

.ms-n64 {
  margin-left: -16rem !important;
}

.ms-n72 {
  margin-left: -18rem !important;
}

.ms-n80 {
  margin-left: -20rem !important;
}

.ms-npx {
  margin-left: -1px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.p-32 {
  padding: 8rem !important;
}

.p-40 {
  padding: 10rem !important;
}

.p-48 {
  padding: 12rem !important;
}

.p-56 {
  padding: 14rem !important;
}

.p-64 {
  padding: 16rem !important;
}

.p-72 {
  padding: 18rem !important;
}

.p-80 {
  padding: 20rem !important;
}

.p-px {
  padding: 1px !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-14 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-18 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.px-20 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.px-24 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.px-32 {
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.px-40 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.px-48 {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}

.px-56 {
  padding-left: 14rem !important;
  padding-right: 14rem !important;
}

.px-64 {
  padding-left: 16rem !important;
  padding-right: 16rem !important;
}

.px-72 {
  padding-left: 18rem !important;
  padding-right: 18rem !important;
}

.px-80 {
  padding-left: 20rem !important;
  padding-right: 20rem !important;
}

.px-px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-48 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-56 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.py-64 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.py-72 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.py-80 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: .75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pt-24 {
  padding-top: 6rem !important;
}

.pt-32 {
  padding-top: 8rem !important;
}

.pt-40 {
  padding-top: 10rem !important;
}

.pt-48 {
  padding-top: 12rem !important;
}

.pt-56 {
  padding-top: 14rem !important;
}

.pt-64 {
  padding-top: 16rem !important;
}

.pt-72 {
  padding-top: 18rem !important;
}

.pt-80 {
  padding-top: 20rem !important;
}

.pt-px {
  padding-top: 1px !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: .75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-12 {
  padding-right: 3rem !important;
}

.pe-14 {
  padding-right: 3.5rem !important;
}

.pe-16 {
  padding-right: 4rem !important;
}

.pe-18 {
  padding-right: 4.5rem !important;
}

.pe-20 {
  padding-right: 5rem !important;
}

.pe-24 {
  padding-right: 6rem !important;
}

.pe-32 {
  padding-right: 8rem !important;
}

.pe-40 {
  padding-right: 10rem !important;
}

.pe-48 {
  padding-right: 12rem !important;
}

.pe-56 {
  padding-right: 14rem !important;
}

.pe-64 {
  padding-right: 16rem !important;
}

.pe-72 {
  padding-right: 18rem !important;
}

.pe-80 {
  padding-right: 20rem !important;
}

.pe-px {
  padding-right: 1px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pb-24 {
  padding-bottom: 6rem !important;
}

.pb-32 {
  padding-bottom: 8rem !important;
}

.pb-40 {
  padding-bottom: 10rem !important;
}

.pb-48 {
  padding-bottom: 12rem !important;
}

.pb-56 {
  padding-bottom: 14rem !important;
}

.pb-64 {
  padding-bottom: 16rem !important;
}

.pb-72 {
  padding-bottom: 18rem !important;
}

.pb-80 {
  padding-bottom: 20rem !important;
}

.pb-px {
  padding-bottom: 1px !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: .75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-12 {
  padding-left: 3rem !important;
}

.ps-14 {
  padding-left: 3.5rem !important;
}

.ps-16 {
  padding-left: 4rem !important;
}

.ps-18 {
  padding-left: 4.5rem !important;
}

.ps-20 {
  padding-left: 5rem !important;
}

.ps-24 {
  padding-left: 6rem !important;
}

.ps-32 {
  padding-left: 8rem !important;
}

.ps-40 {
  padding-left: 10rem !important;
}

.ps-48 {
  padding-left: 12rem !important;
}

.ps-56 {
  padding-left: 14rem !important;
}

.ps-64 {
  padding-left: 16rem !important;
}

.ps-72 {
  padding-left: 18rem !important;
}

.ps-80 {
  padding-left: 20rem !important;
}

.ps-px {
  padding-left: 1px !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: .75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.gap-24 {
  gap: 6rem !important;
}

.gap-32 {
  gap: 8rem !important;
}

.gap-40 {
  gap: 10rem !important;
}

.gap-48 {
  gap: 12rem !important;
}

.gap-56 {
  gap: 14rem !important;
}

.gap-64 {
  gap: 16rem !important;
}

.gap-72 {
  gap: 18rem !important;
}

.gap-80 {
  gap: 20rem !important;
}

.gap-px {
  gap: 1px !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: .75rem !important;
}

.row-gap-4 {
  row-gap: 1rem !important;
}

.row-gap-5 {
  row-gap: 1.25rem !important;
}

.row-gap-6 {
  row-gap: 1.5rem !important;
}

.row-gap-7 {
  row-gap: 1.75rem !important;
}

.row-gap-8 {
  row-gap: 2rem !important;
}

.row-gap-10 {
  row-gap: 2.5rem !important;
}

.row-gap-12 {
  row-gap: 3rem !important;
}

.row-gap-14 {
  row-gap: 3.5rem !important;
}

.row-gap-16 {
  row-gap: 4rem !important;
}

.row-gap-18 {
  row-gap: 4.5rem !important;
}

.row-gap-20 {
  row-gap: 5rem !important;
}

.row-gap-24 {
  row-gap: 6rem !important;
}

.row-gap-32 {
  row-gap: 8rem !important;
}

.row-gap-40 {
  row-gap: 10rem !important;
}

.row-gap-48 {
  row-gap: 12rem !important;
}

.row-gap-56 {
  row-gap: 14rem !important;
}

.row-gap-64 {
  row-gap: 16rem !important;
}

.row-gap-72 {
  row-gap: 18rem !important;
}

.row-gap-80 {
  row-gap: 20rem !important;
}

.row-gap-px {
  row-gap: 1px !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: .25rem !important;
  column-gap: .25rem !important;
}

.column-gap-2 {
  -moz-column-gap: .5rem !important;
  column-gap: .5rem !important;
}

.column-gap-3 {
  -moz-column-gap: .75rem !important;
  column-gap: .75rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}

.column-gap-5 {
  -moz-column-gap: 1.25rem !important;
  column-gap: 1.25rem !important;
}

.column-gap-6 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.column-gap-7 {
  -moz-column-gap: 1.75rem !important;
  column-gap: 1.75rem !important;
}

.column-gap-8 {
  -moz-column-gap: 2rem !important;
  column-gap: 2rem !important;
}

.column-gap-10 {
  -moz-column-gap: 2.5rem !important;
  column-gap: 2.5rem !important;
}

.column-gap-12 {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important;
}

.column-gap-14 {
  -moz-column-gap: 3.5rem !important;
  column-gap: 3.5rem !important;
}

.column-gap-16 {
  -moz-column-gap: 4rem !important;
  column-gap: 4rem !important;
}

.column-gap-18 {
  -moz-column-gap: 4.5rem !important;
  column-gap: 4.5rem !important;
}

.column-gap-20 {
  -moz-column-gap: 5rem !important;
  column-gap: 5rem !important;
}

.column-gap-24 {
  -moz-column-gap: 6rem !important;
  column-gap: 6rem !important;
}

.column-gap-32 {
  -moz-column-gap: 8rem !important;
  column-gap: 8rem !important;
}

.column-gap-40 {
  -moz-column-gap: 10rem !important;
  column-gap: 10rem !important;
}

.column-gap-48 {
  -moz-column-gap: 12rem !important;
  column-gap: 12rem !important;
}

.column-gap-56 {
  -moz-column-gap: 14rem !important;
  column-gap: 14rem !important;
}

.column-gap-64 {
  -moz-column-gap: 16rem !important;
  column-gap: 16rem !important;
}

.column-gap-72 {
  -moz-column-gap: 18rem !important;
  column-gap: 18rem !important;
}

.column-gap-80 {
  -moz-column-gap: 20rem !important;
  column-gap: 20rem !important;
}

.column-gap-px {
  -moz-column-gap: 1px !important;
  column-gap: 1px !important;
}

.font-monospace {
  font-family: var(--x-font-monospace) !important;
}

.font-base {
  font-family: var(--x-font-sans-serif) !important;
}

.font-serif {
  font-family: var(--x-font-serif) !important;
}

.font-display {
  font-family: var(--x-font-display) !important;
}

.font-code {
  font-family: var(--x-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-3 {
  font-size: calc(1.2625rem + .15vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: .875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium, .fw-semibold {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-xxs {
  line-height: 1.125 !important;
}

.lh-xs {
  line-height: 1.2 !important;
}

.lh-sm {
  line-height: 1.375 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.625 !important;
}

.lh-xl {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary, .text-primary-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-primary-rgb), var(--x-text-opacity)) !important;
}

.text-secondary, .text-secondary-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-secondary-rgb), var(--x-text-opacity)) !important;
}

.text-success, .text-success-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-success-rgb), var(--x-text-opacity)) !important;
}

.text-info, .text-info-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-info-rgb), var(--x-text-opacity)) !important;
}

.text-warning, .text-warning-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-warning-rgb), var(--x-text-opacity)) !important;
}

.text-danger, .text-danger-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-danger-rgb), var(--x-text-opacity)) !important;
}

.text-light, .text-light-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-light-rgb), var(--x-text-opacity)) !important;
}

.text-dark, .text-dark-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-dark-rgb), var(--x-text-opacity)) !important;
}

.text-black, .text-black-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-black-rgb), var(--x-text-opacity)) !important;
}

.text-white, .text-white-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-white-rgb), var(--x-text-opacity)) !important;
}

.text-body, .text-body-hover:hover {
  --x-text-opacity: 1;
  color: rgba(var(--x-body-color-rgb), var(--x-text-opacity)) !important;
}

.text-muted, .text-muted-hover:hover {
  --x-text-opacity: 1;
  color: var(--x-secondary-color) !important;
}

.text-black-50, .text-black-50-hover:hover {
  --x-text-opacity: 1;
  color: rgba(0, 0, 0, .5) !important;
}

.text-white-50, .text-white-50-hover:hover {
  --x-text-opacity: 1;
  color: rgba(255, 255, 255, .5) !important;
}

.text-body-secondary, .text-body-secondary-hover:hover {
  --x-text-opacity: 1;
  color: var(--x-secondary-color) !important;
}

.text-body-tertiary, .text-body-tertiary-hover:hover {
  --x-text-opacity: 1;
  color: var(--x-tertiary-color) !important;
}

.text-body-emphasis, .text-body-emphasis-hover:hover {
  --x-text-opacity: 1;
  color: var(--x-emphasis-color) !important;
}

.text-reset, .text-reset-hover:hover {
  --x-text-opacity: 1;
  color: inherit !important;
}

.text-heading, .text-heading-hover:hover {
  --x-text-opacity: 1;
  color: var(--x-heading-color) !important;
}

.text-opacity-25, .text-opacity-25-hover:hover {
  --x-text-opacity: .25;
}

.text-opacity-50, .text-opacity-50-hover:hover {
  --x-text-opacity: .5;
}

.text-opacity-75, .text-opacity-75-hover:hover {
  --x-text-opacity: .75;
}

.text-opacity-100, .text-opacity-100-hover:hover {
  --x-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--x-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--x-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--x-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--x-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--x-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--x-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--x-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--x-dark-text-emphasis) !important;
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --x-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --x-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --x-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --x-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --x-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-primary-rgb), var(--x-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--x-primary-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-primary-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-secondary-rgb), var(--x-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--x-secondary-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-secondary-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-success {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-success-rgb), var(--x-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--x-success-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-success-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-info {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-info-rgb), var(--x-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--x-info-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-info-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-warning {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-warning-rgb), var(--x-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--x-warning-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-warning-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-danger {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-danger-rgb), var(--x-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--x-danger-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-danger-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-light {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-light-rgb), var(--x-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--x-light-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-light-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline-dark {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-dark-rgb), var(--x-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--x-dark-rgb), var(--x-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--x-dark-rgb), var(--x-link-underline-opacity)) !important;
}

.link-underline {
  --x-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--x-link-color-rgb), var(--x-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: rgba(var(--x-link-color-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: rgba(var(--x-link-color-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --x-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --x-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --x-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --x-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --x-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --x-link-underline-opacity: 1;
}

.bg-primary, .bg-primary-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-primary-rgb), var(--x-bg-opacity)) !important;
}

.bg-secondary, .bg-secondary-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-secondary-rgb), var(--x-bg-opacity)) !important;
}

.bg-success, .bg-success-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-success-rgb), var(--x-bg-opacity)) !important;
}

.bg-info, .bg-info-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-info-rgb), var(--x-bg-opacity)) !important;
}

.bg-warning, .bg-warning-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-warning-rgb), var(--x-bg-opacity)) !important;
}

.bg-danger, .bg-danger-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-danger-rgb), var(--x-bg-opacity)) !important;
}

.bg-light, .bg-light-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-light-rgb), var(--x-bg-opacity)) !important;
}

.bg-dark, .bg-dark-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-dark-rgb), var(--x-bg-opacity)) !important;
}

.bg-black, .bg-black-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-black-rgb), var(--x-bg-opacity)) !important;
}

.bg-white, .bg-white-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
}

.bg-body, .bg-body-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-body-bg-rgb), var(--x-bg-opacity)) !important;
}

.bg-transparent, .bg-transparent-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 0) !important;
}

.bg-body-secondary, .bg-body-secondary-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-secondary-bg-rgb), var(--x-bg-opacity)) !important;
}

.bg-body-tertiary, .bg-body-tertiary-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-tertiary-bg-rgb), var(--x-bg-opacity)) !important;
}

.bg-gray-50, .bg-gray-50-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-gray-50-rgb), var(--x-bg-opacity)) !important;
}

.bg-gray-100, .bg-gray-100-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-gray-100-rgb), var(--x-bg-opacity)) !important;
}

.bg-gray-200, .bg-gray-200-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-gray-200-rgb), var(--x-bg-opacity)) !important;
}

.bg-gray-300, .bg-gray-300-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-gray-300-rgb), var(--x-bg-opacity)) !important;
}

.bg-gray-400, .bg-gray-400-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-gray-400-rgb), var(--x-bg-opacity)) !important;
}

.bg-gray-500, .bg-gray-500-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-gray-500-rgb), var(--x-bg-opacity)) !important;
}

.bg-gray-600, .bg-gray-600-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-gray-600-rgb), var(--x-bg-opacity)) !important;
}

.bg-gray-700, .bg-gray-700-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-gray-700-rgb), var(--x-bg-opacity)) !important;
}

.bg-gray-800, .bg-gray-800-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-gray-800-rgb), var(--x-bg-opacity)) !important;
}

.bg-gray-900, .bg-gray-900-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-gray-900-rgb), var(--x-bg-opacity)) !important;
}

.bg-gray-950, .bg-gray-950-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-gray-950-rgb), var(--x-bg-opacity)) !important;
}

.bg-blue-100, .bg-blue-100-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-blue-100-rgb), var(--x-bg-opacity)) !important;
}

.bg-blue-200, .bg-blue-200-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-blue-200-rgb), var(--x-bg-opacity)) !important;
}

.bg-blue-300, .bg-blue-300-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-blue-300-rgb), var(--x-bg-opacity)) !important;
}

.bg-blue-400, .bg-blue-400-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-blue-400-rgb), var(--x-bg-opacity)) !important;
}

.bg-blue-500, .bg-blue-500-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-blue-500-rgb), var(--x-bg-opacity)) !important;
}

.bg-blue-600, .bg-blue-600-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-blue-600-rgb), var(--x-bg-opacity)) !important;
}

.bg-blue-700, .bg-blue-700-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-blue-700-rgb), var(--x-bg-opacity)) !important;
}

.bg-blue-800, .bg-blue-800-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-blue-800-rgb), var(--x-bg-opacity)) !important;
}

.bg-blue-900, .bg-blue-900-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-blue-900-rgb), var(--x-bg-opacity)) !important;
}

.bg-indigo-100, .bg-indigo-100-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-indigo-100-rgb), var(--x-bg-opacity)) !important;
}

.bg-indigo-200, .bg-indigo-200-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-indigo-200-rgb), var(--x-bg-opacity)) !important;
}

.bg-indigo-300, .bg-indigo-300-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-indigo-300-rgb), var(--x-bg-opacity)) !important;
}

.bg-indigo-400, .bg-indigo-400-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-indigo-400-rgb), var(--x-bg-opacity)) !important;
}

.bg-indigo-500, .bg-indigo-500-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-indigo-500-rgb), var(--x-bg-opacity)) !important;
}

.bg-indigo-600, .bg-indigo-600-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-indigo-600-rgb), var(--x-bg-opacity)) !important;
}

.bg-indigo-700, .bg-indigo-700-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-indigo-700-rgb), var(--x-bg-opacity)) !important;
}

.bg-indigo-800, .bg-indigo-800-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-indigo-800-rgb), var(--x-bg-opacity)) !important;
}

.bg-indigo-900, .bg-indigo-900-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-indigo-900-rgb), var(--x-bg-opacity)) !important;
}

.bg-purple-100, .bg-purple-100-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-purple-100-rgb), var(--x-bg-opacity)) !important;
}

.bg-purple-200, .bg-purple-200-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-purple-200-rgb), var(--x-bg-opacity)) !important;
}

.bg-purple-300, .bg-purple-300-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-purple-300-rgb), var(--x-bg-opacity)) !important;
}

.bg-purple-400, .bg-purple-400-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-purple-400-rgb), var(--x-bg-opacity)) !important;
}

.bg-purple-500, .bg-purple-500-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-purple-500-rgb), var(--x-bg-opacity)) !important;
}

.bg-purple-600, .bg-purple-600-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-purple-600-rgb), var(--x-bg-opacity)) !important;
}

.bg-purple-700, .bg-purple-700-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-purple-700-rgb), var(--x-bg-opacity)) !important;
}

.bg-purple-800, .bg-purple-800-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-purple-800-rgb), var(--x-bg-opacity)) !important;
}

.bg-purple-900, .bg-purple-900-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-purple-900-rgb), var(--x-bg-opacity)) !important;
}

.bg-pink-100, .bg-pink-100-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-pink-100-rgb), var(--x-bg-opacity)) !important;
}

.bg-pink-200, .bg-pink-200-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-pink-200-rgb), var(--x-bg-opacity)) !important;
}

.bg-pink-300, .bg-pink-300-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-pink-300-rgb), var(--x-bg-opacity)) !important;
}

.bg-pink-400, .bg-pink-400-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-pink-400-rgb), var(--x-bg-opacity)) !important;
}

.bg-pink-500, .bg-pink-500-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-pink-500-rgb), var(--x-bg-opacity)) !important;
}

.bg-pink-600, .bg-pink-600-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-pink-600-rgb), var(--x-bg-opacity)) !important;
}

.bg-pink-700, .bg-pink-700-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-pink-700-rgb), var(--x-bg-opacity)) !important;
}

.bg-pink-800, .bg-pink-800-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-pink-800-rgb), var(--x-bg-opacity)) !important;
}

.bg-pink-900, .bg-pink-900-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-pink-900-rgb), var(--x-bg-opacity)) !important;
}

.bg-red-100, .bg-red-100-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-red-100-rgb), var(--x-bg-opacity)) !important;
}

.bg-red-200, .bg-red-200-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-red-200-rgb), var(--x-bg-opacity)) !important;
}

.bg-red-300, .bg-red-300-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-red-300-rgb), var(--x-bg-opacity)) !important;
}

.bg-red-400, .bg-red-400-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-red-400-rgb), var(--x-bg-opacity)) !important;
}

.bg-red-500, .bg-red-500-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-red-500-rgb), var(--x-bg-opacity)) !important;
}

.bg-red-600, .bg-red-600-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-red-600-rgb), var(--x-bg-opacity)) !important;
}

.bg-red-700, .bg-red-700-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-red-700-rgb), var(--x-bg-opacity)) !important;
}

.bg-red-800, .bg-red-800-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-red-800-rgb), var(--x-bg-opacity)) !important;
}

.bg-red-900, .bg-red-900-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-red-900-rgb), var(--x-bg-opacity)) !important;
}

.bg-orange-100, .bg-orange-100-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-orange-100-rgb), var(--x-bg-opacity)) !important;
}

.bg-orange-200, .bg-orange-200-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-orange-200-rgb), var(--x-bg-opacity)) !important;
}

.bg-orange-300, .bg-orange-300-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-orange-300-rgb), var(--x-bg-opacity)) !important;
}

.bg-orange-400, .bg-orange-400-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-orange-400-rgb), var(--x-bg-opacity)) !important;
}

.bg-orange-500, .bg-orange-500-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-orange-500-rgb), var(--x-bg-opacity)) !important;
}

.bg-orange-600, .bg-orange-600-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-orange-600-rgb), var(--x-bg-opacity)) !important;
}

.bg-orange-700, .bg-orange-700-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-orange-700-rgb), var(--x-bg-opacity)) !important;
}

.bg-orange-800, .bg-orange-800-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-orange-800-rgb), var(--x-bg-opacity)) !important;
}

.bg-orange-900, .bg-orange-900-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-orange-900-rgb), var(--x-bg-opacity)) !important;
}

.bg-yellow-100, .bg-yellow-100-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-yellow-100-rgb), var(--x-bg-opacity)) !important;
}

.bg-yellow-200, .bg-yellow-200-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-yellow-200-rgb), var(--x-bg-opacity)) !important;
}

.bg-yellow-300, .bg-yellow-300-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-yellow-300-rgb), var(--x-bg-opacity)) !important;
}

.bg-yellow-400, .bg-yellow-400-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-yellow-400-rgb), var(--x-bg-opacity)) !important;
}

.bg-yellow-500, .bg-yellow-500-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-yellow-500-rgb), var(--x-bg-opacity)) !important;
}

.bg-yellow-600, .bg-yellow-600-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-yellow-600-rgb), var(--x-bg-opacity)) !important;
}

.bg-yellow-700, .bg-yellow-700-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-yellow-700-rgb), var(--x-bg-opacity)) !important;
}

.bg-yellow-800, .bg-yellow-800-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-yellow-800-rgb), var(--x-bg-opacity)) !important;
}

.bg-yellow-900, .bg-yellow-900-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-yellow-900-rgb), var(--x-bg-opacity)) !important;
}

.bg-green-100, .bg-green-100-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-green-100-rgb), var(--x-bg-opacity)) !important;
}

.bg-green-200, .bg-green-200-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-green-200-rgb), var(--x-bg-opacity)) !important;
}

.bg-green-300, .bg-green-300-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-green-300-rgb), var(--x-bg-opacity)) !important;
}

.bg-green-400, .bg-green-400-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-green-400-rgb), var(--x-bg-opacity)) !important;
}

.bg-green-500, .bg-green-500-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-green-500-rgb), var(--x-bg-opacity)) !important;
}

.bg-green-600, .bg-green-600-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-green-600-rgb), var(--x-bg-opacity)) !important;
}

.bg-green-700, .bg-green-700-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-green-700-rgb), var(--x-bg-opacity)) !important;
}

.bg-green-800, .bg-green-800-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-green-800-rgb), var(--x-bg-opacity)) !important;
}

.bg-green-900, .bg-green-900-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-green-900-rgb), var(--x-bg-opacity)) !important;
}

.bg-teal-100, .bg-teal-100-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-teal-100-rgb), var(--x-bg-opacity)) !important;
}

.bg-teal-200, .bg-teal-200-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-teal-200-rgb), var(--x-bg-opacity)) !important;
}

.bg-teal-300, .bg-teal-300-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-teal-300-rgb), var(--x-bg-opacity)) !important;
}

.bg-teal-400, .bg-teal-400-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-teal-400-rgb), var(--x-bg-opacity)) !important;
}

.bg-teal-500, .bg-teal-500-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-teal-500-rgb), var(--x-bg-opacity)) !important;
}

.bg-teal-600, .bg-teal-600-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-teal-600-rgb), var(--x-bg-opacity)) !important;
}

.bg-teal-700, .bg-teal-700-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-teal-700-rgb), var(--x-bg-opacity)) !important;
}

.bg-teal-800, .bg-teal-800-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-teal-800-rgb), var(--x-bg-opacity)) !important;
}

.bg-teal-900, .bg-teal-900-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-teal-900-rgb), var(--x-bg-opacity)) !important;
}

.bg-cyan-100, .bg-cyan-100-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-cyan-100-rgb), var(--x-bg-opacity)) !important;
}

.bg-cyan-200, .bg-cyan-200-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-cyan-200-rgb), var(--x-bg-opacity)) !important;
}

.bg-cyan-300, .bg-cyan-300-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-cyan-300-rgb), var(--x-bg-opacity)) !important;
}

.bg-cyan-400, .bg-cyan-400-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-cyan-400-rgb), var(--x-bg-opacity)) !important;
}

.bg-cyan-500, .bg-cyan-500-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-cyan-500-rgb), var(--x-bg-opacity)) !important;
}

.bg-cyan-600, .bg-cyan-600-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-cyan-600-rgb), var(--x-bg-opacity)) !important;
}

.bg-cyan-700, .bg-cyan-700-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-cyan-700-rgb), var(--x-bg-opacity)) !important;
}

.bg-cyan-800, .bg-cyan-800-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-cyan-800-rgb), var(--x-bg-opacity)) !important;
}

.bg-cyan-900, .bg-cyan-900-hover:hover {
  --x-bg-opacity: 1;
  background-color: rgba(var(--x-cyan-900-rgb), var(--x-bg-opacity)) !important;
}

.bg-opacity-0, .bg-opacity-0-hover:hover {
  --x-bg-opacity: 0;
}

.bg-opacity-10, .bg-opacity-10-hover:hover {
  --x-bg-opacity: .1;
}

.bg-opacity-25, .bg-opacity-25-hover:hover {
  --x-bg-opacity: .25;
}

.bg-opacity-50, .bg-opacity-50-hover:hover {
  --x-bg-opacity: .5;
}

.bg-opacity-75, .bg-opacity-75-hover:hover {
  --x-bg-opacity: .75;
}

.bg-opacity-100, .bg-opacity-100-hover:hover {
  --x-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--x-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--x-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--x-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--x-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--x-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--x-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--x-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--x-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--x-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: .375rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: .25rem !important;
}

.rounded-2 {
  border-radius: .375rem !important;
}

.rounded-3 {
  border-radius: .5rem !important;
}

.rounded-4 {
  border-radius: 1rem !important;
}

.rounded-5 {
  border-radius: 2rem !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-card {
  border-radius: var(--x-border-radius) !important;
}

.rounded-inherit {
  border-radius: inherit !important;
}

.rounded-top {
  border-top-left-radius: .375rem !important;
  border-top-right-radius: .375rem !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-top-2 {
  border-top-left-radius: .375rem !important;
  border-top-right-radius: .375rem !important;
}

.rounded-top-3 {
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
}

.rounded-top-4 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.rounded-top-5 {
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
}

.rounded-top-pill {
  border-top-left-radius: 50rem !important;
  border-top-right-radius: 50rem !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-card {
  border-top-left-radius: var(--x-border-radius) !important;
  border-top-right-radius: var(--x-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: .375rem !important;
  border-bottom-right-radius: .375rem !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-end-2 {
  border-top-right-radius: .375rem !important;
  border-bottom-right-radius: .375rem !important;
}

.rounded-end-3 {
  border-top-right-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
}

.rounded-end-4 {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.rounded-end-5 {
  border-top-right-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
}

.rounded-end-pill {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-card {
  border-top-right-radius: var(--x-border-radius) !important;
  border-bottom-right-radius: var(--x-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-card {
  border-bottom-right-radius: var(--x-border-radius) !important;
  border-bottom-left-radius: var(--x-border-radius) !important;
}

.rounded-start {
  border-top-left-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-start-2 {
  border-top-left-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.rounded-start-3 {
  border-top-left-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.rounded-start-4 {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-start-5 {
  border-top-left-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
}

.rounded-start-pill {
  border-top-left-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-card {
  border-bottom-left-radius: var(--x-border-radius) !important;
  border-top-left-radius: var(--x-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1, .z-n1-hover:hover {
  z-index: -1 !important;
}

.z-0, .z-0-hover:hover {
  z-index: 0 !important;
}

.z-1, .z-1-hover:hover {
  z-index: 1 !important;
}

.z-2, .z-2-hover:hover {
  z-index: 2 !important;
}

.z-3, .z-3-hover:hover {
  z-index: 3 !important;
}

.rounded-top-start {
  border-top-left-radius: .375rem !important;
}

.rounded-top-start-0 {
  border-top-left-radius: 0 !important;
}

.rounded-top-start-1 {
  border-top-left-radius: .25rem !important;
}

.rounded-top-start-2 {
  border-top-left-radius: .375rem !important;
}

.rounded-top-start-3 {
  border-top-left-radius: .5rem !important;
}

.rounded-top-start-4 {
  border-top-left-radius: 1rem !important;
}

.rounded-top-start-5 {
  border-top-left-radius: 2rem !important;
}

.rounded-top-start-pill {
  border-top-left-radius: 50rem !important;
}

.rounded-top-start-circle {
  border-top-left-radius: 50% !important;
}

.rounded-top-start-card {
  border-top-left-radius: var(--x-border-radius) !important;
}

.rounded-top-end {
  border-top-right-radius: .375rem !important;
}

.rounded-top-end-0 {
  border-top-right-radius: 0 !important;
}

.rounded-top-end-1 {
  border-top-right-radius: .25rem !important;
}

.rounded-top-end-2 {
  border-top-right-radius: .375rem !important;
}

.rounded-top-end-3 {
  border-top-right-radius: .5rem !important;
}

.rounded-top-end-4 {
  border-top-right-radius: 1rem !important;
}

.rounded-top-end-5 {
  border-top-right-radius: 2rem !important;
}

.rounded-top-end-pill {
  border-top-right-radius: 50rem !important;
}

.rounded-top-end-circle {
  border-top-right-radius: 50% !important;
}

.rounded-top-end-card {
  border-top-right-radius: var(--x-border-radius) !important;
}

.rounded-bottom-end {
  border-bottom-right-radius: .375rem !important;
}

.rounded-bottom-end-0 {
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-end-1 {
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom-end-2 {
  border-bottom-right-radius: .375rem !important;
}

.rounded-bottom-end-3 {
  border-bottom-right-radius: .5rem !important;
}

.rounded-bottom-end-4 {
  border-bottom-right-radius: 1rem !important;
}

.rounded-bottom-end-5 {
  border-bottom-right-radius: 2rem !important;
}

.rounded-bottom-end-pill {
  border-bottom-right-radius: 50rem !important;
}

.rounded-bottom-end-circle {
  border-bottom-right-radius: 50% !important;
}

.rounded-bottom-end-card {
  border-bottom-right-radius: var(--x-border-radius) !important;
}

.rounded-bottom-start {
  border-bottom-left-radius: .375rem !important;
}

.rounded-bottom-start-0 {
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-start-1 {
  border-bottom-left-radius: .25rem !important;
}

.rounded-bottom-start-2 {
  border-bottom-left-radius: .375rem !important;
}

.rounded-bottom-start-3 {
  border-bottom-left-radius: .5rem !important;
}

.rounded-bottom-start-4 {
  border-bottom-left-radius: 1rem !important;
}

.rounded-bottom-start-5 {
  border-bottom-left-radius: 2rem !important;
}

.rounded-bottom-start-pill {
  border-bottom-left-radius: 50rem !important;
}

.rounded-bottom-start-circle {
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-start-card {
  border-bottom-left-radius: var(--x-border-radius) !important;
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0) !important;
}

.border-current {
  border-color: currentColor !important;
}

.border-solid {
  --x-border-style: solid;
}

.border-dashed {
  --x-border-style: dashed;
}

.border-dotted {
  --x-border-style: dotted;
}

.border-none {
  --x-border-style: none;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-text {
  cursor: text !important;
}

.text-xxs {
  font-size: .625rem !important;
}

.text-xs {
  font-size: .75rem !important;
}

.text-sm {
  font-size: .875rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-md {
  font-size: 1.125rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-xl {
  font-size: calc(1.275rem + .3vw) !important;
}

.text-2xl {
  font-size: calc(1.325rem + .9vw) !important;
}

.ls-tighter {
  letter-spacing: -.05em !important;
}

.ls-tight {
  letter-spacing: -.025em !important;
}

.ls-normal {
  letter-spacing: 0 !important;
}

.ls-wide {
  letter-spacing: .025em !important;
}

.ls-wider {
  letter-spacing: .05em !important;
}

.ls-widest {
  letter-spacing: .1em !important;
}

.text-transparent {
  color: rgba(0, 0, 0, 0) !important;
}

.text-current {
  color: currentColor !important;
}

.bg-current {
  background-color: currentColor !important;
}

.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

.bg-clip-border {
  background-clip: border-box !important;
}

.bg-clip-padding {
  background-clip: padding-box !important;
}

.bg-clip-content {
  background-clip: content-box !important;
}

.bg-clip-text {
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.shadow-soft-1, .shadow-soft-1-hover:hover {
  box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
}

.shadow-soft-2, .shadow-soft-2-hover:hover {
  box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
}

.shadow-soft-3, .shadow-soft-3-hover:hover {
  box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
}

.shadow-soft-4, .shadow-soft-4-hover:hover {
  box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
}

.shadow-soft-5, .shadow-soft-5-hover:hover {
  box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
}

.shadow-soft-6, .shadow-soft-6-hover:hover {
  box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
}

.w-rem-0 {
  width: 0 !important;
}

.w-rem-1 {
  width: .25rem !important;
}

.w-rem-2 {
  width: .5rem !important;
}

.w-rem-3 {
  width: .75rem !important;
}

.w-rem-4 {
  width: 1rem !important;
}

.w-rem-5 {
  width: 1.25rem !important;
}

.w-rem-6 {
  width: 1.5rem !important;
}

.w-rem-7 {
  width: 1.75rem !important;
}

.w-rem-8 {
  width: 2rem !important;
}

.w-rem-10 {
  width: 2.5rem !important;
}

.w-rem-12 {
  width: 3rem !important;
}

.w-rem-14 {
  width: 3.5rem !important;
}

.w-rem-16 {
  width: 4rem !important;
}

.w-rem-18 {
  width: 4.5rem !important;
}

.w-rem-20 {
  width: 5rem !important;
}

.w-rem-24 {
  width: 6rem !important;
}

.w-rem-32 {
  width: 8rem !important;
}

.w-rem-40 {
  width: 10rem !important;
}

.w-rem-48 {
  width: 12rem !important;
}

.w-rem-56 {
  width: 14rem !important;
}

.w-rem-64 {
  width: 16rem !important;
}

.w-rem-72 {
  width: 18rem !important;
}

.w-rem-80 {
  width: 20rem !important;
}

.w-rem-px {
  width: 1px !important;
}

.h-rem-0 {
  height: 0 !important;
}

.h-rem-1 {
  height: .25rem !important;
}

.h-rem-2 {
  height: .5rem !important;
}

.h-rem-3 {
  height: .75rem !important;
}

.h-rem-4 {
  height: 1rem !important;
}

.h-rem-5 {
  height: 1.25rem !important;
}

.h-rem-6 {
  height: 1.5rem !important;
}

.h-rem-7 {
  height: 1.75rem !important;
}

.h-rem-8 {
  height: 2rem !important;
}

.h-rem-10 {
  height: 2.5rem !important;
}

.h-rem-12 {
  height: 3rem !important;
}

.h-rem-14 {
  height: 3.5rem !important;
}

.h-rem-16 {
  height: 4rem !important;
}

.h-rem-18 {
  height: 4.5rem !important;
}

.h-rem-20 {
  height: 5rem !important;
}

.h-rem-24 {
  height: 6rem !important;
}

.h-rem-32 {
  height: 8rem !important;
}

.h-rem-40 {
  height: 10rem !important;
}

.h-rem-48 {
  height: 12rem !important;
}

.h-rem-56 {
  height: 14rem !important;
}

.h-rem-64 {
  height: 16rem !important;
}

.h-rem-72 {
  height: 18rem !important;
}

.h-rem-80 {
  height: 20rem !important;
}

.h-rem-px {
  height: 1px !important;
}

.scroll-auto {
  scroll-behaviour: auto !important;
}

.scroll-smooth {
  scroll-behaviour: smooth !important;
}

.scroll-snap-start {
  scroll-snap-align: start !important;
}

.scroll-snap-end {
  scroll-snap-align: end !important;
}

.scroll-snap-center {
  scroll-snap-align: center !important;
}

.scroll-snap-none {
  scroll-snap-align: none !important;
}

.scroll-snap-normal {
  scroll-snap-stop: normal !important;
}

.scroll-snap-always {
  scroll-snap-stop: always !important;
}

.scroll-snap-none {
  scroll-snap-type: none !important;
}

.scroll-snap-x {
  scroll-snap-type: x var(--x-scroll-snap-strictness) !important;
}

.scroll-snap-y {
  scroll-snap-type: y var(--x-scroll-snap-strictness) !important;
}

.scroll-snap-both {
  scroll-snap-type: both var(--x-scroll-snap-strictness) !important;
}

.scroll-snap-mandatory {
  --x-scroll-snap-strictness: mandatory;
}

.scroll-snap-proximity {
  --x-scroll-snap-strictness: proximity;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke !important;
}

.transition-opacity {
  transition-property: opacity !important;
}

.transition-shadow {
  transition-property: box-shadow !important;
}

.transition-transform {
  transition-property: transform !important;
}

.ease-base {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.ease-linear {
  transition-timing-function: linear !important;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.duration-base {
  transition-duration: .15s !important;
}

.duration-75 {
  transition-duration: 75ms !important;
}

.duration-100 {
  transition-duration: .1s !important;
}

.duration-150 {
  transition-duration: .15s !important;
}

.duration-300 {
  transition-duration: .3s !important;
}

.delay-75 {
  transition-delay: 75ms !important;
}

.delay-100 {
  transition-delay: .1s !important;
}

.delay-150 {
  transition-delay: .15s !important;
}

.delay-300 {
  transition-delay: .3s !important;
}

.animation-none {
  animation: none !important;
}

.animation-spin {
  animation: 1s linear infinite spin !important;
}

.animation-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping !important;
}

.animation-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse !important;
}

.animation-bounce {
  animation: 1s infinite bounce !important;
}

.animation-slide-left {
  animation: 3s infinite slideLeft !important;
}

.animation-slide-right {
  animation: 3s infinite slideRight !important;
}

.animation-move-slow-left {
  animation: 15s linear infinite slideLeft !important;
}

.animation-move-slow-right {
  animation: 15s linear infinite slideRight !important;
}

.blur-5 {
  --x-blur: 5px !important;
}

.blur-10 {
  --x-blur: 10px !important;
}

.blur-25 {
  --x-blur: 25px !important;
}

.blur-50 {
  --x-blur: 50px !important;
}

.blur-75 {
  --x-blur: 75px !important;
}

.blur-100 {
  --x-blur: 100px !important;
}

.blur-125 {
  --x-blur: 125px !important;
}

.blur-150 {
  --x-blur: 150px !important;
}

.blur-200 {
  --x-blur: 200px !important;
}

.transform-none {
  transform: none !important;
}

.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-end {
  transform-origin: 100% 0 !important;
}

.origin-end {
  transform-origin: 100% !important;
}

.origin-bottom-end {
  transform-origin: 100% 100% !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-start {
  transform-origin: 0 100% !important;
}

.origin-left {
  transform-origin: 0 !important;
}

.origin-top-start {
  transform-origin: 0 0 !important;
}

.scale-0, .scale-0-hover:hover {
  --x-scale-x: 0 !important;
  --x-scale-y: 0 !important;
}

.scale-25, .scale-25-hover:hover {
  --x-scale-x: .25 !important;
  --x-scale-y: .25 !important;
}

.scale-50, .scale-50-hover:hover {
  --x-scale-x: .5 !important;
  --x-scale-y: .5 !important;
}

.scale-75, .scale-75-hover:hover {
  --x-scale-x: .75 !important;
  --x-scale-y: .75 !important;
}

.scale-100, .scale-100-hover:hover {
  --x-scale-x: 1 !important;
  --x-scale-y: 1 !important;
}

.scale-105, .scale-105-hover:hover {
  --x-scale-x: 1.05 !important;
  --x-scale-y: 1.05 !important;
}

.scale-110, .scale-110-hover:hover {
  --x-scale-x: 1.1 !important;
  --x-scale-y: 1.1 !important;
}

.scale-125, .scale-125-hover:hover {
  --x-scale-x: 1.25 !important;
  --x-scale-y: 1.25 !important;
}

.scale-150, .scale-150-hover:hover {
  --x-scale-x: 1.5 !important;
  --x-scale-y: 1.5 !important;
}

.rotate-0, .rotate-0-hover:hover {
  --x-rotate: 0deg !important;
}

.rotate-1, .rotate-1-hover:hover {
  --x-rotate: 1deg !important;
}

.rotate-2, .rotate-2-hover:hover {
  --x-rotate: 2deg !important;
}

.rotate-3, .rotate-3-hover:hover {
  --x-rotate: 3deg !important;
}

.rotate-6, .rotate-6-hover:hover {
  --x-rotate: 6deg !important;
}

.rotate-12, .rotate-12-hover:hover {
  --x-rotate: 12deg !important;
}

.rotate-30, .rotate-30-hover:hover {
  --x-rotate: 30deg !important;
}

.rotate-45, .rotate-45-hover:hover {
  --x-rotate: 45deg !important;
}

.rotate-90, .rotate-90-hover:hover {
  --x-rotate: 90deg !important;
}

.rotate-180, .rotate-180-hover:hover {
  --x-rotate: 180deg !important;
}

.rotate-n1, .rotate-n1-hover:hover {
  --x-rotate: -1deg !important;
}

.rotate-n2, .rotate-n2-hover:hover {
  --x-rotate: -2deg !important;
}

.rotate-n3, .rotate-n3-hover:hover {
  --x-rotate: -3deg !important;
}

.rotate-n6, .rotate-n6-hover:hover {
  --x-rotate: -6deg !important;
}

.rotate-n12, .rotate-n12-hover:hover {
  --x-rotate: -12deg !important;
}

.rotate-n30, .rotate-n30-hover:hover {
  --x-rotate: -30deg !important;
}

.rotate-n45, .rotate-n45-hover:hover {
  --x-rotate: -45deg !important;
}

.rotate-n90, .rotate-n90-hover:hover {
  --x-rotate: -90deg !important;
}

.rotate-n180, .rotate-n180-hover:hover {
  --x-rotate: -180deg !important;
}

.rotate-y-n1, .rotate-y-n1-hover:hover {
  --x-rotate-y: -1deg !important;
}

.rotate-y-n2, .rotate-y-n2-hover:hover {
  --x-rotate-y: -2deg !important;
}

.rotate-y-n3, .rotate-y-n3-hover:hover {
  --x-rotate-y: -3deg !important;
}

.rotate-y-n6, .rotate-y-n6-hover:hover {
  --x-rotate-y: -6deg !important;
}

.rotate-y-n12, .rotate-y-n12-hover:hover {
  --x-rotate-y: -12deg !important;
}

.rotate-y-n30, .rotate-y-n30-hover:hover {
  --x-rotate-y: -30deg !important;
}

.rotate-y-n45, .rotate-y-n45-hover:hover {
  --x-rotate-y: -45deg !important;
}

.rotate-y-n90, .rotate-y-n90-hover:hover {
  --x-rotate-y: -90deg !important;
}

.rotate-y-n180, .rotate-y-n180-hover:hover {
  --x-rotate-y: -180deg !important;
}

.rotate-x-n1 {
  --x-rotate-x: -1deg !important;
}

.rotate-x-n2 {
  --x-rotate-x: -2deg !important;
}

.rotate-x-n3 {
  --x-rotate-x: -3deg !important;
}

.rotate-x-n6 {
  --x-rotate-x: -6deg !important;
}

.rotate-x-n12 {
  --x-rotate-x: -12deg !important;
}

.rotate-x-n30 {
  --x-rotate-x: -30deg !important;
}

.rotate-x-n45 {
  --x-rotate-x: -45deg !important;
}

.rotate-x-n90 {
  --x-rotate-x: -90deg !important;
}

.rotate-x-n180 {
  --x-rotate-x: -180deg !important;
}

.translate-y-0, .translate-y-0-hover:hover {
  --x-translate-y: 0 !important;
}

.translate-y-25, .translate-y-25-hover:hover {
  --x-translate-y: 25% !important;
}

.translate-y-50, .translate-y-50-hover:hover {
  --x-translate-y: 50% !important;
}

.translate-y-75, .translate-y-75-hover:hover {
  --x-translate-y: 75% !important;
}

.translate-y-100, .translate-y-100-hover:hover {
  --x-translate-y: 100% !important;
}

.translate-y-n25, .translate-y-n25-hover:hover {
  --x-translate-y: -25% !important;
}

.translate-y-n50, .translate-y-n50-hover:hover {
  --x-translate-y: -50% !important;
}

.translate-y-n75, .translate-y-n75-hover:hover {
  --x-translate-y: -75% !important;
}

.translate-y-n100, .translate-y-n100-hover:hover {
  --x-translate-y: -100% !important;
}

.translate-x-0, .translate-x-0-hover:hover {
  --x-translate-x: 0 !important;
}

.translate-x-25, .translate-x-25-hover:hover {
  --x-translate-x: 25% !important;
}

.translate-x-50, .translate-x-50-hover:hover {
  --x-translate-x: 50% !important;
}

.translate-x-75, .translate-x-75-hover:hover {
  --x-translate-x: 75% !important;
}

.translate-x-100, .translate-x-100-hover:hover {
  --x-translate-x: 100% !important;
}

.translate-x-n25, .translate-x-n25-hover:hover {
  --x-translate-x: -25% !important;
}

.translate-x-n50, .translate-x-n50-hover:hover {
  --x-translate-x: -50% !important;
}

.translate-x-n75, .translate-x-n75-hover:hover {
  --x-translate-x: -75% !important;
}

.translate-x-n100, .translate-x-n100-hover:hover {
  --x-translate-x: -100% !important;
}

.skew-y-0 {
  --x-skew-y: 0deg !important;
}

.skew-y-1 {
  --x-skew-y: 1deg !important;
}

.skew-y-2 {
  --x-skew-y: 2deg !important;
}

.skew-y-3 {
  --x-skew-y: 3deg !important;
}

.skew-y-6 {
  --x-skew-y: 6deg !important;
}

.skew-y-12 {
  --x-skew-y: 12deg !important;
}

.skew-x-0 {
  --x-skew-x: 0deg !important;
}

.skew-x-1 {
  --x-skew-x: 1deg !important;
}

.skew-x-2 {
  --x-skew-x: 2deg !important;
}

.skew-x-3 {
  --x-skew-x: 3deg !important;
}

.skew-x-6 {
  --x-skew-x: 6deg !important;
}

.skew-x-12 {
  --x-skew-x: 12deg !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .overflow-sm-auto {
    overflow: auto !important;
  }

  .overflow-sm-hidden {
    overflow: hidden !important;
  }

  .overflow-sm-visible {
    overflow: visible !important;
  }

  .overflow-sm-scroll {
    overflow: scroll !important;
  }

  .overflow-x-sm-auto {
    overflow-x: auto !important;
  }

  .overflow-x-sm-hidden {
    overflow-x: hidden !important;
  }

  .overflow-x-sm-visible {
    overflow-x: visible !important;
  }

  .overflow-x-sm-scroll {
    overflow-x: scroll !important;
  }

  .overflow-y-sm-auto {
    overflow-y: auto !important;
  }

  .overflow-y-sm-hidden {
    overflow-y: hidden !important;
  }

  .overflow-y-sm-visible {
    overflow-y: visible !important;
  }

  .overflow-y-sm-scroll {
    overflow-y: scroll !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .shadow-sm, .shadow-sm-hover:hover {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-1, .shadow-sm-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-2, .shadow-sm-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-3, .shadow-sm-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-4, .shadow-sm-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-5, .shadow-sm-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-6, .shadow-sm-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-sm-inset, .shadow-sm-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
  }

  .shadow-sm-none, .shadow-sm-none-hover:hover {
    box-shadow: none !important;
  }

  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-sm-0 {
    top: 0 !important;
  }

  .top-sm-50 {
    top: 50% !important;
  }

  .top-sm-100 {
    top: 100% !important;
  }

  .top-sm-1 {
    top: .25rem !important;
  }

  .top-sm-2 {
    top: .5rem !important;
  }

  .top-sm-3 {
    top: .75rem !important;
  }

  .top-sm-4 {
    top: 1rem !important;
  }

  .top-sm-5 {
    top: 1.25rem !important;
  }

  .top-sm-6 {
    top: 1.5rem !important;
  }

  .top-sm-7 {
    top: 1.75rem !important;
  }

  .top-sm-8 {
    top: 2rem !important;
  }

  .top-sm-10 {
    top: 2.5rem !important;
  }

  .top-sm-12 {
    top: 3rem !important;
  }

  .top-sm-14 {
    top: 3.5rem !important;
  }

  .top-sm-16 {
    top: 4rem !important;
  }

  .top-sm-18 {
    top: 4.5rem !important;
  }

  .top-sm-20 {
    top: 5rem !important;
  }

  .top-sm-24 {
    top: 6rem !important;
  }

  .top-sm-32 {
    top: 8rem !important;
  }

  .top-sm-40 {
    top: 10rem !important;
  }

  .top-sm-48 {
    top: 12rem !important;
  }

  .top-sm-56 {
    top: 14rem !important;
  }

  .top-sm-64 {
    top: 16rem !important;
  }

  .top-sm-72 {
    top: 18rem !important;
  }

  .top-sm-80 {
    top: 20rem !important;
  }

  .top-sm-px {
    top: 1px !important;
  }

  .bottom-sm-0 {
    bottom: 0 !important;
  }

  .bottom-sm-50 {
    bottom: 50% !important;
  }

  .bottom-sm-100 {
    bottom: 100% !important;
  }

  .start-sm-0 {
    left: 0 !important;
  }

  .start-sm-50 {
    left: 50% !important;
  }

  .start-sm-100 {
    left: 100% !important;
  }

  .end-sm-0 {
    right: 0 !important;
  }

  .end-sm-50 {
    right: 50% !important;
  }

  .end-sm-100 {
    right: 100% !important;
  }

  .border-sm {
    border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-sm-0 {
    border: 0 !important;
  }

  .border-top-sm {
    border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-top-sm-0 {
    border-top: 0 !important;
  }

  .border-end-sm {
    border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-end-sm-0 {
    border-right: 0 !important;
  }

  .border-bottom-sm {
    border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }

  .border-start-sm {
    border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-start-sm-0 {
    border-left: 0 !important;
  }

  .border-sm-primary, .border-sm-primary-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-primary-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-secondary, .border-sm-secondary-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-secondary-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-success, .border-sm-success-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-success-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-info, .border-sm-info-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-info-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-warning, .border-sm-warning-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-warning-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-danger, .border-sm-danger-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-danger-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-light, .border-sm-light-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-light-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-dark, .border-sm-dark-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-dark-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-black, .border-sm-black-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-black-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-white, .border-sm-white-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-white-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-gray-50, .border-sm-gray-50-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-50-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-gray-100, .border-sm-gray-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-gray-200, .border-sm-gray-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-gray-300, .border-sm-gray-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-gray-400, .border-sm-gray-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-gray-500, .border-sm-gray-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-gray-600, .border-sm-gray-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-gray-700, .border-sm-gray-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-gray-800, .border-sm-gray-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-gray-900, .border-sm-gray-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-gray-950, .border-sm-gray-950-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-950-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-blue-100, .border-sm-blue-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-blue-200, .border-sm-blue-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-blue-300, .border-sm-blue-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-blue-400, .border-sm-blue-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-blue-500, .border-sm-blue-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-blue-600, .border-sm-blue-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-blue-700, .border-sm-blue-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-blue-800, .border-sm-blue-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-blue-900, .border-sm-blue-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-indigo-100, .border-sm-indigo-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-indigo-200, .border-sm-indigo-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-indigo-300, .border-sm-indigo-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-indigo-400, .border-sm-indigo-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-indigo-500, .border-sm-indigo-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-indigo-600, .border-sm-indigo-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-indigo-700, .border-sm-indigo-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-indigo-800, .border-sm-indigo-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-indigo-900, .border-sm-indigo-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-purple-100, .border-sm-purple-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-purple-200, .border-sm-purple-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-purple-300, .border-sm-purple-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-purple-400, .border-sm-purple-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-purple-500, .border-sm-purple-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-purple-600, .border-sm-purple-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-purple-700, .border-sm-purple-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-purple-800, .border-sm-purple-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-purple-900, .border-sm-purple-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-pink-100, .border-sm-pink-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-pink-200, .border-sm-pink-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-pink-300, .border-sm-pink-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-pink-400, .border-sm-pink-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-pink-500, .border-sm-pink-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-pink-600, .border-sm-pink-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-pink-700, .border-sm-pink-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-pink-800, .border-sm-pink-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-pink-900, .border-sm-pink-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-red-100, .border-sm-red-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-red-200, .border-sm-red-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-red-300, .border-sm-red-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-red-400, .border-sm-red-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-red-500, .border-sm-red-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-red-600, .border-sm-red-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-red-700, .border-sm-red-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-red-800, .border-sm-red-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-red-900, .border-sm-red-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-orange-100, .border-sm-orange-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-orange-200, .border-sm-orange-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-orange-300, .border-sm-orange-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-orange-400, .border-sm-orange-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-orange-500, .border-sm-orange-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-orange-600, .border-sm-orange-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-orange-700, .border-sm-orange-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-orange-800, .border-sm-orange-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-orange-900, .border-sm-orange-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-yellow-100, .border-sm-yellow-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-yellow-200, .border-sm-yellow-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-yellow-300, .border-sm-yellow-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-yellow-400, .border-sm-yellow-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-yellow-500, .border-sm-yellow-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-yellow-600, .border-sm-yellow-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-yellow-700, .border-sm-yellow-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-yellow-800, .border-sm-yellow-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-yellow-900, .border-sm-yellow-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-green-100, .border-sm-green-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-green-200, .border-sm-green-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-green-300, .border-sm-green-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-green-400, .border-sm-green-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-green-500, .border-sm-green-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-green-600, .border-sm-green-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-green-700, .border-sm-green-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-green-800, .border-sm-green-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-green-900, .border-sm-green-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-teal-100, .border-sm-teal-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-teal-200, .border-sm-teal-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-teal-300, .border-sm-teal-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-teal-400, .border-sm-teal-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-teal-500, .border-sm-teal-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-teal-600, .border-sm-teal-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-teal-700, .border-sm-teal-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-teal-800, .border-sm-teal-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-teal-900, .border-sm-teal-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-cyan-100, .border-sm-cyan-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-cyan-200, .border-sm-cyan-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-cyan-300, .border-sm-cyan-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-cyan-400, .border-sm-cyan-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-cyan-500, .border-sm-cyan-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-cyan-600, .border-sm-cyan-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-cyan-700, .border-sm-cyan-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-cyan-800, .border-sm-cyan-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-sm-cyan-900, .border-sm-cyan-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-900-rgb), var(--x-border-opacity)) !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .mw-sm-screen-sm {
    max-width: 640px !important;
  }

  .mw-sm-screen-md {
    max-width: 768px !important;
  }

  .mw-sm-screen-lg {
    max-width: 1024px !important;
  }

  .mw-sm-screen-xl {
    max-width: 1280px !important;
  }

  .mw-sm-screen-xxl {
    max-width: 1536px !important;
  }

  .mw-sm-0 {
    max-width: 0 !important;
  }

  .mw-sm-100 {
    max-width: 100% !important;
  }

  .mw-sm-read {
    max-width: 65ch !important;
  }

  .mw-sm-fit {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .vh-sm-100 {
    height: 100vh !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-initial {
    flex: 0 auto !important;
  }

  .flex-sm-none {
    flex: none !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: .75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 1.75rem !important;
  }

  .m-sm-8 {
    margin: 2rem !important;
  }

  .m-sm-10 {
    margin: 2.5rem !important;
  }

  .m-sm-12 {
    margin: 3rem !important;
  }

  .m-sm-14 {
    margin: 3.5rem !important;
  }

  .m-sm-16 {
    margin: 4rem !important;
  }

  .m-sm-18 {
    margin: 4.5rem !important;
  }

  .m-sm-20 {
    margin: 5rem !important;
  }

  .m-sm-24 {
    margin: 6rem !important;
  }

  .m-sm-32 {
    margin: 8rem !important;
  }

  .m-sm-40 {
    margin: 10rem !important;
  }

  .m-sm-48 {
    margin: 12rem !important;
  }

  .m-sm-56 {
    margin: 14rem !important;
  }

  .m-sm-64 {
    margin: 16rem !important;
  }

  .m-sm-72 {
    margin: 18rem !important;
  }

  .m-sm-80 {
    margin: 20rem !important;
  }

  .m-sm-px {
    margin: 1px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-sm-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-sm-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-sm-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-sm-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-sm-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-sm-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-sm-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-sm-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-sm-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .mx-sm-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mx-sm-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .mx-sm-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .mx-sm-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .mx-sm-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .mx-sm-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mx-sm-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-sm-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-sm-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-sm-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-sm-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-sm-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-sm-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-sm-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: .75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-8 {
    margin-top: 2rem !important;
  }

  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-12 {
    margin-top: 3rem !important;
  }

  .mt-sm-14 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-16 {
    margin-top: 4rem !important;
  }

  .mt-sm-18 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-20 {
    margin-top: 5rem !important;
  }

  .mt-sm-24 {
    margin-top: 6rem !important;
  }

  .mt-sm-32 {
    margin-top: 8rem !important;
  }

  .mt-sm-40 {
    margin-top: 10rem !important;
  }

  .mt-sm-48 {
    margin-top: 12rem !important;
  }

  .mt-sm-56 {
    margin-top: 14rem !important;
  }

  .mt-sm-64 {
    margin-top: 16rem !important;
  }

  .mt-sm-72 {
    margin-top: 18rem !important;
  }

  .mt-sm-80 {
    margin-top: 20rem !important;
  }

  .mt-sm-px {
    margin-top: 1px !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: .75rem !important;
  }

  .me-sm-4 {
    margin-right: 1rem !important;
  }

  .me-sm-5 {
    margin-right: 1.25rem !important;
  }

  .me-sm-6 {
    margin-right: 1.5rem !important;
  }

  .me-sm-7 {
    margin-right: 1.75rem !important;
  }

  .me-sm-8 {
    margin-right: 2rem !important;
  }

  .me-sm-10 {
    margin-right: 2.5rem !important;
  }

  .me-sm-12 {
    margin-right: 3rem !important;
  }

  .me-sm-14 {
    margin-right: 3.5rem !important;
  }

  .me-sm-16 {
    margin-right: 4rem !important;
  }

  .me-sm-18 {
    margin-right: 4.5rem !important;
  }

  .me-sm-20 {
    margin-right: 5rem !important;
  }

  .me-sm-24 {
    margin-right: 6rem !important;
  }

  .me-sm-32 {
    margin-right: 8rem !important;
  }

  .me-sm-40 {
    margin-right: 10rem !important;
  }

  .me-sm-48 {
    margin-right: 12rem !important;
  }

  .me-sm-56 {
    margin-right: 14rem !important;
  }

  .me-sm-64 {
    margin-right: 16rem !important;
  }

  .me-sm-72 {
    margin-right: 18rem !important;
  }

  .me-sm-80 {
    margin-right: 20rem !important;
  }

  .me-sm-px {
    margin-right: 1px !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: .75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-24 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-32 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-40 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-48 {
    margin-bottom: 12rem !important;
  }

  .mb-sm-56 {
    margin-bottom: 14rem !important;
  }

  .mb-sm-64 {
    margin-bottom: 16rem !important;
  }

  .mb-sm-72 {
    margin-bottom: 18rem !important;
  }

  .mb-sm-80 {
    margin-bottom: 20rem !important;
  }

  .mb-sm-px {
    margin-bottom: 1px !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: .75rem !important;
  }

  .ms-sm-4 {
    margin-left: 1rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }

  .ms-sm-8 {
    margin-left: 2rem !important;
  }

  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-12 {
    margin-left: 3rem !important;
  }

  .ms-sm-14 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-16 {
    margin-left: 4rem !important;
  }

  .ms-sm-18 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-20 {
    margin-left: 5rem !important;
  }

  .ms-sm-24 {
    margin-left: 6rem !important;
  }

  .ms-sm-32 {
    margin-left: 8rem !important;
  }

  .ms-sm-40 {
    margin-left: 10rem !important;
  }

  .ms-sm-48 {
    margin-left: 12rem !important;
  }

  .ms-sm-56 {
    margin-left: 14rem !important;
  }

  .ms-sm-64 {
    margin-left: 16rem !important;
  }

  .ms-sm-72 {
    margin-left: 18rem !important;
  }

  .ms-sm-80 {
    margin-left: 20rem !important;
  }

  .ms-sm-px {
    margin-left: 1px !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.25rem !important;
  }

  .m-sm-n6 {
    margin: -1.5rem !important;
  }

  .m-sm-n7 {
    margin: -1.75rem !important;
  }

  .m-sm-n8 {
    margin: -2rem !important;
  }

  .m-sm-n10 {
    margin: -2.5rem !important;
  }

  .m-sm-n12 {
    margin: -3rem !important;
  }

  .m-sm-n14 {
    margin: -3.5rem !important;
  }

  .m-sm-n16 {
    margin: -4rem !important;
  }

  .m-sm-n18 {
    margin: -4.5rem !important;
  }

  .m-sm-n20 {
    margin: -5rem !important;
  }

  .m-sm-n24 {
    margin: -6rem !important;
  }

  .m-sm-n32 {
    margin: -8rem !important;
  }

  .m-sm-n40 {
    margin: -10rem !important;
  }

  .m-sm-n48 {
    margin: -12rem !important;
  }

  .m-sm-n56 {
    margin: -14rem !important;
  }

  .m-sm-n64 {
    margin: -16rem !important;
  }

  .m-sm-n72 {
    margin: -18rem !important;
  }

  .m-sm-n80 {
    margin: -20rem !important;
  }

  .m-sm-npx {
    margin: -1px !important;
  }

  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-sm-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-sm-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-sm-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-sm-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-sm-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-sm-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-sm-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-sm-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-sm-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-sm-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .mx-sm-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .mx-sm-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .mx-sm-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .mx-sm-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .mx-sm-n72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .mx-sm-n80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .mx-sm-npx {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .my-sm-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-sm-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-sm-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-sm-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-sm-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-sm-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-sm-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-sm-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-sm-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-sm-n72 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-sm-n80 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-sm-npx {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mt-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -.75rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2rem !important;
  }

  .mt-sm-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n12 {
    margin-top: -3rem !important;
  }

  .mt-sm-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n16 {
    margin-top: -4rem !important;
  }

  .mt-sm-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-sm-n20 {
    margin-top: -5rem !important;
  }

  .mt-sm-n24 {
    margin-top: -6rem !important;
  }

  .mt-sm-n32 {
    margin-top: -8rem !important;
  }

  .mt-sm-n40 {
    margin-top: -10rem !important;
  }

  .mt-sm-n48 {
    margin-top: -12rem !important;
  }

  .mt-sm-n56 {
    margin-top: -14rem !important;
  }

  .mt-sm-n64 {
    margin-top: -16rem !important;
  }

  .mt-sm-n72 {
    margin-top: -18rem !important;
  }

  .mt-sm-n80 {
    margin-top: -20rem !important;
  }

  .mt-sm-npx {
    margin-top: -1px !important;
  }

  .me-sm-n1 {
    margin-right: -.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -.75rem !important;
  }

  .me-sm-n4 {
    margin-right: -1rem !important;
  }

  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }

  .me-sm-n8 {
    margin-right: -2rem !important;
  }

  .me-sm-n10 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n12 {
    margin-right: -3rem !important;
  }

  .me-sm-n14 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n16 {
    margin-right: -4rem !important;
  }

  .me-sm-n18 {
    margin-right: -4.5rem !important;
  }

  .me-sm-n20 {
    margin-right: -5rem !important;
  }

  .me-sm-n24 {
    margin-right: -6rem !important;
  }

  .me-sm-n32 {
    margin-right: -8rem !important;
  }

  .me-sm-n40 {
    margin-right: -10rem !important;
  }

  .me-sm-n48 {
    margin-right: -12rem !important;
  }

  .me-sm-n56 {
    margin-right: -14rem !important;
  }

  .me-sm-n64 {
    margin-right: -16rem !important;
  }

  .me-sm-n72 {
    margin-right: -18rem !important;
  }

  .me-sm-n80 {
    margin-right: -20rem !important;
  }

  .me-sm-npx {
    margin-right: -1px !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -.75rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-sm-n20 {
    margin-bottom: -5rem !important;
  }

  .mb-sm-n24 {
    margin-bottom: -6rem !important;
  }

  .mb-sm-n32 {
    margin-bottom: -8rem !important;
  }

  .mb-sm-n40 {
    margin-bottom: -10rem !important;
  }

  .mb-sm-n48 {
    margin-bottom: -12rem !important;
  }

  .mb-sm-n56 {
    margin-bottom: -14rem !important;
  }

  .mb-sm-n64 {
    margin-bottom: -16rem !important;
  }

  .mb-sm-n72 {
    margin-bottom: -18rem !important;
  }

  .mb-sm-n80 {
    margin-bottom: -20rem !important;
  }

  .mb-sm-npx {
    margin-bottom: -1px !important;
  }

  .ms-sm-n1 {
    margin-left: -.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -.75rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1rem !important;
  }

  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-sm-n8 {
    margin-left: -2rem !important;
  }

  .ms-sm-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n12 {
    margin-left: -3rem !important;
  }

  .ms-sm-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n16 {
    margin-left: -4rem !important;
  }

  .ms-sm-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-sm-n20 {
    margin-left: -5rem !important;
  }

  .ms-sm-n24 {
    margin-left: -6rem !important;
  }

  .ms-sm-n32 {
    margin-left: -8rem !important;
  }

  .ms-sm-n40 {
    margin-left: -10rem !important;
  }

  .ms-sm-n48 {
    margin-left: -12rem !important;
  }

  .ms-sm-n56 {
    margin-left: -14rem !important;
  }

  .ms-sm-n64 {
    margin-left: -16rem !important;
  }

  .ms-sm-n72 {
    margin-left: -18rem !important;
  }

  .ms-sm-n80 {
    margin-left: -20rem !important;
  }

  .ms-sm-npx {
    margin-left: -1px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: .75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 1.75rem !important;
  }

  .p-sm-8 {
    padding: 2rem !important;
  }

  .p-sm-10 {
    padding: 2.5rem !important;
  }

  .p-sm-12 {
    padding: 3rem !important;
  }

  .p-sm-14 {
    padding: 3.5rem !important;
  }

  .p-sm-16 {
    padding: 4rem !important;
  }

  .p-sm-18 {
    padding: 4.5rem !important;
  }

  .p-sm-20 {
    padding: 5rem !important;
  }

  .p-sm-24 {
    padding: 6rem !important;
  }

  .p-sm-32 {
    padding: 8rem !important;
  }

  .p-sm-40 {
    padding: 10rem !important;
  }

  .p-sm-48 {
    padding: 12rem !important;
  }

  .p-sm-56 {
    padding: 14rem !important;
  }

  .p-sm-64 {
    padding: 16rem !important;
  }

  .p-sm-72 {
    padding: 18rem !important;
  }

  .p-sm-80 {
    padding: 20rem !important;
  }

  .p-sm-px {
    padding: 1px !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-sm-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-sm-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-sm-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-sm-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-sm-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-sm-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-sm-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-sm-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-sm-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-sm-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-sm-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .px-sm-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .px-sm-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .px-sm-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .px-sm-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .px-sm-72 {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .px-sm-80 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .px-sm-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-sm-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-sm-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-sm-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-sm-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-sm-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-sm-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-sm-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: .75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-8 {
    padding-top: 2rem !important;
  }

  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-12 {
    padding-top: 3rem !important;
  }

  .pt-sm-14 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-16 {
    padding-top: 4rem !important;
  }

  .pt-sm-18 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-20 {
    padding-top: 5rem !important;
  }

  .pt-sm-24 {
    padding-top: 6rem !important;
  }

  .pt-sm-32 {
    padding-top: 8rem !important;
  }

  .pt-sm-40 {
    padding-top: 10rem !important;
  }

  .pt-sm-48 {
    padding-top: 12rem !important;
  }

  .pt-sm-56 {
    padding-top: 14rem !important;
  }

  .pt-sm-64 {
    padding-top: 16rem !important;
  }

  .pt-sm-72 {
    padding-top: 18rem !important;
  }

  .pt-sm-80 {
    padding-top: 20rem !important;
  }

  .pt-sm-px {
    padding-top: 1px !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: .75rem !important;
  }

  .pe-sm-4 {
    padding-right: 1rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }

  .pe-sm-8 {
    padding-right: 2rem !important;
  }

  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-12 {
    padding-right: 3rem !important;
  }

  .pe-sm-14 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-16 {
    padding-right: 4rem !important;
  }

  .pe-sm-18 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-20 {
    padding-right: 5rem !important;
  }

  .pe-sm-24 {
    padding-right: 6rem !important;
  }

  .pe-sm-32 {
    padding-right: 8rem !important;
  }

  .pe-sm-40 {
    padding-right: 10rem !important;
  }

  .pe-sm-48 {
    padding-right: 12rem !important;
  }

  .pe-sm-56 {
    padding-right: 14rem !important;
  }

  .pe-sm-64 {
    padding-right: 16rem !important;
  }

  .pe-sm-72 {
    padding-right: 18rem !important;
  }

  .pe-sm-80 {
    padding-right: 20rem !important;
  }

  .pe-sm-px {
    padding-right: 1px !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: .75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-24 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-32 {
    padding-bottom: 8rem !important;
  }

  .pb-sm-40 {
    padding-bottom: 10rem !important;
  }

  .pb-sm-48 {
    padding-bottom: 12rem !important;
  }

  .pb-sm-56 {
    padding-bottom: 14rem !important;
  }

  .pb-sm-64 {
    padding-bottom: 16rem !important;
  }

  .pb-sm-72 {
    padding-bottom: 18rem !important;
  }

  .pb-sm-80 {
    padding-bottom: 20rem !important;
  }

  .pb-sm-px {
    padding-bottom: 1px !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: .75rem !important;
  }

  .ps-sm-4 {
    padding-left: 1rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }

  .ps-sm-8 {
    padding-left: 2rem !important;
  }

  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-12 {
    padding-left: 3rem !important;
  }

  .ps-sm-14 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-16 {
    padding-left: 4rem !important;
  }

  .ps-sm-18 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-20 {
    padding-left: 5rem !important;
  }

  .ps-sm-24 {
    padding-left: 6rem !important;
  }

  .ps-sm-32 {
    padding-left: 8rem !important;
  }

  .ps-sm-40 {
    padding-left: 10rem !important;
  }

  .ps-sm-48 {
    padding-left: 12rem !important;
  }

  .ps-sm-56 {
    padding-left: 14rem !important;
  }

  .ps-sm-64 {
    padding-left: 16rem !important;
  }

  .ps-sm-72 {
    padding-left: 18rem !important;
  }

  .ps-sm-80 {
    padding-left: 20rem !important;
  }

  .ps-sm-px {
    padding-left: 1px !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: .75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 1.75rem !important;
  }

  .gap-sm-8 {
    gap: 2rem !important;
  }

  .gap-sm-10 {
    gap: 2.5rem !important;
  }

  .gap-sm-12 {
    gap: 3rem !important;
  }

  .gap-sm-14 {
    gap: 3.5rem !important;
  }

  .gap-sm-16 {
    gap: 4rem !important;
  }

  .gap-sm-18 {
    gap: 4.5rem !important;
  }

  .gap-sm-20 {
    gap: 5rem !important;
  }

  .gap-sm-24 {
    gap: 6rem !important;
  }

  .gap-sm-32 {
    gap: 8rem !important;
  }

  .gap-sm-40 {
    gap: 10rem !important;
  }

  .gap-sm-48 {
    gap: 12rem !important;
  }

  .gap-sm-56 {
    gap: 14rem !important;
  }

  .gap-sm-64 {
    gap: 16rem !important;
  }

  .gap-sm-72 {
    gap: 18rem !important;
  }

  .gap-sm-80 {
    gap: 20rem !important;
  }

  .gap-sm-px {
    gap: 1px !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: .75rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-sm-8 {
    row-gap: 2rem !important;
  }

  .row-gap-sm-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-sm-12 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-sm-16 {
    row-gap: 4rem !important;
  }

  .row-gap-sm-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-sm-20 {
    row-gap: 5rem !important;
  }

  .row-gap-sm-24 {
    row-gap: 6rem !important;
  }

  .row-gap-sm-32 {
    row-gap: 8rem !important;
  }

  .row-gap-sm-40 {
    row-gap: 10rem !important;
  }

  .row-gap-sm-48 {
    row-gap: 12rem !important;
  }

  .row-gap-sm-56 {
    row-gap: 14rem !important;
  }

  .row-gap-sm-64 {
    row-gap: 16rem !important;
  }

  .row-gap-sm-72 {
    row-gap: 18rem !important;
  }

  .row-gap-sm-80 {
    row-gap: 20rem !important;
  }

  .row-gap-sm-px {
    row-gap: 1px !important;
  }

  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    -moz-column-gap: .75rem !important;
    column-gap: .75rem !important;
  }

  .column-gap-sm-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-sm-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }

  .column-gap-sm-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }

  .column-gap-sm-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-sm-10 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .column-gap-sm-12 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .column-gap-sm-14 {
    -moz-column-gap: 3.5rem !important;
    column-gap: 3.5rem !important;
  }

  .column-gap-sm-16 {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }

  .column-gap-sm-18 {
    -moz-column-gap: 4.5rem !important;
    column-gap: 4.5rem !important;
  }

  .column-gap-sm-20 {
    -moz-column-gap: 5rem !important;
    column-gap: 5rem !important;
  }

  .column-gap-sm-24 {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }

  .column-gap-sm-32 {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }

  .column-gap-sm-40 {
    -moz-column-gap: 10rem !important;
    column-gap: 10rem !important;
  }

  .column-gap-sm-48 {
    -moz-column-gap: 12rem !important;
    column-gap: 12rem !important;
  }

  .column-gap-sm-56 {
    -moz-column-gap: 14rem !important;
    column-gap: 14rem !important;
  }

  .column-gap-sm-64 {
    -moz-column-gap: 16rem !important;
    column-gap: 16rem !important;
  }

  .column-gap-sm-72 {
    -moz-column-gap: 18rem !important;
    column-gap: 18rem !important;
  }

  .column-gap-sm-80 {
    -moz-column-gap: 20rem !important;
    column-gap: 20rem !important;
  }

  .column-gap-sm-px {
    -moz-column-gap: 1px !important;
    column-gap: 1px !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .rounded-sm {
    border-radius: .375rem !important;
  }

  .rounded-sm-0 {
    border-radius: 0 !important;
  }

  .rounded-sm-1 {
    border-radius: .25rem !important;
  }

  .rounded-sm-2 {
    border-radius: .375rem !important;
  }

  .rounded-sm-3 {
    border-radius: .5rem !important;
  }

  .rounded-sm-4 {
    border-radius: 1rem !important;
  }

  .rounded-sm-5 {
    border-radius: 2rem !important;
  }

  .rounded-sm-pill {
    border-radius: 50rem !important;
  }

  .rounded-sm-circle {
    border-radius: 50% !important;
  }

  .rounded-sm-card {
    border-radius: var(--x-border-radius) !important;
  }

  .rounded-sm-inherit {
    border-radius: inherit !important;
  }

  .rounded-top-sm {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-sm-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-sm-1 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-sm-2 {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-sm-3 {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-sm-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-sm-5 {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important;
  }

  .rounded-top-sm-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-sm-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-top-sm-card {
    border-top-left-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-end-sm {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-sm-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-sm-1 {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-end-sm-2 {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-sm-3 {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-end-sm-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-end-sm-5 {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;
  }

  .rounded-end-sm-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-sm-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-end-sm-card {
    border-top-right-radius: var(--x-border-radius) !important;
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-sm {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-sm-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-sm-1 {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-sm-2 {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-sm-3 {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-sm-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-sm-5 {
    border-bottom-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-bottom-sm-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-sm-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-sm-card {
    border-bottom-right-radius: var(--x-border-radius) !important;
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .rounded-start-sm {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-sm-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-sm-1 {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-start-sm-2 {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-sm-3 {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-start-sm-4 {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-start-sm-5 {
    border-top-left-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-start-sm-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-sm-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-start-sm-card {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-top-start-sm {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-sm-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-top-start-sm-1 {
    border-top-left-radius: .25rem !important;
  }

  .rounded-top-start-sm-2 {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-sm-3 {
    border-top-left-radius: .5rem !important;
  }

  .rounded-top-start-sm-4 {
    border-top-left-radius: 1rem !important;
  }

  .rounded-top-start-sm-5 {
    border-top-left-radius: 2rem !important;
  }

  .rounded-top-start-sm-pill {
    border-top-left-radius: 50rem !important;
  }

  .rounded-top-start-sm-circle {
    border-top-left-radius: 50% !important;
  }

  .rounded-top-start-sm-card {
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-top-end-sm {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-sm-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-top-end-sm-1 {
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-end-sm-2 {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-sm-3 {
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-end-sm-4 {
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-end-sm-5 {
    border-top-right-radius: 2rem !important;
  }

  .rounded-top-end-sm-pill {
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-end-sm-circle {
    border-top-right-radius: 50% !important;
  }

  .rounded-top-end-sm-card {
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-end-sm {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-sm-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-end-sm-1 {
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-bottom-end-sm-2 {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-sm-3 {
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-bottom-end-sm-4 {
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-bottom-end-sm-5 {
    border-bottom-right-radius: 2rem !important;
  }

  .rounded-bottom-end-sm-pill {
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-end-sm-circle {
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-end-sm-card {
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-start-sm {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-sm-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-start-sm-1 {
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-start-sm-2 {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-sm-3 {
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-start-sm-4 {
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-start-sm-5 {
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-bottom-start-sm-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-start-sm-circle {
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-start-sm-card {
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .shadow-soft-sm-1, .shadow-soft-sm-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-sm-2, .shadow-soft-sm-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-sm-3, .shadow-soft-sm-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-sm-4, .shadow-soft-sm-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-sm-5, .shadow-soft-sm-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-sm-6, .shadow-soft-sm-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
  }

  .w-rem-sm-0 {
    width: 0 !important;
  }

  .w-rem-sm-1 {
    width: .25rem !important;
  }

  .w-rem-sm-2 {
    width: .5rem !important;
  }

  .w-rem-sm-3 {
    width: .75rem !important;
  }

  .w-rem-sm-4 {
    width: 1rem !important;
  }

  .w-rem-sm-5 {
    width: 1.25rem !important;
  }

  .w-rem-sm-6 {
    width: 1.5rem !important;
  }

  .w-rem-sm-7 {
    width: 1.75rem !important;
  }

  .w-rem-sm-8 {
    width: 2rem !important;
  }

  .w-rem-sm-10 {
    width: 2.5rem !important;
  }

  .w-rem-sm-12 {
    width: 3rem !important;
  }

  .w-rem-sm-14 {
    width: 3.5rem !important;
  }

  .w-rem-sm-16 {
    width: 4rem !important;
  }

  .w-rem-sm-18 {
    width: 4.5rem !important;
  }

  .w-rem-sm-20 {
    width: 5rem !important;
  }

  .w-rem-sm-24 {
    width: 6rem !important;
  }

  .w-rem-sm-32 {
    width: 8rem !important;
  }

  .w-rem-sm-40 {
    width: 10rem !important;
  }

  .w-rem-sm-48 {
    width: 12rem !important;
  }

  .w-rem-sm-56 {
    width: 14rem !important;
  }

  .w-rem-sm-64 {
    width: 16rem !important;
  }

  .w-rem-sm-72 {
    width: 18rem !important;
  }

  .w-rem-sm-80 {
    width: 20rem !important;
  }

  .w-rem-sm-px {
    width: 1px !important;
  }

  .h-rem-sm-0 {
    height: 0 !important;
  }

  .h-rem-sm-1 {
    height: .25rem !important;
  }

  .h-rem-sm-2 {
    height: .5rem !important;
  }

  .h-rem-sm-3 {
    height: .75rem !important;
  }

  .h-rem-sm-4 {
    height: 1rem !important;
  }

  .h-rem-sm-5 {
    height: 1.25rem !important;
  }

  .h-rem-sm-6 {
    height: 1.5rem !important;
  }

  .h-rem-sm-7 {
    height: 1.75rem !important;
  }

  .h-rem-sm-8 {
    height: 2rem !important;
  }

  .h-rem-sm-10 {
    height: 2.5rem !important;
  }

  .h-rem-sm-12 {
    height: 3rem !important;
  }

  .h-rem-sm-14 {
    height: 3.5rem !important;
  }

  .h-rem-sm-16 {
    height: 4rem !important;
  }

  .h-rem-sm-18 {
    height: 4.5rem !important;
  }

  .h-rem-sm-20 {
    height: 5rem !important;
  }

  .h-rem-sm-24 {
    height: 6rem !important;
  }

  .h-rem-sm-32 {
    height: 8rem !important;
  }

  .h-rem-sm-40 {
    height: 10rem !important;
  }

  .h-rem-sm-48 {
    height: 12rem !important;
  }

  .h-rem-sm-56 {
    height: 14rem !important;
  }

  .h-rem-sm-64 {
    height: 16rem !important;
  }

  .h-rem-sm-72 {
    height: 18rem !important;
  }

  .h-rem-sm-80 {
    height: 20rem !important;
  }

  .h-rem-sm-px {
    height: 1px !important;
  }

  .transition-sm {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .transition-sm-none {
    transition-property: none !important;
  }

  .transition-sm-all {
    transition-property: all !important;
  }

  .transition-sm-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .transition-sm-opacity {
    transition-property: opacity !important;
  }

  .transition-sm-shadow {
    transition-property: box-shadow !important;
  }

  .transition-sm-transform {
    transition-property: transform !important;
  }

  .ease-sm-base {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .ease-sm-linear {
    transition-timing-function: linear !important;
  }

  .ease-sm-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
  }

  .ease-sm-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
  }

  .ease-sm-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .transform-sm-none {
    transform: none !important;
  }

  .scale-sm-0, .scale-sm-0-hover:hover {
    --x-scale-x: 0 !important;
    --x-scale-y: 0 !important;
  }

  .scale-sm-25, .scale-sm-25-hover:hover {
    --x-scale-x: .25 !important;
    --x-scale-y: .25 !important;
  }

  .scale-sm-50, .scale-sm-50-hover:hover {
    --x-scale-x: .5 !important;
    --x-scale-y: .5 !important;
  }

  .scale-sm-75, .scale-sm-75-hover:hover {
    --x-scale-x: .75 !important;
    --x-scale-y: .75 !important;
  }

  .scale-sm-100, .scale-sm-100-hover:hover {
    --x-scale-x: 1 !important;
    --x-scale-y: 1 !important;
  }

  .scale-sm-105, .scale-sm-105-hover:hover {
    --x-scale-x: 1.05 !important;
    --x-scale-y: 1.05 !important;
  }

  .scale-sm-110, .scale-sm-110-hover:hover {
    --x-scale-x: 1.1 !important;
    --x-scale-y: 1.1 !important;
  }

  .scale-sm-125, .scale-sm-125-hover:hover {
    --x-scale-x: 1.25 !important;
    --x-scale-y: 1.25 !important;
  }

  .scale-sm-150, .scale-sm-150-hover:hover {
    --x-scale-x: 1.5 !important;
    --x-scale-y: 1.5 !important;
  }

  .rotate-sm-0, .rotate-sm-0-hover:hover {
    --x-rotate: 0deg !important;
  }

  .rotate-sm-1, .rotate-sm-1-hover:hover {
    --x-rotate: 1deg !important;
  }

  .rotate-sm-2, .rotate-sm-2-hover:hover {
    --x-rotate: 2deg !important;
  }

  .rotate-sm-3, .rotate-sm-3-hover:hover {
    --x-rotate: 3deg !important;
  }

  .rotate-sm-6, .rotate-sm-6-hover:hover {
    --x-rotate: 6deg !important;
  }

  .rotate-sm-12, .rotate-sm-12-hover:hover {
    --x-rotate: 12deg !important;
  }

  .rotate-sm-30, .rotate-sm-30-hover:hover {
    --x-rotate: 30deg !important;
  }

  .rotate-sm-45, .rotate-sm-45-hover:hover {
    --x-rotate: 45deg !important;
  }

  .rotate-sm-90, .rotate-sm-90-hover:hover {
    --x-rotate: 90deg !important;
  }

  .rotate-sm-180, .rotate-sm-180-hover:hover {
    --x-rotate: 180deg !important;
  }

  .rotate-sm-n1, .rotate-sm-n1-hover:hover {
    --x-rotate: -1deg !important;
  }

  .rotate-sm-n2, .rotate-sm-n2-hover:hover {
    --x-rotate: -2deg !important;
  }

  .rotate-sm-n3, .rotate-sm-n3-hover:hover {
    --x-rotate: -3deg !important;
  }

  .rotate-sm-n6, .rotate-sm-n6-hover:hover {
    --x-rotate: -6deg !important;
  }

  .rotate-sm-n12, .rotate-sm-n12-hover:hover {
    --x-rotate: -12deg !important;
  }

  .rotate-sm-n30, .rotate-sm-n30-hover:hover {
    --x-rotate: -30deg !important;
  }

  .rotate-sm-n45, .rotate-sm-n45-hover:hover {
    --x-rotate: -45deg !important;
  }

  .rotate-sm-n90, .rotate-sm-n90-hover:hover {
    --x-rotate: -90deg !important;
  }

  .rotate-sm-n180, .rotate-sm-n180-hover:hover {
    --x-rotate: -180deg !important;
  }

  .rotate-y-sm-n1, .rotate-y-sm-n1-hover:hover {
    --x-rotate-y: -1deg !important;
  }

  .rotate-y-sm-n2, .rotate-y-sm-n2-hover:hover {
    --x-rotate-y: -2deg !important;
  }

  .rotate-y-sm-n3, .rotate-y-sm-n3-hover:hover {
    --x-rotate-y: -3deg !important;
  }

  .rotate-y-sm-n6, .rotate-y-sm-n6-hover:hover {
    --x-rotate-y: -6deg !important;
  }

  .rotate-y-sm-n12, .rotate-y-sm-n12-hover:hover {
    --x-rotate-y: -12deg !important;
  }

  .rotate-y-sm-n30, .rotate-y-sm-n30-hover:hover {
    --x-rotate-y: -30deg !important;
  }

  .rotate-y-sm-n45, .rotate-y-sm-n45-hover:hover {
    --x-rotate-y: -45deg !important;
  }

  .rotate-y-sm-n90, .rotate-y-sm-n90-hover:hover {
    --x-rotate-y: -90deg !important;
  }

  .rotate-y-sm-n180, .rotate-y-sm-n180-hover:hover {
    --x-rotate-y: -180deg !important;
  }

  .rotate-x-sm-n1 {
    --x-rotate-x: -1deg !important;
  }

  .rotate-x-sm-n2 {
    --x-rotate-x: -2deg !important;
  }

  .rotate-x-sm-n3 {
    --x-rotate-x: -3deg !important;
  }

  .rotate-x-sm-n6 {
    --x-rotate-x: -6deg !important;
  }

  .rotate-x-sm-n12 {
    --x-rotate-x: -12deg !important;
  }

  .rotate-x-sm-n30 {
    --x-rotate-x: -30deg !important;
  }

  .rotate-x-sm-n45 {
    --x-rotate-x: -45deg !important;
  }

  .rotate-x-sm-n90 {
    --x-rotate-x: -90deg !important;
  }

  .rotate-x-sm-n180 {
    --x-rotate-x: -180deg !important;
  }

  .translate-y-sm-0, .translate-y-sm-0-hover:hover {
    --x-translate-y: 0 !important;
  }

  .translate-y-sm-25, .translate-y-sm-25-hover:hover {
    --x-translate-y: 25% !important;
  }

  .translate-y-sm-50, .translate-y-sm-50-hover:hover {
    --x-translate-y: 50% !important;
  }

  .translate-y-sm-75, .translate-y-sm-75-hover:hover {
    --x-translate-y: 75% !important;
  }

  .translate-y-sm-100, .translate-y-sm-100-hover:hover {
    --x-translate-y: 100% !important;
  }

  .translate-y-sm-n25, .translate-y-sm-n25-hover:hover {
    --x-translate-y: -25% !important;
  }

  .translate-y-sm-n50, .translate-y-sm-n50-hover:hover {
    --x-translate-y: -50% !important;
  }

  .translate-y-sm-n75, .translate-y-sm-n75-hover:hover {
    --x-translate-y: -75% !important;
  }

  .translate-y-sm-n100, .translate-y-sm-n100-hover:hover {
    --x-translate-y: -100% !important;
  }

  .translate-x-sm-0, .translate-x-sm-0-hover:hover {
    --x-translate-x: 0 !important;
  }

  .translate-x-sm-25, .translate-x-sm-25-hover:hover {
    --x-translate-x: 25% !important;
  }

  .translate-x-sm-50, .translate-x-sm-50-hover:hover {
    --x-translate-x: 50% !important;
  }

  .translate-x-sm-75, .translate-x-sm-75-hover:hover {
    --x-translate-x: 75% !important;
  }

  .translate-x-sm-100, .translate-x-sm-100-hover:hover {
    --x-translate-x: 100% !important;
  }

  .translate-x-sm-n25, .translate-x-sm-n25-hover:hover {
    --x-translate-x: -25% !important;
  }

  .translate-x-sm-n50, .translate-x-sm-n50-hover:hover {
    --x-translate-x: -50% !important;
  }

  .translate-x-sm-n75, .translate-x-sm-n75-hover:hover {
    --x-translate-x: -75% !important;
  }

  .translate-x-sm-n100, .translate-x-sm-n100-hover:hover {
    --x-translate-x: -100% !important;
  }

  .skew-y-sm-0 {
    --x-skew-y: 0deg !important;
  }

  .skew-y-sm-1 {
    --x-skew-y: 1deg !important;
  }

  .skew-y-sm-2 {
    --x-skew-y: 2deg !important;
  }

  .skew-y-sm-3 {
    --x-skew-y: 3deg !important;
  }

  .skew-y-sm-6 {
    --x-skew-y: 6deg !important;
  }

  .skew-y-sm-12 {
    --x-skew-y: 12deg !important;
  }

  .skew-x-sm-0 {
    --x-skew-x: 0deg !important;
  }

  .skew-x-sm-1 {
    --x-skew-x: 1deg !important;
  }

  .skew-x-sm-2 {
    --x-skew-x: 2deg !important;
  }

  .skew-x-sm-3 {
    --x-skew-x: 3deg !important;
  }

  .skew-x-sm-6 {
    --x-skew-x: 6deg !important;
  }

  .skew-x-sm-12 {
    --x-skew-x: 12deg !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .overflow-md-auto {
    overflow: auto !important;
  }

  .overflow-md-hidden {
    overflow: hidden !important;
  }

  .overflow-md-visible {
    overflow: visible !important;
  }

  .overflow-md-scroll {
    overflow: scroll !important;
  }

  .overflow-x-md-auto {
    overflow-x: auto !important;
  }

  .overflow-x-md-hidden {
    overflow-x: hidden !important;
  }

  .overflow-x-md-visible {
    overflow-x: visible !important;
  }

  .overflow-x-md-scroll {
    overflow-x: scroll !important;
  }

  .overflow-y-md-auto {
    overflow-y: auto !important;
  }

  .overflow-y-md-hidden {
    overflow-y: hidden !important;
  }

  .overflow-y-md-visible {
    overflow-y: visible !important;
  }

  .overflow-y-md-scroll {
    overflow-y: scroll !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .shadow-md, .shadow-md-hover:hover {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-1, .shadow-md-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-2, .shadow-md-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-3, .shadow-md-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-4, .shadow-md-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-5, .shadow-md-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-6, .shadow-md-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-md-inset, .shadow-md-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
  }

  .shadow-md-none, .shadow-md-none-hover:hover {
    box-shadow: none !important;
  }

  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-md-0 {
    top: 0 !important;
  }

  .top-md-50 {
    top: 50% !important;
  }

  .top-md-100 {
    top: 100% !important;
  }

  .top-md-1 {
    top: .25rem !important;
  }

  .top-md-2 {
    top: .5rem !important;
  }

  .top-md-3 {
    top: .75rem !important;
  }

  .top-md-4 {
    top: 1rem !important;
  }

  .top-md-5 {
    top: 1.25rem !important;
  }

  .top-md-6 {
    top: 1.5rem !important;
  }

  .top-md-7 {
    top: 1.75rem !important;
  }

  .top-md-8 {
    top: 2rem !important;
  }

  .top-md-10 {
    top: 2.5rem !important;
  }

  .top-md-12 {
    top: 3rem !important;
  }

  .top-md-14 {
    top: 3.5rem !important;
  }

  .top-md-16 {
    top: 4rem !important;
  }

  .top-md-18 {
    top: 4.5rem !important;
  }

  .top-md-20 {
    top: 5rem !important;
  }

  .top-md-24 {
    top: 6rem !important;
  }

  .top-md-32 {
    top: 8rem !important;
  }

  .top-md-40 {
    top: 10rem !important;
  }

  .top-md-48 {
    top: 12rem !important;
  }

  .top-md-56 {
    top: 14rem !important;
  }

  .top-md-64 {
    top: 16rem !important;
  }

  .top-md-72 {
    top: 18rem !important;
  }

  .top-md-80 {
    top: 20rem !important;
  }

  .top-md-px {
    top: 1px !important;
  }

  .bottom-md-0 {
    bottom: 0 !important;
  }

  .bottom-md-50 {
    bottom: 50% !important;
  }

  .bottom-md-100 {
    bottom: 100% !important;
  }

  .start-md-0 {
    left: 0 !important;
  }

  .start-md-50 {
    left: 50% !important;
  }

  .start-md-100 {
    left: 100% !important;
  }

  .end-md-0 {
    right: 0 !important;
  }

  .end-md-50 {
    right: 50% !important;
  }

  .end-md-100 {
    right: 100% !important;
  }

  .border-md {
    border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-md-0 {
    border: 0 !important;
  }

  .border-top-md {
    border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-top-md-0 {
    border-top: 0 !important;
  }

  .border-end-md {
    border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-end-md-0 {
    border-right: 0 !important;
  }

  .border-bottom-md {
    border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }

  .border-start-md {
    border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-start-md-0 {
    border-left: 0 !important;
  }

  .border-md-primary, .border-md-primary-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-primary-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-secondary, .border-md-secondary-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-secondary-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-success, .border-md-success-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-success-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-info, .border-md-info-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-info-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-warning, .border-md-warning-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-warning-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-danger, .border-md-danger-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-danger-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-light, .border-md-light-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-light-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-dark, .border-md-dark-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-dark-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-black, .border-md-black-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-black-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-white, .border-md-white-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-white-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-gray-50, .border-md-gray-50-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-50-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-gray-100, .border-md-gray-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-gray-200, .border-md-gray-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-gray-300, .border-md-gray-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-gray-400, .border-md-gray-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-gray-500, .border-md-gray-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-gray-600, .border-md-gray-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-gray-700, .border-md-gray-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-gray-800, .border-md-gray-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-gray-900, .border-md-gray-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-gray-950, .border-md-gray-950-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-950-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-blue-100, .border-md-blue-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-blue-200, .border-md-blue-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-blue-300, .border-md-blue-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-blue-400, .border-md-blue-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-blue-500, .border-md-blue-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-blue-600, .border-md-blue-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-blue-700, .border-md-blue-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-blue-800, .border-md-blue-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-blue-900, .border-md-blue-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-indigo-100, .border-md-indigo-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-indigo-200, .border-md-indigo-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-indigo-300, .border-md-indigo-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-indigo-400, .border-md-indigo-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-indigo-500, .border-md-indigo-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-indigo-600, .border-md-indigo-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-indigo-700, .border-md-indigo-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-indigo-800, .border-md-indigo-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-indigo-900, .border-md-indigo-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-purple-100, .border-md-purple-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-purple-200, .border-md-purple-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-purple-300, .border-md-purple-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-purple-400, .border-md-purple-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-purple-500, .border-md-purple-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-purple-600, .border-md-purple-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-purple-700, .border-md-purple-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-purple-800, .border-md-purple-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-purple-900, .border-md-purple-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-pink-100, .border-md-pink-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-pink-200, .border-md-pink-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-pink-300, .border-md-pink-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-pink-400, .border-md-pink-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-pink-500, .border-md-pink-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-pink-600, .border-md-pink-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-pink-700, .border-md-pink-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-pink-800, .border-md-pink-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-pink-900, .border-md-pink-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-red-100, .border-md-red-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-red-200, .border-md-red-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-red-300, .border-md-red-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-red-400, .border-md-red-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-red-500, .border-md-red-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-red-600, .border-md-red-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-red-700, .border-md-red-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-red-800, .border-md-red-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-red-900, .border-md-red-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-orange-100, .border-md-orange-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-orange-200, .border-md-orange-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-orange-300, .border-md-orange-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-orange-400, .border-md-orange-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-orange-500, .border-md-orange-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-orange-600, .border-md-orange-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-orange-700, .border-md-orange-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-orange-800, .border-md-orange-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-orange-900, .border-md-orange-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-yellow-100, .border-md-yellow-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-yellow-200, .border-md-yellow-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-yellow-300, .border-md-yellow-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-yellow-400, .border-md-yellow-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-yellow-500, .border-md-yellow-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-yellow-600, .border-md-yellow-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-yellow-700, .border-md-yellow-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-yellow-800, .border-md-yellow-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-yellow-900, .border-md-yellow-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-green-100, .border-md-green-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-green-200, .border-md-green-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-green-300, .border-md-green-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-green-400, .border-md-green-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-green-500, .border-md-green-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-green-600, .border-md-green-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-green-700, .border-md-green-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-green-800, .border-md-green-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-green-900, .border-md-green-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-teal-100, .border-md-teal-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-teal-200, .border-md-teal-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-teal-300, .border-md-teal-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-teal-400, .border-md-teal-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-teal-500, .border-md-teal-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-teal-600, .border-md-teal-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-teal-700, .border-md-teal-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-teal-800, .border-md-teal-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-teal-900, .border-md-teal-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-cyan-100, .border-md-cyan-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-cyan-200, .border-md-cyan-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-cyan-300, .border-md-cyan-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-cyan-400, .border-md-cyan-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-cyan-500, .border-md-cyan-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-cyan-600, .border-md-cyan-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-cyan-700, .border-md-cyan-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-cyan-800, .border-md-cyan-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-md-cyan-900, .border-md-cyan-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-900-rgb), var(--x-border-opacity)) !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .mw-md-screen-sm {
    max-width: 640px !important;
  }

  .mw-md-screen-md {
    max-width: 768px !important;
  }

  .mw-md-screen-lg {
    max-width: 1024px !important;
  }

  .mw-md-screen-xl {
    max-width: 1280px !important;
  }

  .mw-md-screen-xxl {
    max-width: 1536px !important;
  }

  .mw-md-0 {
    max-width: 0 !important;
  }

  .mw-md-100 {
    max-width: 100% !important;
  }

  .mw-md-read {
    max-width: 65ch !important;
  }

  .mw-md-fit {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .vh-md-100 {
    height: 100vh !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-initial {
    flex: 0 auto !important;
  }

  .flex-md-none {
    flex: none !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: .75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 1.75rem !important;
  }

  .m-md-8 {
    margin: 2rem !important;
  }

  .m-md-10 {
    margin: 2.5rem !important;
  }

  .m-md-12 {
    margin: 3rem !important;
  }

  .m-md-14 {
    margin: 3.5rem !important;
  }

  .m-md-16 {
    margin: 4rem !important;
  }

  .m-md-18 {
    margin: 4.5rem !important;
  }

  .m-md-20 {
    margin: 5rem !important;
  }

  .m-md-24 {
    margin: 6rem !important;
  }

  .m-md-32 {
    margin: 8rem !important;
  }

  .m-md-40 {
    margin: 10rem !important;
  }

  .m-md-48 {
    margin: 12rem !important;
  }

  .m-md-56 {
    margin: 14rem !important;
  }

  .m-md-64 {
    margin: 16rem !important;
  }

  .m-md-72 {
    margin: 18rem !important;
  }

  .m-md-80 {
    margin: 20rem !important;
  }

  .m-md-px {
    margin: 1px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-md-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-md-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-md-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-md-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-md-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-md-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-md-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-md-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-md-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-md-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .mx-md-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mx-md-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .mx-md-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .mx-md-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .mx-md-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .mx-md-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mx-md-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-md-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-md-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-md-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-md-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-md-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-md-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-md-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: .75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 1.75rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }

  .mt-md-10 {
    margin-top: 2.5rem !important;
  }

  .mt-md-12 {
    margin-top: 3rem !important;
  }

  .mt-md-14 {
    margin-top: 3.5rem !important;
  }

  .mt-md-16 {
    margin-top: 4rem !important;
  }

  .mt-md-18 {
    margin-top: 4.5rem !important;
  }

  .mt-md-20 {
    margin-top: 5rem !important;
  }

  .mt-md-24 {
    margin-top: 6rem !important;
  }

  .mt-md-32 {
    margin-top: 8rem !important;
  }

  .mt-md-40 {
    margin-top: 10rem !important;
  }

  .mt-md-48 {
    margin-top: 12rem !important;
  }

  .mt-md-56 {
    margin-top: 14rem !important;
  }

  .mt-md-64 {
    margin-top: 16rem !important;
  }

  .mt-md-72 {
    margin-top: 18rem !important;
  }

  .mt-md-80 {
    margin-top: 20rem !important;
  }

  .mt-md-px {
    margin-top: 1px !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: .75rem !important;
  }

  .me-md-4 {
    margin-right: 1rem !important;
  }

  .me-md-5 {
    margin-right: 1.25rem !important;
  }

  .me-md-6 {
    margin-right: 1.5rem !important;
  }

  .me-md-7 {
    margin-right: 1.75rem !important;
  }

  .me-md-8 {
    margin-right: 2rem !important;
  }

  .me-md-10 {
    margin-right: 2.5rem !important;
  }

  .me-md-12 {
    margin-right: 3rem !important;
  }

  .me-md-14 {
    margin-right: 3.5rem !important;
  }

  .me-md-16 {
    margin-right: 4rem !important;
  }

  .me-md-18 {
    margin-right: 4.5rem !important;
  }

  .me-md-20 {
    margin-right: 5rem !important;
  }

  .me-md-24 {
    margin-right: 6rem !important;
  }

  .me-md-32 {
    margin-right: 8rem !important;
  }

  .me-md-40 {
    margin-right: 10rem !important;
  }

  .me-md-48 {
    margin-right: 12rem !important;
  }

  .me-md-56 {
    margin-right: 14rem !important;
  }

  .me-md-64 {
    margin-right: 16rem !important;
  }

  .me-md-72 {
    margin-right: 18rem !important;
  }

  .me-md-80 {
    margin-right: 20rem !important;
  }

  .me-md-px {
    margin-right: 1px !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: .75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2rem !important;
  }

  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-12 {
    margin-bottom: 3rem !important;
  }

  .mb-md-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-16 {
    margin-bottom: 4rem !important;
  }

  .mb-md-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-20 {
    margin-bottom: 5rem !important;
  }

  .mb-md-24 {
    margin-bottom: 6rem !important;
  }

  .mb-md-32 {
    margin-bottom: 8rem !important;
  }

  .mb-md-40 {
    margin-bottom: 10rem !important;
  }

  .mb-md-48 {
    margin-bottom: 12rem !important;
  }

  .mb-md-56 {
    margin-bottom: 14rem !important;
  }

  .mb-md-64 {
    margin-bottom: 16rem !important;
  }

  .mb-md-72 {
    margin-bottom: 18rem !important;
  }

  .mb-md-80 {
    margin-bottom: 20rem !important;
  }

  .mb-md-px {
    margin-bottom: 1px !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: .75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 1.75rem !important;
  }

  .ms-md-8 {
    margin-left: 2rem !important;
  }

  .ms-md-10 {
    margin-left: 2.5rem !important;
  }

  .ms-md-12 {
    margin-left: 3rem !important;
  }

  .ms-md-14 {
    margin-left: 3.5rem !important;
  }

  .ms-md-16 {
    margin-left: 4rem !important;
  }

  .ms-md-18 {
    margin-left: 4.5rem !important;
  }

  .ms-md-20 {
    margin-left: 5rem !important;
  }

  .ms-md-24 {
    margin-left: 6rem !important;
  }

  .ms-md-32 {
    margin-left: 8rem !important;
  }

  .ms-md-40 {
    margin-left: 10rem !important;
  }

  .ms-md-48 {
    margin-left: 12rem !important;
  }

  .ms-md-56 {
    margin-left: 14rem !important;
  }

  .ms-md-64 {
    margin-left: 16rem !important;
  }

  .ms-md-72 {
    margin-left: 18rem !important;
  }

  .ms-md-80 {
    margin-left: 20rem !important;
  }

  .ms-md-px {
    margin-left: 1px !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .m-md-n3 {
    margin: -.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.25rem !important;
  }

  .m-md-n6 {
    margin: -1.5rem !important;
  }

  .m-md-n7 {
    margin: -1.75rem !important;
  }

  .m-md-n8 {
    margin: -2rem !important;
  }

  .m-md-n10 {
    margin: -2.5rem !important;
  }

  .m-md-n12 {
    margin: -3rem !important;
  }

  .m-md-n14 {
    margin: -3.5rem !important;
  }

  .m-md-n16 {
    margin: -4rem !important;
  }

  .m-md-n18 {
    margin: -4.5rem !important;
  }

  .m-md-n20 {
    margin: -5rem !important;
  }

  .m-md-n24 {
    margin: -6rem !important;
  }

  .m-md-n32 {
    margin: -8rem !important;
  }

  .m-md-n40 {
    margin: -10rem !important;
  }

  .m-md-n48 {
    margin: -12rem !important;
  }

  .m-md-n56 {
    margin: -14rem !important;
  }

  .m-md-n64 {
    margin: -16rem !important;
  }

  .m-md-n72 {
    margin: -18rem !important;
  }

  .m-md-n80 {
    margin: -20rem !important;
  }

  .m-md-npx {
    margin: -1px !important;
  }

  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-md-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-md-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-md-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-md-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-md-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-md-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-md-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-md-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-md-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-md-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-md-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .mx-md-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .mx-md-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .mx-md-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .mx-md-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .mx-md-n72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .mx-md-n80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .mx-md-npx {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .my-md-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-md-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-md-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-md-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-md-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-md-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-md-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-md-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-md-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-md-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-md-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-md-n72 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-md-n80 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-md-npx {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mt-md-n1 {
    margin-top: -.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -.75rem !important;
  }

  .mt-md-n4 {
    margin-top: -1rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-md-n8 {
    margin-top: -2rem !important;
  }

  .mt-md-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n12 {
    margin-top: -3rem !important;
  }

  .mt-md-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n16 {
    margin-top: -4rem !important;
  }

  .mt-md-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-md-n20 {
    margin-top: -5rem !important;
  }

  .mt-md-n24 {
    margin-top: -6rem !important;
  }

  .mt-md-n32 {
    margin-top: -8rem !important;
  }

  .mt-md-n40 {
    margin-top: -10rem !important;
  }

  .mt-md-n48 {
    margin-top: -12rem !important;
  }

  .mt-md-n56 {
    margin-top: -14rem !important;
  }

  .mt-md-n64 {
    margin-top: -16rem !important;
  }

  .mt-md-n72 {
    margin-top: -18rem !important;
  }

  .mt-md-n80 {
    margin-top: -20rem !important;
  }

  .mt-md-npx {
    margin-top: -1px !important;
  }

  .me-md-n1 {
    margin-right: -.25rem !important;
  }

  .me-md-n2 {
    margin-right: -.5rem !important;
  }

  .me-md-n3 {
    margin-right: -.75rem !important;
  }

  .me-md-n4 {
    margin-right: -1rem !important;
  }

  .me-md-n5 {
    margin-right: -1.25rem !important;
  }

  .me-md-n6 {
    margin-right: -1.5rem !important;
  }

  .me-md-n7 {
    margin-right: -1.75rem !important;
  }

  .me-md-n8 {
    margin-right: -2rem !important;
  }

  .me-md-n10 {
    margin-right: -2.5rem !important;
  }

  .me-md-n12 {
    margin-right: -3rem !important;
  }

  .me-md-n14 {
    margin-right: -3.5rem !important;
  }

  .me-md-n16 {
    margin-right: -4rem !important;
  }

  .me-md-n18 {
    margin-right: -4.5rem !important;
  }

  .me-md-n20 {
    margin-right: -5rem !important;
  }

  .me-md-n24 {
    margin-right: -6rem !important;
  }

  .me-md-n32 {
    margin-right: -8rem !important;
  }

  .me-md-n40 {
    margin-right: -10rem !important;
  }

  .me-md-n48 {
    margin-right: -12rem !important;
  }

  .me-md-n56 {
    margin-right: -14rem !important;
  }

  .me-md-n64 {
    margin-right: -16rem !important;
  }

  .me-md-n72 {
    margin-right: -18rem !important;
  }

  .me-md-n80 {
    margin-right: -20rem !important;
  }

  .me-md-npx {
    margin-right: -1px !important;
  }

  .mb-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -.75rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-md-n20 {
    margin-bottom: -5rem !important;
  }

  .mb-md-n24 {
    margin-bottom: -6rem !important;
  }

  .mb-md-n32 {
    margin-bottom: -8rem !important;
  }

  .mb-md-n40 {
    margin-bottom: -10rem !important;
  }

  .mb-md-n48 {
    margin-bottom: -12rem !important;
  }

  .mb-md-n56 {
    margin-bottom: -14rem !important;
  }

  .mb-md-n64 {
    margin-bottom: -16rem !important;
  }

  .mb-md-n72 {
    margin-bottom: -18rem !important;
  }

  .mb-md-n80 {
    margin-bottom: -20rem !important;
  }

  .mb-md-npx {
    margin-bottom: -1px !important;
  }

  .ms-md-n1 {
    margin-left: -.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -.75rem !important;
  }

  .ms-md-n4 {
    margin-left: -1rem !important;
  }

  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-md-n8 {
    margin-left: -2rem !important;
  }

  .ms-md-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n12 {
    margin-left: -3rem !important;
  }

  .ms-md-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n16 {
    margin-left: -4rem !important;
  }

  .ms-md-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-md-n20 {
    margin-left: -5rem !important;
  }

  .ms-md-n24 {
    margin-left: -6rem !important;
  }

  .ms-md-n32 {
    margin-left: -8rem !important;
  }

  .ms-md-n40 {
    margin-left: -10rem !important;
  }

  .ms-md-n48 {
    margin-left: -12rem !important;
  }

  .ms-md-n56 {
    margin-left: -14rem !important;
  }

  .ms-md-n64 {
    margin-left: -16rem !important;
  }

  .ms-md-n72 {
    margin-left: -18rem !important;
  }

  .ms-md-n80 {
    margin-left: -20rem !important;
  }

  .ms-md-npx {
    margin-left: -1px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: .75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 1.75rem !important;
  }

  .p-md-8 {
    padding: 2rem !important;
  }

  .p-md-10 {
    padding: 2.5rem !important;
  }

  .p-md-12 {
    padding: 3rem !important;
  }

  .p-md-14 {
    padding: 3.5rem !important;
  }

  .p-md-16 {
    padding: 4rem !important;
  }

  .p-md-18 {
    padding: 4.5rem !important;
  }

  .p-md-20 {
    padding: 5rem !important;
  }

  .p-md-24 {
    padding: 6rem !important;
  }

  .p-md-32 {
    padding: 8rem !important;
  }

  .p-md-40 {
    padding: 10rem !important;
  }

  .p-md-48 {
    padding: 12rem !important;
  }

  .p-md-56 {
    padding: 14rem !important;
  }

  .p-md-64 {
    padding: 16rem !important;
  }

  .p-md-72 {
    padding: 18rem !important;
  }

  .p-md-80 {
    padding: 20rem !important;
  }

  .p-md-px {
    padding: 1px !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-md-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-md-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-md-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-md-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-md-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-md-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-md-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-md-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-md-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-md-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-md-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .px-md-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .px-md-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .px-md-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .px-md-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .px-md-72 {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .px-md-80 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .px-md-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-md-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-md-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-md-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-md-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-md-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-md-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-md-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: .75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 1.75rem !important;
  }

  .pt-md-8 {
    padding-top: 2rem !important;
  }

  .pt-md-10 {
    padding-top: 2.5rem !important;
  }

  .pt-md-12 {
    padding-top: 3rem !important;
  }

  .pt-md-14 {
    padding-top: 3.5rem !important;
  }

  .pt-md-16 {
    padding-top: 4rem !important;
  }

  .pt-md-18 {
    padding-top: 4.5rem !important;
  }

  .pt-md-20 {
    padding-top: 5rem !important;
  }

  .pt-md-24 {
    padding-top: 6rem !important;
  }

  .pt-md-32 {
    padding-top: 8rem !important;
  }

  .pt-md-40 {
    padding-top: 10rem !important;
  }

  .pt-md-48 {
    padding-top: 12rem !important;
  }

  .pt-md-56 {
    padding-top: 14rem !important;
  }

  .pt-md-64 {
    padding-top: 16rem !important;
  }

  .pt-md-72 {
    padding-top: 18rem !important;
  }

  .pt-md-80 {
    padding-top: 20rem !important;
  }

  .pt-md-px {
    padding-top: 1px !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: .75rem !important;
  }

  .pe-md-4 {
    padding-right: 1rem !important;
  }

  .pe-md-5 {
    padding-right: 1.25rem !important;
  }

  .pe-md-6 {
    padding-right: 1.5rem !important;
  }

  .pe-md-7 {
    padding-right: 1.75rem !important;
  }

  .pe-md-8 {
    padding-right: 2rem !important;
  }

  .pe-md-10 {
    padding-right: 2.5rem !important;
  }

  .pe-md-12 {
    padding-right: 3rem !important;
  }

  .pe-md-14 {
    padding-right: 3.5rem !important;
  }

  .pe-md-16 {
    padding-right: 4rem !important;
  }

  .pe-md-18 {
    padding-right: 4.5rem !important;
  }

  .pe-md-20 {
    padding-right: 5rem !important;
  }

  .pe-md-24 {
    padding-right: 6rem !important;
  }

  .pe-md-32 {
    padding-right: 8rem !important;
  }

  .pe-md-40 {
    padding-right: 10rem !important;
  }

  .pe-md-48 {
    padding-right: 12rem !important;
  }

  .pe-md-56 {
    padding-right: 14rem !important;
  }

  .pe-md-64 {
    padding-right: 16rem !important;
  }

  .pe-md-72 {
    padding-right: 18rem !important;
  }

  .pe-md-80 {
    padding-right: 20rem !important;
  }

  .pe-md-px {
    padding-right: 1px !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: .75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2rem !important;
  }

  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-12 {
    padding-bottom: 3rem !important;
  }

  .pb-md-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-16 {
    padding-bottom: 4rem !important;
  }

  .pb-md-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-20 {
    padding-bottom: 5rem !important;
  }

  .pb-md-24 {
    padding-bottom: 6rem !important;
  }

  .pb-md-32 {
    padding-bottom: 8rem !important;
  }

  .pb-md-40 {
    padding-bottom: 10rem !important;
  }

  .pb-md-48 {
    padding-bottom: 12rem !important;
  }

  .pb-md-56 {
    padding-bottom: 14rem !important;
  }

  .pb-md-64 {
    padding-bottom: 16rem !important;
  }

  .pb-md-72 {
    padding-bottom: 18rem !important;
  }

  .pb-md-80 {
    padding-bottom: 20rem !important;
  }

  .pb-md-px {
    padding-bottom: 1px !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: .75rem !important;
  }

  .ps-md-4 {
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 1.25rem !important;
  }

  .ps-md-6 {
    padding-left: 1.5rem !important;
  }

  .ps-md-7 {
    padding-left: 1.75rem !important;
  }

  .ps-md-8 {
    padding-left: 2rem !important;
  }

  .ps-md-10 {
    padding-left: 2.5rem !important;
  }

  .ps-md-12 {
    padding-left: 3rem !important;
  }

  .ps-md-14 {
    padding-left: 3.5rem !important;
  }

  .ps-md-16 {
    padding-left: 4rem !important;
  }

  .ps-md-18 {
    padding-left: 4.5rem !important;
  }

  .ps-md-20 {
    padding-left: 5rem !important;
  }

  .ps-md-24 {
    padding-left: 6rem !important;
  }

  .ps-md-32 {
    padding-left: 8rem !important;
  }

  .ps-md-40 {
    padding-left: 10rem !important;
  }

  .ps-md-48 {
    padding-left: 12rem !important;
  }

  .ps-md-56 {
    padding-left: 14rem !important;
  }

  .ps-md-64 {
    padding-left: 16rem !important;
  }

  .ps-md-72 {
    padding-left: 18rem !important;
  }

  .ps-md-80 {
    padding-left: 20rem !important;
  }

  .ps-md-px {
    padding-left: 1px !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: .75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 1.75rem !important;
  }

  .gap-md-8 {
    gap: 2rem !important;
  }

  .gap-md-10 {
    gap: 2.5rem !important;
  }

  .gap-md-12 {
    gap: 3rem !important;
  }

  .gap-md-14 {
    gap: 3.5rem !important;
  }

  .gap-md-16 {
    gap: 4rem !important;
  }

  .gap-md-18 {
    gap: 4.5rem !important;
  }

  .gap-md-20 {
    gap: 5rem !important;
  }

  .gap-md-24 {
    gap: 6rem !important;
  }

  .gap-md-32 {
    gap: 8rem !important;
  }

  .gap-md-40 {
    gap: 10rem !important;
  }

  .gap-md-48 {
    gap: 12rem !important;
  }

  .gap-md-56 {
    gap: 14rem !important;
  }

  .gap-md-64 {
    gap: 16rem !important;
  }

  .gap-md-72 {
    gap: 18rem !important;
  }

  .gap-md-80 {
    gap: 20rem !important;
  }

  .gap-md-px {
    gap: 1px !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: .75rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1rem !important;
  }

  .row-gap-md-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-md-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-md-8 {
    row-gap: 2rem !important;
  }

  .row-gap-md-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-md-12 {
    row-gap: 3rem !important;
  }

  .row-gap-md-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-md-16 {
    row-gap: 4rem !important;
  }

  .row-gap-md-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-md-20 {
    row-gap: 5rem !important;
  }

  .row-gap-md-24 {
    row-gap: 6rem !important;
  }

  .row-gap-md-32 {
    row-gap: 8rem !important;
  }

  .row-gap-md-40 {
    row-gap: 10rem !important;
  }

  .row-gap-md-48 {
    row-gap: 12rem !important;
  }

  .row-gap-md-56 {
    row-gap: 14rem !important;
  }

  .row-gap-md-64 {
    row-gap: 16rem !important;
  }

  .row-gap-md-72 {
    row-gap: 18rem !important;
  }

  .row-gap-md-80 {
    row-gap: 20rem !important;
  }

  .row-gap-md-px {
    row-gap: 1px !important;
  }

  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    -moz-column-gap: .75rem !important;
    column-gap: .75rem !important;
  }

  .column-gap-md-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-md-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }

  .column-gap-md-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-md-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }

  .column-gap-md-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-md-10 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .column-gap-md-12 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .column-gap-md-14 {
    -moz-column-gap: 3.5rem !important;
    column-gap: 3.5rem !important;
  }

  .column-gap-md-16 {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }

  .column-gap-md-18 {
    -moz-column-gap: 4.5rem !important;
    column-gap: 4.5rem !important;
  }

  .column-gap-md-20 {
    -moz-column-gap: 5rem !important;
    column-gap: 5rem !important;
  }

  .column-gap-md-24 {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }

  .column-gap-md-32 {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }

  .column-gap-md-40 {
    -moz-column-gap: 10rem !important;
    column-gap: 10rem !important;
  }

  .column-gap-md-48 {
    -moz-column-gap: 12rem !important;
    column-gap: 12rem !important;
  }

  .column-gap-md-56 {
    -moz-column-gap: 14rem !important;
    column-gap: 14rem !important;
  }

  .column-gap-md-64 {
    -moz-column-gap: 16rem !important;
    column-gap: 16rem !important;
  }

  .column-gap-md-72 {
    -moz-column-gap: 18rem !important;
    column-gap: 18rem !important;
  }

  .column-gap-md-80 {
    -moz-column-gap: 20rem !important;
    column-gap: 20rem !important;
  }

  .column-gap-md-px {
    -moz-column-gap: 1px !important;
    column-gap: 1px !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .rounded-md {
    border-radius: .375rem !important;
  }

  .rounded-md-0 {
    border-radius: 0 !important;
  }

  .rounded-md-1 {
    border-radius: .25rem !important;
  }

  .rounded-md-2 {
    border-radius: .375rem !important;
  }

  .rounded-md-3 {
    border-radius: .5rem !important;
  }

  .rounded-md-4 {
    border-radius: 1rem !important;
  }

  .rounded-md-5 {
    border-radius: 2rem !important;
  }

  .rounded-md-pill {
    border-radius: 50rem !important;
  }

  .rounded-md-circle {
    border-radius: 50% !important;
  }

  .rounded-md-card {
    border-radius: var(--x-border-radius) !important;
  }

  .rounded-md-inherit {
    border-radius: inherit !important;
  }

  .rounded-top-md {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-md-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-md-1 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-md-2 {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-md-3 {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-md-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-md-5 {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important;
  }

  .rounded-top-md-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-md-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-top-md-card {
    border-top-left-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-end-md {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-md-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-md-1 {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-end-md-2 {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-md-3 {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-end-md-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-end-md-5 {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;
  }

  .rounded-end-md-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-md-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-end-md-card {
    border-top-right-radius: var(--x-border-radius) !important;
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-md {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-md-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-md-1 {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-md-2 {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-md-3 {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-md-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-md-5 {
    border-bottom-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-bottom-md-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-md-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-md-card {
    border-bottom-right-radius: var(--x-border-radius) !important;
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .rounded-start-md {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-md-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-md-1 {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-start-md-2 {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-md-3 {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-start-md-4 {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-start-md-5 {
    border-top-left-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-start-md-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-md-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-start-md-card {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-top-start-md {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-md-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-top-start-md-1 {
    border-top-left-radius: .25rem !important;
  }

  .rounded-top-start-md-2 {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-md-3 {
    border-top-left-radius: .5rem !important;
  }

  .rounded-top-start-md-4 {
    border-top-left-radius: 1rem !important;
  }

  .rounded-top-start-md-5 {
    border-top-left-radius: 2rem !important;
  }

  .rounded-top-start-md-pill {
    border-top-left-radius: 50rem !important;
  }

  .rounded-top-start-md-circle {
    border-top-left-radius: 50% !important;
  }

  .rounded-top-start-md-card {
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-top-end-md {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-md-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-top-end-md-1 {
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-end-md-2 {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-md-3 {
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-end-md-4 {
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-end-md-5 {
    border-top-right-radius: 2rem !important;
  }

  .rounded-top-end-md-pill {
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-end-md-circle {
    border-top-right-radius: 50% !important;
  }

  .rounded-top-end-md-card {
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-end-md {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-md-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-end-md-1 {
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-bottom-end-md-2 {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-md-3 {
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-bottom-end-md-4 {
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-bottom-end-md-5 {
    border-bottom-right-radius: 2rem !important;
  }

  .rounded-bottom-end-md-pill {
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-end-md-circle {
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-end-md-card {
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-start-md {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-md-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-start-md-1 {
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-start-md-2 {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-md-3 {
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-start-md-4 {
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-start-md-5 {
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-bottom-start-md-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-start-md-circle {
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-start-md-card {
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .shadow-soft-md-1, .shadow-soft-md-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-md-2, .shadow-soft-md-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-md-3, .shadow-soft-md-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-md-4, .shadow-soft-md-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-md-5, .shadow-soft-md-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-md-6, .shadow-soft-md-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
  }

  .w-rem-md-0 {
    width: 0 !important;
  }

  .w-rem-md-1 {
    width: .25rem !important;
  }

  .w-rem-md-2 {
    width: .5rem !important;
  }

  .w-rem-md-3 {
    width: .75rem !important;
  }

  .w-rem-md-4 {
    width: 1rem !important;
  }

  .w-rem-md-5 {
    width: 1.25rem !important;
  }

  .w-rem-md-6 {
    width: 1.5rem !important;
  }

  .w-rem-md-7 {
    width: 1.75rem !important;
  }

  .w-rem-md-8 {
    width: 2rem !important;
  }

  .w-rem-md-10 {
    width: 2.5rem !important;
  }

  .w-rem-md-12 {
    width: 3rem !important;
  }

  .w-rem-md-14 {
    width: 3.5rem !important;
  }

  .w-rem-md-16 {
    width: 4rem !important;
  }

  .w-rem-md-18 {
    width: 4.5rem !important;
  }

  .w-rem-md-20 {
    width: 5rem !important;
  }

  .w-rem-md-24 {
    width: 6rem !important;
  }

  .w-rem-md-32 {
    width: 8rem !important;
  }

  .w-rem-md-40 {
    width: 10rem !important;
  }

  .w-rem-md-48 {
    width: 12rem !important;
  }

  .w-rem-md-56 {
    width: 14rem !important;
  }

  .w-rem-md-64 {
    width: 16rem !important;
  }

  .w-rem-md-72 {
    width: 18rem !important;
  }

  .w-rem-md-80 {
    width: 20rem !important;
  }

  .w-rem-md-px {
    width: 1px !important;
  }

  .h-rem-md-0 {
    height: 0 !important;
  }

  .h-rem-md-1 {
    height: .25rem !important;
  }

  .h-rem-md-2 {
    height: .5rem !important;
  }

  .h-rem-md-3 {
    height: .75rem !important;
  }

  .h-rem-md-4 {
    height: 1rem !important;
  }

  .h-rem-md-5 {
    height: 1.25rem !important;
  }

  .h-rem-md-6 {
    height: 1.5rem !important;
  }

  .h-rem-md-7 {
    height: 1.75rem !important;
  }

  .h-rem-md-8 {
    height: 2rem !important;
  }

  .h-rem-md-10 {
    height: 2.5rem !important;
  }

  .h-rem-md-12 {
    height: 3rem !important;
  }

  .h-rem-md-14 {
    height: 3.5rem !important;
  }

  .h-rem-md-16 {
    height: 4rem !important;
  }

  .h-rem-md-18 {
    height: 4.5rem !important;
  }

  .h-rem-md-20 {
    height: 5rem !important;
  }

  .h-rem-md-24 {
    height: 6rem !important;
  }

  .h-rem-md-32 {
    height: 8rem !important;
  }

  .h-rem-md-40 {
    height: 10rem !important;
  }

  .h-rem-md-48 {
    height: 12rem !important;
  }

  .h-rem-md-56 {
    height: 14rem !important;
  }

  .h-rem-md-64 {
    height: 16rem !important;
  }

  .h-rem-md-72 {
    height: 18rem !important;
  }

  .h-rem-md-80 {
    height: 20rem !important;
  }

  .h-rem-md-px {
    height: 1px !important;
  }

  .transition-md {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .transition-md-none {
    transition-property: none !important;
  }

  .transition-md-all {
    transition-property: all !important;
  }

  .transition-md-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .transition-md-opacity {
    transition-property: opacity !important;
  }

  .transition-md-shadow {
    transition-property: box-shadow !important;
  }

  .transition-md-transform {
    transition-property: transform !important;
  }

  .ease-md-base {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .ease-md-linear {
    transition-timing-function: linear !important;
  }

  .ease-md-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
  }

  .ease-md-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
  }

  .ease-md-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .transform-md-none {
    transform: none !important;
  }

  .scale-md-0, .scale-md-0-hover:hover {
    --x-scale-x: 0 !important;
    --x-scale-y: 0 !important;
  }

  .scale-md-25, .scale-md-25-hover:hover {
    --x-scale-x: .25 !important;
    --x-scale-y: .25 !important;
  }

  .scale-md-50, .scale-md-50-hover:hover {
    --x-scale-x: .5 !important;
    --x-scale-y: .5 !important;
  }

  .scale-md-75, .scale-md-75-hover:hover {
    --x-scale-x: .75 !important;
    --x-scale-y: .75 !important;
  }

  .scale-md-100, .scale-md-100-hover:hover {
    --x-scale-x: 1 !important;
    --x-scale-y: 1 !important;
  }

  .scale-md-105, .scale-md-105-hover:hover {
    --x-scale-x: 1.05 !important;
    --x-scale-y: 1.05 !important;
  }

  .scale-md-110, .scale-md-110-hover:hover {
    --x-scale-x: 1.1 !important;
    --x-scale-y: 1.1 !important;
  }

  .scale-md-125, .scale-md-125-hover:hover {
    --x-scale-x: 1.25 !important;
    --x-scale-y: 1.25 !important;
  }

  .scale-md-150, .scale-md-150-hover:hover {
    --x-scale-x: 1.5 !important;
    --x-scale-y: 1.5 !important;
  }

  .rotate-md-0, .rotate-md-0-hover:hover {
    --x-rotate: 0deg !important;
  }

  .rotate-md-1, .rotate-md-1-hover:hover {
    --x-rotate: 1deg !important;
  }

  .rotate-md-2, .rotate-md-2-hover:hover {
    --x-rotate: 2deg !important;
  }

  .rotate-md-3, .rotate-md-3-hover:hover {
    --x-rotate: 3deg !important;
  }

  .rotate-md-6, .rotate-md-6-hover:hover {
    --x-rotate: 6deg !important;
  }

  .rotate-md-12, .rotate-md-12-hover:hover {
    --x-rotate: 12deg !important;
  }

  .rotate-md-30, .rotate-md-30-hover:hover {
    --x-rotate: 30deg !important;
  }

  .rotate-md-45, .rotate-md-45-hover:hover {
    --x-rotate: 45deg !important;
  }

  .rotate-md-90, .rotate-md-90-hover:hover {
    --x-rotate: 90deg !important;
  }

  .rotate-md-180, .rotate-md-180-hover:hover {
    --x-rotate: 180deg !important;
  }

  .rotate-md-n1, .rotate-md-n1-hover:hover {
    --x-rotate: -1deg !important;
  }

  .rotate-md-n2, .rotate-md-n2-hover:hover {
    --x-rotate: -2deg !important;
  }

  .rotate-md-n3, .rotate-md-n3-hover:hover {
    --x-rotate: -3deg !important;
  }

  .rotate-md-n6, .rotate-md-n6-hover:hover {
    --x-rotate: -6deg !important;
  }

  .rotate-md-n12, .rotate-md-n12-hover:hover {
    --x-rotate: -12deg !important;
  }

  .rotate-md-n30, .rotate-md-n30-hover:hover {
    --x-rotate: -30deg !important;
  }

  .rotate-md-n45, .rotate-md-n45-hover:hover {
    --x-rotate: -45deg !important;
  }

  .rotate-md-n90, .rotate-md-n90-hover:hover {
    --x-rotate: -90deg !important;
  }

  .rotate-md-n180, .rotate-md-n180-hover:hover {
    --x-rotate: -180deg !important;
  }

  .rotate-y-md-n1, .rotate-y-md-n1-hover:hover {
    --x-rotate-y: -1deg !important;
  }

  .rotate-y-md-n2, .rotate-y-md-n2-hover:hover {
    --x-rotate-y: -2deg !important;
  }

  .rotate-y-md-n3, .rotate-y-md-n3-hover:hover {
    --x-rotate-y: -3deg !important;
  }

  .rotate-y-md-n6, .rotate-y-md-n6-hover:hover {
    --x-rotate-y: -6deg !important;
  }

  .rotate-y-md-n12, .rotate-y-md-n12-hover:hover {
    --x-rotate-y: -12deg !important;
  }

  .rotate-y-md-n30, .rotate-y-md-n30-hover:hover {
    --x-rotate-y: -30deg !important;
  }

  .rotate-y-md-n45, .rotate-y-md-n45-hover:hover {
    --x-rotate-y: -45deg !important;
  }

  .rotate-y-md-n90, .rotate-y-md-n90-hover:hover {
    --x-rotate-y: -90deg !important;
  }

  .rotate-y-md-n180, .rotate-y-md-n180-hover:hover {
    --x-rotate-y: -180deg !important;
  }

  .rotate-x-md-n1 {
    --x-rotate-x: -1deg !important;
  }

  .rotate-x-md-n2 {
    --x-rotate-x: -2deg !important;
  }

  .rotate-x-md-n3 {
    --x-rotate-x: -3deg !important;
  }

  .rotate-x-md-n6 {
    --x-rotate-x: -6deg !important;
  }

  .rotate-x-md-n12 {
    --x-rotate-x: -12deg !important;
  }

  .rotate-x-md-n30 {
    --x-rotate-x: -30deg !important;
  }

  .rotate-x-md-n45 {
    --x-rotate-x: -45deg !important;
  }

  .rotate-x-md-n90 {
    --x-rotate-x: -90deg !important;
  }

  .rotate-x-md-n180 {
    --x-rotate-x: -180deg !important;
  }

  .translate-y-md-0, .translate-y-md-0-hover:hover {
    --x-translate-y: 0 !important;
  }

  .translate-y-md-25, .translate-y-md-25-hover:hover {
    --x-translate-y: 25% !important;
  }

  .translate-y-md-50, .translate-y-md-50-hover:hover {
    --x-translate-y: 50% !important;
  }

  .translate-y-md-75, .translate-y-md-75-hover:hover {
    --x-translate-y: 75% !important;
  }

  .translate-y-md-100, .translate-y-md-100-hover:hover {
    --x-translate-y: 100% !important;
  }

  .translate-y-md-n25, .translate-y-md-n25-hover:hover {
    --x-translate-y: -25% !important;
  }

  .translate-y-md-n50, .translate-y-md-n50-hover:hover {
    --x-translate-y: -50% !important;
  }

  .translate-y-md-n75, .translate-y-md-n75-hover:hover {
    --x-translate-y: -75% !important;
  }

  .translate-y-md-n100, .translate-y-md-n100-hover:hover {
    --x-translate-y: -100% !important;
  }

  .translate-x-md-0, .translate-x-md-0-hover:hover {
    --x-translate-x: 0 !important;
  }

  .translate-x-md-25, .translate-x-md-25-hover:hover {
    --x-translate-x: 25% !important;
  }

  .translate-x-md-50, .translate-x-md-50-hover:hover {
    --x-translate-x: 50% !important;
  }

  .translate-x-md-75, .translate-x-md-75-hover:hover {
    --x-translate-x: 75% !important;
  }

  .translate-x-md-100, .translate-x-md-100-hover:hover {
    --x-translate-x: 100% !important;
  }

  .translate-x-md-n25, .translate-x-md-n25-hover:hover {
    --x-translate-x: -25% !important;
  }

  .translate-x-md-n50, .translate-x-md-n50-hover:hover {
    --x-translate-x: -50% !important;
  }

  .translate-x-md-n75, .translate-x-md-n75-hover:hover {
    --x-translate-x: -75% !important;
  }

  .translate-x-md-n100, .translate-x-md-n100-hover:hover {
    --x-translate-x: -100% !important;
  }

  .skew-y-md-0 {
    --x-skew-y: 0deg !important;
  }

  .skew-y-md-1 {
    --x-skew-y: 1deg !important;
  }

  .skew-y-md-2 {
    --x-skew-y: 2deg !important;
  }

  .skew-y-md-3 {
    --x-skew-y: 3deg !important;
  }

  .skew-y-md-6 {
    --x-skew-y: 6deg !important;
  }

  .skew-y-md-12 {
    --x-skew-y: 12deg !important;
  }

  .skew-x-md-0 {
    --x-skew-x: 0deg !important;
  }

  .skew-x-md-1 {
    --x-skew-x: 1deg !important;
  }

  .skew-x-md-2 {
    --x-skew-x: 2deg !important;
  }

  .skew-x-md-3 {
    --x-skew-x: 3deg !important;
  }

  .skew-x-md-6 {
    --x-skew-x: 6deg !important;
  }

  .skew-x-md-12 {
    --x-skew-x: 12deg !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .overflow-lg-auto {
    overflow: auto !important;
  }

  .overflow-lg-hidden {
    overflow: hidden !important;
  }

  .overflow-lg-visible {
    overflow: visible !important;
  }

  .overflow-lg-scroll {
    overflow: scroll !important;
  }

  .overflow-x-lg-auto {
    overflow-x: auto !important;
  }

  .overflow-x-lg-hidden {
    overflow-x: hidden !important;
  }

  .overflow-x-lg-visible {
    overflow-x: visible !important;
  }

  .overflow-x-lg-scroll {
    overflow-x: scroll !important;
  }

  .overflow-y-lg-auto {
    overflow-y: auto !important;
  }

  .overflow-y-lg-hidden {
    overflow-y: hidden !important;
  }

  .overflow-y-lg-visible {
    overflow-y: visible !important;
  }

  .overflow-y-lg-scroll {
    overflow-y: scroll !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .shadow-lg, .shadow-lg-hover:hover {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-1, .shadow-lg-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-2, .shadow-lg-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-3, .shadow-lg-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-4, .shadow-lg-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-5, .shadow-lg-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-6, .shadow-lg-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-lg-inset, .shadow-lg-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
  }

  .shadow-lg-none, .shadow-lg-none-hover:hover {
    box-shadow: none !important;
  }

  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-lg-0 {
    top: 0 !important;
  }

  .top-lg-50 {
    top: 50% !important;
  }

  .top-lg-100 {
    top: 100% !important;
  }

  .top-lg-1 {
    top: .25rem !important;
  }

  .top-lg-2 {
    top: .5rem !important;
  }

  .top-lg-3 {
    top: .75rem !important;
  }

  .top-lg-4 {
    top: 1rem !important;
  }

  .top-lg-5 {
    top: 1.25rem !important;
  }

  .top-lg-6 {
    top: 1.5rem !important;
  }

  .top-lg-7 {
    top: 1.75rem !important;
  }

  .top-lg-8 {
    top: 2rem !important;
  }

  .top-lg-10 {
    top: 2.5rem !important;
  }

  .top-lg-12 {
    top: 3rem !important;
  }

  .top-lg-14 {
    top: 3.5rem !important;
  }

  .top-lg-16 {
    top: 4rem !important;
  }

  .top-lg-18 {
    top: 4.5rem !important;
  }

  .top-lg-20 {
    top: 5rem !important;
  }

  .top-lg-24 {
    top: 6rem !important;
  }

  .top-lg-32 {
    top: 8rem !important;
  }

  .top-lg-40 {
    top: 10rem !important;
  }

  .top-lg-48 {
    top: 12rem !important;
  }

  .top-lg-56 {
    top: 14rem !important;
  }

  .top-lg-64 {
    top: 16rem !important;
  }

  .top-lg-72 {
    top: 18rem !important;
  }

  .top-lg-80 {
    top: 20rem !important;
  }

  .top-lg-px {
    top: 1px !important;
  }

  .bottom-lg-0 {
    bottom: 0 !important;
  }

  .bottom-lg-50 {
    bottom: 50% !important;
  }

  .bottom-lg-100 {
    bottom: 100% !important;
  }

  .start-lg-0 {
    left: 0 !important;
  }

  .start-lg-50 {
    left: 50% !important;
  }

  .start-lg-100 {
    left: 100% !important;
  }

  .end-lg-0 {
    right: 0 !important;
  }

  .end-lg-50 {
    right: 50% !important;
  }

  .end-lg-100 {
    right: 100% !important;
  }

  .border-lg {
    border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-lg-0 {
    border: 0 !important;
  }

  .border-top-lg {
    border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-top-lg-0 {
    border-top: 0 !important;
  }

  .border-end-lg {
    border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-end-lg-0 {
    border-right: 0 !important;
  }

  .border-bottom-lg {
    border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }

  .border-start-lg {
    border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-start-lg-0 {
    border-left: 0 !important;
  }

  .border-lg-primary, .border-lg-primary-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-primary-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-secondary, .border-lg-secondary-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-secondary-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-success, .border-lg-success-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-success-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-info, .border-lg-info-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-info-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-warning, .border-lg-warning-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-warning-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-danger, .border-lg-danger-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-danger-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-light, .border-lg-light-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-light-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-dark, .border-lg-dark-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-dark-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-black, .border-lg-black-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-black-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-white, .border-lg-white-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-white-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-gray-50, .border-lg-gray-50-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-50-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-gray-100, .border-lg-gray-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-gray-200, .border-lg-gray-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-gray-300, .border-lg-gray-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-gray-400, .border-lg-gray-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-gray-500, .border-lg-gray-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-gray-600, .border-lg-gray-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-gray-700, .border-lg-gray-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-gray-800, .border-lg-gray-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-gray-900, .border-lg-gray-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-gray-950, .border-lg-gray-950-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-950-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-blue-100, .border-lg-blue-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-blue-200, .border-lg-blue-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-blue-300, .border-lg-blue-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-blue-400, .border-lg-blue-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-blue-500, .border-lg-blue-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-blue-600, .border-lg-blue-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-blue-700, .border-lg-blue-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-blue-800, .border-lg-blue-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-blue-900, .border-lg-blue-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-indigo-100, .border-lg-indigo-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-indigo-200, .border-lg-indigo-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-indigo-300, .border-lg-indigo-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-indigo-400, .border-lg-indigo-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-indigo-500, .border-lg-indigo-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-indigo-600, .border-lg-indigo-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-indigo-700, .border-lg-indigo-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-indigo-800, .border-lg-indigo-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-indigo-900, .border-lg-indigo-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-purple-100, .border-lg-purple-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-purple-200, .border-lg-purple-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-purple-300, .border-lg-purple-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-purple-400, .border-lg-purple-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-purple-500, .border-lg-purple-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-purple-600, .border-lg-purple-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-purple-700, .border-lg-purple-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-purple-800, .border-lg-purple-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-purple-900, .border-lg-purple-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-pink-100, .border-lg-pink-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-pink-200, .border-lg-pink-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-pink-300, .border-lg-pink-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-pink-400, .border-lg-pink-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-pink-500, .border-lg-pink-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-pink-600, .border-lg-pink-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-pink-700, .border-lg-pink-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-pink-800, .border-lg-pink-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-pink-900, .border-lg-pink-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-red-100, .border-lg-red-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-red-200, .border-lg-red-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-red-300, .border-lg-red-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-red-400, .border-lg-red-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-red-500, .border-lg-red-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-red-600, .border-lg-red-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-red-700, .border-lg-red-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-red-800, .border-lg-red-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-red-900, .border-lg-red-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-orange-100, .border-lg-orange-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-orange-200, .border-lg-orange-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-orange-300, .border-lg-orange-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-orange-400, .border-lg-orange-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-orange-500, .border-lg-orange-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-orange-600, .border-lg-orange-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-orange-700, .border-lg-orange-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-orange-800, .border-lg-orange-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-orange-900, .border-lg-orange-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-yellow-100, .border-lg-yellow-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-yellow-200, .border-lg-yellow-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-yellow-300, .border-lg-yellow-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-yellow-400, .border-lg-yellow-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-yellow-500, .border-lg-yellow-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-yellow-600, .border-lg-yellow-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-yellow-700, .border-lg-yellow-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-yellow-800, .border-lg-yellow-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-yellow-900, .border-lg-yellow-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-green-100, .border-lg-green-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-green-200, .border-lg-green-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-green-300, .border-lg-green-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-green-400, .border-lg-green-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-green-500, .border-lg-green-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-green-600, .border-lg-green-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-green-700, .border-lg-green-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-green-800, .border-lg-green-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-green-900, .border-lg-green-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-teal-100, .border-lg-teal-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-teal-200, .border-lg-teal-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-teal-300, .border-lg-teal-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-teal-400, .border-lg-teal-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-teal-500, .border-lg-teal-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-teal-600, .border-lg-teal-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-teal-700, .border-lg-teal-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-teal-800, .border-lg-teal-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-teal-900, .border-lg-teal-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-cyan-100, .border-lg-cyan-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-cyan-200, .border-lg-cyan-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-cyan-300, .border-lg-cyan-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-cyan-400, .border-lg-cyan-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-cyan-500, .border-lg-cyan-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-cyan-600, .border-lg-cyan-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-cyan-700, .border-lg-cyan-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-cyan-800, .border-lg-cyan-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-lg-cyan-900, .border-lg-cyan-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-900-rgb), var(--x-border-opacity)) !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .mw-lg-screen-sm {
    max-width: 640px !important;
  }

  .mw-lg-screen-md {
    max-width: 768px !important;
  }

  .mw-lg-screen-lg {
    max-width: 1024px !important;
  }

  .mw-lg-screen-xl {
    max-width: 1280px !important;
  }

  .mw-lg-screen-xxl {
    max-width: 1536px !important;
  }

  .mw-lg-0 {
    max-width: 0 !important;
  }

  .mw-lg-100 {
    max-width: 100% !important;
  }

  .mw-lg-read {
    max-width: 65ch !important;
  }

  .mw-lg-fit {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .vh-lg-100 {
    height: 100vh !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-initial {
    flex: 0 auto !important;
  }

  .flex-lg-none {
    flex: none !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: .75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 1.75rem !important;
  }

  .m-lg-8 {
    margin: 2rem !important;
  }

  .m-lg-10 {
    margin: 2.5rem !important;
  }

  .m-lg-12 {
    margin: 3rem !important;
  }

  .m-lg-14 {
    margin: 3.5rem !important;
  }

  .m-lg-16 {
    margin: 4rem !important;
  }

  .m-lg-18 {
    margin: 4.5rem !important;
  }

  .m-lg-20 {
    margin: 5rem !important;
  }

  .m-lg-24 {
    margin: 6rem !important;
  }

  .m-lg-32 {
    margin: 8rem !important;
  }

  .m-lg-40 {
    margin: 10rem !important;
  }

  .m-lg-48 {
    margin: 12rem !important;
  }

  .m-lg-56 {
    margin: 14rem !important;
  }

  .m-lg-64 {
    margin: 16rem !important;
  }

  .m-lg-72 {
    margin: 18rem !important;
  }

  .m-lg-80 {
    margin: 20rem !important;
  }

  .m-lg-px {
    margin: 1px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-lg-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-lg-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-lg-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-lg-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-lg-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-lg-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-lg-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-lg-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-lg-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .mx-lg-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mx-lg-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .mx-lg-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .mx-lg-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .mx-lg-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .mx-lg-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mx-lg-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-lg-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-lg-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-lg-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-lg-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-lg-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-lg-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-lg-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: .75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-12 {
    margin-top: 3rem !important;
  }

  .mt-lg-14 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-16 {
    margin-top: 4rem !important;
  }

  .mt-lg-18 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-20 {
    margin-top: 5rem !important;
  }

  .mt-lg-24 {
    margin-top: 6rem !important;
  }

  .mt-lg-32 {
    margin-top: 8rem !important;
  }

  .mt-lg-40 {
    margin-top: 10rem !important;
  }

  .mt-lg-48 {
    margin-top: 12rem !important;
  }

  .mt-lg-56 {
    margin-top: 14rem !important;
  }

  .mt-lg-64 {
    margin-top: 16rem !important;
  }

  .mt-lg-72 {
    margin-top: 18rem !important;
  }

  .mt-lg-80 {
    margin-top: 20rem !important;
  }

  .mt-lg-px {
    margin-top: 1px !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: .75rem !important;
  }

  .me-lg-4 {
    margin-right: 1rem !important;
  }

  .me-lg-5 {
    margin-right: 1.25rem !important;
  }

  .me-lg-6 {
    margin-right: 1.5rem !important;
  }

  .me-lg-7 {
    margin-right: 1.75rem !important;
  }

  .me-lg-8 {
    margin-right: 2rem !important;
  }

  .me-lg-10 {
    margin-right: 2.5rem !important;
  }

  .me-lg-12 {
    margin-right: 3rem !important;
  }

  .me-lg-14 {
    margin-right: 3.5rem !important;
  }

  .me-lg-16 {
    margin-right: 4rem !important;
  }

  .me-lg-18 {
    margin-right: 4.5rem !important;
  }

  .me-lg-20 {
    margin-right: 5rem !important;
  }

  .me-lg-24 {
    margin-right: 6rem !important;
  }

  .me-lg-32 {
    margin-right: 8rem !important;
  }

  .me-lg-40 {
    margin-right: 10rem !important;
  }

  .me-lg-48 {
    margin-right: 12rem !important;
  }

  .me-lg-56 {
    margin-right: 14rem !important;
  }

  .me-lg-64 {
    margin-right: 16rem !important;
  }

  .me-lg-72 {
    margin-right: 18rem !important;
  }

  .me-lg-80 {
    margin-right: 20rem !important;
  }

  .me-lg-px {
    margin-right: 1px !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: .75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-24 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-32 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-40 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-48 {
    margin-bottom: 12rem !important;
  }

  .mb-lg-56 {
    margin-bottom: 14rem !important;
  }

  .mb-lg-64 {
    margin-bottom: 16rem !important;
  }

  .mb-lg-72 {
    margin-bottom: 18rem !important;
  }

  .mb-lg-80 {
    margin-bottom: 20rem !important;
  }

  .mb-lg-px {
    margin-bottom: 1px !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: .75rem !important;
  }

  .ms-lg-4 {
    margin-left: 1rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }

  .ms-lg-8 {
    margin-left: 2rem !important;
  }

  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-12 {
    margin-left: 3rem !important;
  }

  .ms-lg-14 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-16 {
    margin-left: 4rem !important;
  }

  .ms-lg-18 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-20 {
    margin-left: 5rem !important;
  }

  .ms-lg-24 {
    margin-left: 6rem !important;
  }

  .ms-lg-32 {
    margin-left: 8rem !important;
  }

  .ms-lg-40 {
    margin-left: 10rem !important;
  }

  .ms-lg-48 {
    margin-left: 12rem !important;
  }

  .ms-lg-56 {
    margin-left: 14rem !important;
  }

  .ms-lg-64 {
    margin-left: 16rem !important;
  }

  .ms-lg-72 {
    margin-left: 18rem !important;
  }

  .ms-lg-80 {
    margin-left: 20rem !important;
  }

  .ms-lg-px {
    margin-left: 1px !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.25rem !important;
  }

  .m-lg-n6 {
    margin: -1.5rem !important;
  }

  .m-lg-n7 {
    margin: -1.75rem !important;
  }

  .m-lg-n8 {
    margin: -2rem !important;
  }

  .m-lg-n10 {
    margin: -2.5rem !important;
  }

  .m-lg-n12 {
    margin: -3rem !important;
  }

  .m-lg-n14 {
    margin: -3.5rem !important;
  }

  .m-lg-n16 {
    margin: -4rem !important;
  }

  .m-lg-n18 {
    margin: -4.5rem !important;
  }

  .m-lg-n20 {
    margin: -5rem !important;
  }

  .m-lg-n24 {
    margin: -6rem !important;
  }

  .m-lg-n32 {
    margin: -8rem !important;
  }

  .m-lg-n40 {
    margin: -10rem !important;
  }

  .m-lg-n48 {
    margin: -12rem !important;
  }

  .m-lg-n56 {
    margin: -14rem !important;
  }

  .m-lg-n64 {
    margin: -16rem !important;
  }

  .m-lg-n72 {
    margin: -18rem !important;
  }

  .m-lg-n80 {
    margin: -20rem !important;
  }

  .m-lg-npx {
    margin: -1px !important;
  }

  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-lg-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-lg-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-lg-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-lg-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-lg-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-lg-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-lg-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-lg-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-lg-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-lg-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .mx-lg-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .mx-lg-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .mx-lg-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .mx-lg-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .mx-lg-n72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .mx-lg-n80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .mx-lg-npx {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .my-lg-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-lg-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-lg-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-lg-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-lg-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-lg-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-lg-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-lg-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-lg-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-lg-n72 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-lg-n80 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-lg-npx {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mt-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -.75rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2rem !important;
  }

  .mt-lg-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n12 {
    margin-top: -3rem !important;
  }

  .mt-lg-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n16 {
    margin-top: -4rem !important;
  }

  .mt-lg-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-lg-n20 {
    margin-top: -5rem !important;
  }

  .mt-lg-n24 {
    margin-top: -6rem !important;
  }

  .mt-lg-n32 {
    margin-top: -8rem !important;
  }

  .mt-lg-n40 {
    margin-top: -10rem !important;
  }

  .mt-lg-n48 {
    margin-top: -12rem !important;
  }

  .mt-lg-n56 {
    margin-top: -14rem !important;
  }

  .mt-lg-n64 {
    margin-top: -16rem !important;
  }

  .mt-lg-n72 {
    margin-top: -18rem !important;
  }

  .mt-lg-n80 {
    margin-top: -20rem !important;
  }

  .mt-lg-npx {
    margin-top: -1px !important;
  }

  .me-lg-n1 {
    margin-right: -.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -.75rem !important;
  }

  .me-lg-n4 {
    margin-right: -1rem !important;
  }

  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }

  .me-lg-n8 {
    margin-right: -2rem !important;
  }

  .me-lg-n10 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n12 {
    margin-right: -3rem !important;
  }

  .me-lg-n14 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n16 {
    margin-right: -4rem !important;
  }

  .me-lg-n18 {
    margin-right: -4.5rem !important;
  }

  .me-lg-n20 {
    margin-right: -5rem !important;
  }

  .me-lg-n24 {
    margin-right: -6rem !important;
  }

  .me-lg-n32 {
    margin-right: -8rem !important;
  }

  .me-lg-n40 {
    margin-right: -10rem !important;
  }

  .me-lg-n48 {
    margin-right: -12rem !important;
  }

  .me-lg-n56 {
    margin-right: -14rem !important;
  }

  .me-lg-n64 {
    margin-right: -16rem !important;
  }

  .me-lg-n72 {
    margin-right: -18rem !important;
  }

  .me-lg-n80 {
    margin-right: -20rem !important;
  }

  .me-lg-npx {
    margin-right: -1px !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -.75rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-lg-n20 {
    margin-bottom: -5rem !important;
  }

  .mb-lg-n24 {
    margin-bottom: -6rem !important;
  }

  .mb-lg-n32 {
    margin-bottom: -8rem !important;
  }

  .mb-lg-n40 {
    margin-bottom: -10rem !important;
  }

  .mb-lg-n48 {
    margin-bottom: -12rem !important;
  }

  .mb-lg-n56 {
    margin-bottom: -14rem !important;
  }

  .mb-lg-n64 {
    margin-bottom: -16rem !important;
  }

  .mb-lg-n72 {
    margin-bottom: -18rem !important;
  }

  .mb-lg-n80 {
    margin-bottom: -20rem !important;
  }

  .mb-lg-npx {
    margin-bottom: -1px !important;
  }

  .ms-lg-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -.75rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1rem !important;
  }

  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-lg-n8 {
    margin-left: -2rem !important;
  }

  .ms-lg-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n12 {
    margin-left: -3rem !important;
  }

  .ms-lg-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n16 {
    margin-left: -4rem !important;
  }

  .ms-lg-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-lg-n20 {
    margin-left: -5rem !important;
  }

  .ms-lg-n24 {
    margin-left: -6rem !important;
  }

  .ms-lg-n32 {
    margin-left: -8rem !important;
  }

  .ms-lg-n40 {
    margin-left: -10rem !important;
  }

  .ms-lg-n48 {
    margin-left: -12rem !important;
  }

  .ms-lg-n56 {
    margin-left: -14rem !important;
  }

  .ms-lg-n64 {
    margin-left: -16rem !important;
  }

  .ms-lg-n72 {
    margin-left: -18rem !important;
  }

  .ms-lg-n80 {
    margin-left: -20rem !important;
  }

  .ms-lg-npx {
    margin-left: -1px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: .75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 1.75rem !important;
  }

  .p-lg-8 {
    padding: 2rem !important;
  }

  .p-lg-10 {
    padding: 2.5rem !important;
  }

  .p-lg-12 {
    padding: 3rem !important;
  }

  .p-lg-14 {
    padding: 3.5rem !important;
  }

  .p-lg-16 {
    padding: 4rem !important;
  }

  .p-lg-18 {
    padding: 4.5rem !important;
  }

  .p-lg-20 {
    padding: 5rem !important;
  }

  .p-lg-24 {
    padding: 6rem !important;
  }

  .p-lg-32 {
    padding: 8rem !important;
  }

  .p-lg-40 {
    padding: 10rem !important;
  }

  .p-lg-48 {
    padding: 12rem !important;
  }

  .p-lg-56 {
    padding: 14rem !important;
  }

  .p-lg-64 {
    padding: 16rem !important;
  }

  .p-lg-72 {
    padding: 18rem !important;
  }

  .p-lg-80 {
    padding: 20rem !important;
  }

  .p-lg-px {
    padding: 1px !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-lg-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-lg-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-lg-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-lg-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-lg-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-lg-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-lg-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-lg-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-lg-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-lg-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-lg-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .px-lg-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .px-lg-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .px-lg-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .px-lg-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .px-lg-72 {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .px-lg-80 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .px-lg-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-lg-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-lg-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-lg-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-lg-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-lg-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-lg-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-lg-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: .75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-8 {
    padding-top: 2rem !important;
  }

  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-12 {
    padding-top: 3rem !important;
  }

  .pt-lg-14 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-16 {
    padding-top: 4rem !important;
  }

  .pt-lg-18 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-20 {
    padding-top: 5rem !important;
  }

  .pt-lg-24 {
    padding-top: 6rem !important;
  }

  .pt-lg-32 {
    padding-top: 8rem !important;
  }

  .pt-lg-40 {
    padding-top: 10rem !important;
  }

  .pt-lg-48 {
    padding-top: 12rem !important;
  }

  .pt-lg-56 {
    padding-top: 14rem !important;
  }

  .pt-lg-64 {
    padding-top: 16rem !important;
  }

  .pt-lg-72 {
    padding-top: 18rem !important;
  }

  .pt-lg-80 {
    padding-top: 20rem !important;
  }

  .pt-lg-px {
    padding-top: 1px !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: .75rem !important;
  }

  .pe-lg-4 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }

  .pe-lg-8 {
    padding-right: 2rem !important;
  }

  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-12 {
    padding-right: 3rem !important;
  }

  .pe-lg-14 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-16 {
    padding-right: 4rem !important;
  }

  .pe-lg-18 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-20 {
    padding-right: 5rem !important;
  }

  .pe-lg-24 {
    padding-right: 6rem !important;
  }

  .pe-lg-32 {
    padding-right: 8rem !important;
  }

  .pe-lg-40 {
    padding-right: 10rem !important;
  }

  .pe-lg-48 {
    padding-right: 12rem !important;
  }

  .pe-lg-56 {
    padding-right: 14rem !important;
  }

  .pe-lg-64 {
    padding-right: 16rem !important;
  }

  .pe-lg-72 {
    padding-right: 18rem !important;
  }

  .pe-lg-80 {
    padding-right: 20rem !important;
  }

  .pe-lg-px {
    padding-right: 1px !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: .75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-24 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-32 {
    padding-bottom: 8rem !important;
  }

  .pb-lg-40 {
    padding-bottom: 10rem !important;
  }

  .pb-lg-48 {
    padding-bottom: 12rem !important;
  }

  .pb-lg-56 {
    padding-bottom: 14rem !important;
  }

  .pb-lg-64 {
    padding-bottom: 16rem !important;
  }

  .pb-lg-72 {
    padding-bottom: 18rem !important;
  }

  .pb-lg-80 {
    padding-bottom: 20rem !important;
  }

  .pb-lg-px {
    padding-bottom: 1px !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: .75rem !important;
  }

  .ps-lg-4 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }

  .ps-lg-8 {
    padding-left: 2rem !important;
  }

  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-12 {
    padding-left: 3rem !important;
  }

  .ps-lg-14 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-16 {
    padding-left: 4rem !important;
  }

  .ps-lg-18 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-20 {
    padding-left: 5rem !important;
  }

  .ps-lg-24 {
    padding-left: 6rem !important;
  }

  .ps-lg-32 {
    padding-left: 8rem !important;
  }

  .ps-lg-40 {
    padding-left: 10rem !important;
  }

  .ps-lg-48 {
    padding-left: 12rem !important;
  }

  .ps-lg-56 {
    padding-left: 14rem !important;
  }

  .ps-lg-64 {
    padding-left: 16rem !important;
  }

  .ps-lg-72 {
    padding-left: 18rem !important;
  }

  .ps-lg-80 {
    padding-left: 20rem !important;
  }

  .ps-lg-px {
    padding-left: 1px !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: .75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 1.75rem !important;
  }

  .gap-lg-8 {
    gap: 2rem !important;
  }

  .gap-lg-10 {
    gap: 2.5rem !important;
  }

  .gap-lg-12 {
    gap: 3rem !important;
  }

  .gap-lg-14 {
    gap: 3.5rem !important;
  }

  .gap-lg-16 {
    gap: 4rem !important;
  }

  .gap-lg-18 {
    gap: 4.5rem !important;
  }

  .gap-lg-20 {
    gap: 5rem !important;
  }

  .gap-lg-24 {
    gap: 6rem !important;
  }

  .gap-lg-32 {
    gap: 8rem !important;
  }

  .gap-lg-40 {
    gap: 10rem !important;
  }

  .gap-lg-48 {
    gap: 12rem !important;
  }

  .gap-lg-56 {
    gap: 14rem !important;
  }

  .gap-lg-64 {
    gap: 16rem !important;
  }

  .gap-lg-72 {
    gap: 18rem !important;
  }

  .gap-lg-80 {
    gap: 20rem !important;
  }

  .gap-lg-px {
    gap: 1px !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: .75rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-lg-8 {
    row-gap: 2rem !important;
  }

  .row-gap-lg-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-lg-12 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-lg-16 {
    row-gap: 4rem !important;
  }

  .row-gap-lg-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-lg-20 {
    row-gap: 5rem !important;
  }

  .row-gap-lg-24 {
    row-gap: 6rem !important;
  }

  .row-gap-lg-32 {
    row-gap: 8rem !important;
  }

  .row-gap-lg-40 {
    row-gap: 10rem !important;
  }

  .row-gap-lg-48 {
    row-gap: 12rem !important;
  }

  .row-gap-lg-56 {
    row-gap: 14rem !important;
  }

  .row-gap-lg-64 {
    row-gap: 16rem !important;
  }

  .row-gap-lg-72 {
    row-gap: 18rem !important;
  }

  .row-gap-lg-80 {
    row-gap: 20rem !important;
  }

  .row-gap-lg-px {
    row-gap: 1px !important;
  }

  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    -moz-column-gap: .75rem !important;
    column-gap: .75rem !important;
  }

  .column-gap-lg-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-lg-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }

  .column-gap-lg-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }

  .column-gap-lg-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-lg-10 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .column-gap-lg-12 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .column-gap-lg-14 {
    -moz-column-gap: 3.5rem !important;
    column-gap: 3.5rem !important;
  }

  .column-gap-lg-16 {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }

  .column-gap-lg-18 {
    -moz-column-gap: 4.5rem !important;
    column-gap: 4.5rem !important;
  }

  .column-gap-lg-20 {
    -moz-column-gap: 5rem !important;
    column-gap: 5rem !important;
  }

  .column-gap-lg-24 {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }

  .column-gap-lg-32 {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }

  .column-gap-lg-40 {
    -moz-column-gap: 10rem !important;
    column-gap: 10rem !important;
  }

  .column-gap-lg-48 {
    -moz-column-gap: 12rem !important;
    column-gap: 12rem !important;
  }

  .column-gap-lg-56 {
    -moz-column-gap: 14rem !important;
    column-gap: 14rem !important;
  }

  .column-gap-lg-64 {
    -moz-column-gap: 16rem !important;
    column-gap: 16rem !important;
  }

  .column-gap-lg-72 {
    -moz-column-gap: 18rem !important;
    column-gap: 18rem !important;
  }

  .column-gap-lg-80 {
    -moz-column-gap: 20rem !important;
    column-gap: 20rem !important;
  }

  .column-gap-lg-px {
    -moz-column-gap: 1px !important;
    column-gap: 1px !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .rounded-lg {
    border-radius: .375rem !important;
  }

  .rounded-lg-0 {
    border-radius: 0 !important;
  }

  .rounded-lg-1 {
    border-radius: .25rem !important;
  }

  .rounded-lg-2 {
    border-radius: .375rem !important;
  }

  .rounded-lg-3 {
    border-radius: .5rem !important;
  }

  .rounded-lg-4 {
    border-radius: 1rem !important;
  }

  .rounded-lg-5 {
    border-radius: 2rem !important;
  }

  .rounded-lg-pill {
    border-radius: 50rem !important;
  }

  .rounded-lg-circle {
    border-radius: 50% !important;
  }

  .rounded-lg-card {
    border-radius: var(--x-border-radius) !important;
  }

  .rounded-lg-inherit {
    border-radius: inherit !important;
  }

  .rounded-top-lg {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-lg-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-lg-1 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-lg-2 {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-lg-3 {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-lg-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-lg-5 {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important;
  }

  .rounded-top-lg-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-lg-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-top-lg-card {
    border-top-left-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-end-lg {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-lg-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-lg-1 {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-end-lg-2 {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-lg-3 {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-end-lg-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-end-lg-5 {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;
  }

  .rounded-end-lg-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-lg-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-end-lg-card {
    border-top-right-radius: var(--x-border-radius) !important;
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-lg {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-lg-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-lg-1 {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-lg-2 {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-lg-3 {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-lg-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-lg-5 {
    border-bottom-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-bottom-lg-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-lg-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-lg-card {
    border-bottom-right-radius: var(--x-border-radius) !important;
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .rounded-start-lg {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-lg-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-lg-1 {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-start-lg-2 {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-lg-3 {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-start-lg-4 {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-start-lg-5 {
    border-top-left-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-start-lg-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-lg-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-start-lg-card {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-top-start-lg {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-lg-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-top-start-lg-1 {
    border-top-left-radius: .25rem !important;
  }

  .rounded-top-start-lg-2 {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-lg-3 {
    border-top-left-radius: .5rem !important;
  }

  .rounded-top-start-lg-4 {
    border-top-left-radius: 1rem !important;
  }

  .rounded-top-start-lg-5 {
    border-top-left-radius: 2rem !important;
  }

  .rounded-top-start-lg-pill {
    border-top-left-radius: 50rem !important;
  }

  .rounded-top-start-lg-circle {
    border-top-left-radius: 50% !important;
  }

  .rounded-top-start-lg-card {
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-top-end-lg {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-lg-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-top-end-lg-1 {
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-end-lg-2 {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-lg-3 {
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-end-lg-4 {
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-end-lg-5 {
    border-top-right-radius: 2rem !important;
  }

  .rounded-top-end-lg-pill {
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-end-lg-circle {
    border-top-right-radius: 50% !important;
  }

  .rounded-top-end-lg-card {
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-end-lg {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-lg-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-end-lg-1 {
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-bottom-end-lg-2 {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-lg-3 {
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-bottom-end-lg-4 {
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-bottom-end-lg-5 {
    border-bottom-right-radius: 2rem !important;
  }

  .rounded-bottom-end-lg-pill {
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-end-lg-circle {
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-end-lg-card {
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-start-lg {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-lg-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-start-lg-1 {
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-start-lg-2 {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-lg-3 {
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-start-lg-4 {
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-start-lg-5 {
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-bottom-start-lg-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-start-lg-circle {
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-start-lg-card {
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .shadow-soft-lg-1, .shadow-soft-lg-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-lg-2, .shadow-soft-lg-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-lg-3, .shadow-soft-lg-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-lg-4, .shadow-soft-lg-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-lg-5, .shadow-soft-lg-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-lg-6, .shadow-soft-lg-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
  }

  .w-rem-lg-0 {
    width: 0 !important;
  }

  .w-rem-lg-1 {
    width: .25rem !important;
  }

  .w-rem-lg-2 {
    width: .5rem !important;
  }

  .w-rem-lg-3 {
    width: .75rem !important;
  }

  .w-rem-lg-4 {
    width: 1rem !important;
  }

  .w-rem-lg-5 {
    width: 1.25rem !important;
  }

  .w-rem-lg-6 {
    width: 1.5rem !important;
  }

  .w-rem-lg-7 {
    width: 1.75rem !important;
  }

  .w-rem-lg-8 {
    width: 2rem !important;
  }

  .w-rem-lg-10 {
    width: 2.5rem !important;
  }

  .w-rem-lg-12 {
    width: 3rem !important;
  }

  .w-rem-lg-14 {
    width: 3.5rem !important;
  }

  .w-rem-lg-16 {
    width: 4rem !important;
  }

  .w-rem-lg-18 {
    width: 4.5rem !important;
  }

  .w-rem-lg-20 {
    width: 5rem !important;
  }

  .w-rem-lg-24 {
    width: 6rem !important;
  }

  .w-rem-lg-32 {
    width: 8rem !important;
  }

  .w-rem-lg-40 {
    width: 10rem !important;
  }

  .w-rem-lg-48 {
    width: 12rem !important;
  }

  .w-rem-lg-56 {
    width: 14rem !important;
  }

  .w-rem-lg-64 {
    width: 16rem !important;
  }

  .w-rem-lg-72 {
    width: 18rem !important;
  }

  .w-rem-lg-80 {
    width: 20rem !important;
  }

  .w-rem-lg-px {
    width: 1px !important;
  }

  .h-rem-lg-0 {
    height: 0 !important;
  }

  .h-rem-lg-1 {
    height: .25rem !important;
  }

  .h-rem-lg-2 {
    height: .5rem !important;
  }

  .h-rem-lg-3 {
    height: .75rem !important;
  }

  .h-rem-lg-4 {
    height: 1rem !important;
  }

  .h-rem-lg-5 {
    height: 1.25rem !important;
  }

  .h-rem-lg-6 {
    height: 1.5rem !important;
  }

  .h-rem-lg-7 {
    height: 1.75rem !important;
  }

  .h-rem-lg-8 {
    height: 2rem !important;
  }

  .h-rem-lg-10 {
    height: 2.5rem !important;
  }

  .h-rem-lg-12 {
    height: 3rem !important;
  }

  .h-rem-lg-14 {
    height: 3.5rem !important;
  }

  .h-rem-lg-16 {
    height: 4rem !important;
  }

  .h-rem-lg-18 {
    height: 4.5rem !important;
  }

  .h-rem-lg-20 {
    height: 5rem !important;
  }

  .h-rem-lg-24 {
    height: 6rem !important;
  }

  .h-rem-lg-32 {
    height: 8rem !important;
  }

  .h-rem-lg-40 {
    height: 10rem !important;
  }

  .h-rem-lg-48 {
    height: 12rem !important;
  }

  .h-rem-lg-56 {
    height: 14rem !important;
  }

  .h-rem-lg-64 {
    height: 16rem !important;
  }

  .h-rem-lg-72 {
    height: 18rem !important;
  }

  .h-rem-lg-80 {
    height: 20rem !important;
  }

  .h-rem-lg-px {
    height: 1px !important;
  }

  .transition-lg {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .transition-lg-none {
    transition-property: none !important;
  }

  .transition-lg-all {
    transition-property: all !important;
  }

  .transition-lg-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .transition-lg-opacity {
    transition-property: opacity !important;
  }

  .transition-lg-shadow {
    transition-property: box-shadow !important;
  }

  .transition-lg-transform {
    transition-property: transform !important;
  }

  .ease-lg-base {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .ease-lg-linear {
    transition-timing-function: linear !important;
  }

  .ease-lg-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
  }

  .ease-lg-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
  }

  .ease-lg-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .transform-lg-none {
    transform: none !important;
  }

  .scale-lg-0, .scale-lg-0-hover:hover {
    --x-scale-x: 0 !important;
    --x-scale-y: 0 !important;
  }

  .scale-lg-25, .scale-lg-25-hover:hover {
    --x-scale-x: .25 !important;
    --x-scale-y: .25 !important;
  }

  .scale-lg-50, .scale-lg-50-hover:hover {
    --x-scale-x: .5 !important;
    --x-scale-y: .5 !important;
  }

  .scale-lg-75, .scale-lg-75-hover:hover {
    --x-scale-x: .75 !important;
    --x-scale-y: .75 !important;
  }

  .scale-lg-100, .scale-lg-100-hover:hover {
    --x-scale-x: 1 !important;
    --x-scale-y: 1 !important;
  }

  .scale-lg-105, .scale-lg-105-hover:hover {
    --x-scale-x: 1.05 !important;
    --x-scale-y: 1.05 !important;
  }

  .scale-lg-110, .scale-lg-110-hover:hover {
    --x-scale-x: 1.1 !important;
    --x-scale-y: 1.1 !important;
  }

  .scale-lg-125, .scale-lg-125-hover:hover {
    --x-scale-x: 1.25 !important;
    --x-scale-y: 1.25 !important;
  }

  .scale-lg-150, .scale-lg-150-hover:hover {
    --x-scale-x: 1.5 !important;
    --x-scale-y: 1.5 !important;
  }

  .rotate-lg-0, .rotate-lg-0-hover:hover {
    --x-rotate: 0deg !important;
  }

  .rotate-lg-1, .rotate-lg-1-hover:hover {
    --x-rotate: 1deg !important;
  }

  .rotate-lg-2, .rotate-lg-2-hover:hover {
    --x-rotate: 2deg !important;
  }

  .rotate-lg-3, .rotate-lg-3-hover:hover {
    --x-rotate: 3deg !important;
  }

  .rotate-lg-6, .rotate-lg-6-hover:hover {
    --x-rotate: 6deg !important;
  }

  .rotate-lg-12, .rotate-lg-12-hover:hover {
    --x-rotate: 12deg !important;
  }

  .rotate-lg-30, .rotate-lg-30-hover:hover {
    --x-rotate: 30deg !important;
  }

  .rotate-lg-45, .rotate-lg-45-hover:hover {
    --x-rotate: 45deg !important;
  }

  .rotate-lg-90, .rotate-lg-90-hover:hover {
    --x-rotate: 90deg !important;
  }

  .rotate-lg-180, .rotate-lg-180-hover:hover {
    --x-rotate: 180deg !important;
  }

  .rotate-lg-n1, .rotate-lg-n1-hover:hover {
    --x-rotate: -1deg !important;
  }

  .rotate-lg-n2, .rotate-lg-n2-hover:hover {
    --x-rotate: -2deg !important;
  }

  .rotate-lg-n3, .rotate-lg-n3-hover:hover {
    --x-rotate: -3deg !important;
  }

  .rotate-lg-n6, .rotate-lg-n6-hover:hover {
    --x-rotate: -6deg !important;
  }

  .rotate-lg-n12, .rotate-lg-n12-hover:hover {
    --x-rotate: -12deg !important;
  }

  .rotate-lg-n30, .rotate-lg-n30-hover:hover {
    --x-rotate: -30deg !important;
  }

  .rotate-lg-n45, .rotate-lg-n45-hover:hover {
    --x-rotate: -45deg !important;
  }

  .rotate-lg-n90, .rotate-lg-n90-hover:hover {
    --x-rotate: -90deg !important;
  }

  .rotate-lg-n180, .rotate-lg-n180-hover:hover {
    --x-rotate: -180deg !important;
  }

  .rotate-y-lg-n1, .rotate-y-lg-n1-hover:hover {
    --x-rotate-y: -1deg !important;
  }

  .rotate-y-lg-n2, .rotate-y-lg-n2-hover:hover {
    --x-rotate-y: -2deg !important;
  }

  .rotate-y-lg-n3, .rotate-y-lg-n3-hover:hover {
    --x-rotate-y: -3deg !important;
  }

  .rotate-y-lg-n6, .rotate-y-lg-n6-hover:hover {
    --x-rotate-y: -6deg !important;
  }

  .rotate-y-lg-n12, .rotate-y-lg-n12-hover:hover {
    --x-rotate-y: -12deg !important;
  }

  .rotate-y-lg-n30, .rotate-y-lg-n30-hover:hover {
    --x-rotate-y: -30deg !important;
  }

  .rotate-y-lg-n45, .rotate-y-lg-n45-hover:hover {
    --x-rotate-y: -45deg !important;
  }

  .rotate-y-lg-n90, .rotate-y-lg-n90-hover:hover {
    --x-rotate-y: -90deg !important;
  }

  .rotate-y-lg-n180, .rotate-y-lg-n180-hover:hover {
    --x-rotate-y: -180deg !important;
  }

  .rotate-x-lg-n1 {
    --x-rotate-x: -1deg !important;
  }

  .rotate-x-lg-n2 {
    --x-rotate-x: -2deg !important;
  }

  .rotate-x-lg-n3 {
    --x-rotate-x: -3deg !important;
  }

  .rotate-x-lg-n6 {
    --x-rotate-x: -6deg !important;
  }

  .rotate-x-lg-n12 {
    --x-rotate-x: -12deg !important;
  }

  .rotate-x-lg-n30 {
    --x-rotate-x: -30deg !important;
  }

  .rotate-x-lg-n45 {
    --x-rotate-x: -45deg !important;
  }

  .rotate-x-lg-n90 {
    --x-rotate-x: -90deg !important;
  }

  .rotate-x-lg-n180 {
    --x-rotate-x: -180deg !important;
  }

  .translate-y-lg-0, .translate-y-lg-0-hover:hover {
    --x-translate-y: 0 !important;
  }

  .translate-y-lg-25, .translate-y-lg-25-hover:hover {
    --x-translate-y: 25% !important;
  }

  .translate-y-lg-50, .translate-y-lg-50-hover:hover {
    --x-translate-y: 50% !important;
  }

  .translate-y-lg-75, .translate-y-lg-75-hover:hover {
    --x-translate-y: 75% !important;
  }

  .translate-y-lg-100, .translate-y-lg-100-hover:hover {
    --x-translate-y: 100% !important;
  }

  .translate-y-lg-n25, .translate-y-lg-n25-hover:hover {
    --x-translate-y: -25% !important;
  }

  .translate-y-lg-n50, .translate-y-lg-n50-hover:hover {
    --x-translate-y: -50% !important;
  }

  .translate-y-lg-n75, .translate-y-lg-n75-hover:hover {
    --x-translate-y: -75% !important;
  }

  .translate-y-lg-n100, .translate-y-lg-n100-hover:hover {
    --x-translate-y: -100% !important;
  }

  .translate-x-lg-0, .translate-x-lg-0-hover:hover {
    --x-translate-x: 0 !important;
  }

  .translate-x-lg-25, .translate-x-lg-25-hover:hover {
    --x-translate-x: 25% !important;
  }

  .translate-x-lg-50, .translate-x-lg-50-hover:hover {
    --x-translate-x: 50% !important;
  }

  .translate-x-lg-75, .translate-x-lg-75-hover:hover {
    --x-translate-x: 75% !important;
  }

  .translate-x-lg-100, .translate-x-lg-100-hover:hover {
    --x-translate-x: 100% !important;
  }

  .translate-x-lg-n25, .translate-x-lg-n25-hover:hover {
    --x-translate-x: -25% !important;
  }

  .translate-x-lg-n50, .translate-x-lg-n50-hover:hover {
    --x-translate-x: -50% !important;
  }

  .translate-x-lg-n75, .translate-x-lg-n75-hover:hover {
    --x-translate-x: -75% !important;
  }

  .translate-x-lg-n100, .translate-x-lg-n100-hover:hover {
    --x-translate-x: -100% !important;
  }

  .skew-y-lg-0 {
    --x-skew-y: 0deg !important;
  }

  .skew-y-lg-1 {
    --x-skew-y: 1deg !important;
  }

  .skew-y-lg-2 {
    --x-skew-y: 2deg !important;
  }

  .skew-y-lg-3 {
    --x-skew-y: 3deg !important;
  }

  .skew-y-lg-6 {
    --x-skew-y: 6deg !important;
  }

  .skew-y-lg-12 {
    --x-skew-y: 12deg !important;
  }

  .skew-x-lg-0 {
    --x-skew-x: 0deg !important;
  }

  .skew-x-lg-1 {
    --x-skew-x: 1deg !important;
  }

  .skew-x-lg-2 {
    --x-skew-x: 2deg !important;
  }

  .skew-x-lg-3 {
    --x-skew-x: 3deg !important;
  }

  .skew-x-lg-6 {
    --x-skew-x: 6deg !important;
  }

  .skew-x-lg-12 {
    --x-skew-x: 12deg !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .overflow-xl-auto {
    overflow: auto !important;
  }

  .overflow-xl-hidden {
    overflow: hidden !important;
  }

  .overflow-xl-visible {
    overflow: visible !important;
  }

  .overflow-xl-scroll {
    overflow: scroll !important;
  }

  .overflow-x-xl-auto {
    overflow-x: auto !important;
  }

  .overflow-x-xl-hidden {
    overflow-x: hidden !important;
  }

  .overflow-x-xl-visible {
    overflow-x: visible !important;
  }

  .overflow-x-xl-scroll {
    overflow-x: scroll !important;
  }

  .overflow-y-xl-auto {
    overflow-y: auto !important;
  }

  .overflow-y-xl-hidden {
    overflow-y: hidden !important;
  }

  .overflow-y-xl-visible {
    overflow-y: visible !important;
  }

  .overflow-y-xl-scroll {
    overflow-y: scroll !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .shadow-xl, .shadow-xl-hover:hover {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-1, .shadow-xl-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-2, .shadow-xl-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-3, .shadow-xl-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-4, .shadow-xl-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-5, .shadow-xl-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-6, .shadow-xl-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xl-inset, .shadow-xl-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
  }

  .shadow-xl-none, .shadow-xl-none-hover:hover {
    box-shadow: none !important;
  }

  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-xl-0 {
    top: 0 !important;
  }

  .top-xl-50 {
    top: 50% !important;
  }

  .top-xl-100 {
    top: 100% !important;
  }

  .top-xl-1 {
    top: .25rem !important;
  }

  .top-xl-2 {
    top: .5rem !important;
  }

  .top-xl-3 {
    top: .75rem !important;
  }

  .top-xl-4 {
    top: 1rem !important;
  }

  .top-xl-5 {
    top: 1.25rem !important;
  }

  .top-xl-6 {
    top: 1.5rem !important;
  }

  .top-xl-7 {
    top: 1.75rem !important;
  }

  .top-xl-8 {
    top: 2rem !important;
  }

  .top-xl-10 {
    top: 2.5rem !important;
  }

  .top-xl-12 {
    top: 3rem !important;
  }

  .top-xl-14 {
    top: 3.5rem !important;
  }

  .top-xl-16 {
    top: 4rem !important;
  }

  .top-xl-18 {
    top: 4.5rem !important;
  }

  .top-xl-20 {
    top: 5rem !important;
  }

  .top-xl-24 {
    top: 6rem !important;
  }

  .top-xl-32 {
    top: 8rem !important;
  }

  .top-xl-40 {
    top: 10rem !important;
  }

  .top-xl-48 {
    top: 12rem !important;
  }

  .top-xl-56 {
    top: 14rem !important;
  }

  .top-xl-64 {
    top: 16rem !important;
  }

  .top-xl-72 {
    top: 18rem !important;
  }

  .top-xl-80 {
    top: 20rem !important;
  }

  .top-xl-px {
    top: 1px !important;
  }

  .bottom-xl-0 {
    bottom: 0 !important;
  }

  .bottom-xl-50 {
    bottom: 50% !important;
  }

  .bottom-xl-100 {
    bottom: 100% !important;
  }

  .start-xl-0 {
    left: 0 !important;
  }

  .start-xl-50 {
    left: 50% !important;
  }

  .start-xl-100 {
    left: 100% !important;
  }

  .end-xl-0 {
    right: 0 !important;
  }

  .end-xl-50 {
    right: 50% !important;
  }

  .end-xl-100 {
    right: 100% !important;
  }

  .border-xl {
    border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-xl-0 {
    border: 0 !important;
  }

  .border-top-xl {
    border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-top-xl-0 {
    border-top: 0 !important;
  }

  .border-end-xl {
    border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-end-xl-0 {
    border-right: 0 !important;
  }

  .border-bottom-xl {
    border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }

  .border-start-xl {
    border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-start-xl-0 {
    border-left: 0 !important;
  }

  .border-xl-primary, .border-xl-primary-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-primary-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-secondary, .border-xl-secondary-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-secondary-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-success, .border-xl-success-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-success-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-info, .border-xl-info-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-info-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-warning, .border-xl-warning-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-warning-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-danger, .border-xl-danger-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-danger-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-light, .border-xl-light-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-light-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-dark, .border-xl-dark-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-dark-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-black, .border-xl-black-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-black-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-white, .border-xl-white-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-white-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-gray-50, .border-xl-gray-50-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-50-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-gray-100, .border-xl-gray-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-gray-200, .border-xl-gray-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-gray-300, .border-xl-gray-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-gray-400, .border-xl-gray-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-gray-500, .border-xl-gray-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-gray-600, .border-xl-gray-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-gray-700, .border-xl-gray-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-gray-800, .border-xl-gray-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-gray-900, .border-xl-gray-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-gray-950, .border-xl-gray-950-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-950-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-blue-100, .border-xl-blue-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-blue-200, .border-xl-blue-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-blue-300, .border-xl-blue-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-blue-400, .border-xl-blue-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-blue-500, .border-xl-blue-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-blue-600, .border-xl-blue-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-blue-700, .border-xl-blue-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-blue-800, .border-xl-blue-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-blue-900, .border-xl-blue-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-indigo-100, .border-xl-indigo-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-indigo-200, .border-xl-indigo-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-indigo-300, .border-xl-indigo-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-indigo-400, .border-xl-indigo-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-indigo-500, .border-xl-indigo-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-indigo-600, .border-xl-indigo-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-indigo-700, .border-xl-indigo-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-indigo-800, .border-xl-indigo-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-indigo-900, .border-xl-indigo-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-purple-100, .border-xl-purple-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-purple-200, .border-xl-purple-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-purple-300, .border-xl-purple-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-purple-400, .border-xl-purple-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-purple-500, .border-xl-purple-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-purple-600, .border-xl-purple-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-purple-700, .border-xl-purple-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-purple-800, .border-xl-purple-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-purple-900, .border-xl-purple-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-pink-100, .border-xl-pink-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-pink-200, .border-xl-pink-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-pink-300, .border-xl-pink-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-pink-400, .border-xl-pink-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-pink-500, .border-xl-pink-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-pink-600, .border-xl-pink-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-pink-700, .border-xl-pink-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-pink-800, .border-xl-pink-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-pink-900, .border-xl-pink-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-red-100, .border-xl-red-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-red-200, .border-xl-red-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-red-300, .border-xl-red-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-red-400, .border-xl-red-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-red-500, .border-xl-red-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-red-600, .border-xl-red-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-red-700, .border-xl-red-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-red-800, .border-xl-red-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-red-900, .border-xl-red-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-orange-100, .border-xl-orange-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-orange-200, .border-xl-orange-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-orange-300, .border-xl-orange-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-orange-400, .border-xl-orange-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-orange-500, .border-xl-orange-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-orange-600, .border-xl-orange-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-orange-700, .border-xl-orange-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-orange-800, .border-xl-orange-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-orange-900, .border-xl-orange-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-yellow-100, .border-xl-yellow-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-yellow-200, .border-xl-yellow-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-yellow-300, .border-xl-yellow-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-yellow-400, .border-xl-yellow-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-yellow-500, .border-xl-yellow-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-yellow-600, .border-xl-yellow-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-yellow-700, .border-xl-yellow-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-yellow-800, .border-xl-yellow-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-yellow-900, .border-xl-yellow-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-green-100, .border-xl-green-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-green-200, .border-xl-green-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-green-300, .border-xl-green-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-green-400, .border-xl-green-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-green-500, .border-xl-green-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-green-600, .border-xl-green-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-green-700, .border-xl-green-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-green-800, .border-xl-green-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-green-900, .border-xl-green-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-teal-100, .border-xl-teal-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-teal-200, .border-xl-teal-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-teal-300, .border-xl-teal-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-teal-400, .border-xl-teal-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-teal-500, .border-xl-teal-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-teal-600, .border-xl-teal-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-teal-700, .border-xl-teal-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-teal-800, .border-xl-teal-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-teal-900, .border-xl-teal-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-cyan-100, .border-xl-cyan-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-cyan-200, .border-xl-cyan-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-cyan-300, .border-xl-cyan-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-cyan-400, .border-xl-cyan-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-cyan-500, .border-xl-cyan-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-cyan-600, .border-xl-cyan-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-cyan-700, .border-xl-cyan-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-cyan-800, .border-xl-cyan-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xl-cyan-900, .border-xl-cyan-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-900-rgb), var(--x-border-opacity)) !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .mw-xl-screen-sm {
    max-width: 640px !important;
  }

  .mw-xl-screen-md {
    max-width: 768px !important;
  }

  .mw-xl-screen-lg {
    max-width: 1024px !important;
  }

  .mw-xl-screen-xl {
    max-width: 1280px !important;
  }

  .mw-xl-screen-xxl {
    max-width: 1536px !important;
  }

  .mw-xl-0 {
    max-width: 0 !important;
  }

  .mw-xl-100 {
    max-width: 100% !important;
  }

  .mw-xl-read {
    max-width: 65ch !important;
  }

  .mw-xl-fit {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .vh-xl-100 {
    height: 100vh !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-initial {
    flex: 0 auto !important;
  }

  .flex-xl-none {
    flex: none !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: .75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 1.75rem !important;
  }

  .m-xl-8 {
    margin: 2rem !important;
  }

  .m-xl-10 {
    margin: 2.5rem !important;
  }

  .m-xl-12 {
    margin: 3rem !important;
  }

  .m-xl-14 {
    margin: 3.5rem !important;
  }

  .m-xl-16 {
    margin: 4rem !important;
  }

  .m-xl-18 {
    margin: 4.5rem !important;
  }

  .m-xl-20 {
    margin: 5rem !important;
  }

  .m-xl-24 {
    margin: 6rem !important;
  }

  .m-xl-32 {
    margin: 8rem !important;
  }

  .m-xl-40 {
    margin: 10rem !important;
  }

  .m-xl-48 {
    margin: 12rem !important;
  }

  .m-xl-56 {
    margin: 14rem !important;
  }

  .m-xl-64 {
    margin: 16rem !important;
  }

  .m-xl-72 {
    margin: 18rem !important;
  }

  .m-xl-80 {
    margin: 20rem !important;
  }

  .m-xl-px {
    margin: 1px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-xl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-xl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-xl-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xl-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-xl-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-xl-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xl-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-xl-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-xl-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .mx-xl-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mx-xl-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .mx-xl-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .mx-xl-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .mx-xl-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .mx-xl-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mx-xl-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xl-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xl-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xl-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-xl-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-xl-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-xl-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-xl-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: .75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-8 {
    margin-top: 2rem !important;
  }

  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-12 {
    margin-top: 3rem !important;
  }

  .mt-xl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-16 {
    margin-top: 4rem !important;
  }

  .mt-xl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-20 {
    margin-top: 5rem !important;
  }

  .mt-xl-24 {
    margin-top: 6rem !important;
  }

  .mt-xl-32 {
    margin-top: 8rem !important;
  }

  .mt-xl-40 {
    margin-top: 10rem !important;
  }

  .mt-xl-48 {
    margin-top: 12rem !important;
  }

  .mt-xl-56 {
    margin-top: 14rem !important;
  }

  .mt-xl-64 {
    margin-top: 16rem !important;
  }

  .mt-xl-72 {
    margin-top: 18rem !important;
  }

  .mt-xl-80 {
    margin-top: 20rem !important;
  }

  .mt-xl-px {
    margin-top: 1px !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: .75rem !important;
  }

  .me-xl-4 {
    margin-right: 1rem !important;
  }

  .me-xl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xl-8 {
    margin-right: 2rem !important;
  }

  .me-xl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xl-12 {
    margin-right: 3rem !important;
  }

  .me-xl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xl-16 {
    margin-right: 4rem !important;
  }

  .me-xl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xl-20 {
    margin-right: 5rem !important;
  }

  .me-xl-24 {
    margin-right: 6rem !important;
  }

  .me-xl-32 {
    margin-right: 8rem !important;
  }

  .me-xl-40 {
    margin-right: 10rem !important;
  }

  .me-xl-48 {
    margin-right: 12rem !important;
  }

  .me-xl-56 {
    margin-right: 14rem !important;
  }

  .me-xl-64 {
    margin-right: 16rem !important;
  }

  .me-xl-72 {
    margin-right: 18rem !important;
  }

  .me-xl-80 {
    margin-right: 20rem !important;
  }

  .me-xl-px {
    margin-right: 1px !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: .75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-24 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-32 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-40 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-48 {
    margin-bottom: 12rem !important;
  }

  .mb-xl-56 {
    margin-bottom: 14rem !important;
  }

  .mb-xl-64 {
    margin-bottom: 16rem !important;
  }

  .mb-xl-72 {
    margin-bottom: 18rem !important;
  }

  .mb-xl-80 {
    margin-bottom: 20rem !important;
  }

  .mb-xl-px {
    margin-bottom: 1px !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: .75rem !important;
  }

  .ms-xl-4 {
    margin-left: 1rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xl-8 {
    margin-left: 2rem !important;
  }

  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-12 {
    margin-left: 3rem !important;
  }

  .ms-xl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-16 {
    margin-left: 4rem !important;
  }

  .ms-xl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-20 {
    margin-left: 5rem !important;
  }

  .ms-xl-24 {
    margin-left: 6rem !important;
  }

  .ms-xl-32 {
    margin-left: 8rem !important;
  }

  .ms-xl-40 {
    margin-left: 10rem !important;
  }

  .ms-xl-48 {
    margin-left: 12rem !important;
  }

  .ms-xl-56 {
    margin-left: 14rem !important;
  }

  .ms-xl-64 {
    margin-left: 16rem !important;
  }

  .ms-xl-72 {
    margin-left: 18rem !important;
  }

  .ms-xl-80 {
    margin-left: 20rem !important;
  }

  .ms-xl-px {
    margin-left: 1px !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.25rem !important;
  }

  .m-xl-n6 {
    margin: -1.5rem !important;
  }

  .m-xl-n7 {
    margin: -1.75rem !important;
  }

  .m-xl-n8 {
    margin: -2rem !important;
  }

  .m-xl-n10 {
    margin: -2.5rem !important;
  }

  .m-xl-n12 {
    margin: -3rem !important;
  }

  .m-xl-n14 {
    margin: -3.5rem !important;
  }

  .m-xl-n16 {
    margin: -4rem !important;
  }

  .m-xl-n18 {
    margin: -4.5rem !important;
  }

  .m-xl-n20 {
    margin: -5rem !important;
  }

  .m-xl-n24 {
    margin: -6rem !important;
  }

  .m-xl-n32 {
    margin: -8rem !important;
  }

  .m-xl-n40 {
    margin: -10rem !important;
  }

  .m-xl-n48 {
    margin: -12rem !important;
  }

  .m-xl-n56 {
    margin: -14rem !important;
  }

  .m-xl-n64 {
    margin: -16rem !important;
  }

  .m-xl-n72 {
    margin: -18rem !important;
  }

  .m-xl-n80 {
    margin: -20rem !important;
  }

  .m-xl-npx {
    margin: -1px !important;
  }

  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-xl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-xl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-xl-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-xl-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xl-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-xl-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-xl-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-xl-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-xl-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-xl-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .mx-xl-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .mx-xl-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .mx-xl-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .mx-xl-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .mx-xl-n72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .mx-xl-n80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .mx-xl-npx {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .my-xl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xl-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xl-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xl-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xl-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-xl-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-xl-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-xl-n72 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-xl-n80 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-xl-npx {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mt-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -.75rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xl-n20 {
    margin-top: -5rem !important;
  }

  .mt-xl-n24 {
    margin-top: -6rem !important;
  }

  .mt-xl-n32 {
    margin-top: -8rem !important;
  }

  .mt-xl-n40 {
    margin-top: -10rem !important;
  }

  .mt-xl-n48 {
    margin-top: -12rem !important;
  }

  .mt-xl-n56 {
    margin-top: -14rem !important;
  }

  .mt-xl-n64 {
    margin-top: -16rem !important;
  }

  .mt-xl-n72 {
    margin-top: -18rem !important;
  }

  .mt-xl-n80 {
    margin-top: -20rem !important;
  }

  .mt-xl-npx {
    margin-top: -1px !important;
  }

  .me-xl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -.75rem !important;
  }

  .me-xl-n4 {
    margin-right: -1rem !important;
  }

  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xl-n8 {
    margin-right: -2rem !important;
  }

  .me-xl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n12 {
    margin-right: -3rem !important;
  }

  .me-xl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n16 {
    margin-right: -4rem !important;
  }

  .me-xl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xl-n20 {
    margin-right: -5rem !important;
  }

  .me-xl-n24 {
    margin-right: -6rem !important;
  }

  .me-xl-n32 {
    margin-right: -8rem !important;
  }

  .me-xl-n40 {
    margin-right: -10rem !important;
  }

  .me-xl-n48 {
    margin-right: -12rem !important;
  }

  .me-xl-n56 {
    margin-right: -14rem !important;
  }

  .me-xl-n64 {
    margin-right: -16rem !important;
  }

  .me-xl-n72 {
    margin-right: -18rem !important;
  }

  .me-xl-n80 {
    margin-right: -20rem !important;
  }

  .me-xl-npx {
    margin-right: -1px !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -.75rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xl-n20 {
    margin-bottom: -5rem !important;
  }

  .mb-xl-n24 {
    margin-bottom: -6rem !important;
  }

  .mb-xl-n32 {
    margin-bottom: -8rem !important;
  }

  .mb-xl-n40 {
    margin-bottom: -10rem !important;
  }

  .mb-xl-n48 {
    margin-bottom: -12rem !important;
  }

  .mb-xl-n56 {
    margin-bottom: -14rem !important;
  }

  .mb-xl-n64 {
    margin-bottom: -16rem !important;
  }

  .mb-xl-n72 {
    margin-bottom: -18rem !important;
  }

  .mb-xl-n80 {
    margin-bottom: -20rem !important;
  }

  .mb-xl-npx {
    margin-bottom: -1px !important;
  }

  .ms-xl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -.75rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xl-n20 {
    margin-left: -5rem !important;
  }

  .ms-xl-n24 {
    margin-left: -6rem !important;
  }

  .ms-xl-n32 {
    margin-left: -8rem !important;
  }

  .ms-xl-n40 {
    margin-left: -10rem !important;
  }

  .ms-xl-n48 {
    margin-left: -12rem !important;
  }

  .ms-xl-n56 {
    margin-left: -14rem !important;
  }

  .ms-xl-n64 {
    margin-left: -16rem !important;
  }

  .ms-xl-n72 {
    margin-left: -18rem !important;
  }

  .ms-xl-n80 {
    margin-left: -20rem !important;
  }

  .ms-xl-npx {
    margin-left: -1px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: .75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 1.75rem !important;
  }

  .p-xl-8 {
    padding: 2rem !important;
  }

  .p-xl-10 {
    padding: 2.5rem !important;
  }

  .p-xl-12 {
    padding: 3rem !important;
  }

  .p-xl-14 {
    padding: 3.5rem !important;
  }

  .p-xl-16 {
    padding: 4rem !important;
  }

  .p-xl-18 {
    padding: 4.5rem !important;
  }

  .p-xl-20 {
    padding: 5rem !important;
  }

  .p-xl-24 {
    padding: 6rem !important;
  }

  .p-xl-32 {
    padding: 8rem !important;
  }

  .p-xl-40 {
    padding: 10rem !important;
  }

  .p-xl-48 {
    padding: 12rem !important;
  }

  .p-xl-56 {
    padding: 14rem !important;
  }

  .p-xl-64 {
    padding: 16rem !important;
  }

  .p-xl-72 {
    padding: 18rem !important;
  }

  .p-xl-80 {
    padding: 20rem !important;
  }

  .p-xl-px {
    padding: 1px !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-xl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-xl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-xl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-xl-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-xl-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xl-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-xl-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-xl-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-xl-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-xl-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-xl-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .px-xl-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .px-xl-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .px-xl-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .px-xl-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .px-xl-72 {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .px-xl-80 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .px-xl-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xl-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xl-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-xl-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-xl-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-xl-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-xl-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-xl-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: .75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-8 {
    padding-top: 2rem !important;
  }

  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-12 {
    padding-top: 3rem !important;
  }

  .pt-xl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-16 {
    padding-top: 4rem !important;
  }

  .pt-xl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-20 {
    padding-top: 5rem !important;
  }

  .pt-xl-24 {
    padding-top: 6rem !important;
  }

  .pt-xl-32 {
    padding-top: 8rem !important;
  }

  .pt-xl-40 {
    padding-top: 10rem !important;
  }

  .pt-xl-48 {
    padding-top: 12rem !important;
  }

  .pt-xl-56 {
    padding-top: 14rem !important;
  }

  .pt-xl-64 {
    padding-top: 16rem !important;
  }

  .pt-xl-72 {
    padding-top: 18rem !important;
  }

  .pt-xl-80 {
    padding-top: 20rem !important;
  }

  .pt-xl-px {
    padding-top: 1px !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: .75rem !important;
  }

  .pe-xl-4 {
    padding-right: 1rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xl-8 {
    padding-right: 2rem !important;
  }

  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-12 {
    padding-right: 3rem !important;
  }

  .pe-xl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-16 {
    padding-right: 4rem !important;
  }

  .pe-xl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-20 {
    padding-right: 5rem !important;
  }

  .pe-xl-24 {
    padding-right: 6rem !important;
  }

  .pe-xl-32 {
    padding-right: 8rem !important;
  }

  .pe-xl-40 {
    padding-right: 10rem !important;
  }

  .pe-xl-48 {
    padding-right: 12rem !important;
  }

  .pe-xl-56 {
    padding-right: 14rem !important;
  }

  .pe-xl-64 {
    padding-right: 16rem !important;
  }

  .pe-xl-72 {
    padding-right: 18rem !important;
  }

  .pe-xl-80 {
    padding-right: 20rem !important;
  }

  .pe-xl-px {
    padding-right: 1px !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: .75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-24 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-32 {
    padding-bottom: 8rem !important;
  }

  .pb-xl-40 {
    padding-bottom: 10rem !important;
  }

  .pb-xl-48 {
    padding-bottom: 12rem !important;
  }

  .pb-xl-56 {
    padding-bottom: 14rem !important;
  }

  .pb-xl-64 {
    padding-bottom: 16rem !important;
  }

  .pb-xl-72 {
    padding-bottom: 18rem !important;
  }

  .pb-xl-80 {
    padding-bottom: 20rem !important;
  }

  .pb-xl-px {
    padding-bottom: 1px !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: .75rem !important;
  }

  .ps-xl-4 {
    padding-left: 1rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xl-8 {
    padding-left: 2rem !important;
  }

  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-12 {
    padding-left: 3rem !important;
  }

  .ps-xl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-16 {
    padding-left: 4rem !important;
  }

  .ps-xl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-20 {
    padding-left: 5rem !important;
  }

  .ps-xl-24 {
    padding-left: 6rem !important;
  }

  .ps-xl-32 {
    padding-left: 8rem !important;
  }

  .ps-xl-40 {
    padding-left: 10rem !important;
  }

  .ps-xl-48 {
    padding-left: 12rem !important;
  }

  .ps-xl-56 {
    padding-left: 14rem !important;
  }

  .ps-xl-64 {
    padding-left: 16rem !important;
  }

  .ps-xl-72 {
    padding-left: 18rem !important;
  }

  .ps-xl-80 {
    padding-left: 20rem !important;
  }

  .ps-xl-px {
    padding-left: 1px !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: .75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 1.75rem !important;
  }

  .gap-xl-8 {
    gap: 2rem !important;
  }

  .gap-xl-10 {
    gap: 2.5rem !important;
  }

  .gap-xl-12 {
    gap: 3rem !important;
  }

  .gap-xl-14 {
    gap: 3.5rem !important;
  }

  .gap-xl-16 {
    gap: 4rem !important;
  }

  .gap-xl-18 {
    gap: 4.5rem !important;
  }

  .gap-xl-20 {
    gap: 5rem !important;
  }

  .gap-xl-24 {
    gap: 6rem !important;
  }

  .gap-xl-32 {
    gap: 8rem !important;
  }

  .gap-xl-40 {
    gap: 10rem !important;
  }

  .gap-xl-48 {
    gap: 12rem !important;
  }

  .gap-xl-56 {
    gap: 14rem !important;
  }

  .gap-xl-64 {
    gap: 16rem !important;
  }

  .gap-xl-72 {
    gap: 18rem !important;
  }

  .gap-xl-80 {
    gap: 20rem !important;
  }

  .gap-xl-px {
    gap: 1px !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: .75rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xl-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xl-12 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xl-16 {
    row-gap: 4rem !important;
  }

  .row-gap-xl-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xl-20 {
    row-gap: 5rem !important;
  }

  .row-gap-xl-24 {
    row-gap: 6rem !important;
  }

  .row-gap-xl-32 {
    row-gap: 8rem !important;
  }

  .row-gap-xl-40 {
    row-gap: 10rem !important;
  }

  .row-gap-xl-48 {
    row-gap: 12rem !important;
  }

  .row-gap-xl-56 {
    row-gap: 14rem !important;
  }

  .row-gap-xl-64 {
    row-gap: 16rem !important;
  }

  .row-gap-xl-72 {
    row-gap: 18rem !important;
  }

  .row-gap-xl-80 {
    row-gap: 20rem !important;
  }

  .row-gap-xl-px {
    row-gap: 1px !important;
  }

  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    -moz-column-gap: .75rem !important;
    column-gap: .75rem !important;
  }

  .column-gap-xl-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xl-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }

  .column-gap-xl-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }

  .column-gap-xl-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-xl-10 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .column-gap-xl-12 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .column-gap-xl-14 {
    -moz-column-gap: 3.5rem !important;
    column-gap: 3.5rem !important;
  }

  .column-gap-xl-16 {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }

  .column-gap-xl-18 {
    -moz-column-gap: 4.5rem !important;
    column-gap: 4.5rem !important;
  }

  .column-gap-xl-20 {
    -moz-column-gap: 5rem !important;
    column-gap: 5rem !important;
  }

  .column-gap-xl-24 {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }

  .column-gap-xl-32 {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }

  .column-gap-xl-40 {
    -moz-column-gap: 10rem !important;
    column-gap: 10rem !important;
  }

  .column-gap-xl-48 {
    -moz-column-gap: 12rem !important;
    column-gap: 12rem !important;
  }

  .column-gap-xl-56 {
    -moz-column-gap: 14rem !important;
    column-gap: 14rem !important;
  }

  .column-gap-xl-64 {
    -moz-column-gap: 16rem !important;
    column-gap: 16rem !important;
  }

  .column-gap-xl-72 {
    -moz-column-gap: 18rem !important;
    column-gap: 18rem !important;
  }

  .column-gap-xl-80 {
    -moz-column-gap: 20rem !important;
    column-gap: 20rem !important;
  }

  .column-gap-xl-px {
    -moz-column-gap: 1px !important;
    column-gap: 1px !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .rounded-xl {
    border-radius: .375rem !important;
  }

  .rounded-xl-0 {
    border-radius: 0 !important;
  }

  .rounded-xl-1 {
    border-radius: .25rem !important;
  }

  .rounded-xl-2 {
    border-radius: .375rem !important;
  }

  .rounded-xl-3 {
    border-radius: .5rem !important;
  }

  .rounded-xl-4 {
    border-radius: 1rem !important;
  }

  .rounded-xl-5 {
    border-radius: 2rem !important;
  }

  .rounded-xl-pill {
    border-radius: 50rem !important;
  }

  .rounded-xl-circle {
    border-radius: 50% !important;
  }

  .rounded-xl-card {
    border-radius: var(--x-border-radius) !important;
  }

  .rounded-xl-inherit {
    border-radius: inherit !important;
  }

  .rounded-top-xl {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-xl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-xl-1 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-xl-2 {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-xl-3 {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-xl-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-xl-5 {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important;
  }

  .rounded-top-xl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-xl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-top-xl-card {
    border-top-left-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-end-xl {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-xl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-xl-1 {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-end-xl-2 {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-xl-3 {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-end-xl-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-end-xl-5 {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;
  }

  .rounded-end-xl-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-xl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-end-xl-card {
    border-top-right-radius: var(--x-border-radius) !important;
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-xl {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-xl-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-xl-1 {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-xl-2 {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-xl-3 {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-xl-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-xl-5 {
    border-bottom-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-bottom-xl-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-xl-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-xl-card {
    border-bottom-right-radius: var(--x-border-radius) !important;
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .rounded-start-xl {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-xl-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-xl-1 {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-start-xl-2 {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-xl-3 {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-start-xl-4 {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-start-xl-5 {
    border-top-left-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-start-xl-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-xl-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-start-xl-card {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-top-start-xl {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-xl-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-top-start-xl-1 {
    border-top-left-radius: .25rem !important;
  }

  .rounded-top-start-xl-2 {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-xl-3 {
    border-top-left-radius: .5rem !important;
  }

  .rounded-top-start-xl-4 {
    border-top-left-radius: 1rem !important;
  }

  .rounded-top-start-xl-5 {
    border-top-left-radius: 2rem !important;
  }

  .rounded-top-start-xl-pill {
    border-top-left-radius: 50rem !important;
  }

  .rounded-top-start-xl-circle {
    border-top-left-radius: 50% !important;
  }

  .rounded-top-start-xl-card {
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-top-end-xl {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-xl-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-top-end-xl-1 {
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-end-xl-2 {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-xl-3 {
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-end-xl-4 {
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-end-xl-5 {
    border-top-right-radius: 2rem !important;
  }

  .rounded-top-end-xl-pill {
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-end-xl-circle {
    border-top-right-radius: 50% !important;
  }

  .rounded-top-end-xl-card {
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-end-xl {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-xl-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-end-xl-1 {
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-bottom-end-xl-2 {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-xl-3 {
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-bottom-end-xl-4 {
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-bottom-end-xl-5 {
    border-bottom-right-radius: 2rem !important;
  }

  .rounded-bottom-end-xl-pill {
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-end-xl-circle {
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-end-xl-card {
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-start-xl {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-xl-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-start-xl-1 {
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-start-xl-2 {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-xl-3 {
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-start-xl-4 {
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-start-xl-5 {
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-bottom-start-xl-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-start-xl-circle {
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-start-xl-card {
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .shadow-soft-xl-1, .shadow-soft-xl-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xl-2, .shadow-soft-xl-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xl-3, .shadow-soft-xl-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xl-4, .shadow-soft-xl-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xl-5, .shadow-soft-xl-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xl-6, .shadow-soft-xl-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
  }

  .w-rem-xl-0 {
    width: 0 !important;
  }

  .w-rem-xl-1 {
    width: .25rem !important;
  }

  .w-rem-xl-2 {
    width: .5rem !important;
  }

  .w-rem-xl-3 {
    width: .75rem !important;
  }

  .w-rem-xl-4 {
    width: 1rem !important;
  }

  .w-rem-xl-5 {
    width: 1.25rem !important;
  }

  .w-rem-xl-6 {
    width: 1.5rem !important;
  }

  .w-rem-xl-7 {
    width: 1.75rem !important;
  }

  .w-rem-xl-8 {
    width: 2rem !important;
  }

  .w-rem-xl-10 {
    width: 2.5rem !important;
  }

  .w-rem-xl-12 {
    width: 3rem !important;
  }

  .w-rem-xl-14 {
    width: 3.5rem !important;
  }

  .w-rem-xl-16 {
    width: 4rem !important;
  }

  .w-rem-xl-18 {
    width: 4.5rem !important;
  }

  .w-rem-xl-20 {
    width: 5rem !important;
  }

  .w-rem-xl-24 {
    width: 6rem !important;
  }

  .w-rem-xl-32 {
    width: 8rem !important;
  }

  .w-rem-xl-40 {
    width: 10rem !important;
  }

  .w-rem-xl-48 {
    width: 12rem !important;
  }

  .w-rem-xl-56 {
    width: 14rem !important;
  }

  .w-rem-xl-64 {
    width: 16rem !important;
  }

  .w-rem-xl-72 {
    width: 18rem !important;
  }

  .w-rem-xl-80 {
    width: 20rem !important;
  }

  .w-rem-xl-px {
    width: 1px !important;
  }

  .h-rem-xl-0 {
    height: 0 !important;
  }

  .h-rem-xl-1 {
    height: .25rem !important;
  }

  .h-rem-xl-2 {
    height: .5rem !important;
  }

  .h-rem-xl-3 {
    height: .75rem !important;
  }

  .h-rem-xl-4 {
    height: 1rem !important;
  }

  .h-rem-xl-5 {
    height: 1.25rem !important;
  }

  .h-rem-xl-6 {
    height: 1.5rem !important;
  }

  .h-rem-xl-7 {
    height: 1.75rem !important;
  }

  .h-rem-xl-8 {
    height: 2rem !important;
  }

  .h-rem-xl-10 {
    height: 2.5rem !important;
  }

  .h-rem-xl-12 {
    height: 3rem !important;
  }

  .h-rem-xl-14 {
    height: 3.5rem !important;
  }

  .h-rem-xl-16 {
    height: 4rem !important;
  }

  .h-rem-xl-18 {
    height: 4.5rem !important;
  }

  .h-rem-xl-20 {
    height: 5rem !important;
  }

  .h-rem-xl-24 {
    height: 6rem !important;
  }

  .h-rem-xl-32 {
    height: 8rem !important;
  }

  .h-rem-xl-40 {
    height: 10rem !important;
  }

  .h-rem-xl-48 {
    height: 12rem !important;
  }

  .h-rem-xl-56 {
    height: 14rem !important;
  }

  .h-rem-xl-64 {
    height: 16rem !important;
  }

  .h-rem-xl-72 {
    height: 18rem !important;
  }

  .h-rem-xl-80 {
    height: 20rem !important;
  }

  .h-rem-xl-px {
    height: 1px !important;
  }

  .transition-xl {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .transition-xl-none {
    transition-property: none !important;
  }

  .transition-xl-all {
    transition-property: all !important;
  }

  .transition-xl-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .transition-xl-opacity {
    transition-property: opacity !important;
  }

  .transition-xl-shadow {
    transition-property: box-shadow !important;
  }

  .transition-xl-transform {
    transition-property: transform !important;
  }

  .ease-xl-base {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .ease-xl-linear {
    transition-timing-function: linear !important;
  }

  .ease-xl-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
  }

  .ease-xl-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
  }

  .ease-xl-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .transform-xl-none {
    transform: none !important;
  }

  .scale-xl-0, .scale-xl-0-hover:hover {
    --x-scale-x: 0 !important;
    --x-scale-y: 0 !important;
  }

  .scale-xl-25, .scale-xl-25-hover:hover {
    --x-scale-x: .25 !important;
    --x-scale-y: .25 !important;
  }

  .scale-xl-50, .scale-xl-50-hover:hover {
    --x-scale-x: .5 !important;
    --x-scale-y: .5 !important;
  }

  .scale-xl-75, .scale-xl-75-hover:hover {
    --x-scale-x: .75 !important;
    --x-scale-y: .75 !important;
  }

  .scale-xl-100, .scale-xl-100-hover:hover {
    --x-scale-x: 1 !important;
    --x-scale-y: 1 !important;
  }

  .scale-xl-105, .scale-xl-105-hover:hover {
    --x-scale-x: 1.05 !important;
    --x-scale-y: 1.05 !important;
  }

  .scale-xl-110, .scale-xl-110-hover:hover {
    --x-scale-x: 1.1 !important;
    --x-scale-y: 1.1 !important;
  }

  .scale-xl-125, .scale-xl-125-hover:hover {
    --x-scale-x: 1.25 !important;
    --x-scale-y: 1.25 !important;
  }

  .scale-xl-150, .scale-xl-150-hover:hover {
    --x-scale-x: 1.5 !important;
    --x-scale-y: 1.5 !important;
  }

  .rotate-xl-0, .rotate-xl-0-hover:hover {
    --x-rotate: 0deg !important;
  }

  .rotate-xl-1, .rotate-xl-1-hover:hover {
    --x-rotate: 1deg !important;
  }

  .rotate-xl-2, .rotate-xl-2-hover:hover {
    --x-rotate: 2deg !important;
  }

  .rotate-xl-3, .rotate-xl-3-hover:hover {
    --x-rotate: 3deg !important;
  }

  .rotate-xl-6, .rotate-xl-6-hover:hover {
    --x-rotate: 6deg !important;
  }

  .rotate-xl-12, .rotate-xl-12-hover:hover {
    --x-rotate: 12deg !important;
  }

  .rotate-xl-30, .rotate-xl-30-hover:hover {
    --x-rotate: 30deg !important;
  }

  .rotate-xl-45, .rotate-xl-45-hover:hover {
    --x-rotate: 45deg !important;
  }

  .rotate-xl-90, .rotate-xl-90-hover:hover {
    --x-rotate: 90deg !important;
  }

  .rotate-xl-180, .rotate-xl-180-hover:hover {
    --x-rotate: 180deg !important;
  }

  .rotate-xl-n1, .rotate-xl-n1-hover:hover {
    --x-rotate: -1deg !important;
  }

  .rotate-xl-n2, .rotate-xl-n2-hover:hover {
    --x-rotate: -2deg !important;
  }

  .rotate-xl-n3, .rotate-xl-n3-hover:hover {
    --x-rotate: -3deg !important;
  }

  .rotate-xl-n6, .rotate-xl-n6-hover:hover {
    --x-rotate: -6deg !important;
  }

  .rotate-xl-n12, .rotate-xl-n12-hover:hover {
    --x-rotate: -12deg !important;
  }

  .rotate-xl-n30, .rotate-xl-n30-hover:hover {
    --x-rotate: -30deg !important;
  }

  .rotate-xl-n45, .rotate-xl-n45-hover:hover {
    --x-rotate: -45deg !important;
  }

  .rotate-xl-n90, .rotate-xl-n90-hover:hover {
    --x-rotate: -90deg !important;
  }

  .rotate-xl-n180, .rotate-xl-n180-hover:hover {
    --x-rotate: -180deg !important;
  }

  .rotate-y-xl-n1, .rotate-y-xl-n1-hover:hover {
    --x-rotate-y: -1deg !important;
  }

  .rotate-y-xl-n2, .rotate-y-xl-n2-hover:hover {
    --x-rotate-y: -2deg !important;
  }

  .rotate-y-xl-n3, .rotate-y-xl-n3-hover:hover {
    --x-rotate-y: -3deg !important;
  }

  .rotate-y-xl-n6, .rotate-y-xl-n6-hover:hover {
    --x-rotate-y: -6deg !important;
  }

  .rotate-y-xl-n12, .rotate-y-xl-n12-hover:hover {
    --x-rotate-y: -12deg !important;
  }

  .rotate-y-xl-n30, .rotate-y-xl-n30-hover:hover {
    --x-rotate-y: -30deg !important;
  }

  .rotate-y-xl-n45, .rotate-y-xl-n45-hover:hover {
    --x-rotate-y: -45deg !important;
  }

  .rotate-y-xl-n90, .rotate-y-xl-n90-hover:hover {
    --x-rotate-y: -90deg !important;
  }

  .rotate-y-xl-n180, .rotate-y-xl-n180-hover:hover {
    --x-rotate-y: -180deg !important;
  }

  .rotate-x-xl-n1 {
    --x-rotate-x: -1deg !important;
  }

  .rotate-x-xl-n2 {
    --x-rotate-x: -2deg !important;
  }

  .rotate-x-xl-n3 {
    --x-rotate-x: -3deg !important;
  }

  .rotate-x-xl-n6 {
    --x-rotate-x: -6deg !important;
  }

  .rotate-x-xl-n12 {
    --x-rotate-x: -12deg !important;
  }

  .rotate-x-xl-n30 {
    --x-rotate-x: -30deg !important;
  }

  .rotate-x-xl-n45 {
    --x-rotate-x: -45deg !important;
  }

  .rotate-x-xl-n90 {
    --x-rotate-x: -90deg !important;
  }

  .rotate-x-xl-n180 {
    --x-rotate-x: -180deg !important;
  }

  .translate-y-xl-0, .translate-y-xl-0-hover:hover {
    --x-translate-y: 0 !important;
  }

  .translate-y-xl-25, .translate-y-xl-25-hover:hover {
    --x-translate-y: 25% !important;
  }

  .translate-y-xl-50, .translate-y-xl-50-hover:hover {
    --x-translate-y: 50% !important;
  }

  .translate-y-xl-75, .translate-y-xl-75-hover:hover {
    --x-translate-y: 75% !important;
  }

  .translate-y-xl-100, .translate-y-xl-100-hover:hover {
    --x-translate-y: 100% !important;
  }

  .translate-y-xl-n25, .translate-y-xl-n25-hover:hover {
    --x-translate-y: -25% !important;
  }

  .translate-y-xl-n50, .translate-y-xl-n50-hover:hover {
    --x-translate-y: -50% !important;
  }

  .translate-y-xl-n75, .translate-y-xl-n75-hover:hover {
    --x-translate-y: -75% !important;
  }

  .translate-y-xl-n100, .translate-y-xl-n100-hover:hover {
    --x-translate-y: -100% !important;
  }

  .translate-x-xl-0, .translate-x-xl-0-hover:hover {
    --x-translate-x: 0 !important;
  }

  .translate-x-xl-25, .translate-x-xl-25-hover:hover {
    --x-translate-x: 25% !important;
  }

  .translate-x-xl-50, .translate-x-xl-50-hover:hover {
    --x-translate-x: 50% !important;
  }

  .translate-x-xl-75, .translate-x-xl-75-hover:hover {
    --x-translate-x: 75% !important;
  }

  .translate-x-xl-100, .translate-x-xl-100-hover:hover {
    --x-translate-x: 100% !important;
  }

  .translate-x-xl-n25, .translate-x-xl-n25-hover:hover {
    --x-translate-x: -25% !important;
  }

  .translate-x-xl-n50, .translate-x-xl-n50-hover:hover {
    --x-translate-x: -50% !important;
  }

  .translate-x-xl-n75, .translate-x-xl-n75-hover:hover {
    --x-translate-x: -75% !important;
  }

  .translate-x-xl-n100, .translate-x-xl-n100-hover:hover {
    --x-translate-x: -100% !important;
  }

  .skew-y-xl-0 {
    --x-skew-y: 0deg !important;
  }

  .skew-y-xl-1 {
    --x-skew-y: 1deg !important;
  }

  .skew-y-xl-2 {
    --x-skew-y: 2deg !important;
  }

  .skew-y-xl-3 {
    --x-skew-y: 3deg !important;
  }

  .skew-y-xl-6 {
    --x-skew-y: 6deg !important;
  }

  .skew-y-xl-12 {
    --x-skew-y: 12deg !important;
  }

  .skew-x-xl-0 {
    --x-skew-x: 0deg !important;
  }

  .skew-x-xl-1 {
    --x-skew-x: 1deg !important;
  }

  .skew-x-xl-2 {
    --x-skew-x: 2deg !important;
  }

  .skew-x-xl-3 {
    --x-skew-x: 3deg !important;
  }

  .skew-x-xl-6 {
    --x-skew-x: 6deg !important;
  }

  .skew-x-xl-12 {
    --x-skew-x: 12deg !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .overflow-xxl-auto {
    overflow: auto !important;
  }

  .overflow-xxl-hidden {
    overflow: hidden !important;
  }

  .overflow-xxl-visible {
    overflow: visible !important;
  }

  .overflow-xxl-scroll {
    overflow: scroll !important;
  }

  .overflow-x-xxl-auto {
    overflow-x: auto !important;
  }

  .overflow-x-xxl-hidden {
    overflow-x: hidden !important;
  }

  .overflow-x-xxl-visible {
    overflow-x: visible !important;
  }

  .overflow-x-xxl-scroll {
    overflow-x: scroll !important;
  }

  .overflow-y-xxl-auto {
    overflow-y: auto !important;
  }

  .overflow-y-xxl-hidden {
    overflow-y: hidden !important;
  }

  .overflow-y-xxl-visible {
    overflow-y: visible !important;
  }

  .overflow-y-xxl-scroll {
    overflow-y: scroll !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .shadow-xxl, .shadow-xxl-hover:hover {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-1, .shadow-xxl-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-2, .shadow-xxl-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-3, .shadow-xxl-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-4, .shadow-xxl-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-5, .shadow-xxl-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-6, .shadow-xxl-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06) !important;
  }

  .shadow-xxl-inset, .shadow-xxl-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075) !important;
  }

  .shadow-xxl-none, .shadow-xxl-none-hover:hover {
    box-shadow: none !important;
  }

  .position-xxl-static {
    position: static !important;
  }

  .position-xxl-relative {
    position: relative !important;
  }

  .position-xxl-absolute {
    position: absolute !important;
  }

  .position-xxl-fixed {
    position: fixed !important;
  }

  .position-xxl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .top-xxl-0 {
    top: 0 !important;
  }

  .top-xxl-50 {
    top: 50% !important;
  }

  .top-xxl-100 {
    top: 100% !important;
  }

  .top-xxl-1 {
    top: .25rem !important;
  }

  .top-xxl-2 {
    top: .5rem !important;
  }

  .top-xxl-3 {
    top: .75rem !important;
  }

  .top-xxl-4 {
    top: 1rem !important;
  }

  .top-xxl-5 {
    top: 1.25rem !important;
  }

  .top-xxl-6 {
    top: 1.5rem !important;
  }

  .top-xxl-7 {
    top: 1.75rem !important;
  }

  .top-xxl-8 {
    top: 2rem !important;
  }

  .top-xxl-10 {
    top: 2.5rem !important;
  }

  .top-xxl-12 {
    top: 3rem !important;
  }

  .top-xxl-14 {
    top: 3.5rem !important;
  }

  .top-xxl-16 {
    top: 4rem !important;
  }

  .top-xxl-18 {
    top: 4.5rem !important;
  }

  .top-xxl-20 {
    top: 5rem !important;
  }

  .top-xxl-24 {
    top: 6rem !important;
  }

  .top-xxl-32 {
    top: 8rem !important;
  }

  .top-xxl-40 {
    top: 10rem !important;
  }

  .top-xxl-48 {
    top: 12rem !important;
  }

  .top-xxl-56 {
    top: 14rem !important;
  }

  .top-xxl-64 {
    top: 16rem !important;
  }

  .top-xxl-72 {
    top: 18rem !important;
  }

  .top-xxl-80 {
    top: 20rem !important;
  }

  .top-xxl-px {
    top: 1px !important;
  }

  .bottom-xxl-0 {
    bottom: 0 !important;
  }

  .bottom-xxl-50 {
    bottom: 50% !important;
  }

  .bottom-xxl-100 {
    bottom: 100% !important;
  }

  .start-xxl-0 {
    left: 0 !important;
  }

  .start-xxl-50 {
    left: 50% !important;
  }

  .start-xxl-100 {
    left: 100% !important;
  }

  .end-xxl-0 {
    right: 0 !important;
  }

  .end-xxl-50 {
    right: 50% !important;
  }

  .end-xxl-100 {
    right: 100% !important;
  }

  .border-xxl {
    border: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-xxl-0 {
    border: 0 !important;
  }

  .border-top-xxl {
    border-top: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-top-xxl-0 {
    border-top: 0 !important;
  }

  .border-end-xxl {
    border-right: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-end-xxl-0 {
    border-right: 0 !important;
  }

  .border-bottom-xxl {
    border-bottom: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }

  .border-start-xxl {
    border-left: var(--x-border-width) var(--x-border-style) var(--x-border-color) !important;
  }

  .border-start-xxl-0 {
    border-left: 0 !important;
  }

  .border-xxl-primary, .border-xxl-primary-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-primary-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-secondary, .border-xxl-secondary-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-secondary-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-success, .border-xxl-success-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-success-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-info, .border-xxl-info-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-info-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-warning, .border-xxl-warning-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-warning-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-danger, .border-xxl-danger-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-danger-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-light, .border-xxl-light-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-light-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-dark, .border-xxl-dark-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-dark-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-black, .border-xxl-black-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-black-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-white, .border-xxl-white-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-white-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-gray-50, .border-xxl-gray-50-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-50-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-gray-100, .border-xxl-gray-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-gray-200, .border-xxl-gray-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-gray-300, .border-xxl-gray-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-gray-400, .border-xxl-gray-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-gray-500, .border-xxl-gray-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-gray-600, .border-xxl-gray-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-gray-700, .border-xxl-gray-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-gray-800, .border-xxl-gray-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-gray-900, .border-xxl-gray-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-gray-950, .border-xxl-gray-950-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-gray-950-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-blue-100, .border-xxl-blue-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-blue-200, .border-xxl-blue-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-blue-300, .border-xxl-blue-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-blue-400, .border-xxl-blue-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-blue-500, .border-xxl-blue-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-blue-600, .border-xxl-blue-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-blue-700, .border-xxl-blue-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-blue-800, .border-xxl-blue-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-blue-900, .border-xxl-blue-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-blue-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-indigo-100, .border-xxl-indigo-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-indigo-200, .border-xxl-indigo-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-indigo-300, .border-xxl-indigo-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-indigo-400, .border-xxl-indigo-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-indigo-500, .border-xxl-indigo-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-indigo-600, .border-xxl-indigo-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-indigo-700, .border-xxl-indigo-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-indigo-800, .border-xxl-indigo-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-indigo-900, .border-xxl-indigo-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-indigo-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-purple-100, .border-xxl-purple-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-purple-200, .border-xxl-purple-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-purple-300, .border-xxl-purple-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-purple-400, .border-xxl-purple-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-purple-500, .border-xxl-purple-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-purple-600, .border-xxl-purple-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-purple-700, .border-xxl-purple-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-purple-800, .border-xxl-purple-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-purple-900, .border-xxl-purple-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-purple-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-pink-100, .border-xxl-pink-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-pink-200, .border-xxl-pink-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-pink-300, .border-xxl-pink-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-pink-400, .border-xxl-pink-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-pink-500, .border-xxl-pink-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-pink-600, .border-xxl-pink-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-pink-700, .border-xxl-pink-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-pink-800, .border-xxl-pink-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-pink-900, .border-xxl-pink-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-pink-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-red-100, .border-xxl-red-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-red-200, .border-xxl-red-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-red-300, .border-xxl-red-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-red-400, .border-xxl-red-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-red-500, .border-xxl-red-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-red-600, .border-xxl-red-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-red-700, .border-xxl-red-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-red-800, .border-xxl-red-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-red-900, .border-xxl-red-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-red-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-orange-100, .border-xxl-orange-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-orange-200, .border-xxl-orange-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-orange-300, .border-xxl-orange-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-orange-400, .border-xxl-orange-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-orange-500, .border-xxl-orange-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-orange-600, .border-xxl-orange-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-orange-700, .border-xxl-orange-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-orange-800, .border-xxl-orange-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-orange-900, .border-xxl-orange-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-orange-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-yellow-100, .border-xxl-yellow-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-yellow-200, .border-xxl-yellow-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-yellow-300, .border-xxl-yellow-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-yellow-400, .border-xxl-yellow-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-yellow-500, .border-xxl-yellow-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-yellow-600, .border-xxl-yellow-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-yellow-700, .border-xxl-yellow-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-yellow-800, .border-xxl-yellow-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-yellow-900, .border-xxl-yellow-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-yellow-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-green-100, .border-xxl-green-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-green-200, .border-xxl-green-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-green-300, .border-xxl-green-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-green-400, .border-xxl-green-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-green-500, .border-xxl-green-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-green-600, .border-xxl-green-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-green-700, .border-xxl-green-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-green-800, .border-xxl-green-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-green-900, .border-xxl-green-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-green-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-teal-100, .border-xxl-teal-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-teal-200, .border-xxl-teal-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-teal-300, .border-xxl-teal-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-teal-400, .border-xxl-teal-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-teal-500, .border-xxl-teal-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-teal-600, .border-xxl-teal-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-teal-700, .border-xxl-teal-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-teal-800, .border-xxl-teal-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-teal-900, .border-xxl-teal-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-teal-900-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-cyan-100, .border-xxl-cyan-100-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-100-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-cyan-200, .border-xxl-cyan-200-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-200-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-cyan-300, .border-xxl-cyan-300-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-300-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-cyan-400, .border-xxl-cyan-400-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-400-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-cyan-500, .border-xxl-cyan-500-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-500-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-cyan-600, .border-xxl-cyan-600-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-600-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-cyan-700, .border-xxl-cyan-700-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-700-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-cyan-800, .border-xxl-cyan-800-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-800-rgb), var(--x-border-opacity)) !important;
  }

  .border-xxl-cyan-900, .border-xxl-cyan-900-hover:hover {
    --x-border-opacity: 1;
    border-color: rgba(var(--x-cyan-900-rgb), var(--x-border-opacity)) !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .mw-xxl-screen-sm {
    max-width: 640px !important;
  }

  .mw-xxl-screen-md {
    max-width: 768px !important;
  }

  .mw-xxl-screen-lg {
    max-width: 1024px !important;
  }

  .mw-xxl-screen-xl {
    max-width: 1280px !important;
  }

  .mw-xxl-screen-xxl {
    max-width: 1536px !important;
  }

  .mw-xxl-0 {
    max-width: 0 !important;
  }

  .mw-xxl-100 {
    max-width: 100% !important;
  }

  .mw-xxl-read {
    max-width: 65ch !important;
  }

  .mw-xxl-fit {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .h-xxl-25 {
    height: 25% !important;
  }

  .h-xxl-50 {
    height: 50% !important;
  }

  .h-xxl-75 {
    height: 75% !important;
  }

  .h-xxl-100 {
    height: 100% !important;
  }

  .h-xxl-auto {
    height: auto !important;
  }

  .vh-xxl-100 {
    height: 100vh !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-initial {
    flex: 0 auto !important;
  }

  .flex-xxl-none {
    flex: none !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: .75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 1.75rem !important;
  }

  .m-xxl-8 {
    margin: 2rem !important;
  }

  .m-xxl-10 {
    margin: 2.5rem !important;
  }

  .m-xxl-12 {
    margin: 3rem !important;
  }

  .m-xxl-14 {
    margin: 3.5rem !important;
  }

  .m-xxl-16 {
    margin: 4rem !important;
  }

  .m-xxl-18 {
    margin: 4.5rem !important;
  }

  .m-xxl-20 {
    margin: 5rem !important;
  }

  .m-xxl-24 {
    margin: 6rem !important;
  }

  .m-xxl-32 {
    margin: 8rem !important;
  }

  .m-xxl-40 {
    margin: 10rem !important;
  }

  .m-xxl-48 {
    margin: 12rem !important;
  }

  .m-xxl-56 {
    margin: 14rem !important;
  }

  .m-xxl-64 {
    margin: 16rem !important;
  }

  .m-xxl-72 {
    margin: 18rem !important;
  }

  .m-xxl-80 {
    margin: 20rem !important;
  }

  .m-xxl-px {
    margin: 1px !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .mx-xxl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-xxl-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xxl-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-xxl-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-xxl-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xxl-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-xxl-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-xxl-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .mx-xxl-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mx-xxl-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .mx-xxl-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .mx-xxl-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .mx-xxl-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .mx-xxl-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mx-xxl-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xxl-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xxl-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .my-xxl-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-xxl-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-xxl-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .my-xxl-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-xxl-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: .75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2rem !important;
  }

  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-12 {
    margin-top: 3rem !important;
  }

  .mt-xxl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-16 {
    margin-top: 4rem !important;
  }

  .mt-xxl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-20 {
    margin-top: 5rem !important;
  }

  .mt-xxl-24 {
    margin-top: 6rem !important;
  }

  .mt-xxl-32 {
    margin-top: 8rem !important;
  }

  .mt-xxl-40 {
    margin-top: 10rem !important;
  }

  .mt-xxl-48 {
    margin-top: 12rem !important;
  }

  .mt-xxl-56 {
    margin-top: 14rem !important;
  }

  .mt-xxl-64 {
    margin-top: 16rem !important;
  }

  .mt-xxl-72 {
    margin-top: 18rem !important;
  }

  .mt-xxl-80 {
    margin-top: 20rem !important;
  }

  .mt-xxl-px {
    margin-top: 1px !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: .75rem !important;
  }

  .me-xxl-4 {
    margin-right: 1rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xxl-8 {
    margin-right: 2rem !important;
  }

  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-12 {
    margin-right: 3rem !important;
  }

  .me-xxl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-16 {
    margin-right: 4rem !important;
  }

  .me-xxl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-20 {
    margin-right: 5rem !important;
  }

  .me-xxl-24 {
    margin-right: 6rem !important;
  }

  .me-xxl-32 {
    margin-right: 8rem !important;
  }

  .me-xxl-40 {
    margin-right: 10rem !important;
  }

  .me-xxl-48 {
    margin-right: 12rem !important;
  }

  .me-xxl-56 {
    margin-right: 14rem !important;
  }

  .me-xxl-64 {
    margin-right: 16rem !important;
  }

  .me-xxl-72 {
    margin-right: 18rem !important;
  }

  .me-xxl-80 {
    margin-right: 20rem !important;
  }

  .me-xxl-px {
    margin-right: 1px !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: .75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-24 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-32 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-40 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-48 {
    margin-bottom: 12rem !important;
  }

  .mb-xxl-56 {
    margin-bottom: 14rem !important;
  }

  .mb-xxl-64 {
    margin-bottom: 16rem !important;
  }

  .mb-xxl-72 {
    margin-bottom: 18rem !important;
  }

  .mb-xxl-80 {
    margin-bottom: 20rem !important;
  }

  .mb-xxl-px {
    margin-bottom: 1px !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: .75rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2rem !important;
  }

  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-12 {
    margin-left: 3rem !important;
  }

  .ms-xxl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-16 {
    margin-left: 4rem !important;
  }

  .ms-xxl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-20 {
    margin-left: 5rem !important;
  }

  .ms-xxl-24 {
    margin-left: 6rem !important;
  }

  .ms-xxl-32 {
    margin-left: 8rem !important;
  }

  .ms-xxl-40 {
    margin-left: 10rem !important;
  }

  .ms-xxl-48 {
    margin-left: 12rem !important;
  }

  .ms-xxl-56 {
    margin-left: 14rem !important;
  }

  .ms-xxl-64 {
    margin-left: 16rem !important;
  }

  .ms-xxl-72 {
    margin-left: 18rem !important;
  }

  .ms-xxl-80 {
    margin-left: 20rem !important;
  }

  .ms-xxl-px {
    margin-left: 1px !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.25rem !important;
  }

  .m-xxl-n2 {
    margin: -.5rem !important;
  }

  .m-xxl-n3 {
    margin: -.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.25rem !important;
  }

  .m-xxl-n6 {
    margin: -1.5rem !important;
  }

  .m-xxl-n7 {
    margin: -1.75rem !important;
  }

  .m-xxl-n8 {
    margin: -2rem !important;
  }

  .m-xxl-n10 {
    margin: -2.5rem !important;
  }

  .m-xxl-n12 {
    margin: -3rem !important;
  }

  .m-xxl-n14 {
    margin: -3.5rem !important;
  }

  .m-xxl-n16 {
    margin: -4rem !important;
  }

  .m-xxl-n18 {
    margin: -4.5rem !important;
  }

  .m-xxl-n20 {
    margin: -5rem !important;
  }

  .m-xxl-n24 {
    margin: -6rem !important;
  }

  .m-xxl-n32 {
    margin: -8rem !important;
  }

  .m-xxl-n40 {
    margin: -10rem !important;
  }

  .m-xxl-n48 {
    margin: -12rem !important;
  }

  .m-xxl-n56 {
    margin: -14rem !important;
  }

  .m-xxl-n64 {
    margin: -16rem !important;
  }

  .m-xxl-n72 {
    margin: -18rem !important;
  }

  .m-xxl-n80 {
    margin: -20rem !important;
  }

  .m-xxl-npx {
    margin: -1px !important;
  }

  .mx-xxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mx-xxl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .mx-xxl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-xxl-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-xxl-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xxl-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-xxl-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-xxl-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mx-xxl-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-xxl-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-xxl-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .mx-xxl-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .mx-xxl-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .mx-xxl-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .mx-xxl-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .mx-xxl-n72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .mx-xxl-n80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .mx-xxl-npx {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .my-xxl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xxl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xxl-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xxl-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xxl-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xxl-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .my-xxl-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-xxl-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-xxl-n72 {
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .my-xxl-n80 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-xxl-npx {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mt-xxl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -.75rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xxl-n20 {
    margin-top: -5rem !important;
  }

  .mt-xxl-n24 {
    margin-top: -6rem !important;
  }

  .mt-xxl-n32 {
    margin-top: -8rem !important;
  }

  .mt-xxl-n40 {
    margin-top: -10rem !important;
  }

  .mt-xxl-n48 {
    margin-top: -12rem !important;
  }

  .mt-xxl-n56 {
    margin-top: -14rem !important;
  }

  .mt-xxl-n64 {
    margin-top: -16rem !important;
  }

  .mt-xxl-n72 {
    margin-top: -18rem !important;
  }

  .mt-xxl-n80 {
    margin-top: -20rem !important;
  }

  .mt-xxl-npx {
    margin-top: -1px !important;
  }

  .me-xxl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -.75rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1rem !important;
  }

  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xxl-n8 {
    margin-right: -2rem !important;
  }

  .me-xxl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n12 {
    margin-right: -3rem !important;
  }

  .me-xxl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n16 {
    margin-right: -4rem !important;
  }

  .me-xxl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xxl-n20 {
    margin-right: -5rem !important;
  }

  .me-xxl-n24 {
    margin-right: -6rem !important;
  }

  .me-xxl-n32 {
    margin-right: -8rem !important;
  }

  .me-xxl-n40 {
    margin-right: -10rem !important;
  }

  .me-xxl-n48 {
    margin-right: -12rem !important;
  }

  .me-xxl-n56 {
    margin-right: -14rem !important;
  }

  .me-xxl-n64 {
    margin-right: -16rem !important;
  }

  .me-xxl-n72 {
    margin-right: -18rem !important;
  }

  .me-xxl-n80 {
    margin-right: -20rem !important;
  }

  .me-xxl-npx {
    margin-right: -1px !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -.75rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xxl-n20 {
    margin-bottom: -5rem !important;
  }

  .mb-xxl-n24 {
    margin-bottom: -6rem !important;
  }

  .mb-xxl-n32 {
    margin-bottom: -8rem !important;
  }

  .mb-xxl-n40 {
    margin-bottom: -10rem !important;
  }

  .mb-xxl-n48 {
    margin-bottom: -12rem !important;
  }

  .mb-xxl-n56 {
    margin-bottom: -14rem !important;
  }

  .mb-xxl-n64 {
    margin-bottom: -16rem !important;
  }

  .mb-xxl-n72 {
    margin-bottom: -18rem !important;
  }

  .mb-xxl-n80 {
    margin-bottom: -20rem !important;
  }

  .mb-xxl-npx {
    margin-bottom: -1px !important;
  }

  .ms-xxl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -.75rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xxl-n20 {
    margin-left: -5rem !important;
  }

  .ms-xxl-n24 {
    margin-left: -6rem !important;
  }

  .ms-xxl-n32 {
    margin-left: -8rem !important;
  }

  .ms-xxl-n40 {
    margin-left: -10rem !important;
  }

  .ms-xxl-n48 {
    margin-left: -12rem !important;
  }

  .ms-xxl-n56 {
    margin-left: -14rem !important;
  }

  .ms-xxl-n64 {
    margin-left: -16rem !important;
  }

  .ms-xxl-n72 {
    margin-left: -18rem !important;
  }

  .ms-xxl-n80 {
    margin-left: -20rem !important;
  }

  .ms-xxl-npx {
    margin-left: -1px !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: .75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 1.75rem !important;
  }

  .p-xxl-8 {
    padding: 2rem !important;
  }

  .p-xxl-10 {
    padding: 2.5rem !important;
  }

  .p-xxl-12 {
    padding: 3rem !important;
  }

  .p-xxl-14 {
    padding: 3.5rem !important;
  }

  .p-xxl-16 {
    padding: 4rem !important;
  }

  .p-xxl-18 {
    padding: 4.5rem !important;
  }

  .p-xxl-20 {
    padding: 5rem !important;
  }

  .p-xxl-24 {
    padding: 6rem !important;
  }

  .p-xxl-32 {
    padding: 8rem !important;
  }

  .p-xxl-40 {
    padding: 10rem !important;
  }

  .p-xxl-48 {
    padding: 12rem !important;
  }

  .p-xxl-56 {
    padding: 14rem !important;
  }

  .p-xxl-64 {
    padding: 16rem !important;
  }

  .p-xxl-72 {
    padding: 18rem !important;
  }

  .p-xxl-80 {
    padding: 20rem !important;
  }

  .p-xxl-px {
    padding: 1px !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-xxl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .px-xxl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-xxl-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-xxl-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xxl-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-xxl-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-xxl-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-xxl-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-xxl-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-xxl-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .px-xxl-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .px-xxl-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .px-xxl-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .px-xxl-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .px-xxl-72 {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .px-xxl-80 {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .px-xxl-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xxl-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xxl-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xxl-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-xxl-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-xxl-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-xxl-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .py-xxl-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-xxl-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: .75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2rem !important;
  }

  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-12 {
    padding-top: 3rem !important;
  }

  .pt-xxl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-16 {
    padding-top: 4rem !important;
  }

  .pt-xxl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-20 {
    padding-top: 5rem !important;
  }

  .pt-xxl-24 {
    padding-top: 6rem !important;
  }

  .pt-xxl-32 {
    padding-top: 8rem !important;
  }

  .pt-xxl-40 {
    padding-top: 10rem !important;
  }

  .pt-xxl-48 {
    padding-top: 12rem !important;
  }

  .pt-xxl-56 {
    padding-top: 14rem !important;
  }

  .pt-xxl-64 {
    padding-top: 16rem !important;
  }

  .pt-xxl-72 {
    padding-top: 18rem !important;
  }

  .pt-xxl-80 {
    padding-top: 20rem !important;
  }

  .pt-xxl-px {
    padding-top: 1px !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: .75rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2rem !important;
  }

  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-12 {
    padding-right: 3rem !important;
  }

  .pe-xxl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-16 {
    padding-right: 4rem !important;
  }

  .pe-xxl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-20 {
    padding-right: 5rem !important;
  }

  .pe-xxl-24 {
    padding-right: 6rem !important;
  }

  .pe-xxl-32 {
    padding-right: 8rem !important;
  }

  .pe-xxl-40 {
    padding-right: 10rem !important;
  }

  .pe-xxl-48 {
    padding-right: 12rem !important;
  }

  .pe-xxl-56 {
    padding-right: 14rem !important;
  }

  .pe-xxl-64 {
    padding-right: 16rem !important;
  }

  .pe-xxl-72 {
    padding-right: 18rem !important;
  }

  .pe-xxl-80 {
    padding-right: 20rem !important;
  }

  .pe-xxl-px {
    padding-right: 1px !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: .75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 5rem !important;
  }

  .pb-xxl-24 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-32 {
    padding-bottom: 8rem !important;
  }

  .pb-xxl-40 {
    padding-bottom: 10rem !important;
  }

  .pb-xxl-48 {
    padding-bottom: 12rem !important;
  }

  .pb-xxl-56 {
    padding-bottom: 14rem !important;
  }

  .pb-xxl-64 {
    padding-bottom: 16rem !important;
  }

  .pb-xxl-72 {
    padding-bottom: 18rem !important;
  }

  .pb-xxl-80 {
    padding-bottom: 20rem !important;
  }

  .pb-xxl-px {
    padding-bottom: 1px !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: .75rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2rem !important;
  }

  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-12 {
    padding-left: 3rem !important;
  }

  .ps-xxl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-16 {
    padding-left: 4rem !important;
  }

  .ps-xxl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-20 {
    padding-left: 5rem !important;
  }

  .ps-xxl-24 {
    padding-left: 6rem !important;
  }

  .ps-xxl-32 {
    padding-left: 8rem !important;
  }

  .ps-xxl-40 {
    padding-left: 10rem !important;
  }

  .ps-xxl-48 {
    padding-left: 12rem !important;
  }

  .ps-xxl-56 {
    padding-left: 14rem !important;
  }

  .ps-xxl-64 {
    padding-left: 16rem !important;
  }

  .ps-xxl-72 {
    padding-left: 18rem !important;
  }

  .ps-xxl-80 {
    padding-left: 20rem !important;
  }

  .ps-xxl-px {
    padding-left: 1px !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: .75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 1.75rem !important;
  }

  .gap-xxl-8 {
    gap: 2rem !important;
  }

  .gap-xxl-10 {
    gap: 2.5rem !important;
  }

  .gap-xxl-12 {
    gap: 3rem !important;
  }

  .gap-xxl-14 {
    gap: 3.5rem !important;
  }

  .gap-xxl-16 {
    gap: 4rem !important;
  }

  .gap-xxl-18 {
    gap: 4.5rem !important;
  }

  .gap-xxl-20 {
    gap: 5rem !important;
  }

  .gap-xxl-24 {
    gap: 6rem !important;
  }

  .gap-xxl-32 {
    gap: 8rem !important;
  }

  .gap-xxl-40 {
    gap: 10rem !important;
  }

  .gap-xxl-48 {
    gap: 12rem !important;
  }

  .gap-xxl-56 {
    gap: 14rem !important;
  }

  .gap-xxl-64 {
    gap: 16rem !important;
  }

  .gap-xxl-72 {
    gap: 18rem !important;
  }

  .gap-xxl-80 {
    gap: 20rem !important;
  }

  .gap-xxl-px {
    gap: 1px !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: .75rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xxl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xxl-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xxl-12 {
    row-gap: 3rem !important;
  }

  .row-gap-xxl-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xxl-16 {
    row-gap: 4rem !important;
  }

  .row-gap-xxl-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xxl-20 {
    row-gap: 5rem !important;
  }

  .row-gap-xxl-24 {
    row-gap: 6rem !important;
  }

  .row-gap-xxl-32 {
    row-gap: 8rem !important;
  }

  .row-gap-xxl-40 {
    row-gap: 10rem !important;
  }

  .row-gap-xxl-48 {
    row-gap: 12rem !important;
  }

  .row-gap-xxl-56 {
    row-gap: 14rem !important;
  }

  .row-gap-xxl-64 {
    row-gap: 16rem !important;
  }

  .row-gap-xxl-72 {
    row-gap: 18rem !important;
  }

  .row-gap-xxl-80 {
    row-gap: 20rem !important;
  }

  .row-gap-xxl-px {
    row-gap: 1px !important;
  }

  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    -moz-column-gap: .75rem !important;
    column-gap: .75rem !important;
  }

  .column-gap-xxl-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xxl-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }

  .column-gap-xxl-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }

  .column-gap-xxl-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-xxl-10 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .column-gap-xxl-12 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .column-gap-xxl-14 {
    -moz-column-gap: 3.5rem !important;
    column-gap: 3.5rem !important;
  }

  .column-gap-xxl-16 {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }

  .column-gap-xxl-18 {
    -moz-column-gap: 4.5rem !important;
    column-gap: 4.5rem !important;
  }

  .column-gap-xxl-20 {
    -moz-column-gap: 5rem !important;
    column-gap: 5rem !important;
  }

  .column-gap-xxl-24 {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }

  .column-gap-xxl-32 {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }

  .column-gap-xxl-40 {
    -moz-column-gap: 10rem !important;
    column-gap: 10rem !important;
  }

  .column-gap-xxl-48 {
    -moz-column-gap: 12rem !important;
    column-gap: 12rem !important;
  }

  .column-gap-xxl-56 {
    -moz-column-gap: 14rem !important;
    column-gap: 14rem !important;
  }

  .column-gap-xxl-64 {
    -moz-column-gap: 16rem !important;
    column-gap: 16rem !important;
  }

  .column-gap-xxl-72 {
    -moz-column-gap: 18rem !important;
    column-gap: 18rem !important;
  }

  .column-gap-xxl-80 {
    -moz-column-gap: 20rem !important;
    column-gap: 20rem !important;
  }

  .column-gap-xxl-px {
    -moz-column-gap: 1px !important;
    column-gap: 1px !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .rounded-xxl {
    border-radius: .375rem !important;
  }

  .rounded-xxl-0 {
    border-radius: 0 !important;
  }

  .rounded-xxl-1 {
    border-radius: .25rem !important;
  }

  .rounded-xxl-2 {
    border-radius: .375rem !important;
  }

  .rounded-xxl-3 {
    border-radius: .5rem !important;
  }

  .rounded-xxl-4 {
    border-radius: 1rem !important;
  }

  .rounded-xxl-5 {
    border-radius: 2rem !important;
  }

  .rounded-xxl-pill {
    border-radius: 50rem !important;
  }

  .rounded-xxl-circle {
    border-radius: 50% !important;
  }

  .rounded-xxl-card {
    border-radius: var(--x-border-radius) !important;
  }

  .rounded-xxl-inherit {
    border-radius: inherit !important;
  }

  .rounded-top-xxl {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-xxl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-top-xxl-1 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-xxl-2 {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-xxl-3 {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-xxl-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-xxl-5 {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important;
  }

  .rounded-top-xxl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-xxl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .rounded-top-xxl-card {
    border-top-left-radius: var(--x-border-radius) !important;
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-end-xxl {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-xxl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-end-xxl-1 {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-end-xxl-2 {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-end-xxl-3 {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-end-xxl-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-end-xxl-5 {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;
  }

  .rounded-end-xxl-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-end-xxl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .rounded-end-xxl-card {
    border-top-right-radius: var(--x-border-radius) !important;
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-xxl {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-xxl-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-xxl-1 {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-xxl-2 {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-xxl-3 {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-xxl-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-xxl-5 {
    border-bottom-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-bottom-xxl-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-xxl-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-xxl-card {
    border-bottom-right-radius: var(--x-border-radius) !important;
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .rounded-start-xxl {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-xxl-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rounded-start-xxl-1 {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-start-xxl-2 {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-start-xxl-3 {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-start-xxl-4 {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-start-xxl-5 {
    border-top-left-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-start-xxl-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-start-xxl-circle {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .rounded-start-xxl-card {
    border-bottom-left-radius: var(--x-border-radius) !important;
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-top-start-xxl {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-xxl-0 {
    border-top-left-radius: 0 !important;
  }

  .rounded-top-start-xxl-1 {
    border-top-left-radius: .25rem !important;
  }

  .rounded-top-start-xxl-2 {
    border-top-left-radius: .375rem !important;
  }

  .rounded-top-start-xxl-3 {
    border-top-left-radius: .5rem !important;
  }

  .rounded-top-start-xxl-4 {
    border-top-left-radius: 1rem !important;
  }

  .rounded-top-start-xxl-5 {
    border-top-left-radius: 2rem !important;
  }

  .rounded-top-start-xxl-pill {
    border-top-left-radius: 50rem !important;
  }

  .rounded-top-start-xxl-circle {
    border-top-left-radius: 50% !important;
  }

  .rounded-top-start-xxl-card {
    border-top-left-radius: var(--x-border-radius) !important;
  }

  .rounded-top-end-xxl {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-xxl-0 {
    border-top-right-radius: 0 !important;
  }

  .rounded-top-end-xxl-1 {
    border-top-right-radius: .25rem !important;
  }

  .rounded-top-end-xxl-2 {
    border-top-right-radius: .375rem !important;
  }

  .rounded-top-end-xxl-3 {
    border-top-right-radius: .5rem !important;
  }

  .rounded-top-end-xxl-4 {
    border-top-right-radius: 1rem !important;
  }

  .rounded-top-end-xxl-5 {
    border-top-right-radius: 2rem !important;
  }

  .rounded-top-end-xxl-pill {
    border-top-right-radius: 50rem !important;
  }

  .rounded-top-end-xxl-circle {
    border-top-right-radius: 50% !important;
  }

  .rounded-top-end-xxl-card {
    border-top-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-end-xxl {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-xxl-0 {
    border-bottom-right-radius: 0 !important;
  }

  .rounded-bottom-end-xxl-1 {
    border-bottom-right-radius: .25rem !important;
  }

  .rounded-bottom-end-xxl-2 {
    border-bottom-right-radius: .375rem !important;
  }

  .rounded-bottom-end-xxl-3 {
    border-bottom-right-radius: .5rem !important;
  }

  .rounded-bottom-end-xxl-4 {
    border-bottom-right-radius: 1rem !important;
  }

  .rounded-bottom-end-xxl-5 {
    border-bottom-right-radius: 2rem !important;
  }

  .rounded-bottom-end-xxl-pill {
    border-bottom-right-radius: 50rem !important;
  }

  .rounded-bottom-end-xxl-circle {
    border-bottom-right-radius: 50% !important;
  }

  .rounded-bottom-end-xxl-card {
    border-bottom-right-radius: var(--x-border-radius) !important;
  }

  .rounded-bottom-start-xxl {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-xxl-0 {
    border-bottom-left-radius: 0 !important;
  }

  .rounded-bottom-start-xxl-1 {
    border-bottom-left-radius: .25rem !important;
  }

  .rounded-bottom-start-xxl-2 {
    border-bottom-left-radius: .375rem !important;
  }

  .rounded-bottom-start-xxl-3 {
    border-bottom-left-radius: .5rem !important;
  }

  .rounded-bottom-start-xxl-4 {
    border-bottom-left-radius: 1rem !important;
  }

  .rounded-bottom-start-xxl-5 {
    border-bottom-left-radius: 2rem !important;
  }

  .rounded-bottom-start-xxl-pill {
    border-bottom-left-radius: 50rem !important;
  }

  .rounded-bottom-start-xxl-circle {
    border-bottom-left-radius: 50% !important;
  }

  .rounded-bottom-start-xxl-card {
    border-bottom-left-radius: var(--x-border-radius) !important;
  }

  .shadow-soft-xxl-1, .shadow-soft-xxl-1-hover:hover {
    box-shadow: 0 1px 1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xxl-2, .shadow-soft-xxl-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xxl-3, .shadow-soft-xxl-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xxl-4, .shadow-soft-xxl-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xxl-5, .shadow-soft-xxl-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10, 22, 70, .04) !important;
  }

  .shadow-soft-xxl-6, .shadow-soft-xxl-6-hover:hover {
    box-shadow: 0 36px 50px rgba(10, 22, 70, .04) !important;
  }

  .w-rem-xxl-0 {
    width: 0 !important;
  }

  .w-rem-xxl-1 {
    width: .25rem !important;
  }

  .w-rem-xxl-2 {
    width: .5rem !important;
  }

  .w-rem-xxl-3 {
    width: .75rem !important;
  }

  .w-rem-xxl-4 {
    width: 1rem !important;
  }

  .w-rem-xxl-5 {
    width: 1.25rem !important;
  }

  .w-rem-xxl-6 {
    width: 1.5rem !important;
  }

  .w-rem-xxl-7 {
    width: 1.75rem !important;
  }

  .w-rem-xxl-8 {
    width: 2rem !important;
  }

  .w-rem-xxl-10 {
    width: 2.5rem !important;
  }

  .w-rem-xxl-12 {
    width: 3rem !important;
  }

  .w-rem-xxl-14 {
    width: 3.5rem !important;
  }

  .w-rem-xxl-16 {
    width: 4rem !important;
  }

  .w-rem-xxl-18 {
    width: 4.5rem !important;
  }

  .w-rem-xxl-20 {
    width: 5rem !important;
  }

  .w-rem-xxl-24 {
    width: 6rem !important;
  }

  .w-rem-xxl-32 {
    width: 8rem !important;
  }

  .w-rem-xxl-40 {
    width: 10rem !important;
  }

  .w-rem-xxl-48 {
    width: 12rem !important;
  }

  .w-rem-xxl-56 {
    width: 14rem !important;
  }

  .w-rem-xxl-64 {
    width: 16rem !important;
  }

  .w-rem-xxl-72 {
    width: 18rem !important;
  }

  .w-rem-xxl-80 {
    width: 20rem !important;
  }

  .w-rem-xxl-px {
    width: 1px !important;
  }

  .h-rem-xxl-0 {
    height: 0 !important;
  }

  .h-rem-xxl-1 {
    height: .25rem !important;
  }

  .h-rem-xxl-2 {
    height: .5rem !important;
  }

  .h-rem-xxl-3 {
    height: .75rem !important;
  }

  .h-rem-xxl-4 {
    height: 1rem !important;
  }

  .h-rem-xxl-5 {
    height: 1.25rem !important;
  }

  .h-rem-xxl-6 {
    height: 1.5rem !important;
  }

  .h-rem-xxl-7 {
    height: 1.75rem !important;
  }

  .h-rem-xxl-8 {
    height: 2rem !important;
  }

  .h-rem-xxl-10 {
    height: 2.5rem !important;
  }

  .h-rem-xxl-12 {
    height: 3rem !important;
  }

  .h-rem-xxl-14 {
    height: 3.5rem !important;
  }

  .h-rem-xxl-16 {
    height: 4rem !important;
  }

  .h-rem-xxl-18 {
    height: 4.5rem !important;
  }

  .h-rem-xxl-20 {
    height: 5rem !important;
  }

  .h-rem-xxl-24 {
    height: 6rem !important;
  }

  .h-rem-xxl-32 {
    height: 8rem !important;
  }

  .h-rem-xxl-40 {
    height: 10rem !important;
  }

  .h-rem-xxl-48 {
    height: 12rem !important;
  }

  .h-rem-xxl-56 {
    height: 14rem !important;
  }

  .h-rem-xxl-64 {
    height: 16rem !important;
  }

  .h-rem-xxl-72 {
    height: 18rem !important;
  }

  .h-rem-xxl-80 {
    height: 20rem !important;
  }

  .h-rem-xxl-px {
    height: 1px !important;
  }

  .transition-xxl {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .transition-xxl-none {
    transition-property: none !important;
  }

  .transition-xxl-all {
    transition-property: all !important;
  }

  .transition-xxl-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .transition-xxl-opacity {
    transition-property: opacity !important;
  }

  .transition-xxl-shadow {
    transition-property: box-shadow !important;
  }

  .transition-xxl-transform {
    transition-property: transform !important;
  }

  .ease-xxl-base {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .ease-xxl-linear {
    transition-timing-function: linear !important;
  }

  .ease-xxl-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
  }

  .ease-xxl-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
  }

  .ease-xxl-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  }

  .transform-xxl-none {
    transform: none !important;
  }

  .scale-xxl-0, .scale-xxl-0-hover:hover {
    --x-scale-x: 0 !important;
    --x-scale-y: 0 !important;
  }

  .scale-xxl-25, .scale-xxl-25-hover:hover {
    --x-scale-x: .25 !important;
    --x-scale-y: .25 !important;
  }

  .scale-xxl-50, .scale-xxl-50-hover:hover {
    --x-scale-x: .5 !important;
    --x-scale-y: .5 !important;
  }

  .scale-xxl-75, .scale-xxl-75-hover:hover {
    --x-scale-x: .75 !important;
    --x-scale-y: .75 !important;
  }

  .scale-xxl-100, .scale-xxl-100-hover:hover {
    --x-scale-x: 1 !important;
    --x-scale-y: 1 !important;
  }

  .scale-xxl-105, .scale-xxl-105-hover:hover {
    --x-scale-x: 1.05 !important;
    --x-scale-y: 1.05 !important;
  }

  .scale-xxl-110, .scale-xxl-110-hover:hover {
    --x-scale-x: 1.1 !important;
    --x-scale-y: 1.1 !important;
  }

  .scale-xxl-125, .scale-xxl-125-hover:hover {
    --x-scale-x: 1.25 !important;
    --x-scale-y: 1.25 !important;
  }

  .scale-xxl-150, .scale-xxl-150-hover:hover {
    --x-scale-x: 1.5 !important;
    --x-scale-y: 1.5 !important;
  }

  .rotate-xxl-0, .rotate-xxl-0-hover:hover {
    --x-rotate: 0deg !important;
  }

  .rotate-xxl-1, .rotate-xxl-1-hover:hover {
    --x-rotate: 1deg !important;
  }

  .rotate-xxl-2, .rotate-xxl-2-hover:hover {
    --x-rotate: 2deg !important;
  }

  .rotate-xxl-3, .rotate-xxl-3-hover:hover {
    --x-rotate: 3deg !important;
  }

  .rotate-xxl-6, .rotate-xxl-6-hover:hover {
    --x-rotate: 6deg !important;
  }

  .rotate-xxl-12, .rotate-xxl-12-hover:hover {
    --x-rotate: 12deg !important;
  }

  .rotate-xxl-30, .rotate-xxl-30-hover:hover {
    --x-rotate: 30deg !important;
  }

  .rotate-xxl-45, .rotate-xxl-45-hover:hover {
    --x-rotate: 45deg !important;
  }

  .rotate-xxl-90, .rotate-xxl-90-hover:hover {
    --x-rotate: 90deg !important;
  }

  .rotate-xxl-180, .rotate-xxl-180-hover:hover {
    --x-rotate: 180deg !important;
  }

  .rotate-xxl-n1, .rotate-xxl-n1-hover:hover {
    --x-rotate: -1deg !important;
  }

  .rotate-xxl-n2, .rotate-xxl-n2-hover:hover {
    --x-rotate: -2deg !important;
  }

  .rotate-xxl-n3, .rotate-xxl-n3-hover:hover {
    --x-rotate: -3deg !important;
  }

  .rotate-xxl-n6, .rotate-xxl-n6-hover:hover {
    --x-rotate: -6deg !important;
  }

  .rotate-xxl-n12, .rotate-xxl-n12-hover:hover {
    --x-rotate: -12deg !important;
  }

  .rotate-xxl-n30, .rotate-xxl-n30-hover:hover {
    --x-rotate: -30deg !important;
  }

  .rotate-xxl-n45, .rotate-xxl-n45-hover:hover {
    --x-rotate: -45deg !important;
  }

  .rotate-xxl-n90, .rotate-xxl-n90-hover:hover {
    --x-rotate: -90deg !important;
  }

  .rotate-xxl-n180, .rotate-xxl-n180-hover:hover {
    --x-rotate: -180deg !important;
  }

  .rotate-y-xxl-n1, .rotate-y-xxl-n1-hover:hover {
    --x-rotate-y: -1deg !important;
  }

  .rotate-y-xxl-n2, .rotate-y-xxl-n2-hover:hover {
    --x-rotate-y: -2deg !important;
  }

  .rotate-y-xxl-n3, .rotate-y-xxl-n3-hover:hover {
    --x-rotate-y: -3deg !important;
  }

  .rotate-y-xxl-n6, .rotate-y-xxl-n6-hover:hover {
    --x-rotate-y: -6deg !important;
  }

  .rotate-y-xxl-n12, .rotate-y-xxl-n12-hover:hover {
    --x-rotate-y: -12deg !important;
  }

  .rotate-y-xxl-n30, .rotate-y-xxl-n30-hover:hover {
    --x-rotate-y: -30deg !important;
  }

  .rotate-y-xxl-n45, .rotate-y-xxl-n45-hover:hover {
    --x-rotate-y: -45deg !important;
  }

  .rotate-y-xxl-n90, .rotate-y-xxl-n90-hover:hover {
    --x-rotate-y: -90deg !important;
  }

  .rotate-y-xxl-n180, .rotate-y-xxl-n180-hover:hover {
    --x-rotate-y: -180deg !important;
  }

  .rotate-x-xxl-n1 {
    --x-rotate-x: -1deg !important;
  }

  .rotate-x-xxl-n2 {
    --x-rotate-x: -2deg !important;
  }

  .rotate-x-xxl-n3 {
    --x-rotate-x: -3deg !important;
  }

  .rotate-x-xxl-n6 {
    --x-rotate-x: -6deg !important;
  }

  .rotate-x-xxl-n12 {
    --x-rotate-x: -12deg !important;
  }

  .rotate-x-xxl-n30 {
    --x-rotate-x: -30deg !important;
  }

  .rotate-x-xxl-n45 {
    --x-rotate-x: -45deg !important;
  }

  .rotate-x-xxl-n90 {
    --x-rotate-x: -90deg !important;
  }

  .rotate-x-xxl-n180 {
    --x-rotate-x: -180deg !important;
  }

  .translate-y-xxl-0, .translate-y-xxl-0-hover:hover {
    --x-translate-y: 0 !important;
  }

  .translate-y-xxl-25, .translate-y-xxl-25-hover:hover {
    --x-translate-y: 25% !important;
  }

  .translate-y-xxl-50, .translate-y-xxl-50-hover:hover {
    --x-translate-y: 50% !important;
  }

  .translate-y-xxl-75, .translate-y-xxl-75-hover:hover {
    --x-translate-y: 75% !important;
  }

  .translate-y-xxl-100, .translate-y-xxl-100-hover:hover {
    --x-translate-y: 100% !important;
  }

  .translate-y-xxl-n25, .translate-y-xxl-n25-hover:hover {
    --x-translate-y: -25% !important;
  }

  .translate-y-xxl-n50, .translate-y-xxl-n50-hover:hover {
    --x-translate-y: -50% !important;
  }

  .translate-y-xxl-n75, .translate-y-xxl-n75-hover:hover {
    --x-translate-y: -75% !important;
  }

  .translate-y-xxl-n100, .translate-y-xxl-n100-hover:hover {
    --x-translate-y: -100% !important;
  }

  .translate-x-xxl-0, .translate-x-xxl-0-hover:hover {
    --x-translate-x: 0 !important;
  }

  .translate-x-xxl-25, .translate-x-xxl-25-hover:hover {
    --x-translate-x: 25% !important;
  }

  .translate-x-xxl-50, .translate-x-xxl-50-hover:hover {
    --x-translate-x: 50% !important;
  }

  .translate-x-xxl-75, .translate-x-xxl-75-hover:hover {
    --x-translate-x: 75% !important;
  }

  .translate-x-xxl-100, .translate-x-xxl-100-hover:hover {
    --x-translate-x: 100% !important;
  }

  .translate-x-xxl-n25, .translate-x-xxl-n25-hover:hover {
    --x-translate-x: -25% !important;
  }

  .translate-x-xxl-n50, .translate-x-xxl-n50-hover:hover {
    --x-translate-x: -50% !important;
  }

  .translate-x-xxl-n75, .translate-x-xxl-n75-hover:hover {
    --x-translate-x: -75% !important;
  }

  .translate-x-xxl-n100, .translate-x-xxl-n100-hover:hover {
    --x-translate-x: -100% !important;
  }

  .skew-y-xxl-0 {
    --x-skew-y: 0deg !important;
  }

  .skew-y-xxl-1 {
    --x-skew-y: 1deg !important;
  }

  .skew-y-xxl-2 {
    --x-skew-y: 2deg !important;
  }

  .skew-y-xxl-3 {
    --x-skew-y: 3deg !important;
  }

  .skew-y-xxl-6 {
    --x-skew-y: 6deg !important;
  }

  .skew-y-xxl-12 {
    --x-skew-y: 12deg !important;
  }

  .skew-x-xxl-0 {
    --x-skew-x: 0deg !important;
  }

  .skew-x-xxl-1 {
    --x-skew-x: 1deg !important;
  }

  .skew-x-xxl-2 {
    --x-skew-x: 2deg !important;
  }

  .skew-x-xxl-3 {
    --x-skew-x: 3deg !important;
  }

  .skew-x-xxl-6 {
    --x-skew-x: 6deg !important;
  }

  .skew-x-xxl-12 {
    --x-skew-x: 12deg !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.75rem !important;
  }

  .fs-3 {
    font-size: 1.375rem !important;
  }

  .text-xl {
    font-size: 1.5rem !important;
  }

  .text-2xl {
    font-size: 2rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.article, .prose {
  --x-article-font-size: 1rem;
  --x-article-color: var(--x-body-color);
  --x-article-line-height: 1.9;
  --x-article-img-border-radius: var(--x-border-radius);
  --x-article-pre-border-radius: var(--x-border-radius);
  font-size: var(--x-article-font-size);
  color: var(--x-article-color);
  line-height: var(--x-article-line-height);
}

.article > p, .prose > p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.article > h2, .article > .h2, .prose > h2, .prose > .h2 {
  letter-spacing: -.025em;
  white-space: pre-wrap;
  margin-top: 2em;
  margin-bottom: .5em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.article > h2:first-child, .article > .h2:first-child, .prose > h2:first-child, .prose > .h2:first-child {
  margin-top: 1em;
}

.article > h3, .article > .h3, .prose > h3, .prose > .h3 {
  white-space: pre-wrap;
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
}

.article > h4, .article > .h4, .prose > h4, .prose > .h4 {
  margin-top: 2em;
  font-size: 1em;
  font-weight: 500;
}

.article > img, .prose > img {
  border-radius: var(--x-article-img-border-radius);
}

.article > figure, .article > img, .article > video, .prose > figure, .prose > img, .prose > video {
  margin-top: 2em;
  margin-bottom: 2em;
}

.article pre, .prose pre {
  color: #fff;
  white-space: pre-wrap;
  border-radius: var(--x-article-pre-border-radius);
  background-color: #252422;
  max-width: 100%;
  margin-top: 1.75em;
  margin-bottom: 1.75em;
  padding: 1rem;
  font-size: .875em;
  line-height: 1.7;
  overflow-x: auto;
}

.article > :first-child, .prose > :first-child, .article h2 + *, .article .h2 + *, .article h3 + *, .article .h3 + *, .article hr + *, .prose h2 + *, .prose .h2 + *, .prose h3 + *, .prose .h3 + *, .prose hr + * {
  margin-top: 0;
}

.article > hr, .prose > hr {
  margin-top: 3em;
  margin-bottom: 3em;
}

.article > ul, .article > ol, .prose > ul, .prose > ol {
  padding-left: 1.5rem;
}

.article > ul li, .article > ol li, .prose > ul li, .prose > ol li {
  padding: .25rem 0;
}

.article > blockquote, .prose > blockquote {
  color: #16192c;
  letter-spacing: -.012em;
  background-color: var(--x-surface-secondary);
  border-radius: .5rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1.5rem 3.5rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.article > blockquote > p, .prose > blockquote > p {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 991.98px) {
  .section-step-lg {
    padding-bottom: 3rem;
  }

  .section-step-lg + .section-step-lg {
    padding-top: 3rem;
  }

  .section-step-lg:last-child {
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .section-step-lg {
    padding-bottom: 5rem;
    position: relative;
  }

  .section-step-lg + .section-step-lg {
    padding-top: 5rem;
  }

  .section-step-lg:last-child {
    padding-bottom: 0;
  }

  .section-step-lg:not(:last-child):before {
    content: "";
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-dasharray='6,12' stroke-linecap='round'%3E%3Cpath stroke='%23E7EAF0' stroke-width='3' transform='translate(173.245679, 51.548257) scale(-1, 1) translate(-173.245679, -51.548257)' d='M-6.75432109,1.54825684 C113.245679,110.326533 233.245679,130.359459 353.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E") center / 360px 100px no-repeat;
    width: 360px;
    height: 100px;
    margin: auto;
    display: block;
    position: absolute;
    bottom: -80px;
    left: 0;
    right: 0;
    transform: rotate(40deg);
  }
}

@media (min-width: 992px) and (max-width: 767.98px) {
  .section-step-lg:not(:last-child):before {
    bottom: 20px;
    transform: rotate(90deg)scale(.7);
  }
}

@media (min-width: 992px) {
  .section-step-lg:nth-child(odd) .row > div:first-child {
    order: 2;
  }

  .section-step-lg:nth-child(odd) .row > div:last-child {
    order: 1;
  }

  .section-step-lg:nth-child(2n):before {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-dasharray='6,12' stroke-linecap='round'%3E%3Cpath stroke='%23E7EAF0' stroke-width='3' d='M2.24567891,1.54825684 C122.245679,110.326533 242.245679,130.359459 362.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E") center / 360px 100px no-repeat;
    transform: rotate(-40deg);
  }
}

@media (min-width: 992px) and (max-width: 767.98px) {
  .section-step-lg:nth-child(2n):before {
    bottom: 20px;
    transform: rotate(-90deg)scale(.7);
  }
}

.modal-backdrop {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

[data-bs-theme="main"] {
  --x-body-bg: #fff;
  --x-surface-secondary: #f1f5f9;
  --x-surface-secondary-rgb: 241, 245, 249;
  --x-primary: #23305e;
  --x-primary-rgb: 35, 48, 94;
  --x-secondary: #8fbcbb;
  --x-secondary-rgb: 143, 188, 187;
  --x-tertiary: #81a1c1;
  --x-tertiary-rgb: 129, 161, 193;
  --x-dark: #3b4252;
  --x-dark-rgb: 59, 66, 82;
  --x-link-color: rgba(var(--x-primary-rgb), .8);
  --x-link-hover-color: rgba(var(--x-primary-rgb), 1);
  --x-border-color: var(--x-gray-200);
}

[data-bs-theme="main"] .btn-primary {
  --x-btn-bg: var(--x-primary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-primary);
  --x-btn-hover-bg: #2b3b74;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #2b3b74;
  --x-btn-active-bg: #2b3b74;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #2b3b74;
}

[data-bs-theme="main"] .btn-secondary {
  --x-btn-bg: var(--x-secondary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-secondary);
  --x-btn-hover-bg: #a2c7c7;
  --x-btn-hover-color: #000;
  --x-btn-hover-border-color: #a2c7c7;
  --x-btn-active-bg: #a2c7c7;
  --x-btn-active-color: #000;
  --x-btn-active-border-color: #a2c7c7;
}

[data-bs-theme="main"] .btn-tertiary {
  --x-btn-bg: var(--x-tertiary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-tertiary);
  --x-btn-hover-bg: #96b0cb;
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: #96b0cb;
  --x-btn-active-bg: #96b0cb;
  --x-btn-active-color: var(--x-white);
  --x-btn-active-border-color: #96b0cb;
}

[data-bs-theme="main"] .btn-dark {
  --x-btn-bg: var(--x-dark);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-dark);
  --x-btn-hover-bg: #485064;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #485064;
  --x-btn-active-bg: #485064;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #485064;
}

[data-bs-theme="main"] .btn-neutral {
  --x-btn-hover-bg: var(--x-dark);
  --x-btn-hover-border-color: var(--x-dark);
  --x-btn-hover-color: #fff;
}

[data-bs-theme="main"] .navbar {
  --x-navbar-hover-bg: var(--x-gray-200);
  --x-navbar-active-bg: var(--x-gray-200);
}

[data-bs-theme="main"] .card {
  --x-card-border-color: var(--x-border-color);
}

[data-bs-theme="flame"] {
  --x-body-bg: #fafaf9;
  --x-surface-secondary: #f5f5f4;
  --x-surface-secondary-rgb: 245, 245, 244;
  --x-primary: #eb5e28;
  --x-primary-rgb: 235, 94, 40;
  --x-secondary: #ccc5b9;
  --x-secondary-rgb: 204, 197, 185;
  --x-tertiary: #252422;
  --x-tertiary-rgb: 37, 36, 34;
  --x-dark: #252422;
  --x-dark-rgb: 37, 36, 34;
  --x-link-color: rgba(var(--x-primary-rgb), .8);
  --x-link-hover-color: rgba(var(--x-primary-rgb), 1);
  --x-border-color: var(--x-gray-200);
}

[data-bs-theme="flame"] .btn-primary {
  --x-btn-bg: var(--x-primary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-primary);
  --x-btn-hover-bg: #ee7344;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #ee7344;
  --x-btn-active-bg: #ee7344;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #ee7344;
}

[data-bs-theme="flame"] .btn-secondary {
  --x-btn-bg: var(--x-secondary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-secondary);
  --x-btn-hover-bg: #d9d4cb;
  --x-btn-hover-color: #000;
  --x-btn-hover-border-color: #d9d4cb;
  --x-btn-active-bg: #d9d4cb;
  --x-btn-active-color: #000;
  --x-btn-active-border-color: #d9d4cb;
}

[data-bs-theme="flame"] .btn-tertiary {
  --x-btn-bg: var(--x-tertiary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-tertiary);
  --x-btn-hover-bg: #353431;
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: #353431;
  --x-btn-active-bg: #353431;
  --x-btn-active-color: var(--x-white);
  --x-btn-active-border-color: #353431;
}

[data-bs-theme="flame"] .btn-dark {
  --x-btn-bg: var(--x-dark);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-dark);
  --x-btn-hover-bg: #353431;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #353431;
  --x-btn-active-bg: #353431;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #353431;
}

[data-bs-theme="flame"] .btn-neutral {
  --x-btn-hover-bg: var(--x-dark);
  --x-btn-hover-border-color: var(--x-dark);
  --x-btn-hover-color: #fff;
}

[data-bs-theme="flame"] .navbar {
  --x-navbar-hover-bg: var(--x-gray-200);
  --x-navbar-active-bg: var(--x-gray-200);
}

[data-bs-theme="flame"] .card {
  --x-card-border-color: var(--x-border-color);
}

[data-bs-theme="green"] {
  --x-body-bg: #fff;
  --x-surface-secondary: #f9fafb;
  --x-surface-secondary-rgb: 249, 250, 251;
  --x-primary: #386641;
  --x-primary-rgb: 56, 102, 65;
  --x-secondary: #6a994e;
  --x-secondary-rgb: 106, 153, 78;
  --x-tertiary: #bc6c25;
  --x-tertiary-rgb: 188, 108, 37;
  --x-dark: #252422;
  --x-dark-rgb: 37, 36, 34;
  --x-link-color: rgba(var(--x-primary-rgb), .8);
  --x-link-hover-color: rgba(var(--x-primary-rgb), 1);
  --x-border-color: var(--x-gray-200);
}

[data-bs-theme="green"] .btn-primary {
  --x-btn-bg: var(--x-primary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-primary);
  --x-btn-hover-bg: #437a4e;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #437a4e;
  --x-btn-active-bg: #437a4e;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #437a4e;
}

[data-bs-theme="green"] .btn-secondary {
  --x-btn-bg: var(--x-secondary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-secondary);
  --x-btn-hover-bg: #79ab5a;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #79ab5a;
  --x-btn-active-bg: #79ab5a;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #79ab5a;
}

[data-bs-theme="green"] .btn-tertiary {
  --x-btn-bg: var(--x-tertiary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-tertiary);
  --x-btn-hover-bg: #d57b2a;
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: #d57b2a;
  --x-btn-active-bg: #d57b2a;
  --x-btn-active-color: var(--x-white);
  --x-btn-active-border-color: #d57b2a;
}

[data-bs-theme="green"] .btn-dark {
  --x-btn-bg: var(--x-dark);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-dark);
  --x-btn-hover-bg: #353431;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #353431;
  --x-btn-active-bg: #353431;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #353431;
}

[data-bs-theme="green"] .btn-neutral {
  --x-btn-hover-bg: var(--x-dark);
  --x-btn-hover-border-color: var(--x-dark);
  --x-btn-hover-color: #fff;
}

[data-bs-theme="green"] .navbar {
  --x-navbar-hover-bg: var(--x-gray-200);
  --x-navbar-active-bg: var(--x-gray-200);
}

[data-bs-theme="green"] .card {
  --x-card-border-color: var(--x-border-color);
}

[data-bs-theme="blue"] {
  --x-body-bg: #fff;
  --x-surface-secondary: #f1f5f9;
  --x-surface-secondary-rgb: 241, 245, 249;
  --x-primary: #3d5a80;
  --x-primary-rgb: 61, 90, 128;
  --x-secondary: #98c1d9;
  --x-secondary-rgb: 152, 193, 217;
  --x-tertiary: #ee6c4d;
  --x-tertiary-rgb: 238, 108, 77;
  --x-dark: #252422;
  --x-dark-rgb: 37, 36, 34;
  --x-link-color: rgba(var(--x-primary-rgb), .8);
  --x-link-hover-color: rgba(var(--x-primary-rgb), 1);
  --x-border-color: var(--x-gray-200);
}

[data-bs-theme="blue"] .btn-primary {
  --x-btn-bg: var(--x-primary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-primary);
  --x-btn-hover-bg: #476995;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #476995;
  --x-btn-active-bg: #476995;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #476995;
}

[data-bs-theme="blue"] .btn-secondary {
  --x-btn-bg: var(--x-secondary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-secondary);
  --x-btn-hover-bg: #aecee1;
  --x-btn-hover-color: #000;
  --x-btn-hover-border-color: #aecee1;
  --x-btn-active-bg: #aecee1;
  --x-btn-active-color: #000;
  --x-btn-active-border-color: #aecee1;
}

[data-bs-theme="blue"] .btn-tertiary {
  --x-btn-bg: var(--x-tertiary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-tertiary);
  --x-btn-hover-bg: #f18369;
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: #f18369;
  --x-btn-active-bg: #f18369;
  --x-btn-active-color: var(--x-white);
  --x-btn-active-border-color: #f18369;
}

[data-bs-theme="blue"] .btn-dark {
  --x-btn-bg: var(--x-dark);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-dark);
  --x-btn-hover-bg: #353431;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #353431;
  --x-btn-active-bg: #353431;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #353431;
}

[data-bs-theme="blue"] .btn-neutral {
  --x-btn-hover-bg: var(--x-dark);
  --x-btn-hover-border-color: var(--x-dark);
  --x-btn-hover-color: #fff;
}

[data-bs-theme="blue"] .navbar {
  --x-navbar-hover-bg: var(--x-gray-200);
  --x-navbar-active-bg: var(--x-gray-200);
}

[data-bs-theme="blue"] .card {
  --x-card-border-color: var(--x-border-color);
}

[data-bs-theme="purple"] {
  --x-body-bg: #fff;
  --x-surface-secondary: #f1f5f9;
  --x-surface-secondary-rgb: 241, 245, 249;
  --x-primary: #5c0099;
  --x-primary-rgb: 92, 0, 153;
  --x-secondary: #fdc500;
  --x-secondary-rgb: 253, 197, 0;
  --x-tertiary: #fdc500;
  --x-tertiary-rgb: 253, 197, 0;
  --x-dark: #16192c;
  --x-dark-rgb: 22, 25, 44;
  --x-link-color: rgba(var(--x-primary-rgb), .8);
  --x-link-hover-color: rgba(var(--x-primary-rgb), 1);
  --x-border-color: var(--x-gray-200);
}

[data-bs-theme="purple"] .btn-primary {
  --x-btn-bg: var(--x-primary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-primary);
  --x-btn-hover-bg: #6e00b8;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #6e00b8;
  --x-btn-active-bg: #6e00b8;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #6e00b8;
}

[data-bs-theme="purple"] .btn-secondary {
  --x-btn-bg: var(--x-secondary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-secondary);
  --x-btn-hover-bg: #ffcd1d;
  --x-btn-hover-color: #000;
  --x-btn-hover-border-color: #ffcd1d;
  --x-btn-active-bg: #ffcd1d;
  --x-btn-active-color: #000;
  --x-btn-active-border-color: #ffcd1d;
}

[data-bs-theme="purple"] .btn-tertiary {
  --x-btn-bg: var(--x-tertiary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-tertiary);
  --x-btn-hover-bg: #ffcd1d;
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: #ffcd1d;
  --x-btn-active-bg: #ffcd1d;
  --x-btn-active-color: var(--x-white);
  --x-btn-active-border-color: #ffcd1d;
}

[data-bs-theme="purple"] .btn-dark {
  --x-btn-bg: var(--x-dark);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-dark);
  --x-btn-hover-bg: #202540;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #202540;
  --x-btn-active-bg: #202540;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #202540;
}

[data-bs-theme="purple"] .btn-neutral {
  --x-btn-hover-bg: var(--x-dark);
  --x-btn-hover-border-color: var(--x-dark);
  --x-btn-hover-color: #fff;
}

[data-bs-theme="purple"] .navbar {
  --x-navbar-hover-bg: var(--x-gray-200);
  --x-navbar-active-bg: var(--x-gray-200);
}

[data-bs-theme="purple"] .card {
  --x-card-border-color: var(--x-border-color);
}

[data-bs-theme="red"] {
  --x-body-bg: #fff;
  --x-surface-secondary: #f1f5f9;
  --x-surface-secondary-rgb: 241, 245, 249;
  --x-primary: #d62828;
  --x-primary-rgb: 214, 40, 40;
  --x-secondary: #fcbf49;
  --x-secondary-rgb: 252, 191, 73;
  --x-tertiary: #003049;
  --x-tertiary-rgb: 0, 48, 73;
  --x-dark: #16192c;
  --x-dark-rgb: 22, 25, 44;
  --x-link-color: rgba(var(--x-primary-rgb), .8);
  --x-link-hover-color: rgba(var(--x-primary-rgb), 1);
  --x-border-color: var(--x-gray-200);
}

[data-bs-theme="red"] .btn-primary {
  --x-btn-bg: var(--x-primary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-primary);
  --x-btn-hover-bg: #dc4141;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #dc4141;
  --x-btn-active-bg: #dc4141;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #dc4141;
}

[data-bs-theme="red"] .btn-secondary {
  --x-btn-bg: var(--x-secondary);
  --x-btn-color: #000;
  --x-btn-border-color: var(--x-secondary);
  --x-btn-hover-bg: #fcca67;
  --x-btn-hover-color: #000;
  --x-btn-hover-border-color: #fcca67;
  --x-btn-active-bg: #fcca67;
  --x-btn-active-color: #000;
  --x-btn-active-border-color: #fcca67;
}

[data-bs-theme="red"] .btn-tertiary {
  --x-btn-bg: var(--x-tertiary);
  --x-btn-color: #fff;
  --x-btn-border-color: var(--x-tertiary);
  --x-btn-hover-bg: #004468;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #004468;
  --x-btn-active-bg: #004468;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #004468;
}

[data-bs-theme="red"] .btn-dark {
  --x-btn-bg: var(--x-dark);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-dark);
  --x-btn-hover-bg: #202540;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #202540;
  --x-btn-active-bg: #202540;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #202540;
}

[data-bs-theme="red"] .btn-neutral {
  --x-btn-hover-bg: var(--x-gray-800);
  --x-btn-hover-border-color: var(--x-dark);
}

[data-bs-theme="red"] .navbar {
  --x-navbar-hover-bg: var(--x-gray-200);
  --x-navbar-active-bg: var(--x-gray-200);
}

[data-bs-theme="red"] .card {
  --x-card-border-color: var(--x-border-color);
}

[data-bs-theme="yellow"] {
  --x-body-bg: #fff;
  --x-surface-secondary: #fafafa;
  --x-surface-secondary-rgb: 250, 250, 250;
  --x-primary: #eab308;
  --x-primary-rgb: 234, 179, 8;
  --x-secondary: #7678ed;
  --x-secondary-rgb: 118, 120, 237;
  --x-tertiary: #84a59d;
  --x-tertiary-rgb: 132, 165, 157;
  --x-dark: #252422;
  --x-dark-rgb: 37, 36, 34;
  --x-link-color: rgba(var(--x-primary-rgb), .8);
  --x-link-hover-color: rgba(var(--x-primary-rgb), 1);
  --x-border-color: var(--x-gray-200);
}

[data-bs-theme="yellow"] .btn-primary {
  --x-btn-bg: var(--x-primary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-primary);
  --x-btn-hover-bg: #f7c119;
  --x-btn-hover-color: #000;
  --x-btn-hover-border-color: #f7c119;
  --x-btn-active-bg: #f7c119;
  --x-btn-active-color: #000;
  --x-btn-active-border-color: #f7c119;
}

[data-bs-theme="yellow"] .btn-secondary {
  --x-btn-bg: var(--x-secondary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-secondary);
  --x-btn-hover-bg: #9193f1;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #9193f1;
  --x-btn-active-bg: #9193f1;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #9193f1;
}

[data-bs-theme="yellow"] .btn-tertiary {
  --x-btn-bg: var(--x-tertiary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-tertiary);
  --x-btn-hover-bg: #96b2ab;
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: #96b2ab;
  --x-btn-active-bg: #96b2ab;
  --x-btn-active-color: var(--x-white);
  --x-btn-active-border-color: #96b2ab;
}

[data-bs-theme="yellow"] .btn-dark {
  --x-btn-bg: var(--x-dark);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-dark);
  --x-btn-hover-bg: #353431;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #353431;
  --x-btn-active-bg: #353431;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #353431;
}

[data-bs-theme="yellow"] .btn-neutral {
  --x-btn-hover-bg: var(--x-dark);
  --x-btn-hover-border-color: var(--x-dark);
  --x-btn-hover-color: #fff;
}

[data-bs-theme="yellow"] .navbar {
  --x-navbar-hover-bg: var(--x-gray-200);
  --x-navbar-active-bg: var(--x-gray-200);
}

[data-bs-theme="yellow"] .card {
  --x-card-border-color: var(--x-border-color);
}

[data-bs-theme="mauve"] {
  color-scheme: light;
  --x-primary: #6d213c;
  --x-primary-rgb: 109, 33, 60;
  --x-secondary: #faff70;
  --x-secondary-rgb: 250, 255, 112;
  --x-tertiary: #e3c16f;
  --x-tertiary-rgb: 227, 193, 111;
  --x-dark: #16192c;
  --x-dark-rgb: 22, 25, 44;
  --x-link-color: var(--x-primary);
  --x-link-hover-color: #591b31;
}

[data-bs-theme="mauve"] .btn-primary {
  --x-btn-bg: var(--x-primary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-primary);
  --x-btn-hover-bg: #591b31;
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: #591b31;
  --x-btn-active-bg: #4e182b;
  --x-btn-active-color: var(--x-white);
  --x-btn-active-border-color: #4e182b;
}

[data-bs-theme="mauve"] .btn-secondary {
  --x-btn-bg: var(--x-secondary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-secondary);
  --x-btn-hover-bg: #f9ff57;
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: var(--x-btn-hover-bg);
  --x-btn-active-bg: #f9ff47;
  --x-btn-active-color: var(--x-white);
  --x-btn-active-border-color: var(--x-btn-active-bg);
}

[data-bs-theme="mauve"] .btn-tertiary {
  --x-btn-bg: var(--x-tertiary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-tertiary);
  --x-btn-hover-bg: #dfb85a;
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: var(--x-btn-hover-bg);
  --x-btn-active-bg: #dcb24d;
  --x-btn-active-color: var(--x-white);
  --x-btn-active-border-color: var(--x-btn-active-bg);
}

[data-bs-theme="mauve"] .btn-dark {
  --x-btn-bg: var(--x-dark);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-dark);
  --x-btn-hover-bg: #0e0f1b;
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: var(--x-btn-hover-bg);
  --x-btn-active-bg: #080a11;
  --x-btn-active-color: var(--x-white);
  --x-btn-active-border-color: var(--x-btn-active-bg);
}

[data-bs-theme="mauve"] .btn-neutral {
  --x-btn-hover-bg: var(--x-dark);
  --x-btn-hover-border-color: var(--x-dark);
  --x-btn-hover-color: #fff;
}

[data-bs-theme="pink"] {
  --x-body-bg: #fff;
  --x-surface-secondary: #f1f5f9;
  --x-surface-secondary-rgb: 241, 245, 249;
  --x-primary: #e34989;
  --x-primary-rgb: 227, 73, 137;
  --x-secondary: #ffadc6;
  --x-secondary-rgb: 255, 173, 198;
  --x-tertiary: #573c27;
  --x-tertiary-rgb: 87, 60, 39;
  --x-dark: #252422;
  --x-dark-rgb: 37, 36, 34;
  --x-link-color: rgba(var(--x-primary-rgb), .8);
  --x-link-hover-color: rgba(var(--x-primary-rgb), 1);
  --x-border-color: var(--x-gray-200);
}

[data-bs-theme="pink"] .btn-primary {
  --x-btn-bg: var(--x-primary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-primary);
  --x-btn-hover-bg: #e7649a;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #e7649a;
  --x-btn-active-bg: #e7649a;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #e7649a;
}

[data-bs-theme="pink"] .btn-secondary {
  --x-btn-bg: var(--x-secondary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-secondary);
  --x-btn-hover-bg: #ffccdb;
  --x-btn-hover-color: #000;
  --x-btn-hover-border-color: #ffccdb;
  --x-btn-active-bg: #ffccdb;
  --x-btn-active-color: #000;
  --x-btn-active-border-color: #ffccdb;
}

[data-bs-theme="pink"] .btn-tertiary {
  --x-btn-bg: var(--x-tertiary);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-tertiary);
  --x-btn-hover-bg: #6c4b30;
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: #6c4b30;
  --x-btn-active-bg: #6c4b30;
  --x-btn-active-color: var(--x-white);
  --x-btn-active-border-color: #6c4b30;
}

[data-bs-theme="pink"] .btn-dark {
  --x-btn-bg: var(--x-dark);
  --x-btn-color: var(--x-white);
  --x-btn-border-color: var(--x-dark);
  --x-btn-hover-bg: #353431;
  --x-btn-hover-color: #fff;
  --x-btn-hover-border-color: #353431;
  --x-btn-active-bg: #353431;
  --x-btn-active-color: #fff;
  --x-btn-active-border-color: #353431;
}

[data-bs-theme="pink"] .btn-neutral {
  --x-btn-hover-bg: var(--x-dark);
  --x-btn-hover-border-color: var(--x-dark);
  --x-btn-hover-color: #fff;
}

[data-bs-theme="pink"] .navbar {
  --x-navbar-hover-bg: var(--x-gray-200);
  --x-navbar-active-bg: var(--x-gray-200);
}

[data-bs-theme="pink"] .card {
  --x-card-border-color: var(--x-border-color);
}

/*# sourceMappingURL=main.css.map */
