// Functions
@import "bootstrap/scss/functions";

// Variables 
@import './themes/ff';  

// @import "@webpixels/css";    
// @import "@webpixels/css/extras";

@import "../../../webpixels/css/scss";
@import "../../../webpixels/css/scss/extra";     
 
// Core  
// @import "../../../../../webpixels/css/scss/variables";
// @import "../../../../../webpixels/css/scss/maps";
// @import "../../../../../webpixels/css/scss/core/mixins";

// // Components
// @import "../../../../../webpixels/css/scss/base";
// @import "../../../../../webpixels/css/scss/forms";
// @import "../../../../../webpixels/css/scss/components";         

// // Extras
// @import "../../../../../webpixels/css/scss/extras/article";
// @import "../../../../../webpixels/css/scss/extras/section-step";
 
// Custom    
@import "./custom/overrides";   
    
// Themes
[data-bs-theme="main"] {
  @include main;
}

[data-bs-theme="flame"] {
  @include flame;
}

[data-bs-theme="green"] {
  @include green; 
}

[data-bs-theme="blue"] {
  @include blue;
}

[data-bs-theme="purple"] {
  @include purple;
}

[data-bs-theme="red"] {
  @include red;
}

[data-bs-theme="yellow"] {
  @include yellow;
}

[data-bs-theme="mauve"] {
  @include mauve;
}

[data-bs-theme="pink"] {
  @include pink;
}
