//
// Form
//

form {
    margin-block-end: 0;
}

.form-control-muted {
    background-color: $input-muted-bg;
    border-color: $input-muted-bg;
    
    &:focus {
        background-color: $input-muted-focus-bg;
    }
}

.form-control-alt {
    background-color: $input-alt-bg;
    border-color: $input-alt-bg;
    box-shadow: $input-alt-box-shadow;

    &:focus {
        background-color: $input-alt-focus-bg;
        border-color: $input-alt-focus-border-color;
        box-shadow: $input-alt-focus-box-shadow;
    }
}

// Form control flush
// Removes borders and paddings from inputs and text areas

.form-control-flush {
    padding: 0;
    border-width: 0;
    background-color: transparent;
    box-shadow: none;

    &:focus {
        background-color: transparent;
        box-shadow: none;
        border-width: 0;
    } 
}

// Textarea

textarea[resize="none"] {
    resize: none!important;
}

textarea[resize="both"] {
    resize: both!important;
}

textarea[resize="vertical"] {
    resize: vertical!important;
}

textarea[resize="horizontal"] {
    resize: horizontal!important;
}

// Autosize textarea
.textarea-autosize {
    display: grid;

    &:after {
        content: attr(data-replicated-value) " ";
        white-space: pre-wrap;
        visibility: hidden;
    }

    > textarea {
        resize: none;
        overflow: hidden;
    }

    > textarea,
    &:after {
        border: 1px solid black;
        padding: 0.5rem;
        font: inherit;
        grid-area: 1 / 1 / 2 / 2;
    }
}